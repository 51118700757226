import { Autocomplete, IconButton, Popper, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { FilePicker } from "../../../../../Single Components/filePicker";
import { useState } from "react";
import { viewAllMaterialAPICall, viewAllPatternsAPICall, viewAllProductCareAPICall, viewAllReadymadeBrandAPICall } from "../../../../../../API/Product Master/ProductMasterAPI";
import { viewUOMListAPICall, viewUOMSubListAPICall } from "../../../../Purchase Manager/Pages/UnitOfMeasurment/unitOfMeasurmentAPICall";
import HSNC from "../../../../../../Assets/JSON/gstHsnCodes.json"
import { useSelector } from "react-redux";
import { getAllTaxSettingsListAPICall } from "../../../../../../API/Settings/TaxSettings/taxSettingsAPI";
import CountryListWithCode from "../../../../../../Assets/JSON/countryListWithCode.json";
import ReturnPolicy from "../../../../../../Assets/JSON/returnPolicy.json"
import { attributeListAPICall } from "../../../Configuration/Attribute/AttributeAPI";
import { filterObjFromList, validateFileSize } from "../../../../../../Js/generalFunctions";
import ErrorSnackbar from "../../../../../Single Components/SnackBars/ErrorSnackbar";
import { today } from "../../../../../../Js/Date";
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

export const ProductsSwitchInputField = (props) => {

    const { options, editedData, editEnabled,productAdded } = props
    // console.log("switch",editedData);
    const allBrandList = useSelector((state) => state.readymadeBrandSlice.value);
    const uomList = useSelector((state) => state.unitOfMeasurmentSlice.apiResList);
    const uomSubList = useSelector((state) => state.unitOfMeasurmentSlice.subUomList);
    const taxListFull = useSelector((state) => state.taxSettingsSlice.value);
    const taxList=taxListFull?.filter((item)=>item?.status === true)
    const allProductCareList = useSelector((state) => state.productCareSlice.value);
    const allPatternList = useSelector((state) => state.patternSlice.value);
    const allMaterialList = useSelector((state) => state.materialSlice.value);
    const attributeList = useSelector((state) => state?.attributeSlice?.value);
    const trackingList = ["No Tracking", "By Lots", "By Unique Serial No"]
    const hsnFiltered = [...new Set(HSNC.map(item => item.hsn))]

    const [hsCodeFiltered, setHsCodeFiltered] = useState([])
    const [rowIndex, setRowIndex] = useState(-1);

    const formValuesInitialState = {
        barcode: "",
        brand: null,
        uom: null,
        purchaseUom: null,
        cost: 0,
        salesPrice: 0,
        hsCode: null,
        hsnCode: null,
        releaseDate: today,

        nonTaxable:false,
        vendorTax: null,
        customerTax: null,

        tracking: null,
        reorderQty: "",

        materialComp: "",
        productCare: null,
        pattern: null,
        material: null,
        countryOfOrigin: null,
        returnPolicy: null,

        attribute: null
    }
    const [formModified, setFormModified] = useState(false)
    const [formValues, setFormValues] = useState(formValuesInitialState)
    const [validationAlert, setValidationAlert] = useState({})

    const [image1, setImage1] = useState(null);
    const [image2, setImage2] = useState(null);
    const [image3, setImage3] = useState(null);
    const [image4, setImage4] = useState(null);

    const [attributeValueArray, setAttributeValueArray] = useState([])

    console.log(attributeValueArray);
    const [attributeTable, setAttributeTable] = useState([])
    const [purchaseUOMList, setPurchaseUOMList] = useState([])
    const [snackBarStates, setSnackBarStates] = useState({
        success: false,
        error: false,
        message: "",
        alert: false
    });



    const closeSnackbar = () => {
        setSnackBarStates({
            ...snackBarStates,
            success: false,
            error: false,
            alert: false,
            message: "",
        });
    };

    const getImage1 = (e) => {
        let file = e.target.files[0];
        let result = validateFileSize(file, 1, 400);

        if (result?.isValid) {
            setImage1(file);
        } else {
            setSnackBarStates({ ...snackBarStates, message: "Select image with size less than 400 kb,current size " + result?.actualFileSize + " kb", alert: true })
        }
        setFormModified(!formModified)
    };
    //Get Image 2
    const getImage2 = (e) => {
        let file = e.target.files[0];
        let result = validateFileSize(file, 1, 400);

        if (result?.isValid) {
            setImage2(file);
        } else {
            setSnackBarStates({ ...snackBarStates, message: "Select image with size less than 400 kb,current size " + result?.actualFileSize + " kb", alert: true })
        }
        setFormModified(!formModified)
    };
    //Get Image 3
    const getImage3 = (e) => {
        let file = e.target.files[0];
        let result = validateFileSize(file, 1, 400);

        if (result?.isValid) {
            setImage3(file);
        } else {
            setSnackBarStates({ ...snackBarStates, message: "Select image with size less than 400 kb,current size " + result?.actualFileSize + " kb", alert: true })
        }
        setFormModified(!formModified)
    };
    //Get Image 4
    const getImage4 = (e) => {
        let file = e.target.files[0];
        let result = validateFileSize(file, 1, 400);

        if (result?.isValid) {
            setImage4(file);
        } else {
            setSnackBarStates({ ...snackBarStates, message: "Select image with size less than 400 kb,current size " + result?.actualFileSize + " kb", alert: true })
        }
        setFormModified(!formModified)
    };

    const getFormInfo = (key) => (e, newValue) => {
        const { value } = e.target;
        setValidationAlert({});
        if (key === "uom") {
            setFormValues({ ...formValues, uom: newValue, purchaseUom: null });
        }
        else if (key === "hsnCode") {
            setFormValues({ ...formValues, hsnCode: newValue, hsCode: null });
        }

        else {
            setFormValues({ ...formValues, [key]: newValue === undefined ? value : newValue });
        }
        setFormModified(!formModified)
    };
    const addAttributeTable = (key) => () => {

        let clearAttributes = () => {
            setAttributeValueArray([])
            setFormValues({ ...formValues, attribute: null })
            setRowIndex(-1)
        }

        if (formValues?.attribute === null) {
            setValidationAlert({ ...validationAlert, attribute: "select this field" })
        }
        else if (attributeValueArray.length === 0) {
            setValidationAlert({ ...validationAlert, attributeValue: "select this field" })
        }
        else {

            const attributeId = formValues?.attribute?._id;

            // Check if the attributeId already exists in the attributeTable
            const isAttributeIdExist = attributeTable.some(item => item.attributeId === attributeId);


            if(isAttributeIdExist){
                setSnackBarStates({ ...snackBarStates, message: "attribute type already  exist!!", error: true })
            }
            else{
                setAttributeTable((prevTable) => [...prevTable, {
                    attribute: formValues?.attribute?.attributeName,
                    values: attributeValueArray,
                    attributeId: formValues?.attribute?._id
                }])
                clearAttributes()
                setFormModified(!formModified)
            }
        }
    }

    const deleteTableData = () => {
        setAttributeValueArray([])
        setFormValues({ ...formValues, attribute: null })
        setRowIndex(-1)
    }
    const handleClickRow = (data, index) => {
        setRowIndex(index);
        setFormValues({
            ...formValues,
            attribute: filterObjFromList("attributeName", attributeList, "attribute", data)
        });

        setAttributeValueArray(data?.values || []);

        const updatedTable = [
            ...attributeTable.slice(0, index),
            ...attributeTable.slice(index + 1),
        ];
        setAttributeTable(updatedTable);
    };


    useEffect(() => {
        viewAllReadymadeBrandAPICall()
        viewUOMListAPICall()
        getAllTaxSettingsListAPICall();
        viewAllPatternsAPICall()
        viewAllMaterialAPICall()
        viewAllProductCareAPICall()
        attributeListAPICall()
        viewUOMSubListAPICall()
    }, [])

    useEffect(() => {
        let filteredArray = []
        filteredArray = HSNC.filter((obj) =>
            obj.hsn === formValues?.hsnCode
        )
        setHsCodeFiltered(filteredArray)
    }, [formValues?.hsnCode])


    useEffect(() => {
        props.onData({
            formValues: formValues,
            attributeTable: attributeTable?.map((item) => {
                return {
                    attribute: item?.attribute,
                    values: item?.values?.map((r, i) => (r?.value)),
                    _id:item?.attributeId || item?._id
                }
            }),
            image1: image1,
            image2: image2,
            image3: image3,
            image4: image4
        });
    }, [formModified])

    // filter purchase uom from selected uom
    useEffect(() => {
        setPurchaseUOMList(filterObjFromList("id", uomList, "parentCatId", formValues?.uom) || [])
    }, [formValues?.uom])

    // useEffect(() => {
    //     editedData !== undefined && formValues?.purchaseUom === null &&
    //         setFormValues({ ...formValues, purchaseUom: filterObjFromList("_id", purchaseUOMList?.uom, "purchaseuom", editedData) })
    //         setFormModified(!formModified)
    //     }, [purchaseUOMList,editedData?.purchaseuom])

    useEffect(() => {
          if(editedData !== undefined && formValues?.hsCode === null){
              setFormValues({ ...formValues, hsCode: filterObjFromList("hs", hsCodeFiltered, "hsCode", editedData) })
              setFormModified(!formModified)
            }

        }, [hsCodeFiltered])

    useEffect(() => {

        if (editedData !== undefined) {

            

            setFormValues({
                ...formValues,
                isEdit: true,
                barcode: editedData?.barcode || "",
                brand: filterObjFromList("brandName", allBrandList, "brand", editedData),
                uom: filterObjFromList("_id", uomSubList, "uom", editedData),
                cost: editedData?.cost  || 0,
                salesPrice: editedData?.salesPrice || 0,
                hsnCode: editedData?.hsnCode || null,
                releaseDate:typeof editedData.releaseDate === 'number'?editedData?.RELEASEDATE: editedData?.releaseDate,

                nonTaxable:editedData?.nonTaxable,
                vendorTax:filterObjFromList("_id",taxList,"vendorTax",editedData),
                customerTax:filterObjFromList("_id",taxList,"customerTax",editedData),

                tracking: editedData?.tracking || null,
                reorderQty: editedData?.reorderQty || "",

                materialComp: editedData?.materialComposition || "",
                productCare: filterObjFromList("productCareName", allProductCareList, "productCare", editedData),
                pattern: filterObjFromList("patternName", allPatternList, "pattern", editedData),
                material: filterObjFromList("clothMaterialName", allMaterialList, "material", editedData),
                countryOfOrigin: filterObjFromList("name", CountryListWithCode, "origin", editedData),
                returnPolicy: filterObjFromList("name", ReturnPolicy, "returnPolicy", editedData),
                purchaseUom:filterObjFromList("_id", uomSubList, "purchaseuom", editedData)
            })
            setAttributeTable(editedData?.attribute || [])
            setFormModified(!formModified)
        }
        // attribute table
    }, [
        editedData,
        allBrandList,
        uomSubList,
        taxListFull,
        allProductCareList,
        allPatternList,
        allMaterialList
    ])

    useEffect(()=>{
        if(productAdded){

        
            setFormValues(formValuesInitialState)
            setImage1(null)
            setImage2(null)
            setImage3(null)
            setImage4(null)
            setAttributeTable([])
        }

    },[productAdded])

    useEffect(() => {
      const handleBeforeUnload = (event) => {
        const shouldShowAlert = !editEnabled;
        if (shouldShowAlert) {
          event.preventDefault();
        }
      };
  
      window.addEventListener('beforeunload', handleBeforeUnload);
  
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }, [editEnabled]);
    return (

        <div className="global-input-field-container products-info-container">
            {
                options?.option === "General Info" ?
                    <>
                        <div className="global-single-input auto-complete">
                            <p>Barcode</p>
                            <input type="number"
                                value={formValues?.barcode}
                                onChange={getFormInfo("barcode")}
                                disabled={editEnabled}
                            />
                        </div>
                        <div className="global-single-input auto-complete">
                            <p>Brand</p>
                            <Autocomplete
                                options={allBrandList || []}
                                getOptionLabel={(option) => option?.brandName}
                                renderOption={(props, option) => (
                                    <li {...props} key={option._id}>
                                      <span>{option.brandName}</span>
                                    </li>
                                  )}
                                renderInput={(params) => (<TextField
                                    {...params}
                                />)}
                                value={formValues?.brand}
                                onChange={getFormInfo("brand")}
                                disabled={editEnabled}

                            />
                        </div>
                        <div className="global-single-input auto-complete">
                            <p>UOM</p>
                            <Autocomplete
                                options={uomSubList || []}
                                getOptionLabel={(option) => option?.uomName}
                                renderOption={(props, option) => (
                                    <li {...props} key={option._id}>
                                      <span>{option.uomName}</span>
                                    </li>
                                  )}
                                renderInput={(params) => (<TextField
                                    {...params}
                                />)}
                                value={formValues?.uom}
                                onChange={getFormInfo("uom")}
                                disabled={editEnabled}

                            />
                        </div>
                        <div className="global-single-input auto-complete">
                            <p>Purchase UOM</p>
                            <Autocomplete
                                options={purchaseUOMList?.uom || []}
                                getOptionLabel={(option) => option?.uomName}
                                renderOption={(props, option) => (
                                    <li {...props} key={option._id}>
                                      <span>{option.uomName}</span>
                                    </li>
                                  )}
                                renderInput={(params) => (<TextField
                                    {...params}
                                />)}
                                value={formValues?.purchaseUom}
                                onChange={getFormInfo("purchaseUom")}
                                disabled={editEnabled}

                            />
                        </div>
                        <div className="global-single-input auto-complete">
                            <p>Cost</p>
                            <input type="number"
                                value={formValues?.cost}
                                onChange={getFormInfo("cost")}
                                disabled={editEnabled}
                            />
                        </div>
                        <div className="global-single-input auto-complete">
                            <p>Sales Price</p>
                            <input type="number"
                                value={formValues?.salesPrice}
                                onChange={getFormInfo("salesPrice")}
                                disabled={editEnabled}
                            />
                        </div>

                        <div className="global-single-input auto-complete">
                            <p>HSN Code</p>
                            <Autocomplete
                                options={hsnFiltered || []}
                                getOptionLabel={(option) => option}
                                renderOption={(props, option) => (
                                    <li {...props} key={option}>
                                      <span>{option}</span>
                                    </li>
                                  )}
                                renderInput={(params) => (<TextField
                                    {...params}
                                />)}
                                value={formValues?.hsnCode}
                                onChange={getFormInfo("hsnCode")}
                                disabled={editEnabled}

                            />
                        </div>

                        <div className="global-single-input auto-complete">
                            <p>HS Code</p>
                            <Autocomplete
                                options={hsCodeFiltered || []}
                                getOptionLabel={(option) => option?.hs}
                                renderOption={(props, option) => (
                                    <li {...props} key={option?.hs}>
                                      <span>{option?.hs}</span>
                                    </li>
                                  )}
                                renderInput={(params) => (<TextField
                                    {...params}
                                />)}
                                value={formValues?.hsCode}
                                onChange={getFormInfo("hsCode")}
                                disabled={editEnabled}

                            />
                        </div>
                        <div className="global-single-input auto-complete">
                            <p>Release Date</p>
                            <input type="date"
                                value={formValues?.releaseDate}
                                onChange={getFormInfo("releaseDate")}
                                disabled={editEnabled}
                            />
                        </div>
                    </>
                    : options?.option === "Accounting" ?
                      <div style={{display:"flex",flexDirection:"column",width:"100%"}}>
                          <div>
                             <input 
                               type="checkbox" 
                               id="productMasterTaxable" 
                               style={{accentColor:"#000",cursor:"pointer"}}
                               value={formValues?.nonTaxable}
                               onChange={(e)=>
                               { setFormValues({...formValues,nonTaxable:e.target.checked})
                                 setFormModified(!formModified)
                            }
                            }
                                checked={formValues?.nonTaxable===true}
                               />
                            <label htmlFor="productMasterTaxable" style={{fontSize:"0.8rem",fontWeight:"600"}}>Non Taxable</label>
                           </div>
                           {
                            formValues?.nonTaxable !== true &&
                               <div style={{display:"flex",justifyContent:"space-between"}}>
                            
                            <div className="global-single-input auto-complete">
                                <p>Vendor Tax</p>
                                <Autocomplete
                                    options={taxList?.filter(obj=>obj?.taxType === "Purchase") || []}
                                    getOptionLabel={(option) => option?.taxName}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option?._id}>
                                          <span>{ option?.taxName}</span>
                                        </li>
                                      )}
                                    renderInput={(params) => (<TextField
                                        {...params}
                                    />)}
                                    value={formValues?.vendorTax}
                                    onChange={getFormInfo("vendorTax")}
                                    disabled={editEnabled}

                                />
                            </div>
                            <div className="global-single-input auto-complete">
                                <p>Customer Tax</p>
                                <Autocomplete
                                    options={taxList?.filter(obj=>obj?.taxType === "Sales") || []}
                                    getOptionLabel={(option) => option?.taxName}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option?._id}>
                                          <span>{ option?.taxName}</span>
                                        </li>
                                      )}
                                    renderInput={(params) => (<TextField
                                        {...params}
                                    />)}
                                    value={formValues?.customerTax}
                                    onChange={getFormInfo("customerTax")}
                                    disabled={editEnabled}

                                />
                            </div>
                               </div>
                           }
                          
                          </div>
                        : options?.option === "Inventory" ?
                            <>
                                <div className="global-single-input auto-complete">
                                    <p>Tracking</p>
                                    <Autocomplete
                                        options={trackingList}
                                        getOptionLabel={(option) => option}
                                        renderInput={(params) => (<TextField
                                            {...params}
                                        />)}
                                        value={formValues?.tracking}
                                        onChange={getFormInfo("tracking")}
                                        disabled={editEnabled}

                                    />
                                </div>
                                <div className="global-single-input auto-complete">
                                    <p>Reorder Quantity</p>
                                    <input type="number"
                                        value={formValues?.reorderQty}
                                        onChange={getFormInfo("reorderQty")}
                                        disabled={editEnabled}
                                    />
                                </div>
                            </>
                            : options?.option === "Other Info" ?
                                <>
                                    <div className="global-single-input auto-complete">
                                        <p>Material Composition</p>
                                        <input type="text"
                                            value={formValues?.materialComp}
                                            onChange={getFormInfo("materialComp")}
                                            disabled={editEnabled}

                                        />
                                    </div>
                                    <div className="global-single-input auto-complete">
                                        <p>Product Care</p>
                                        <Autocomplete
                                            options={allProductCareList || []}
                                            getOptionLabel={(option) => option?.productCareName}
                                            
                                            renderInput={(params) => (<TextField
                                                {...params}
                                            />)}
                                            value={formValues?.productCare}
                                            onChange={getFormInfo("productCare")}
                                            disabled={editEnabled}

                                        />
                                    </div>
                                    <div className="global-single-input auto-complete">
                                        <p>Pattern</p>
                                        <Autocomplete
                                            options={allPatternList || []}
                                            getOptionLabel={(option) => option?.patternName}
                                            renderInput={(params) => (<TextField
                                                {...params}
                                            />)}
                                            value={formValues?.pattern}
                                            onChange={getFormInfo("pattern")}
                                            disabled={editEnabled}

                                        />
                                    </div>
                                    <div className="global-single-input auto-complete">
                                        <p>Material</p>
                                        <Autocomplete
                                            options={allMaterialList || []}
                                            getOptionLabel={(option) => option?.clothMaterialName}
                                            renderInput={(params) => (<TextField
                                                {...params}
                                            />)}
                                            value={formValues?.material}
                                            onChange={getFormInfo("material")}
                                            disabled={editEnabled}

                                        />
                                    </div>
                                    <div className="global-single-input auto-complete">
                                        <p>Country Of Origin</p>
                                        <Autocomplete
                                            options={CountryListWithCode || []}
                                            getOptionLabel={(option) => option?.name}
                                            renderInput={(params) => (<TextField
                                                {...params}
                                            />)}
                                            value={formValues?.countryOfOrigin}
                                            onChange={getFormInfo("countryOfOrigin")}
                                            disabled={editEnabled}

                                        />
                                    </div>
                                    <div className="global-single-input auto-complete">
                                        <p>Return Policy</p>
                                        <Autocomplete
                                            options={ReturnPolicy || []}
                                            getOptionLabel={(option) => option?.name}
                                            renderInput={(params) => (<TextField
                                                {...params}
                                            />)}
                                            value={formValues?.returnPolicy}
                                            onChange={getFormInfo("returnPolicy")}
                                            disabled={editEnabled}

                                        />
                                    </div>
                                </>
                                : options?.option === "Attribute" ?

                                    <div style={{ width: "100%" }}>
                                        <div style={{ display: "flex", padding: "1%" }}>
                                            <div className="global-single-input auto-complete" style={{ width: "18%" }}>
                                                <p>Attribute</p>
                                                <Autocomplete
                                                    options={attributeList || []}
                                                    getOptionLabel={(option) => option?.attributeName}
                                                    renderInput={(params) => (<TextField
                                                        {...params}
                                                    />)}
                                                    value={formValues?.attribute}
                                                    onChange={getFormInfo("attribute")}
                                                    disabled={editEnabled}

                                                />
                                                <p className="doc-validation-alert">{validationAlert?.attribute}</p>
                                            </div>
                                            <div className="global-single-input auto-complete attribute-multiple-auto-complete" style={{ width: "auto", minWidth: "150px",maxWidth:"300px" }}>
                                                <p>Values</p>
                                                <Autocomplete
                                                    options={formValues?.attribute?.attCatogory || []}
                                                    getOptionLabel={(option) => option?.value}
                                                    renderInput={(params) => (<TextField
                                                        {...params}
                                                    />)}
                                                    multiple
                                                    onChange={(e, newValue) => {
                                                        setAttributeValueArray(newValue)
                                                        setValidationAlert({})
                                                    }}
                                                    value={attributeValueArray}
                                                    disabled={editEnabled}

                                                />
                                                <p className="doc-validation-alert">{validationAlert?.attributeValue}</p>
                                            </div>
                                            {rowIndex !== -1 ? (
                                                <IconButton
                                                    onClick={addAttributeTable("edit")}
                                                    style={{ alignSelf: "flex-end" }}
                                                >
                                                    <i class="bi bi-pencil-square edit-icon1"></i>
                                                </IconButton>
                                            ) : (
                                                <button
                                                    onClick={addAttributeTable("add")}
                                                    style={{ alignSelf: "flex-end" }}
                                                    className="plus-btn"
                                                    disabled={editEnabled}
                                                >
                                                    +
                                                </button>
                                            )}
                                            {rowIndex !== -1 && (
                                                <IconButton
                                                    onClick={deleteTableData}
                                                    style={{ alignSelf: "flex-end" }}
                                                >
                                                    <i
                                                        className="bi bi-trash3"
                                                        style={{ color: "#db0000" }}
                                                    ></i>
                                                </IconButton>
                                            )}
                                        </div>
                                        <div className="global-table-container">
                                            <table className="global-table">
                                                <thead>
                                                    <tr>
                                                        <th>Attribute</th>
                                                        <th>Values</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        attributeTable.length !== 0 ?
                                                            attributeTable?.map((r, i) => (
                                                                <tr
                                                                    key={i}
                                                                    onClick={() =>editEnabled===false && handleClickRow(r, i)}
                                                                >
                                                                    <td>{r?.attribute}</td>
                                                                    {/* {
                                                                        editedData===undefined? */}
                                                                            {/* <td className='table-data-box' style={{ textAlign: "center", padding: "3px" }}>
                                                                                {
                                                                                    r?.values?.map((r, i) => (
                                                                                        <div key={i}>{r}</div>
                                                                                    ))
                                                                                }
                                                                            </td> : */}
                                                                            <td className='table-data-box' style={{ textAlign: "center", padding: "3px" }}>
                                                                               <div className="table-data-round-parent">

                                                                                {
                                                                                    r?.values?.map((r, i) => (
                                                                                        <div className='round-border' key={i}>{r?.value}</div>
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                    {/* } */}
                                                                </tr>
                                                            ))
                                                            :
                                                            <tr>
                                                                <td colSpan={2}>No Attribute added</td>
                                                            </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    :
                                    <div style={{ display: "flex", gap: "15px" }}>
                                        <FilePicker
                                            id="productImg1"
                                            uploadImageUrl={image1}
                                            setImage={setImage1}
                                            onChange={getImage1}
                                        />
                                        <FilePicker
                                            id="productImg2"
                                            uploadImageUrl={image2}
                                            setImage={setImage2}
                                            onChange={getImage2}
                                        />
                                        <FilePicker
                                            id="productImg3"
                                            uploadImageUrl={image3}
                                            setImage={setImage3}
                                            onChange={getImage3}
                                        />
                                        <FilePicker
                                            id="productImg4"
                                            uploadImageUrl={image4}
                                            setImage={setImage4}
                                            onChange={getImage4}
                                        />
                                    </div>
            }


            <ErrorSnackbar
                style={{ top: "8%", left: "34%", height: "20px", width: "auto" }}
                open={snackBarStates.alert}
                handleClose={closeSnackbar}
                message={snackBarStates.message}
            />
            <ErrorSnackbar
                open={snackBarStates.error}
                handleClose={closeSnackbar}
                message={snackBarStates.message}
            />
        </div>
    )
}