import { createFilterOptions } from "@mui/material";


const filterOptionsPaginated = createFilterOptions({
  ignoreCase: true,
  matchFrom: "any",
  limit: 30,
  stringify: (option) => `${option.name} ${option.mobileNo}`,
});

const paginatedProductsList= createFilterOptions({
    ignoreCase: true,
    matchFrom: "any",
    limit: 100,
    stringify: (option) => `${option.productName}`, 
})

export const customerFilterOptions = (options, state) => {
  const inputValue = state.inputValue.replace(/^0+/, ''); // Remove leading zeros
  const filtered = filterOptionsPaginated(options, { ...state, inputValue }); // Use the initial filter options with preprocessed inputValue
  return filtered.filter(option => {
      const searchStr = `${option.name} ${option.mobileNo}`;
      return searchStr.toLowerCase().includes(inputValue.toLowerCase());
  });
};
export const productsFilterOptions = (options, state) => {
  const inputValue = state.inputValue.replace(/^0+/, ''); // Remove leading zeros
  const filtered = paginatedProductsList(options, { ...state, inputValue }); // Use the initial filter options with preprocessed inputValue
  return filtered.filter(option => {
      const searchStr = `${option.productName}`;
      return searchStr.toLowerCase().includes(inputValue.toLowerCase());
  });
};



export const pdfBlobOptionsPrint = {
  filename: 'sample.pdf',
  image: { type: 'jpeg', quality: 1 }, 
  html2canvas: { scale: 2, logging: false }, 
  jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
  };


  export const getTableHeightForVirtuso = (tableData) => {
    if (tableData?.length === 1) return "80px";
    if (tableData?.length < 5) return `${tableData.length * 42 + 36}px`;
    return "260px";
  };