import { IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import ActiveRibbon from "../../../../../Assets/statusRibbon/activeRibbon.png";
import endRibbon from "../../../../../Assets/statusRibbon/endRibbon.png";
import { useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import { useLocation, useNavigate } from "react-router-dom";
import "./posShift.css";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { singleViewShiftAPI } from "../GeneralAPI";
export const PosShiftSingleView = () => {
  const location = useLocation();
  const viewId = location.state?.viewId;

  const { shiftSingleViewData } = useSelector((state) => state.GeneralSlice);

  console.log("shiftSingleViewData", shiftSingleViewData);

  const navigate = useNavigate();
  const [singleView, setSingleView] = useState([]);
  // const singleView=useSelector((state)=>state.generalSlice.shiftListSingleView)
  const handleback = () => {
    navigate("/userdashboard/pos/general/shift");
  };

  //initial api call

  useEffect(() => {
    if (viewId) {
      singleViewShiftAPI({ body: { _id: viewId } });
    } else {
      navigate("/userdashboard/pos/general/shift");
    }
  }, [viewId]);

  return (
    <div className="global-page-parent-container">
      <div className="justify-space-between global-white-bg-container">
        <Tooltip title="Back">
          <IconButton onClick={handleback}>
            <i
              className="bi bi-chevron-left"
              style={{ fontSize: "30px", color: "black", cursor: "pointer" }}
            ></i>
          </IconButton>
        </Tooltip>
        {/* <h5 style={{ margin: "0" ,cursor:"pointer"}}onClick={handleback}>SHIFT</h5> */}

        <div
          className="justify-center"
          style={{ gap: "10px", width: "70%", justifyContent: "flex-end" }}
        >
          <ReactToPrint
            trigger={() => (
              <IconButton
                className="printer-div"
                style={{
                  color: "#fff",
                  backgroundColor: "#002995",
                  borderRadius: "0",
                }}
              >
                <i class="bi bi-printer"></i>
              </IconButton>
            )}
            //   content={() => paymentsPrint}
          />
          <Tooltip title="Download">
            <IconButton
              style={{
                backgroundColor: "#ffd2d2",
                color: "#002995",
                borderRadius: "0",
              }}
            >
              <i class="bi bi-arrow-down-circle"></i>
            </IconButton>
          </Tooltip>
        </div>
      </div>

      <div className="global-white-bg-container">
        <div className="ribbon-ctn">
          <img
            src={
              shiftSingleViewData?.status === "active"
                ? ActiveRibbon
                : endRibbon
            }
            alt=""
          />
        </div>
        <div className=" single-viewinfo-parent-ctn">
          <div className="single-view-left-ctn">
            <h3 style={{ margin: "0", cursor: "pointer", fontSize: "larger" }}>
              {shiftSingleViewData?.shiftId || ""}
            </h3>

            <div className="details-pos-shift-cntr">
              <div className="single-view-info-ctn-left flex-column">
                <p>Branch</p>
                <p>Employee</p>
                <p>Opening Date</p>
                <p>Closing Date</p>
              </div>
              <div className="single-view-info-ctn-right flex-column">
                <p>{shiftSingleViewData?.branchName || "---"}</p>
                <p>{shiftSingleViewData?.employee || "---"}</p>
                <p>{shiftSingleViewData?.openingTime || "---"}</p>
                <p>{shiftSingleViewData?.closingTime || "---"}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="shift-bottom-container">
        <div className="shift-info-card margin-right">
          <div className="icon-label-conatiner">
            <ShoppingCartOutlinedIcon
              style={{ color: "#0c008b", fontSize: "2.3rem" }}
            />
            <p>Orders</p>
          </div>
          <p>{shiftSingleViewData?.orderCount || 0}</p>
        </div>
        <div className="shift-info-card margin-right">
          <div className="icon-label-conatiner">
            <LocalShippingOutlinedIcon
              style={{ color: "#870f01", fontSize: "2.3rem" }}
            />
            <p>Delivery</p>
          </div>
          <p>{shiftSingleViewData?.deliveryCount || 0}</p>
        </div>
        <div className="shift-info-card margin-right">
          <div className="icon-label-conatiner">
            <PaymentOutlinedIcon
              style={{
                color: "#00730f",
                fontSize: "2.3rem",
                fontSize: "2.3rem",
              }}
            />
            <p>Payments</p>
          </div>
          <p>{shiftSingleViewData?.paymentCount || 0}</p>
        </div>
        <div className="shift-info-card margin-right">
          <div className="icon-label-conatiner">
            <AccountBalanceWalletOutlinedIcon
              style={{ color: "#dbac00", fontSize: "2.3rem" }}
            />
            <p>Wallet</p>
          </div>
          <p>{shiftSingleViewData?.walletCount || 0}</p>
        </div>
        <div className="shift-info-card">
          <div className="icon-label-conatiner">
            <KeyboardReturnIcon style={{ color: "red", fontSize: "2.3rem" }} />
            <p>Return</p>
          </div>
          <p>{shiftSingleViewData?.returnCount || 0}</p>
        </div>
      </div>
      <div style={{ marginTop: "8px", backgroundColor: "white" }}>
        <h1 style={{ margin: "0", padding: "19px", fontSize: "medium" }}>
          Payment Summery
        </h1>
        <div className="new-global-table-container">
          <table>
            <thead>
              <tr>
                <th>Payment Methord</th>
                <th>IN</th>
                <th>OUT</th>
                <th>Balance</th>
              </tr>
            </thead>
            <tbody>
              {shiftSingleViewData?.paymentSummary?.list?.length > 0 ? (
                shiftSingleViewData?.paymentSummary?.list?.map((r, i) => (
                  <tr
                    key={i}
                    style={{
                      backgroundColor: i % 2 === 0 ? "white" : "#e9e7e7",
                    }}>
                    <td style={{ border: "none", height: "29px" }}>{r.type}</td>
                    <td style={{ border: "none", height: "29px" }}>{r.in}</td>
                    <td style={{ border: "none", height: "29px" }}>{r.out}</td>
                    <td style={{ border: "none", height: "29px" }}>
                      {r.balance}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4}>NO DATA</td>
                </tr>
              )}
            </tbody>
            <tfoot>
              <tr>
                <td
                  colSpan={2}
                  style={{ textAlignLast: "end", paddingRight: "51px" }}
                >
                  {singleView?.paymentSummary?.inTot}
                </td>
                <td colSpan={1} style={{ textAlignLast: "center" }}>
                  {singleView?.paymentSummary?.outTot}
                </td>
                <td colSpan={1} style={{ textAlignLast: "center" }}>
                  {singleView?.paymentSummary?.balTot}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
};
