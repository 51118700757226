import React from 'react'
import { useSelector } from 'react-redux'

export const WOCutterDetails = () => {
  const singleList = useSelector((state) => state?.workOrderNewList?.workorderCutterData)
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(); // Returns date in format: MM/DD/YYYY or based on locale settings
  };

  const hasData = singleList?.list && singleList.list.length > 0;

  return (
    <div className="new-global-table-container work-order-tab-table" style={{ maxHeight: "48vh", border: "1px solid #000" }}>
      <table>
        <thead>
          <tr>
            <th>Product</th>
            <th>Customer</th>
            <th>Cutter</th>
            <th>Qty</th>

            <th>Cunsumed Qty</th>

            <th>Commission</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          { hasData ? singleList?.list?.map((r, i) => (
              <tr key={i}>
                <td>{r?.productName}</td>
                <td>{r?.customer}</td>
                <td>{r?.cutterName}</td>
                <td>{r?.Quantity}</td>
                <td>{r?.consumedQuantity}</td>
                <td>{r?.commission}</td>
                <td>{formatDate(r?.date)}</td>
              </tr>
            )) : (
              <tr>
                <td colSpan={7}>NO DATA</td>
              </tr>
            )
          }


        </tbody>



      </table>
    </div>
  )
}
