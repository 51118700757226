import { createSlice } from "@reduxjs/toolkit";


export const neworderSlice=createSlice({
    name:"neworderslice",
    initialState:{
        customerList:undefined,
        relationList:undefined,
        customerSingleView:undefined,
        productListByStock:undefined,
        generateWoNo:undefined,
        workOrderSingleView:undefined,
        getcustomerOnSearch:undefined,
        getOrderListBasedOnCustomer:undefined
    },
    reducers:{
        get_customer_list:(state,action)=>{
            state.customerList=action.payload.customerData
        },
        get_relation_list:(state,action)=>{
            state.relationList=action.payload.relationData
        },
        get_single_customer:(state,action)=>{
            state.customerSingleView=action.payload.singleCustomerData
        },
        get_all_productList_by_stock:(state,action)=>{
            state.productListByStock=action.payload.productListByStock
        },
        generate_workorderno:(state,action)=>{
            state.generateWoNo=action.payload
        },
        get_workorder_singleview:(state,action)=>{
            state.workOrderSingleView=action.payload
        },
        get_customer_on_search:(state,action)=>{
            state.getcustomerOnSearch=action.payload
        },
        get_orderlist_basedon_customer:(state,action)=>{
            state.getOrderListBasedOnCustomer=action.payload
        }
    }

})
export const {
     get_customer_list,
     get_relation_list,
     get_single_customer,
     get_all_productList_by_stock,
     generate_workorderno,
     get_workorder_singleview,
     get_customer_on_search,
     get_orderlist_basedon_customer
    }=neworderSlice.actions
export default neworderSlice.reducer