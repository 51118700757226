import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import { Autocomplete, TextField } from '@mui/material';
import { switch_to_pos_screen } from '../PosNewSlice';

export const CreateAlteration = () => {
    const dispatch = useDispatch();
    const navigate=useNavigate()

    const demo=[
        {customer:"Nihad",mobile:"1111",lastOrder:""},
        {customer:"Nihad",mobile:"1111",lastOrder:""},
        {customer:"Nihad",mobile:"1111",lastOrder:""},
        {customer:"Nihad",mobile:"1111",lastOrder:""},
        {customer:"Nihad",mobile:"1111",lastOrder:""},
        {customer:"Nihad",mobile:"1111",lastOrder:""},
        {customer:"Nihad",mobile:"1111",lastOrder:""},
        {customer:"Nihad",mobile:"1111",lastOrder:""},
        {customer:"Nihad",mobile:"1111",lastOrder:""},
        {customer:"Nihad",mobile:"1111",lastOrder:""},
        {customer:"Nihad",mobile:"1111",lastOrder:""},
        {customer:"Nihad",mobile:"1111",lastOrder:""},
        {customer:"Nihad",mobile:"1111",lastOrder:""},
        {customer:"Nihad",mobile:"1111",lastOrder:""},
        {customer:"Nihad",mobile:"1111",lastOrder:""},
        {customer:"Nihad",mobile:"1111",lastOrder:""},
      ]

    const homeBtnClickFn=()=>{
        navigate("/userdashboard/posnew")
      }

    useEffect(() => {
        dispatch(switch_to_pos_screen({ posScreenState: true }));
      }, []);
  return (
    <div className='pos-conatiner'>
      <div className='new-order-top-container'>
        <div className='new-order-top-left'>
           <div className='new-order-top-left-home' onClick={homeBtnClickFn}>
             <HomeIcon/> 
           </div>
           <p className='new-order-top-left-p'>ALTERATION</p>
        </div>
        <div className='new-order-top-right'>
       
          <div className='new-order-top-right-btn'  style={{alignItems:"center"}}>
          <div className='return-dialog-top-right-toggle'>
                <p>Old order</p>
                <input
                  className="toggle-checkbox"
                  type={"checkbox"}
                />
            </div>
            <button className='pos-status-blue-btn'>Payment</button>
            <button className='pos-status-blue-btn'>Place Order</button>
            <button className='pos-status-blue-btn pos-return-btn'>Clear</button>
          </div>
        </div>
      </div>
      <hr className='pos-hr' />
      <div className='new-order-second-container'>
         <div className='new-order-second-left'>
            <div className="new-global-single-input" style={{width:"45%",margin:"12px"}}>
              <TextField
                id="outlined-basic"
                label="Order No"
                variant="outlined"
                focused
              />
            </div>
            <div className="new-global-single-input" style={{width:"45%",margin:"12px"}}>
              <TextField
                type='date'
                id="outlined-basic"
                label="Order Date"
                variant="outlined"
                focused
              />
            </div>
            <div className="new-global-single-input" style={{width:"45%",margin:"12px"}}>
              <TextField
                type='date'
                id="outlined-basic"
                label="Delivery Date"
                variant="outlined"
                focused
              />
            </div>
            <div className="new-global-single-input" style={{width:"45%",margin:"12px"}}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={[]}
                getOptionLabel={(option) =>
                  option
                }
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Customer" focused />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    <span>{option}</span>
                  </li>
                )}
              />
              {/* <p className="doc-validation-alert" style={{color:"blue",cursor:"pointer"}}>Add Customer</p> */}
            </div>
            <div className="new-global-single-input" style={{width:"45%",margin:"12px"}}>
              <TextField
                type='text'
                id="outlined-basic"
                label="Customer Address"
                variant="outlined"
                focused
              />
            </div>
            <div className="new-global-single-input" style={{width:"45%",margin:"12px"}}>
              <TextField
                type='text'
                id="outlined-basic"
                label="Contact Number"
                variant="outlined"
                focused
              />
            </div>
         </div>
         <div className='border-right'></div>
         <div className='new-order-second-right'>
            <div className="new-global-single-input new-order-second-right-input">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={[]}
                getOptionLabel={(option) =>
                  option
                }
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Work Order" focused />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    <span>{option}</span>
                  </li>
                )}
              />
             
            </div>
            <div className="new-global-single-input new-order-second-right-input">
              <TextField
                type='text'
                id="outlined-basic"
                label="Barcode"
                variant="outlined"
                focused
              />
            </div>
            <div className="new-global-single-input new-order-second-right-input">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={[]}
                getOptionLabel={(option) =>
                  option
                }
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Product" focused />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    <span>{option}</span>
                  </li>
                )}
              />
               <p className="doc-validation-alert" style={{color:"blue",cursor:"pointer",top:"84%"}}>Available Qty 100 MTR</p>
            </div>
            <div className="new-global-single-input new-order-second-right-input">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={[]}
                getOptionLabel={(option) =>
                  option
                }
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Product Color" focused />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    <span>{option}</span>
                  </li>
                )}
              />
              
            </div>
            <div className="new-global-single-input new-order-second-right-input">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={[]}
                getOptionLabel={(option) =>
                  option
                }
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Type" focused />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    <span>{option}</span>
                  </li>
                )}
              />
              
            </div>
            <div className="new-global-single-input new-order-second-right-input">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={[]}
                getOptionLabel={(option) =>
                  option
                }
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Model" focused />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    <span>{option}</span>
                  </li>
                )}
              />
              
            </div>
            <div className="new-global-single-input new-order-second-right-input">
              <TextField
                type='text'
                id="outlined-basic"
                label="Qty"
                variant="outlined"
                focused
              />
            </div>
            <div className="new-global-single-input new-order-second-right-input">
              <TextField
                type='text'
                id="outlined-basic"
                label="Item Rate"
                variant="outlined"
                focused
              />
            </div>
            <div className="new-global-single-input new-order-second-right-input">
              <TextField
                type='text'
                id="outlined-basic"
                label="Stitch Rate"
                variant="outlined"
                focused
              />
            </div>
            <div className="new-global-single-input new-order-second-right-input">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={[]}
                getOptionLabel={(option) =>
                  option
                }
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Taxes" focused />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    <span>{option}</span>
                  </li>
                )}
              />
               
            </div>
            <div className="new-global-single-input new-order-second-right-input">
              <TextField
                type='text'
                id="outlined-basic"
                label="Amount"
                variant="outlined"
                focused
              />
            </div>
            <button className='new-order-second-right-add-btn'>ADD</button>
         </div>
      </div>
      <div className="new-global-table-container pos-table" style={{ paddingTop: "0 42px",maxHeight:"56vh" }}>
       <table>
        <thead>
            <tr>
                <th>CUSTOMER</th>
                <th>PRODUCT</th>
                <th>COLOR</th>
                <th>TYPE</th>
                <th>MODEL</th>
                <th>QTY</th>
                <th>ITEM RATE</th>
                <th>STITCH RATE</th>
                <th>TAX</th>
                <th>AMOUNT</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
        {
            demo?.map((r,i)=>(
              <tr key={i} style={{ backgroundColor: i % 2 === 0 ? "white" : "#e9e7e7" }}>
                <td>{r?.customer}</td>
                <td>{r?.customer}</td>
                <td>{r?.customer}</td>
                <td>{r?.customer}</td>
                <td>{r?.customer}</td>
                <td>{r?.customer}</td>
                <td>{r?.customer}</td>
                <td>{r?.customer}</td>
                <td>{r?.customer}</td>
                <td>{r?.customer}</td>
                <td>
                   <i class="bi bi-pencil-square edit-icon"></i>
                   
                   <i class="bi bi-trash3 delete-icon"></i>
                </td>
            </tr>
            ))
          }
          <tr style={{ backgroundColor: "rgb(169 168 168)",height:"50px" }}>
             <td style={{ border: "none", fontSize: "1rem", fontWeight: 600 }}>Total</td>
             <td style={{ border: "none" }} colSpan="4"></td>
             <td style={{ border: "none" }}>10</td>
             <td style={{ border: "none" }}>10</td>
             <td style={{ border: "none" }}>10</td>
             <td style={{ border: "none" }}></td>
             <td style={{ border: "none" }}>10</td>
             <td style={{ border: "none" }}></td>
         </tr>

        </tbody>
        {/* <tfoot style={{position:"sticky",bottom:0,textAlign:"center",height:"50px"}}>
          <tr style={{ backgroundColor: "rgb(169 168 168)" }}>
           <td style={{ border: "none", fontSize: "1rem", fontWeight: 600 }}>Total</td>
           <td style={{ border: "none" }} colSpan="4"></td>
           <td style={{ border: "none" }}>10</td>
           <td style={{ border: "none" }}>10</td>
           <td style={{ border: "none" }}>10</td>
           <td style={{ border: "none" }}></td>
           <td style={{ border: "none" }}>10</td>
           <td style={{ border: "none" }}></td>
         </tr>
        </tfoot> */}
       
          
           
         </table>
       </div>
{/* 
       <Dialog open={openDialog==="Customer"} maxWidth="lg">
           <AddCustomer/>
       </Dialog> */}
    </div>
  )
}
