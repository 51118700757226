import React, { useEffect, useState } from 'react'

import CategorySearchandFilter from '../../../Single Components/CategorySearchandFilter'
import { useSelector } from 'react-redux';
import { filterObjFromList } from '../../../../Js/generalFunctions';
import { companyProfileInfoForUser } from '../../../../API/Settings/Company Settings/companySettingsAPI';
import { viewAllCurrencyUpdated } from '../../../../API/Settings/Currency/CurrencyAPI';
import { SalesReportAPIcall } from './SalesReportApi';
import { SalesReport } from '../../Report/SalesReport/SalesReport';
import { getCurrentTime } from '../../../../Js/Date';
import { useNavigate } from 'react-router-dom';
import { setFilterActive } from '../../../Single Components/listSlice/filterCategorySlice';
import store from '../../../../Redux/store';
import TableLoading from "../../../Single Components/TableLoding"


export const SalesNewReport = () => {
   let navigate = useNavigate();
   const [symbol, setSymbol] = useState(null);
   const [FormDataInfo, setFormDataInfo] = useState([])
  const [isLoading, setIsLoading] = useState(false);

   const filtereDataOptions = useSelector((state) => state.listCategory);
   const decimalPosition = localStorage.getItem("decimalPosition");
   const decimalPositionStock = localStorage.getItem("decimalPositionStock");
   const [selectedBranch, setSelectedBranch] = useState(null);
   const SalesReport = useSelector((state) => state?.salesReport?.salesReportlist)
   console.log(SalesReport);
   const currencyListUpdated = useSelector(
      (state) => state?.viewUpdatedCurrency?.value
   );
   const companyProfileData = useSelector(
      (state) => state?.companyProfileSlice?.userValue
   );
   const handleDataFromChild = (data) => {
      setFormDataInfo(data)
   };
   const handleBranchSelect = (selectedBranch) => {
      setSelectedBranch(selectedBranch);

   };

   const handleClickRow = (row) => {
      store.dispatch(setFilterActive(window.location.pathname))
      localStorage.setItem("singleViewById", row?._id)
      localStorage.setItem("fromDate", FormDataInfo?.fromDate);
      localStorage.setItem("toDate", FormDataInfo?.toDate);
      localStorage.setItem("account", row?.itemName)
      navigate("/userdashboard/sales/Report/salesReportSingleView")
   }

   useEffect(() => {
      let currencyObj = filterObjFromList(
         "_id",
         currencyListUpdated,
         "currency",
         companyProfileData
      );
      // console.log(currencyObj);
      setSymbol(currencyObj?.symbol);
   }, [companyProfileData, currencyListUpdated]);

   useEffect(() => {
      viewAllCurrencyUpdated({ status: 0 });
      companyProfileInfoForUser();
   }, []);
   useEffect(() => {
      if (FormDataInfo?.length !== 0) {
         let body = {
            fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} 00:00:00`,
            endDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} 23:59:59`,
            branchId: FormDataInfo?.branchList?.length === 0 ? '' : FormDataInfo?.branchList[0],
            search: FormDataInfo?.search,
         }

         let loadData = window.location.pathname === filtereDataOptions?.filterActive
         !loadData && SalesReportAPIcall({body,setIsLoading})
      }
   }, [FormDataInfo])

   return (
      <div className="global-page-parent-container">
         <div className="global-white-bg-container">
            <div className='justify-space-between'>
               <div className="sharp-arrow" style={{ width: "105Px" }}> <p>Sales Report</p></div>
               <div>
                  <CategorySearchandFilter
                     statusOptions={['PURCHASE ORDER', 'DONE', 'CANCEL']}
                     onData={handleDataFromChild}
                     onBranchSelect={handleBranchSelect}
                  />

               </div>
            </div>

            <h1 style={{ margin: "1px", fontSize: "large" }}>Sales Report</h1>

            <div className='global-report-table-container'>
               <table>
                  <thead>
                     <tr>
                        <th>Item</th>
                        <th>UOM</th>
                        {/* <th>Opening Qty</th> */}
                        <th>Sold Qty</th>
                        <th>Return Qty</th>
                        <th>Closing item</th>
                        <th>Gross Item Sales</th>
                        <th>Total Item Cost</th>
                        <th>Item Profit</th>
                        <th>Gross Profit Per Unit</th>
                     </tr>
                  </thead>
                  {isLoading ? (
                <TableLoading  colSpan={7} rowcount={10}/>
              ) : (
                  <tbody>
                     {SalesReport !== undefined && SalesReport?.length !== 0 ? SalesReport?.map((item, index) => (
                        <tr key={index} onClick={() => handleClickRow(item)}>
                           <td>{item.itemName}</td>
                           <td>{item.uom}</td>
                           {/* <td>{item.openingQty?.toFixed(decimalPositionStock)}</td> */}
                           <td>{item.soldQty?.toFixed(decimalPositionStock)}</td>
                           <td>{item.salesRet?.toFixed(decimalPositionStock)}</td>
                           <td>{item.closingSale?.toFixed(decimalPosition)}</td>
                           <td>{item.grossItemSale?.toFixed(decimalPosition)}</td>
                           <td>{item.grossItemCost?.toFixed(decimalPosition)}</td>
                           <td>{item.grossprofit?.toFixed(decimalPosition)}</td>
                           <td>{item.grossprofitperunit?.toFixed(decimalPosition)}</td>
                        </tr>
                     )) : (
                        <tr>
                           <td colSpan={9}>NO DATA</td>
                        </tr>
                     )}
                  </tbody>
              )}
               </table>
            </div>
         </div>
      </div>
   )
}
