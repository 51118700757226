import React, { useEffect, useState } from "react";
import CategorySearchandFilter from "../../../../Single Components/CategorySearchandFilter";
import ReactToPrint from "react-to-print";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { switch_to_pos_screen } from "../../PosNew/PosNewSlice";
import HomeIcon from "@mui/icons-material/Home";
import { IconButton, Tooltip } from "@mui/material";
import CustomPagination from "../../../../Single Components/CustomPagination";
import { listBillingAPI } from "../GeneralAPI";
import TableLoading from "../../../../Single Components/TableLoding"

const GeneralBilling = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredDatas, setFilteredData] = useState({});
  const [isLoading, setIsLoading] = useState(false);



  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const homeBtnClickFn = () => {
    navigate("/userdashboard/dashboardhome");
    dispatch(switch_to_pos_screen({ posScreenState: false }));
  };
 const billingList=useSelector((state)=>state?.GeneralSlice?.billingListData)
 const totalPages = billingList?.pages;

 const decimalPosition = localStorage.getItem("decimalPosition");
 

 const filtereDataOptions = useSelector((state) => state.listCategory);
 

 const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString(); // Returns date in format: MM/DD/YYYY or based on locale settings
};
 
 
  const handleClickRow = (row) => {
    navigate("/userdashboard/pos/general/billing/singleview", { state: {singleViewById: row._id} });
  };
  
  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
   };

  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  };

  useEffect(()=>{
    if (FormDataInfo.length !== 0) {
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
    !loadData && listBillingAPI({    
        branchList: FormDataInfo?.branchList?.length ===0 ? '' :FormDataInfo?.branchList[0] ,
        shiftId:FormDataInfo?.shiftCode?FormDataInfo?.shiftCode:'',
        status: FormDataInfo?.status?.length === 0 ? '' : FormDataInfo?.status[0],
        search: FormDataInfo?.search,
        customer:FormDataInfo?.customer?.name,
        index: currentPage-1,
      },setIsLoading);
    }
  },[FormDataInfo, currentPage])

  return (
    <div className="pos-conatiner" style={{ backgroundColor: "unset", padding: "0" }}>
      <div className="new-order-top-container" style={{ padding: "0", backgroundColor: "white", paddingInline: "10px" }}>

        <div className="new-order-top-left">
          <strong>BILLING</strong>
        </div>

        {/* <h5 style={{ margin: "0" ,cursor:"pointer"}}onClick={handleback}>SHIFT</h5> */}

        <div
          className="justify-center"
          style={{ gap: "10px", width: "70%", justifyContent: "flex-end" }}
        >
          <button
            className="pos-new-order-btn"
            onClick={() =>
              navigate("/userdashboard/pos/general/billing/create")
            }
          >
            {" "}
            <span>New Order</span>
          </button>
          <ReactToPrint
            trigger={() => (
              <IconButton
                className="printer-div"
                style={{
                  color: "#fff",
                  backgroundColor: "#002995",
                  borderRadius: "0",
                }}
              >
                <i class="bi bi-printer"></i>
              </IconButton>
            )}
          //   content={() => paymentsPrint}
          />
          <Tooltip title="Download">
            <IconButton
              style={{
                backgroundColor: "#ffd2d2",
                color: "#002995",
                borderRadius: "0",
              }}
            >
              <i class="bi bi-arrow-down-circle"></i>
            </IconButton>
          </Tooltip>
          <CategorySearchandFilter
           statusOptions={['DRAFT','CANCEL']}
            onData={handleDataFromChild}
            isShiftCode={true}
            onBranchSelect={handleBranchSelect} 
            isStatus={true}
            isSearch={true}
            isDate={false}
            isCustomer={true}

          />
        </div>
      </div>
      <div className="flex-column table-div-container" >
        <div className='pagi-div'>
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            setFormDataInfo={setFormDataInfo}
          />
        </div>
        <div
          className="new-global-table-container pos-table"
          style={{ paddingTop: "0 42px", maxHeight: "calc(100vh - 196px)" }}
        >
          <table>
            <thead>
              <tr>
                <th>DATE</th>
                <th>ORDER NO</th>
                <th>SHIFT</th>
                <th>EMPLOYEE</th>
                <th>CUSTOMER</th>
                <th>MOBILE</th>
                <th>ORDER AMOUNT</th>
                <th>AMOUNT DUE</th>
                <th>STATUS</th>
              </tr>
            </thead>
            {isLoading ? (
                <TableLoading  colSpan={9} rowcount={10}/>
              ) : (
            <tbody>
              {billingList?.length!==0 ? billingList?.map((r, i) => (
                <tr
                  onClick={() => handleClickRow(r)}
                  key={i}
                  style={{ backgroundColor: i % 2 === 0 ? "white" : "#e9e7e7" }}>
                  <td>{formatDate(r?.date)}</td>
                  <td>{r?.orderNo}</td>
                  <td>{r?.shift}</td>
                  <td>{r?.employee}</td>
                  <td>{r?.customer}</td>
                  <td>{r?.mobile}</td>
                  <td>{r?.orderAmount?.toFixed(decimalPosition)}</td>
                  <td>{r?.amountDue?.toFixed(decimalPosition)}</td>
                  <td>{r?.status}</td>
                </tr>
              )): 
              <tr>
                <td colSpan={9}>NO DATA</td></tr>}
            </tbody>
              )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default GeneralBilling;
