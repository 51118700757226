import React, { useEffect, useState } from "react";
import {
  CircleX,
  Trash2,
  ChevronDown,
  Loader2,
  CirclePlus,
  Loader,
} from "lucide-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select, { components } from "react-select";
import { Dialog, Tooltip } from "@mui/material";
import { addJobCompletionAPI, jobCompletionInitialAPI } from "../../GeneralAPI";
import {
  formatDateAndTime,
  getCurrentDateTime,
} from "../../../../../../util/utilityFunctions";
import ErrorModal from "./errorDialog/ErrorModal";
import './componentStyle.css'

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: "none",
    boxShadow: "none",
    background: "transparent",
    width: "150px",
    "&:hover": {
      border: "none",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#f0f0f0" : "white",
    color: "#333",
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
  }),
  menu: (provided) => ({
    ...provided,
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
    width: "150px",
    zIndex: 9999,
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#333",
  }),
  input: (provided) => ({
    ...provided,
    color: "#333",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#666",
    opacity: state.isFocused ? 1 : 0,
    transition: "opacity 200ms",
  }),
  container: (provided) => ({
    ...provided,
    width: "150px",
  }),
};

const JobCompletionPopup = ({
  isOpen,
  onClose,
  data,
  singleViewId,
  selectedLineData,
}) => {
  //LOCAL STORAGE
  const shiftDetails = JSON.parse(localStorage.getItem("shiftStatus"));

  //states
  const [activeTab, setActiveTab] = useState("Tailor Details");
  const [jobCompletionListData, setJobCompletionListData] = useState("");
  const [loading, setIsLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [error, setError] = useState(null);
  const [customerDetails, setCustomerDetails] = useState({
    Customer: "",
    Product: "",
    Color: "",
    Type: "",
    Model: "",
    Date: "",
  });
  const [orderDetails, setOrderDetails] = useState({
    "Order Qty": "",
    "Pending Qty": "",
    "Total Consumed Fabric": "",
    "Total Consumed Accessories": "",
  });
  // -- table states
  const initialCutterDetails = [
    {
      cutter: "",
      qty: "",
      commission: "",
      totalCommission: "",
      date: new Date(),
    },
  ];

  const initialTailorDetails = [
    {
      tailer: "",
      qty: "",
      stock: "",
      consumedQty: "",
      wastage: "",
      total: "",
      commission: "",
      totalCommission: "",
      date: new Date(),
    },
  ];

  const initialAccessoriesDetails = [{ product: "", qty: "" }];

  // State hooks for managing form details
  const [cutterDetails, setCutterDetails] = useState(initialCutterDetails);
  const [tailorDetails, setTailorDetails] = useState(initialTailorDetails);
  const [accessoriesDetails, setAccessoriesDetails] = useState(
    initialAccessoriesDetails
  );

  // Function to reset all details to their initial state
  const resetState = () => {
    setCutterDetails(initialCutterDetails);
    setTailorDetails(initialTailorDetails);
    setAccessoriesDetails(initialAccessoriesDetails);
  };

  // effect
  useEffect(() => {
    if (jobCompletionListData) {
      setCustomerDetails({
        Customer: jobCompletionListData?.jobCompletionSingleView?.customer,
        Product: jobCompletionListData?.jobCompletionSingleView?.productName,
        Color: jobCompletionListData?.jobCompletionSingleView?.colorName,
        Type: jobCompletionListData?.jobCompletionSingleView?.type,
        Model: jobCompletionListData?.jobCompletionSingleView?.model,
        Date: jobCompletionListData?.jobCompletionSingleView?.date,
      });
      setOrderDetails({
        "Order Qty":
          jobCompletionListData?.jobCompletionSingleView?.orderQuantity,
        "Pending Qty":
          jobCompletionListData?.jobCompletionSingleView?.pendingQuantity,
        "Total Consumed Fabric":
          jobCompletionListData?.jobCompletionSingleView?.totalConsumedFabric,
        "Total Consumed Accessories":
          jobCompletionListData?.jobCompletionSingleView
            ?.totalConsumedAccessories,
      });
    }
  }, [jobCompletionListData]);
  // fetch all options API
  useEffect(() => {
    if (selectedLineData) {
      initialAPIfunc();
    }
  }, [isOpen]);

  const initialAPIfunc = () => {
    setError(false);
    setIsLoading(true);
    jobCompletionInitialAPI({
      body: {
        branchId: data?.branchPk,
        _id: singleViewId,
        lineId: selectedLineData?.lineId,
      },
      setJobCompletionListData,
      setIsLoading,
      setError,
    });
  };

  // functions

  const handleInputChange = (detailType, index, field, value) => {
    if (detailType === "cutter") {
      const newCutterDetails = [...cutterDetails];
      newCutterDetails[index][field] = value;
      setCutterDetails(newCutterDetails);
    } else if (detailType === "tailor") {
      const newTailorDetails = [...tailorDetails];

      //Do not allow the input quantity to be greater than the pending quantity
      if (field === "qty") {
        const pendingQuantity =
          Number(
            jobCompletionListData?.jobCompletionSingleView?.pendingQuantity
          ) || 0;
        const newQty = tailorDetails.reduce(
          (acc, item) => acc + Number(item.qty),
          0
        );
        const totalQty = pendingQuantity - newQty;

        if (Number(value) > totalQty) {
          alert(
            `Input quantity cannot be greater than the remaining Pending quantity.`
          );
          return;
        }
      }
      newTailorDetails[index][field] = value;
      setTailorDetails(newTailorDetails);
    } else if (detailType === "accessories") {
      const newAccessoriesDetails = [...accessoriesDetails];
      newAccessoriesDetails[index][field] = value;
      setAccessoriesDetails(newAccessoriesDetails);
    }
  };

  const detailsMap = {
    "Cutter Details": {
      name: "",
      qty: "",
      commission: "",
      totalCommission: "",
      date: null,
    },
    "Tailor Details": {
      tailer: "",
      qty: "",
      stock: "",
      consumedQty: "",
      wastage: "",
      total: "",
      commission: "",
      totalCommission: "",
      date: null,
    },
    "Accessories Details": {
      product: "",
      customer: "",
      qty: "",
      uom: "",
    },
  };

  const addNewLine = (key) => {
    if (!detailsMap[key]) return;

    switch (key) {
      case "Cutter Details":
        setCutterDetails([...cutterDetails, detailsMap[key]]);
        break;
      case "Tailor Details":
        setTailorDetails([...tailorDetails, detailsMap[key]]);
        break;
      case "Accessories Details":
        setAccessoriesDetails([...accessoriesDetails, detailsMap[key]]);
        break;
      default:
        console.warn(`Unknown key: ${key}`);
    }
  };

  const deleteLineItem = (index, type) => {
    switch (type) {
      case "cutter": {
        const newDetails = cutterDetails.filter((_, i) => i !== index);
        setCutterDetails(newDetails);
        break;
      }
      case "tailor": {
        const newDetails = tailorDetails.filter((_, i) => i !== index);
        setTailorDetails(newDetails);
        break;
      }
      case "accessories": {
        const newDetails = accessoriesDetails.filter((_, i) => i !== index);
        setAccessoriesDetails(newDetails);
        break;
      }
      default:
        console.warn(`Unknown type: ${type}`);
    }
  };

  // Custom DropdownIndicator component
  const CustomDropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <ChevronDown size={18} />
      </components.DropdownIndicator>
    );
  };

  const cutterOptions = jobCompletionListData?.cutter?.map((cutter) => ({
    value: cutter?._id,
    label: cutter?.staff_name,
  }));
  const tailorOptions = jobCompletionListData?.tailor?.map((tailor) => ({
    value: tailor?._id,
    label: tailor?.staff_name,
  }));
  const productOptions = jobCompletionListData?.product?.map((product) => ({
    value: product?.itemId,
    label: product?.productName,
  }));
  const uomOptions = jobCompletionListData?.uomList?.map((uom) => ({
    value: uom?._id,
    label: uom?.uomName,
  }));

  const handleUpdateJobCompletion = () => {
    setAddLoading(true);
    const uploadData = {
      _id: singleViewId,
      lineId: selectedLineData?.lineId,
      productId: selectedLineData?.itemId,
      cutter: cutterDetails.map((item) => ({
        empId: item.name,
        Quantity: Number(item.qty),
        commission: Number(item.commission),
        totalCommission: Number(item.totalCommission),
        entrydate: formatDateAndTime(item.date),
      })),
      tailor: tailorDetails.map((item) => ({
        empId: item.name,
        Quantity: Number(item.qty),
        consumedQuantity: Number(item.consumedQty),
        wastage: Number(item.wastage),
        total: Number(item.total),
        commission: Number(item.commission),
        totalCommission: Number(item.totalCommission),
        entrydate: formatDateAndTime(item.date),
      })),
      accessoryList: accessoriesDetails.map((item) => ({
        productId: item?.product,
        Quantity: Number(item?.qty),
      })),
      Quantity: tailorDetails.reduce((acc, item) => acc + Number(item.qty), 0), // sum of input tailer qty
      postedDate: getCurrentDateTime(),
      shiftId: shiftDetails?._id,
    };

    addJobCompletionAPI({
      body: uploadData,
      setAddLoading,
      setError,
      resetState,
      onClose,
    });
    
  };

  if (isOpen && error) {
    return (
      <ErrorModal onClose={onClose} retry={initialAPIfunc} loading={loading} />
    );
  }
  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onClose}
        maxWidth="md"
        fullWidth
        classes={{ paper: "new-alt-pup-dialog" }}
      >
        <div className="new-alt-pup-overlay">
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "10em",
              }}
            >
              <Loader2 className="in-spn-loader-icon" />
              <p className="in-spn-loader-text">Loading...</p>
            </div>
          ) : (
            <div className="new-alt-pup-content">
              <div className="new-alt-pup-header">
                <h2>Job Completion</h2>
                <div className="new-alt-pup-header-buttons">
                  {/* Tooltip for Update Button */}
                  <Tooltip
                    title={
                      addLoading ? "Uploading..." : "Add new job completion"
                    }
                    arrow
                  >
                    <button
                      className="new-alt-pup-update-button"
                      onClick={handleUpdateJobCompletion}
                    >
                      {addLoading ? (
                        <Loader className="new-altr-cmnt-spinner" size={24} />
                      ) : (
                        <CirclePlus size={24} />
                      )}
                    </button>
                  </Tooltip>

                  {/* Tooltip for Close Button */}
                  <Tooltip title="Close" arrow>
                    <button
                      onClick={() => {
                        onClose();
                        resetState();
                      }}
                      className="new-alt-pup-close-button"
                    >
                      <CircleX size={24} />
                    </button>
                  </Tooltip>
                </div>
              </div>

              <div className="new-alt-pup-body">
                <div className="new-alt-pup-details-grid">
                  <div className="customer-details">
                    {Object?.entries(customerDetails)?.map(([key, value]) => (
                      <div key={key} className="detail-row">
                        <span className="detail-label">{key}</span>
                        <span className="detail-value">{value}</span>
                      </div>
                    ))}
                  </div>
                  <div className="order-details-">
                    {Object?.entries(orderDetails)?.map(([key, value]) => (
                      <div key={key} className="detail-row">
                        <span className="detail-label">{key}</span>
                        <span className="detail-value">{value}</span>
                      </div>
                    ))}
                  </div>
                </div>

                {/* <div className="new-alt-pup-tabs">
              {["Cutter Details", "Tailor Details", "Accessories Details"].map(
                (tab) => (
                  <button
                    key={tab}
                    className={`new-alt-pup-tab-button ${
                      activeTab === tab ? "new-alt-pup-active-tab" : ""
                    }`}
                    onClick={() => setActiveTab(tab)}
                  >
                    {tab}
                  </button>
                )
              )}
            </div> */}
                <div className="tabs-pos">
                  {[
                    "Cutter Details",
                    "Tailor Details",
                    "Accessories Details",
                  ].map((tab) => (
                    <button
                      key={tab}
                      className={`tab-button ${
                        activeTab === tab ? "active-tabs" : ""
                      }`}
                      onClick={() => setActiveTab(tab)}
                    >
                      {tab}
                    </button>
                  ))}
                </div>

                <div className="new-alt-pup-table-container">
                  <div className="new-alt-pup-table-scroll">
                    {activeTab === "Cutter Details" && (
                      <table className="new-alt-pup-table">
                        <thead>
                          <tr>
                            <th>Cutter</th>
                            <th>Qty</th>
                            <th>Commission</th>
                            <th>Total Commission</th>
                            <th>Date</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {cutterDetails?.map((cutter, index) => (
                            <tr key={index}>
                              <td>
                                <Select
                                  value={cutterOptions?.find(
                                    (option) => option.value === cutter.name
                                  )}
                                  onChange={(selectedOption) =>
                                    handleInputChange(
                                      "cutter",
                                      index,
                                      "name",
                                      selectedOption.value
                                    )
                                  }
                                  options={cutterOptions}
                                  isSearchable
                                  placeholder="Cutter"
                                  styles={customStyles}
                                  components={{
                                    DropdownIndicator: CustomDropdownIndicator,
                                  }}
                                  classNamePrefix="react-select"
                                  menuPortalTarget={document.body}
                                  menuPosition="fixed"
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  value={cutter.qty}
                                  onChange={(e) =>
                                    handleInputChange(
                                      "cutter",
                                      index,
                                      "qty",
                                      e.target.value
                                    )
                                  }
                                  placeholder="Quantity"
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  value={cutter.commission}
                                  onChange={(e) =>
                                    handleInputChange(
                                      "cutter",
                                      index,
                                      "commission",
                                      e.target.value
                                    )
                                  }
                                  placeholder="commission"
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  value={cutter.totalCommission}
                                  onChange={(e) =>
                                    handleInputChange(
                                      "cutter",
                                      index,
                                      "totalCommission",
                                      e.target.value
                                    )
                                  }
                                  placeholder="Total commission"
                                />
                              </td>
                              <td>
                                <DatePicker
                                  selected={cutter.date}
                                  onChange={(date) =>
                                    handleInputChange(
                                      "cutter",
                                      index,
                                      "date",
                                      date
                                    )
                                  }
                                  dateFormat="MM/dd/yyyy"
                                  placeholderText="Date"
                                />
                              </td>
                              <td>
                                <Tooltip title="Delete Row" arrow>
                                  <button
                                    className="delete-button"
                                    onClick={() =>
                                      deleteLineItem(index, "cutter")
                                    }
                                  >
                                    <Trash2 size={"15px"} />
                                  </button>
                                </Tooltip>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                    {activeTab === "Tailor Details" && (
                      <div className="">
                        <table className="new-alt-pup-table">
                          <thead>
                            <tr>
                              <th>Tailor</th>
                              <th>Qty</th>
                              <th>Stock</th>
                              <th>Consumed Qty</th>
                              <th>Wastage</th>
                              <th>Total</th>
                              <th>Commission</th>
                              <th>Total Commission</th>
                              <th>Date</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {tailorDetails?.map((cutter, index) => (
                              <tr key={index}>
                                <td>
                                  <Select
                                    value={tailorOptions?.find(
                                      (option) => option.value === cutter.name
                                    )}
                                    onChange={(selectedOption) =>
                                      handleInputChange(
                                        "tailor",
                                        index,
                                        "name",
                                        selectedOption.value
                                      )
                                    }
                                    options={tailorOptions}
                                    isSearchable
                                    placeholder="Tailer"
                                    styles={customStyles}
                                    components={{
                                      DropdownIndicator:
                                        CustomDropdownIndicator,
                                    }}
                                    classNamePrefix="react-select"
                                    menuPortalTarget={document.body}
                                    menuPosition="fixed"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    value={cutter.qty}
                                    onChange={(e) =>
                                      handleInputChange(
                                        "tailor",
                                        index,
                                        "qty",
                                        e.target.value
                                      )
                                    }
                                    placeholder="Quantity"
                                    // className="job-pop-input-md"
                                  />
                                </td>
                                <td>
                                  <input
                                    // className="job-pop-input-md"
                                    type="number"
                                    value={cutter.stock}
                                    onChange={(e) =>
                                      handleInputChange(
                                        "tailor",
                                        index,
                                        "stock",
                                        e.target.value
                                      )
                                    }
                                    placeholder="Stock"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className="job-pop-input-lg"
                                    value={cutter.consumedQty}
                                    onChange={(e) =>
                                      handleInputChange(
                                        "tailor",
                                        index,
                                        "consumedQty",
                                        e.target.value
                                      )
                                    }
                                    placeholder="Consumed Qty"
                                  />
                                </td>
                                <td>
                                  <input
                                    // className="job-pop-input-md"
                                    type="number"
                                    value={cutter.wastage}
                                    onChange={(e) =>
                                      handleInputChange(
                                        "tailor",
                                        index,
                                        "wastage",
                                        e.target.value
                                      )
                                    }
                                    placeholder="Wastage"
                                  />
                                </td>
                                <td>
                                  <input
                                    // className="job-pop-input-md"
                                    type="number"
                                    value={cutter.total}
                                    onChange={(e) =>
                                      handleInputChange(
                                        "tailor",
                                        index,
                                        "total",
                                        e.target.value
                                      )
                                    }
                                    placeholder="Total"
                                  />
                                </td>
                                <td>
                                  <input
                                    className="job-pop-input-lg"
                                    type="number"
                                    value={cutter.commission}
                                    onChange={(e) =>
                                      handleInputChange(
                                        "tailor",
                                        index,
                                        "commission",
                                        e.target.value
                                      )
                                    }
                                    placeholder="commission"
                                  />
                                </td>
                                <td>
                                  <input
                                    // className="job-pop-input-lg"
                                    type="number"
                                    value={cutter.totalCommission}
                                    onChange={(e) =>
                                      handleInputChange(
                                        "tailor",
                                        index,
                                        "totalCommission",
                                        e.target.value
                                      )
                                    }
                                    placeholder="Total commission"
                                  />
                                </td>
                                <td>
                                  <DatePicker
                                    selected={cutter.date}
                                    onChange={(date) =>
                                      handleInputChange(
                                        "tailor",
                                        index,
                                        "date",
                                        date
                                      )
                                    }
                                    dateFormat="MM/dd/yyyy"
                                    placeholderText="Date"
                                    // className="job-pop-input-md"
                                  />
                                </td>
                                <td>
                                  <Tooltip title={`Delete Row `} arrow>
                                    <button
                                      className="delete-button"
                                      onClick={() =>
                                        deleteLineItem(index, "tailor")
                                      }
                                    >
                                      <Trash2 size={"15px"} />
                                    </button>
                                  </Tooltip>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                    {activeTab === "Accessories Details" && (
                      <div className="">
                        <table className="new-alt-pup-table">
                          <thead>
                            <tr>
                              <th>Product</th>
                              <th>Qty</th>
                              {/* <th>UOM</th> */}
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {accessoriesDetails?.map((cutter, index) => (
                              <tr key={index}>
                                <td>
                                  <Select
                                    value={productOptions?.find(
                                      (option) => option.value === cutter.name
                                    )}
                                    onChange={(selectedOption) =>
                                      handleInputChange(
                                        "accessories",
                                        index,
                                        "product",
                                        selectedOption.value
                                      )
                                    }
                                    options={productOptions}
                                    isSearchable
                                    placeholder="Product"
                                    styles={customStyles}
                                    components={{
                                      DropdownIndicator:
                                        CustomDropdownIndicator,
                                    }}
                                    classNamePrefix="react-select"
                                    menuPortalTarget={document.body}
                                    menuPosition="fixed"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    value={cutter.qty}
                                    onChange={(e) =>
                                      handleInputChange(
                                        "accessories",
                                        index,
                                        "qty",
                                        e.target.value
                                      )
                                    }
                                    placeholder="Quantity"
                                  />
                                </td>
                                {/* <td>
                                  <Select
                                    value={uomOptions?.find(
                                      (option) => option.value === cutter.name
                                    )}
                                    onChange={(selectedOption) =>
                                      handleInputChange(
                                        "accessories",
                                        index,
                                        "uom",
                                        selectedOption.value
                                      )
                                    }
                                    options={uomOptions}
                                    isSearchable
                                    placeholder="Select UOM"
                                    styles={customStyles}
                                    components={{
                                      DropdownIndicator:
                                        CustomDropdownIndicator,
                                    }}
                                    classNamePrefix="react-select"
                                    menuPortalTarget={document.body}
                                    menuPosition="fixed"
                                  />
                                </td> */}
                                <td>
                                  <Tooltip title="Delete Row" arrow>
                                    <button
                                      className="delete-button"
                                      onClick={() =>
                                        deleteLineItem(index, "accessories")
                                      }
                                    >
                                      <Trash2 size={"15px"} />
                                    </button>
                                  </Tooltip>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>

                <button
                  className="new-alt-pup-add-line-button"
                  onClick={() => addNewLine(activeTab)}
                >
                  Add Line
                </button>
              </div>
            </div>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default JobCompletionPopup;
