import { Dialog } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { TextFieldComponent } from './CustomFields';
import { useSelector } from 'react-redux';
import { addOrderItemTypeAPI } from '../MeasurmentsAPI';

export const CreateTypeDialog = ({open,label,closeDialog,tablelabel,children,addBtnFn,formData,
    clearFormData
}) => {
    const [formValue,setFormValue]=useState("")
    const handleInputChange = (value) => {
        setFormValue(value)
        formData(value)
      };
     
  useEffect(() => {
    if (clearFormData === "") {
      setFormValue("");
    }
  }, [clearFormData]);

   
      
   
 

  return (
    <>
      <Dialog open={open} maxWidth="lg">
       <div className="add-measurment-dialog" style={{width:"50vw"}}>
        <h4>{label}</h4>
        <div className="add-measurment-input" style={{width:"100%"}}>
           <input
              style={{width:"100%"}}
           
            
             value={formValue}
             onChange={(e)=>handleInputChange(e.target.value)}
            
             />
    
            
           
            
                <button
                className='btn'
                 onClick={addBtnFn}
            >
              +
            </button>
        </div>
        <div className="new-global-table-container add-measurment-dialog-table" style={{maxHeight:"40vh"}}>
            <table>
              <thead>
                <tr>
                  <th>{tablelabel}</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>{children}</tbody>
              <tbody>
              
              </tbody>
            </table>
          </div>
  
      <div className="add-customer-dialog-btn" style={{justifyContent:"center"}}>
        <button
           onClick={() => closeDialog()}
           className="btn-secondary-outlined btn"
        >
          Close
        </button>
      </div>
</div>
      </Dialog>
    </>
   
  )
}
