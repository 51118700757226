import { Autocomplete, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import "./addCustomer.css"
import { open_dialog } from '../PosNewSlice';
import { useDispatch, useSelector } from 'react-redux';
import countyList from "../../../../../Assets/JSON/countryStates.json"
import { filterObjFromList } from '../../../../../Js/generalFunctions';
import { addCustomerNewAPICall, editCustomerNewAPICall } from '../NewOrder/NewOrderAPI';



export const AddCustomer = ({isRelation,formData}) => {
    // console.log(formData);
    // console.log("123456987")
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.loginResponseSlice.value);
    const {customerSingleView} = useSelector((state)=>state.neworderslice)
    // console.log(customerSingleView);
    const [addMoreInfo,setAddMoreInfo]=useState(false)
    const formValueInitial={
      name:"",
      mobile:"",
      phone:"",
      country:null,
      state:null,
      city:"",
      street:"",
      zipCode:"",
      isActive:true
     
  }
  const [formValues,setFormValues]=useState(formValueInitial)
  const [validationAlert,setValidationAlert]=useState({})
    const cancelBtnFn=()=>{
        dispatch(open_dialog({openDialogState:undefined}))
    }
    const formOnChange=(key,value)=>{
       setFormValues({...formValues,[key]: value
      });
     }

     const customerFormData=new FormData()
    customerFormData.append("name",formValues?.name)
    customerFormData.append("mobileNo",formValues?.mobile)
    customerFormData.append("phone",formValues?.phone)
    customerFormData.append("country",formValues.country?.country)
    customerFormData.append("state",formValues.state)
    customerFormData.append("city",formValues.city)
    customerFormData.append("streetName",formValues.street)
    customerFormData.append("zipCode",formValues.zipCode)
    customerFormData.append("status",formValues?.isActive)
    customerFormData.append("isCustomer",true)
    customerFormData.append("isRelation",isRelation ? true : false)
    customerFormData.append("branchId",userInfo?.branchPk)
    isRelation && customerFormData.append("parentId",formData?.customer?._id)
    customerSingleView !== undefined && customerFormData.append("_id",isRelation?formData?.relation?._id:formData?.customer?._id)
 


  

     // api used sales->newBillingAPI
     const createCustomerFn=()=>{
      if(formValues?.name===""){
        setValidationAlert({...validationAlert,name:"fill this field !!"})
      }
      else if(formValues?.mobile===""){
        setValidationAlert({...validationAlert,mobile:"fill this field !!"})
      }
      else{
        customerSingleView !== undefined ? editCustomerNewAPICall(customerFormData) : addCustomerNewAPICall(customerFormData)
      }
    
     }



     useEffect(()=>{
        if(customerSingleView){
            let countryData=filterObjFromList("country",countyList?.countries,"country",customerSingleView)
          setFormValues({...formValues,
            name:customerSingleView?.name||"",
            mobile:customerSingleView?.mobileNo||"",
            phone:customerSingleView?.alternateNumber||"",
            country:countryData,      
            state:countryData?.states?.filter((obj)=>obj===customerSingleView?.state),
            city:customerSingleView?.city||"",
            street:customerSingleView?.streetName||"",
            zipCode:customerSingleView?.zipCode||"",
            isActive:customerSingleView?.status,
          })
        }   
     },[customerSingleView])

  // console.log(formValues);
  return (
      <div className='add-customer-dialog'>
                  <div className="head justify-center">
                    <div className="icon justify-center">
                        <PeopleAltIcon/>
                    </div>
                    {
                      customerSingleView === undefined && isRelation===false?
                      "Create New Customer"
                      :customerSingleView !== undefined && isRelation===false?
                      "Edit Customer"
                      :customerSingleView === undefined && isRelation===true?
                      "Create New Relation"
                      :customerSingleView !== undefined && isRelation===true?
                      "Edit Relation"
                      :null
                    }
                
                  </div>
                  <div className="input-ctn">
                    <button disabled className="btn">Name</button>
                    <input 
                      type="text" 
                      style={{textAlign:"start",paddingLeft:"10px"}}
                      value={formValues?.name}
                      onChange={(e)=>formOnChange("name",e.target.value)}
                    />
                    <p className="doc-validation-alert">{validationAlert?.name}</p>
                  </div>
                  <div className="input-ctn">
                    <button disabled className="btn">Mobile</button>
                    <input 
                       type="text" 
                       style={{textAlign:"start",paddingLeft:"10px"}}
                       value={formValues?.mobile}
                       onChange={(e)=>formOnChange("mobile",e.target.value)}
                    />
                    <p className="doc-validation-alert">{validationAlert?.mobile}</p>
                  </div>
                  <div className="input-ctn">
                    <button disabled className="btn">Phone</button>
                    <input 
                       type="text" 
                       style={{textAlign:"start",paddingLeft:"10px"}}
                       value={formValues?.phone}
                       onChange={(e)=>formOnChange("phone",e.target.value)}
                    />
                  
                  </div>
                  {
                    addMoreInfo &&
                    <>
                    <div className="input-ctn">
                      <button disabled className="btn">Country</button>
                      <Autocomplete
                        className='customer-popup-autocomplete'
                        id="combo-box-demo"
                        options={countyList.countries||[]}
                        getOptionLabel={(option) => option?.country}
                        renderInput={(params) => (
                          <TextField {...params}/>
                        )}
                        value={formValues?.country}
                        onChange={(e,newValue)=>formOnChange("country",newValue)}
                     
                      />
                    </div>
                    <div className="input-ctn">
                      <button disabled className="btn">State</button>
                      <Autocomplete
                        className='customer-popup-autocomplete'
                        id="combo-box-demo"
                        options={formValues?.country?.states||[]}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField {...params}/>
                        )}
                        value={formValues?.state}
                        onChange={(e,newValue)=>formOnChange("state",newValue)}
                     
                      />
                    </div>
                    <div className="input-ctn">
                      <button disabled className="btn">City</button>
                      <input type="text" 
                        style={{textAlign:"start",paddingLeft:"10px"}}
                        value={formValues?.city}
                        onChange={(e)=>formOnChange("city",e.target.value)}
                      />                   
                    </div>
                    <div className="input-ctn">
                      <button disabled className="btn">Street</button>
                      <input type="text" 
                         style={{textAlign:"start",paddingLeft:"10px"}}
                         value={formValues?.street}
                         onChange={(e)=>formOnChange("street",e.target.value)}
                      />                   
                    </div>
                    <div className="input-ctn">
                      <button disabled className="btn">Zip Code</button>
                      <input type="text" 
                         style={{textAlign:"start",paddingLeft:"10px"}}
                         value={formValues?.zipCode}
                         onChange={(e)=>formOnChange("zipCode",e.target.value)}
                      />                   
                    </div>
                    
                    {/* <div style={{display:"flex",alignItems:"center"}}> 
                    <p style={{fontSize:"0.8rem"}}>In Active / Active</p>
                    <input
                       className="toggle-checkbox"
                       type={"checkbox"}
                       value={formValues?.isActive}
                       checked={formValues?.isActive===true}
                       onChange={(e)=>formOnChange("isActive",e.target.value)}
                       
                      />
                       </div> */}
                  
                    </>
                  }
                  <div className="add-customer-dialog-btn">
                    <button
                    onClick={cancelBtnFn}
                    className="btn-secondary-outlined btn">Cancel</button>
                    <button
                  onClick={createCustomerFn}
                    className="btn-primary btn">
                        {/* {customerSingleInfo?"Edit":"Save"} */}
                       { customerSingleView === undefined ?"Save":"Edit"} 
                    </button>

                    {!addMoreInfo &&
                      <p 
                      onClick={()=>setAddMoreInfo(true)}
                      className="doc-validation-alert">Add more fields</p>
                    }
                  </div>
      </div>
  )
}
