import { createSlice } from "@reduxjs/toolkit";

export  const workOrderNewSlice = createSlice({
    name:'workOrderNewList',
    initialState:{
        fullList:undefined,
        workorderpagenationData:null,
        workorderSingleData:null,
        workorderPaymentsData:null,
        workorderMeasurementData:null,
        workorderConsumptionData:null,
        workorderCutterData:null,
        workorderTailorData:null,
        workorderAccessoriesData:null,
        workorderDeliveryData:null,
       
    },
    reducers:{
        get_workorder_full_list:(state,action)=>{
            state.fullList=action.payload
        },
        setworkorderpagenation: (state, action) => {
            state.workorderpagenationData = action.payload;
          },
          setworkorderSingleView: (state, action) => {
            state.workorderSingleData = action.payload;
          },
         //single view table data
         //payment
         setworkorderPayments: (state, action) => {
          state.workorderPaymentsData = action.payload;
        },
      //measurement
      setworkorderMeasurement: (state, action) => {
        state.workorderMeasurementData = action.payload;
      },
      //consumption
      setworkorderConsumption: (state, action) => {
        state.workorderConsumptionData = action.payload;
      },
      //cuttor
      setworkorderCutter: (state, action) => {
        state.workorderCutterData = action.payload;
      },
      //Tailor
      setworkorderTailor: (state, action) => {
        state.workorderTailorData = action.payload;
      },
      //Accessories
      setworkorderAccessories: (state, action) => {
        state.workorderAccessoriesData = action.payload;
      },
      setworkorderDelivery: (state, action) => {
        state.workorderDeliveryData = action.payload;
      },
           
    }

})
 export const{
    get_workorder_full_list,
    setworkorderpagenation,
    setworkorderPayments,
    setworkorderMeasurement,
    setworkorderConsumption,
    setworkorderTailor,
    setworkorderCutter,
  setworkorderSingleView,
    setworkorderAccessories,
    setworkorderDelivery,
 }=workOrderNewSlice.actions
 export default workOrderNewSlice.reducer