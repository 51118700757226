import React from 'react'
import { useSelector } from 'react-redux'

export const WOPayments = () => {
  const singleList = useSelector((state) => state?.workOrderNewList?.workorderPaymentsData)
  //  console.log(singleList);
  const hasDAta = singleList?.paymentList?.length > 0 && singleList?.paymentList

  return (
    <>
      <div className="new-global-table-container work-order-tab-table" style={{ paddingTop: "0 42px", maxHeight: "20vh" }}>
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Payment Method</th>
              <th>Type</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {hasDAta ? singleList?.paymentList?.map((r, i) => (
              <tr key={i}>
                <td>{r?.date}</td>
                <td>{r?.paymentMethod}</td>
                <td>{r?.type}</td>
                <td>{r?.amount}</td>
              </tr>
            )) : (
              <tr><td style={{textAlignLast:"center"}} colSpan={4} >NO DATA</td></tr>
            )
            }

          </tbody>
          <tfoot>
            <tr className="total-row">
              <td colSpan="3"></td>
              <td>{singleList?.amountTotal}</td>

            </tr>
          </tfoot>



        </table>
      </div>


    </>
  )
}
