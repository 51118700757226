import React, { useRef } from "react";
import { IconButton, Tooltip } from "@mui/material";
//import "../../../../../css/Purchase Manager/GRN/GRNsingleView.css"
import postRibbon from "../../../../../Assets/statusRibbon/post.png";
import returnRibbon from "../../../../../Assets/statusRibbon/return.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { companyProfileInfoForUser, viewCompanyProfileInfoAPICall } from "../../../../../API/Settings/Company Settings/companySettingsAPI";
import Draft from "../../../../../Assets/statusRibbon/draft.png";
import { deliveryNoteSingleViewAPICall } from "./deliveryNoteAPI";
import { convertDateFormat, today } from "../../../../../Js/Date";
import ReactToPrint from "react-to-print";
import Template1 from "../../../SalesNew/SalesOrders/SalesQuotation/Template1";
import Template2 from "../../SalesOrders/SalesQuotation/Template2";
import { getTemplateAPI } from "../SalesQuotation/salesQuatationAPI";
import { set_Back_button_action } from "../../../../Single Components/listSlice/filterCategorySlice";
import { InvoiceContent } from "../../../Accounts/PrintComponents/printTemplates/common/InvoiceContent";
import { printSection } from "../../../Accounts/PrintComponents/printTemplates/common/enum";

export const DeliverNoteSingleView = () => {
  let paymentsPrint = useRef();
  let paymentsPrintNew = useRef();
  const componentRef = useRef();

  // data of selected list
  const singleRowId = localStorage.getItem("singleViewById");
  const singleList = useSelector((state) => state?.deliveryNoteSlice?.rowData);
console.log(singleList);
  // company details and info
  const companyProfileData =useSelector(
    (state) => state?.companyProfileSlice?.userValue)

    const dispatch = useDispatch();
    const template =useSelector((state)=>state?.salesQuatationSlice?.setTemplate)

  const formValuesInitialState = {
    status: "",
    companyLogo: "",
    companyName: "",
    companyAddress: "",
    companyContact: "",

    customerName: "",
    customerAddress: "",
    customerContact: "",

    deliveryName: "",
    deliveryAddress: "",
    deliveryContact: "",

    salesOrder:"",
    so:"SO No",
    name: "",
    dateN1:" Date",
    dateN2:"Expiry Date",
    firstDate: "",
    secondDate: "",

    termsAndCondition:"",

    lpo:"",
    sequence:"DN No",
    ref:"",
    subtotal: "",
    inclusiveTax: "",
    exclusiveTax: "",
    total: "",
    totalAmount:"",
    payTerm:"",
    label:"Delivery Note"
  };
  const [formValues, setFormValues] = useState(formValuesInitialState);
  const [data, setData] = useState([]);
  const [tax, setTax] = useState([]);
  const [domain, setDomain] = useState("");

  // api to fetch companyprofile data



  useEffect(() => {
    companyProfileInfoForUser();
    getTemplateAPI();
    if (singleRowId) {
      deliveryNoteSingleViewAPICall({ _id: singleRowId });
    }
  }, []);
  useEffect(() => {
    setFormValues({
      ...formValues,
      status: singleList?.status,
      companyLogo: companyProfileData?.logo || "",
      companyName: companyProfileData?.companyName || "",
      companyAddress: companyProfileData?.companyAddress || "",
      companyContact: companyProfileData?.contactNumber || "",
      companyTRN: companyProfileData?.companyTRN || "",

      customerName: singleList?.customerAddress?.customername|| "",
      customerAddress: singleList?.customerAddress?.streetName || "",
      customerContact: singleList?.customerAddress?.mobileNo || "",
      termsAndCondition:singleList?.termsAndConditions || "",
      salesOrder:singleList?.salesOrderName||"",
      trn: singleList?.vendorAddress?.TRN || "",

      ref: singleList?.reference ||"",
      lpo: singleList?.LpoNo ||"",
      name: singleList?.name || "",
      firstDate: singleList?.date || "",
      //secondDate: singleList?.dueDate || "",
      subtotal: singleList?.subTotal || "",
      inclusiveTax: singleList?.totalTaxInclusive || "",
      exclusiveTax: singleList?.totalTaxExclusive || "",
      totalAmount: singleList?.totalAmount || "",
      total: singleList?.total || "",
      payTerm: singleList?.PAYMENTTERM || "",
    });
    setData(singleList?.salesInfo);
    setTax(singleList?.taxDetails)
  }, [singleList, companyProfileData]);

  let navigateTo = useNavigate();
  return (
    <div className="main-container">
      <div className="first-div-container">
        <div className="post-ribbon">
          {formValues?.status === "DRAFT" && <img src={Draft} alt="" />}
          {formValues?.status === "POSTED" && <img src={postRibbon} alt="" />}
          {formValues?.status === "RETURNED" && <img src={returnRibbon} alt="" />}
        </div>
        <Tooltip title="Back">
          <IconButton>
            <i
              className="bi bi-chevron-left"
              style={{ fontSize: "30px", color: "black", cursor: "pointer" }}
              onClick={() =>
               { navigateTo("/userdashboard/sales/orders/deliveryNote")
               dispatch(set_Back_button_action({ backButtonClickAction: true }));}
              }
            ></i>
          </IconButton>
        </Tooltip>
        <Tooltip title="Edit">
          <IconButton>
            <i
              class="bi bi-pencil-square edit-icon1"
              onClick={() =>
                navigateTo("/userdashboard/sales/orders/deliveryNote/create")
              }
            ></i>
          </IconButton>
        </Tooltip>
        <ReactToPrint
          trigger={() => (
            <IconButton className="printer-div">
              <i style={{ color: "black" }} class="bi bi-printer"></i>
            </IconButton>
          )}
          content={() => paymentsPrint}
        />
        <Tooltip title="Share">
          <IconButton>
            <i class="bi bi-share" style={{ backgroundColor: "#9797e9", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
          </IconButton>
        </Tooltip>

        {/* <ReactToPrint
          trigger={() => (
            <IconButton className="printer-div" style={{ padding: "1px" }}>
              <i style={{ color: "white", backgroundColor: " #d787e7", fontSize: "medium", padding: "8px", borderRadius: "2px" }} class="bi bi-printer"></i>
            </IconButton>
          )}
          content={() => paymentsPrintNew}
        /> */}
         <ReactToPrint
            // The print button
            trigger={() => (
              <IconButton className="printer-div" style={{ padding: "1px" }}>
                <i
                  style={{
                    color: "white",
                    backgroundColor: " #d787e7",
                    fontSize: "medium",
                    padding: "8px",
                    borderRadius: "2px",
                  }}
                  class="bi bi-printer"
                ></i>
              </IconButton>
            )}
            // The content to be printed (InvoiceContent component)
            content={() => componentRef.current}
            // Set the page style for printing
            pageStyle="@page { size: A4; margin: 0; }"
          />

        <Tooltip  title="Download">
          <IconButton>
            <i class="bi bi-arrow-down-circle" style={{ backgroundColor: "rgb(255 118 133 / 69%)", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
          </IconButton>
        </Tooltip>
      </div>
      <hr className="line" />
      <div className="second-main-container">
        <div className="left-side-div">
          <img src={formValues?.companyLogo} alt={formValues?.companyName} />
          <p>{formValues?.companyAddress}</p>
          <p>{formValues?.companyContact}</p>

          <h1 className="vendor-head">Vendor Address</h1>
          <p>{formValues?.customerName}</p>
          <p>{formValues?.customerContact}</p>      
          {/* <p>--</p>
          <p>--</p> */}

          <h1 className="vendor-head">Delivery To</h1>
          {/* <p>--</p>
          <p>--</p>
          <p>--</p> */}
        </div>
        <div className="right-side-div">
          <h1>Delivery Note</h1>
          <p>{formValues?.name}</p>
          <div className="date-div" style={{ width: "171px" }}>
            <p>
              <span class="date-container-single">Date:</span>
              {formValues?.firstDate}
            </p>
            {/* <p>--</p> */}
          </div>
        </div>
      </div>

      <div className="Table-container">
        <div className="Table-data">
          <table>
            <thead>
              <tr style={{ backgroundColor: "#eaeef9" }}>
                <th>No</th>
                <th>Product</th>
                <th>UOM</th>
                <th>Demand Qty</th>
                {formValues.status === "RETURNED"? <th>Return Quantity </th>  :<th>Done Qty</th>}

              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => (
                <tr
                  key={index}
                  style={{
                    backgroundColor: index % 2 === 0 ? "#f1f2f5" : "white",
                  }}
                >
                  <td>{index + 1}</td>
                  <td>{item.product}</td>
                  <td>{item.uomName}</td>
                  <td>{item.qty}</td>
                  <td>{formValues?.status === "RETURNED" ?item?.returnQuantity :item?.deliveredQuantity}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* payments print view */}
      <div style={{ display: "none" }}>
        <div ref={(el) => (paymentsPrint = el)}>
          <div className="print-view-top-container">
            <div
              className="print-view-top-left-container"
              style={{ width: "25%" }}
            >
              <p></p>
              <p></p>
            </div>
            <div
              className="print-view-top-middle-container"
              style={{ width: "50%" }}
            >
              {/* <h3>--</h3>

              <h3>--</h3> */}

              <p>Delivery Note</p>
            </div>

            <div
              className="print-view-top-right-container"
              style={{ width: "25%" }}
            >
              <p>{convertDateFormat(today)}</p>
              <p>{""}</p>
            </div>
          </div>

          <div className="second-main-container">
            <div className="left-side-div">
              <img
                src={formValues?.companyLogo}
                alt={formValues?.companyName}
              />
              <p>{formValues?.companyAddress}</p>
              <p>{formValues?.companyContact}</p>

              <h1 className="vendor-head">Vendor Address</h1>
              <p>{formValues?.vendorName}</p>
              {/* <p>--</p>
              <p>--</p> */}

              <h1 className="vendor-head">Deliver To</h1>
              {/* <p>--</p>
              <p>--</p>
              <p>--</p> */}
            </div>
            <div className="right-side-div">
              <h1>Delivery Note </h1>
              <p>{formValues?.name}</p>
              <div className="date-div" style={{ width: "171px" }}>
                <p>
                  <span class="date-container-single">Date:</span>
                  {formValues?.firstDate}
                </p>
                <p>--</p>
              </div>
            </div>
          </div>

          <div className="Table-container" style={{ padding: "0px 35px" }}>
            <div className="Table-datas">
              <table style={{ overflow: "none" }}>
                <thead>
                  <tr style={{ backgroundColor: "#eaeef9" }}>
                    <th>No</th>
                    <th>Product</th>
                    <th>UOM</th>
                    <th>Demand Qty</th>
                    <th>Done Qty</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((item, index) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: index % 2 === 0 ? "#f1f2f5" : "white",
                        height: "32px",
                      }}
                    >
                      <td>{index + 1}</td>
                      <td>{item.product}</td>
                      <td>{item.uomName}</td>
                      <td>{item.qty}</td>
                      <td>{item.returnQty || item.qty}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="terms">
            <h1>Terms & Conditions</h1>
            <hr className="lines" />
          </div>
        </div>
      </div>
      <div style={{ display: "none" }}>
  {/* <div ref={(el) => (paymentsPrintNew = el)}>
    {template?.templateModel === 'template1' ? (
      <Template1 data={data} formValues={formValues} tax={tax} />
    ) : (
      <Template2 data={data} formValues={formValues} tax={tax}/>
    )}
  </div> */}
   <div ref={(el) => (paymentsPrintNew = el)}>
          <InvoiceContent
            invoiceDetails={{ ...formValues, printSection: printSection.salesOrder }}
            ref={componentRef}
            printData={data}
            taxDetails={tax}
            qrData={domain}
          />
        </div>
</div>
    </div>
  );
};
