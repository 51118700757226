import { Autocomplete, Dialog, IconButton, TextField } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { open_dialog } from '../../../PosNew/PosNewSlice'
import { useDispatch, useSelector } from 'react-redux'
import DeleteIcon from '@mui/icons-material/Delete';
import StyledSnackbar from '../../alteration/components/StyledSnackbar';
import JobCompletionPopup from "../../../General/alteration/components/JobCompletionPopup";

export const WOProducts = () => {
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  // const openDialog = useSelector((state) => state.posNewSlice.openDialog)
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);

  const [addRowScroll, setAddRowScroll] = useState(false);
  const singleViewId=localStorage.getItem("singleViewById")
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedButton, setSelectedButton] = useState("Cutter Details");
  const decimalPosition = localStorage.getItem("decimalPosition");
  const [selectedLineData, setSelectedLineData] = useState(null);

  const singleList = useSelector((state) => state.workOrderNewList?.workorderSingleData)
  // console.log(singleList);
  // const tax = [{ tax1: '1' }, { tax2: '1' }, { tax3: '1' }]
  const hasData = singleList?.list && singleList.list.length > 0;
console.log(selectedLineData);

  const buttonTypes = [
    { buttonName: "Cutter Details" },
    { buttonName: "Tailor Details" },
    { buttonName: "Accessories Details" },

  ]

  const tableDataInitialCutter = {
    Cutter: null,
    qty: "",
    commission: "",
    totalcommission: "",
    date: ""
  };
  const [tableDataCutter, setTableDataCutter] = useState([tableDataInitialCutter]);
  const tableDataInitialTailor = {
    tailor: null,
    qty: "",
    stock: "",
    consumedqty: "",
    wastage: "",
    total: "",
    commission: "",
    totalcommission: "",
    date: ""
  };
  const [tableDataTailor, setTableDataTailor] = useState([tableDataInitialTailor]);
  const tableDataInitialAccessories = {
    Product: null,
    qty: "",
    uom: ""
  };
  const [tableDataAccessories, setTableDataAccessories] = useState([tableDataInitialAccessories]);


  const jobCompletionBtnClickFn = (row) => {
    // dispatch(open_dialog({ openDialogState: "jobcompletion" }))
    setSelectedLineData(row);
    setIsPopupOpen(true);
   
  }
  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  }

  const closeDialog = () => {
    dispatch(open_dialog({ openDialogState: undefined }))
  }

  const addRow = () => {

    setTableDataCutter([
      ...tableDataCutter,
      {
        Cutter: null,
        qty: "",
        commission: "",
        totalcommission: "",
        date: ""
      }
    ]);

    setAddRowScroll(!addRowScroll);
  };

  const deleteRow = (tableType, index) => {

  };
  const totals = {
    ordered: 0,
    completed: 0,
    returned: 0,
    delivered: 0,
    amount: 0,
    vat: 0,
    netAmount: 0,
  };

  if (hasData) {
    singleList.list.forEach((item) => {
      totals.ordered += item?.orderQuantity || 0;
      totals.completed += item?.completedQuantity || 0;
      totals.returned += item?.returnedQuantity || 0;
      totals.delivered += item?.deliveredQuantity || 0;
      totals.amount += item?.amount || 0;
      totals.vat += item?.taxAmount || 0;
      totals.netAmount += item?.netAmount || 0;
    });
  }

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [addRowScroll]);
  return (
    <>
      <div className="new-global-table-container work-order-tab-table" style={{ paddingTop: "0 42px", maxHeight: "20vh" }}>
        <table>
          <thead>
            <tr>
              <th>Product</th>
              <th>Color</th>
              <th>Type</th>
              <th>Model</th>
              <th>Customer</th>

              <th colspan="4" style={{ backgroundColor: "#d5d5fb", borderBottom: "1px solid blue" }}>Quantity</th>
              <th>Stitch Rate</th>
              <th>Item Rate</th>
              <th>Amount</th>
              <th>VAT%</th>
              <th>VAT</th>
              <th>Net Amount</th>
              <th></th>

            </tr>
            <tr class="quantity">
              <th colspan="5"></th>
              <th style={{ backgroundColor: "#d5d5fb", borderRight: "1px solid blue" }}>Ordered</th>
              <th style={{ backgroundColor: "#d5d5fb", borderRight: "1px solid blue" }}>Completed</th>
              <th style={{ backgroundColor: "#d5d5fb", borderRight: "1px solid blue" }}>Returned</th>
              <th style={{ backgroundColor: "#d5d5fb" }}>Delivered</th>
              <th colspan="7"></th>
            </tr>
          </thead>
          <tbody>
            {
              hasData ? singleList?.list?.map((r, i) => (
                <tr >
                  <td>{r?.productName}</td>
                  <td>{r?.colorName}</td>
                  <td>{r?.typeName}</td>
                  <td>{r.modelName}</td>
                  <td>{singleList?.customer}</td>
                  <td>{r?.orderQuantity}</td>
                  <td>{r?.completedQuantity}</td>
                  <td>{r?.returnedQuantity}</td>
                  <td>{r?.deliveredQuantity}</td>
                  <td>{r?.stitchRate}</td>
                  <td>{r?.itemRate}</td>
                  <td>{r?.amount}</td>
                  <td>{r?.vatpercent}</td>
                  <td>{r?.taxAmount?.toFixed(decimalPosition) }</td>
                  <td>{r?.netAmount}</td>
                  <td>
                  <IconButton onClick={() => jobCompletionBtnClickFn(r)}>

                      <i class="bi bi-arrow-right-circle round-circle-job-completion"></i>
                    </IconButton>
                  </td>

                </tr>
              )) : (
                <tr>
                  <td colspan={16}>NO DATA</td>
                </tr>
              )
            }


          </tbody>
          <tfoot>
            <tr className="total-row">
              <td colSpan="5">TOTAL (AED)</td>

              <td>{totals.ordered.toFixed(2)}</td>
              <td>{totals.completed.toFixed(2)}</td>
              <td>{totals.returned.toFixed(2)}</td>
              <td>{totals.delivered.toFixed(2)}</td>
              <td colSpan="2"></td>
              <td>{totals.amount.toFixed(2)}</td>
              <td></td>
              <td>{totals.vat.toFixed(2)}</td>
              <td>{totals.netAmount.toFixed(2)}</td>
              <td></td>
            </tr>
          </tfoot>



        </table>
      </div>
      <div className='work-order-tab-amount-container'>
        <div
          className="new-global-single-input"
          style={{ maxWidth: "250px" }}
        >
          <div className="subtotal-ctn">
            <div className="subtotal-ctn-left">
              <p>Total Before VAT</p>
              {singleList?.taxlist?.map((tax, index) => (
                <p key={index}>{tax.name}</p>
              ))}
              <p>Discount</p>
              <p>Delivery Change</p>
            </div>
            <div className="subtotal-ctn-right">
              <p>{singleList?.totalBeforeVat}</p>
              {singleList?.taxlist?.map((tax, index) => (
                <p key={index}>{tax.taxAmount}</p>
              ))}
              <p>{singleList?.discount}</p>
              <p>{singleList?.deliveryCharge}</p>
            </div>
          </div>
          <hr
            className="global-hr"
            style={{ width: "80%", marginLeft: "25%" }}
          />
          <div className="subtotal-ctn">
            <div className="subtotal-ctn-left">
              <p style={{ color: "#000", fontWeight: "600", fontSize: "0.9rem" }}><p>Total INC VAT</p></p>
              <p style={{ color: "red", fontWeight: "600", fontSize: "0.8rem" }}>Amount Due</p>
            </div>
            <div className="subtotal-ctn-right">
              <p style={{ fontWeight: "600", fontSize: "0.9rem" }}>{singleList?.totalIncVat}</p>
              <p style={{ color: "red", fontWeight: "600", fontSize: "0.8rem" }}>{singleList?.amountDue}</p>
            </div>
          </div>
        </div>
      </div>

      <JobCompletionPopup
        isOpen={isPopupOpen}
        onClose={() => {
          setIsPopupOpen(false);
          setSelectedLineData(null);
        }}
        data={loginResponse}
        singleViewId={singleViewId}
        selectedLineData={selectedLineData}

      />
      <StyledSnackbar />
      {/* <Dialog open={openDialog === "jobcompletion"} maxWidth="lg" >
        <div className='return-dialog-container'>
          <div className='return-dialog-top-container'>
            <p className='return-p'>Job Completion</p>
            <div className='return-dialog-top-right-container'>
              <button className='pos-status-blue-btn'>Update</button>
              <i onClick={closeDialog} class="bi bi-x-circle"></i>
            </div>
          </div>
          <hr className='pos-hr' />
          <div className='return-dialog-bottom-container'>
            <div className='return-dialog-bottom-one'>
              <p>BIL/229</p>

            </div>
            <div className='return-dialog-bottom-two'>
              <div className='return-dialog-bottom-left'>
                <div className='return-dialog-bottom-left-single'>
                  <p className='return-dialog-bottom-left-p'>Customer</p>
                  <p>10/02/2024 10:30AM</p>
                </div>
                <div className='return-dialog-bottom-left-single'>
                  <p className='return-dialog-bottom-left-p'>Product</p>
                  <p>10/02/2024 10:30AM</p>
                </div>
                <div className='return-dialog-bottom-left-single'>
                  <p className='return-dialog-bottom-left-p'>Color</p>
                  <p>Branch 1</p>
                </div>
                <div className='return-dialog-bottom-left-single'>
                  <p className='return-dialog-bottom-left-p'>Type</p>
                  <p>01</p>
                </div>
                <div className='return-dialog-bottom-left-single'>
                  <p className='return-dialog-bottom-left-p'>Model</p>
                  <p>01</p>
                </div>
                <div className='return-dialog-bottom-left-single'>
                  <p className='return-dialog-bottom-left-p'>Date</p>
                  <p>01</p>
                </div>
              </div>
              <div className='return-dialog-bottom-left'>
                <div className='return-dialog-bottom-left-single'>
                  <p className='return-dialog-bottom-left-p'>Order Qty</p>
                  <p className='return-dialog-bottom-right-p'>1</p>
                </div>
                <div className='return-dialog-bottom-left-single'>
                  <p className='return-dialog-bottom-left-p'>Pending Qty</p>
                  <p className='return-dialog-bottom-right-p'>1</p>
                </div>
                <div className='return-dialog-bottom-left-single'>
                  <p className='return-dialog-bottom-left-p'>Total Consumed Fabric</p>
                  <p className='return-dialog-bottom-right-p'>Work Order</p>
                </div>
                <div className='return-dialog-bottom-left-single'>
                  <p className='return-dialog-bottom-left-p'>Total Consumed Accessories</p>
                  <p className='return-dialog-bottom-right-p'>01</p>
                </div>

              </div>
            </div>
            <div style={{ marginTop: "8px", backgroundColor: "white", height: "40vh" }} className='flex-column'>


              <div className="button-div" style={{ padding: "20px 0 0 17px" }}>

                {
                  buttonTypes?.map((r, i) => (
                    <button
                      className={selectedButton === r.buttonName ? 'selected-btn' : ''}
                      onClick={() => handleButtonClick(r.buttonName)}
                    >
                      {r?.buttonName}
                    </button>
                  ))
                }


              </div>
              {selectedButton === "Cutter Details" && (
                <>
                  <div className="global-product-table">
                    <table>
                      <thead>
                        <tr>
                          <th>Cutter</th>
                          <th>Qty</th>
                          <th>Commission</th>
                          <th>Total Commission</th>
                          <th>Date</th>
                          <th style={{ backgroundColor: "#fff" }}></th>
                        </tr>
                      </thead>

                      <tbody>
                        {tableDataCutter?.map((item, index) => (
                          <tr
                            key={index}
                            ref={
                              index === tableDataCutter?.length - 1
                                ? containerRef
                                : null
                            }
                          >


                            <td style={{ minWidth: "180px" }}>
                              <div className="product-table-auto-complete">
                                <Autocomplete
                                  options={[]}
                                  style={{ maxHeight: "100px" }}
                                  getOptionLabel={(option) => option}
                                  renderInput={(params) => (
                                    <TextField

                                      {...params}

                                    />
                                  )}
                                  renderOption={(props, option) => (
                                    <li {...props} key={option._id}>
                                      <span>{option}</span>
                                    </li>
                                  )}

                                />
                              </div>
                            </td>
                            <td style={{ maxWidth: "180px" }}>
                              <div className="global-product-table-input-icon">
                                <input
                                  type="text"

                                />
                              </div>

                            </td>
                            <td style={{ maxWidth: "180px" }}>
                              <div className="global-product-table-input-icon">
                                <input
                                  type="text"

                                />
                              </div>

                            </td>
                            <td style={{ maxWidth: "180px" }}>
                              <div className="global-product-table-input-icon">
                                <input
                                  type="text"

                                />
                              </div>

                            </td>
                            <td style={{ maxWidth: "180px" }}>
                              <div className="global-product-table-input-icon">
                                <input
                                  type="text"

                                />
                              </div>

                            </td>
                            <td style={{ border: "none", width: "4%" }}>
                              <IconButton onClick={() => deleteRow('Cutter Details', index)}>
                                <DeleteIcon color="error" />
                              </IconButton>
                            </td>



                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="add-row-btn-container">
                    <div
                      className="global-single-input auto-complete"
                      style={{
                        width: '76%',
                        fontSize: '0.9rem',
                        paddingLeft: '7.5px',
                        fontWeight: '600',
                        color: 'rgb(134, 152, 229)',
                        cursor: 'pointer',
                      }}
                      onClick={() => addRow('Cutter Details')}
                    >
                      Add Line
                    </div>
                  </div>
                </>
              )}
              {selectedButton === "Tailor Details" && (
                <>
                  <div className="global-product-table">
                    <table>
                      <thead>
                        <tr>
                          <th>Tailor</th>
                          <th>Qty</th>
                          <th>Stock</th>
                          <th>Consumed Qty</th>
                          <th>Wastage</th>
                          <th>total</th>
                          <th>Commission</th>
                          <th>totalCommistion</th>
                          <th>date</th>
                          <th style={{ backgroundColor: "#fff" }}></th>
                        </tr>
                      </thead>

                      <tbody>
                        {tableDataTailor?.map((item, index) => (
                          <tr
                            key={index}
                            ref={
                              index === tableDataTailor?.length - 1
                                ? containerRef
                                : null
                            }
                          >


                            <td style={{ minWidth: "180px" }}>
                              <div className="product-table-auto-complete">
                                <Autocomplete
                                  options={[]}
                                  style={{ maxHeight: "100px" }}
                                  getOptionLabel={(option) => option}
                                  renderInput={(params) => (
                                    <TextField

                                      {...params}

                                    />
                                  )}
                                  renderOption={(props, option) => (
                                    <li {...props} key={option._id}>
                                      <span>{option}</span>
                                    </li>
                                  )}

                                />
                              </div>
                            </td>
                            <td style={{ maxWidth: "180px" }}>
                              <div className="global-product-table-input-icon">
                                <input
                                  type="text"

                                />
                              </div>

                            </td>
                            <td style={{ maxWidth: "180px" }}>
                              <div className="global-product-table-input-icon">
                                <input
                                  type="text"

                                />
                              </div>

                            </td>
                            <td style={{ maxWidth: "180px" }}>
                              <div className="global-product-table-input-icon">
                                <input
                                  type="text"

                                />
                              </div>

                            </td>
                            <td style={{ maxWidth: "180px" }}>
                              <div className="global-product-table-input-icon">
                                <input
                                  type="text"

                                />
                              </div>

                            </td>
                            <td style={{ maxWidth: "180px" }}>
                              <div className="global-product-table-input-icon">
                                <input
                                  type="text"

                                />
                              </div>

                            </td>
                            <td style={{ maxWidth: "180px" }}>
                              <div className="global-product-table-input-icon">
                                <input
                                  type="text"

                                />
                              </div>

                            </td>
                            <td style={{ maxWidth: "180px" }}>
                              <div className="global-product-table-input-icon">
                                <input
                                  type="text"

                                />
                              </div>

                            </td>
                            <td style={{ maxWidth: "180px" }}>
                              <div className="global-product-table-input-icon">
                                <input
                                  type="text"

                                />
                              </div>

                            </td>

                            <td style={{ border: "none", width: "4%" }}>
                              <IconButton onClick={() => deleteRow('Tailor Details', index)}>
                                <DeleteIcon color="error" />
                              </IconButton>
                            </td>



                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="add-row-btn-container">
                    <div
                      className="global-single-input auto-complete"
                      style={{
                        width: '76%',
                        fontSize: '0.9rem',
                        paddingLeft: '7.5px',
                        fontWeight: '600',
                        color: 'rgb(134, 152, 229)',
                        cursor: 'pointer',
                      }}
                      onClick={() => addRow('Tailor Details')}
                    >
                      Add Line
                    </div>
                  </div>
                </>
              )}
              {selectedButton === "Accessories Details" && (
                <>
                  <div className="global-product-table">
                    <table>
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th>Qty</th>
                          <th>uom</th>

                          <th style={{ backgroundColor: "#fff" }}></th>
                        </tr>
                      </thead>

                      <tbody>
                        {tableDataAccessories?.map((item, index) => (
                          <tr
                            key={index}
                            ref={
                              index === tableDataAccessories?.length - 1
                                ? containerRef
                                : null
                            }
                          >


                            <td style={{ minWidth: "180px" }}>
                              <div className="product-table-auto-complete">
                                <Autocomplete
                                  options={[]}
                                  style={{ maxHeight: "100px" }}
                                  getOptionLabel={(option) => option}
                                  renderInput={(params) => (
                                    <TextField

                                      {...params}

                                    />
                                  )}
                                  renderOption={(props, option) => (
                                    <li {...props} key={option._id}>
                                      <span>{option}</span>
                                    </li>
                                  )}

                                />
                              </div>
                            </td>
                            <td style={{ maxWidth: "180px" }}>
                              <div className="global-product-table-input-icon">
                                <input
                                  type="text"

                                />
                              </div>

                            </td>
                            <td style={{ maxWidth: "180px" }}>
                              <div className="global-product-table-input-icon">
                                <input
                                  type="text"

                                />
                              </div>

                            </td>


                            <td style={{ border: "none", width: "4%" }}>
                              <IconButton onClick={() => deleteRow('Accessories Details', index)}>
                                <DeleteIcon color="error" />
                              </IconButton>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="add-row-btn-container">
                    <div
                      className="global-single-input auto-complete"
                      style={{
                        width: '76%',
                        fontSize: '0.9rem',
                        paddingLeft: '7.5px',
                        fontWeight: '600',
                        color: 'rgb(134, 152, 229)',
                        cursor: 'pointer',
                      }}
                      onClick={() => addRow('Accessories Details')}
                    >
                      Add Line
                    </div>
                  </div>
                </>
              )}
            </div>

          </div>
        </div>
      </Dialog> */}
      
    </>
  )
}
