import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { switch_to_pos_screen } from '../../PosNew/PosNewSlice';
import { IconButton, Tooltip } from '@mui/material';
import ReactToPrint from 'react-to-print';
import { useLocation, useNavigate } from 'react-router-dom';
import ActiveRibbon from "../../../../../Assets/statusRibbon/post.png"
import "./workordernew.css"
import { WOProducts } from './WOTabs/WOProducts';
import { WOPayments } from './WOTabs/WOPayments';
import { WOMeasurments } from './WOTabs/WOMeasurments';
import { WOConsumption } from './WOTabs/WOConsumption';
import { WOCutterDetails } from './WOTabs/WOCutterDetails';
import { WOTailorDetails } from './WOTabs/WOTailorDetails';
import { WOAccessoresDetails } from './WOTabs/WOAccessoresDetails';
import { WODeliveryDetails } from './WOTabs/WODeliveryDetails';
import { listWorkOrderAccessoriesAPI, listWorkOrderConsumptionAPI, listWorkOrderCutterAPI, listWorkOrderDeliveryAPI, listWorkOrderMeasurementAPI, listWorkOrderPaymentAPI, listWorkOrderSingleViewAPI, listWorkOrderTailorAPI } from './workOrderNewAPI';
// import { listWorkOrderMeasurementAPI, listWorkOrderSingleViewAPI } from '../GeneralAPI';

export const WorkOrderSingleView = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate=useNavigate() ;
    const {singleViewId}= location.state || {};
    const [selectedButton, setSelectedButton] = useState("Products");
    localStorage.setItem("singleViewById",singleViewId);
    const singleList = useSelector((state)=>state.workOrderNewList?.workorderSingleData)
    // console.log(singleList);
    
    const buttonTypes=[
        {buttonName:"Products"},
        {buttonName:"Payments"},
        {buttonName:"Measurments"},
        {buttonName:"Consumption"},
        {buttonName:"Cutter Details"},
        {buttonName:"Tailor Details"},
        {buttonName:"Accessories Details"},
        {buttonName:"Delivery Details"},

    ]  
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString(); // Returns date in format: MM/DD/YYYY or based on locale settings
    };
     
    const handleback=()=>{
        navigate("/userdashboard/pos/general/workorder")
    }
    const handleButtonClick =(buttonName)=>{
        setSelectedButton(buttonName);
    }
    // useEffect(() => {
    //     dispatch(switch_to_pos_screen({ posScreenState: true }));
    //   }, []);

    useEffect(()=>{
        listWorkOrderSingleViewAPI({_id:singleViewId})
        listWorkOrderMeasurementAPI({_id:singleViewId})
        listWorkOrderConsumptionAPI({_id:singleViewId})
        listWorkOrderCutterAPI({_id:singleViewId})
        listWorkOrderTailorAPI({_id:singleViewId})
        listWorkOrderAccessoriesAPI({_id:singleViewId})
        listWorkOrderDeliveryAPI({_id:singleViewId})
        listWorkOrderPaymentAPI({_id:singleViewId})
    },[])

    //   console.log(selectedButton)
  return (
    <div  className='pos-conatiner'>
         <div
            className="justify-space-between">
                <div className='new-order-top-left'>
                  <Tooltip title="Back">
                    <IconButton  onClick={handleback}>
                     <i className="bi bi-chevron-left" style={{ fontSize: '30px', color: 'black', cursor: 'pointer' }}></i>
                    </IconButton>
               </Tooltip>
             
               <p className='new-order-top-left-p' style={{margin:"auto 20px"}}>ORDERS</p>

                </div>
                
        {/* <h5 style={{ margin: "0" ,cursor:"pointer"}}onClick={handleback}>SHIFT</h5> */}

        <div className="justify-center" style={{ gap: "10px", width: "70%", justifyContent: "flex-end" }}>
            <ReactToPrint
                trigger={() => (
                    <IconButton className="printer-div" style={{ color: "#fff", backgroundColor: "#002995", borderRadius: "0" }}>
                        <i class="bi bi-printer"></i>
                    </IconButton>
                )}
            //   content={() => paymentsPrint}
            />
            <Tooltip title="Download">
                <IconButton style={{ backgroundColor: "#ffd2d2", color: "#002995", borderRadius: "0" }}>
                    <i class="bi bi-arrow-down-circle"></i>
                </IconButton>
            </Tooltip>
        </div>
        </div>
        <hr className='pos-hr' />
        <div className="global-white-bg-container">
        <div className="ribbon-ctn">
            <img src={ActiveRibbon} alt="" />
        </div>
        <div>
           <h3 style={{ margin: "0 36px",cursor:"pointer",fontSize:"larger"  }}>SH01</h3>
           <div className="single-viewinfo-parent-ctn" style={{display:"flex"}}>
           

           <div className="single-view-left-ctn">
       
               <div style={{display:"flex"}}>
                   <div className="single-view-info-ctn-left flex-column">
                       <p>Date</p>
                       <p>Delivery Date</p>
                       <p>Branch</p>
                       <p>Shift No</p>
                       <p>Employee</p>
                   </div>
                   <div className="single-view-info-ctn-right flex-column">
                       <p>{formatDate(singleList?.date)}</p>
                       <p>{formatDate(singleList?.deliveryDate)}</p>
                       <p>{singleList?.branch}</p>
                       <p>{singleList?.shiftId}</p>
                       <p>{singleList?.employee}</p>
                   </div>
               </div>
           </div>
           <div className="single-view-left-ctn">
               {/* <h3 style={{ margin: "0",cursor:"pointer",fontSize:"larger"  }}></h3> */}
       
               <div style={{display:"flex"}}>
                   <div className="single-view-info-ctn-left flex-column">
                       <p>Customer</p>
                       <p>Mobile</p>
                       <p>Order Type</p>
                       <p>RACK</p>

                   </div>
                   <div className="single-view-info-ctn-right flex-column">
                       <p>{singleList?.customer}</p>
                       <p>{singleList?.mobile}</p>
                       <p>{singleList?.orderType}</p>
                       <p>{singleList?.rack}</p>

                   </div>
               </div>
           </div>
           </div>
        </div>
     
        </div>    
        <div style={{ marginTop: "8px", backgroundColor: "white", height: "56vh" }} className='flex-column'>


<div className="button-div" style={{ padding: "20px 0 0 17px" }}>

{
    buttonTypes?.map((r,i)=>(
        <button
        className={selectedButton === r.buttonName ? 'selected-btn' : ''}
        onClick={() => handleButtonClick(r.buttonName)} 
    >
        {r?.buttonName}
    </button>
    ))
}
   

</div>
{selectedButton === "Products" && (
   <WOProducts/>
)}
{selectedButton === "Payments" && (
  <WOPayments/>
)}
{selectedButton === "Measurments" && (
   <WOMeasurments/>
)}
{selectedButton === "Consumption" && (
    <WOConsumption/>
)}
{selectedButton === "Cutter Details" && (
   <WOCutterDetails/>
)}
{selectedButton === "Tailor Details" && (
   <WOTailorDetails/>
)}
{selectedButton === "Accessories Details" && (
   <WOAccessoresDetails/>
)}
{selectedButton === "Delivery Details" && (
  <WODeliveryDetails/>
)}

        </div>
    </div>
  )
}
