

import axios from "axios"
import { HEADERS } from "../../../../../API/UrlAndPaths"
import store from "../../../../../Redux/store"
import { generate_workorderno, get_all_productList_by_stock, get_customer_list, get_customer_on_search, get_orderlist_basedon_customer, get_relation_list, get_single_customer, get_workorder_singleview } from "./neworderSlice"
import { open_dialog, pos_edit_btn, pos_view_btn } from "../PosNewSlice"
import { setErrorSnack, setSnackMsg, setSubmitLoading, setSuccessSnack } from "../../../../../Redux/Dashboard/mainDashBoardSlice"
import { update_all_customer_list } from "../../../../../Redux/Customer/allCustomerListSlice"




// view customer new api 
export const viewCustomerNewAPICall=(body)=>{
    axios.post("account/viewCustomerForDropdown",body,HEADERS)
    .then((res)=>{
        store.dispatch(get_customer_list({customerData:res.data}))
    })
    .catch((err)=>{
        store.dispatch(get_customer_list({customerData:undefined}))
    })
}
/// add customer new api
export const addCustomerNewAPICall=(body)=>{
   axios.post("customer/addCustomer",body,HEADERS)
   .then((res)=>{
       if(res.status===200){
        console.log('success');
        store.dispatch(open_dialog({openDialogState:false}))
        store.dispatch(setSnackMsg("Success"))
        store.dispatch(setSuccessSnack(true))
        if(res.data.isRelation){
            viewRelationNewAPICall({_id:res.data.parentId})
        }
        else{
            viewCustomerNewAPICall()
        }
        
        viewSingleCustomerAPICall({_id:res.data._id})
       }
   })
   .catch((err)=>{
    let errorMsg=""
    if(err.response.status===404){
        errorMsg='Server Error'
    }
    if (err.response.status === 403) {
        errorMsg='Forbidden'
    }
    if (err.response.status === 401) {
        errorMsg='Unauthorized'
    }
    if (err.response.status === 500) {
        errorMsg='Server Error'
    }
    if(err.response.status===undefined){
        errorMsg='Network Connection error!!'
    }

    store.dispatch(setSnackMsg(errorMsg))
    store.dispatch(setErrorSnack(true))
   })
}
// customer single view api call
export const viewSingleCustomerAPICall=(body)=>{
    axios.post("customer/viewcustomerbyid",body,HEADERS)
    .then((res)=>{
         if(res.status===200){
            store.dispatch(get_single_customer({singleCustomerData:res.data}))
         }
    })
}

// view relation new api
export const viewRelationNewAPICall=(body)=>{
    axios.post("customer/viewaddedrelations",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
          store.dispatch(get_relation_list({relationData:res.data}))
        }
    })
    .catch((err)=>{
         store.dispatch(get_relation_list({relationData:undefined})) 
    })
}

// edit customer
export const editCustomerNewAPICall=(body)=>{
    axios.put("customer/editCustomer",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(open_dialog({openDialogState:false}))
            store.dispatch(setSnackMsg("Success"))
            store.dispatch(setSuccessSnack(true))
            viewCustomerNewAPICall()
            viewSingleCustomerAPICall({_id:res.data._id})
         
          
        }
    })
    .catch((err)=>{
        let errorMsg=""
        if(err.response.status===404){
            errorMsg='Server Error'
        }
        if (err.response.status === 403) {
            errorMsg='Forbidden'
        }
        if (err.response.status === 401) {
            errorMsg='Unauthorized'
        }
        if (err.response.status === 500) {
            errorMsg='Server Error'
        }
        if(err.response.status===undefined){
            errorMsg='Network Connection error!!'
        }
    
        store.dispatch(setSnackMsg(errorMsg))
        store.dispatch(setErrorSnack(true))
    })
}

// all product list by stock
export const allProductListByStock=(body)=>{
    axios.post("readymade/filterproductbystock",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
          store.dispatch(get_all_productList_by_stock({productListByStock:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(get_all_productList_by_stock({productListByStock:undefined}))
    })
}

// generate workorder no api call
export const generateWorkOrderNoAPICall=(body)=>{
    axios.post("workorder/generateWoNo",body,HEADERS)
    .then((res)=>{
         if(res.status===200){
            store.dispatch(generate_workorderno(res.data))
         }
    })   
    .catch((err)=>{
        store.dispatch(generate_workorderno(undefined))
    })
}
// add work order api call
export const AddWorkOrderAPICall=(body,navigate)=>{
    axios.post("workorder/addWorkorder",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            console.log('added');
            localStorage.setItem("singleOrderId",res?.data?._id)
            navigate("/userdashboard/pos/measurment")
        }
    })
    .catch((err)=>{
        console.log(err);
    })
}

export const editWorkOrderAPICall=(body)=>{
    axios.put("workorder/editWorkorderNew",body,HEADERS)
    .then((res)=>{
        console.log("edited");
    })
    .catch((err)=>{
        console.log(err.response);
    })
}
// work order single view
export const workOrderSingleViewAPI=(body)=>{
   axios.post("workorder/viewworkorderforedit",body,HEADERS)
   .then((res)=>{
    if(res.status===200){
        store.dispatch(get_workorder_singleview(res.data))
    }
   })
   .catch((err)=>{
       store.dispatch(get_workorder_singleview(undefined))
   })
}

// view customer on search in view order
export const viewCustomerOnSearch=(body)=>{
    store.dispatch(setSubmitLoading(true))
    axios.post("customer/viewcustomeronsearch",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_customer_on_search(res.data))
            store.dispatch(setSubmitLoading(false))
        }
    })
    .catch((err)=>{
        store.dispatch(get_customer_on_search(undefined))
        store.dispatch(setSubmitLoading(false))
       })
}

// view order based on customer 
export const viewOrderListBasedOnCustomer=(body)=>{
    store.dispatch(setSubmitLoading(true))
    axios.post("workorder/ordersearch",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_orderlist_basedon_customer(res.data))
            store.dispatch(setSubmitLoading(false))
            store.dispatch(pos_edit_btn(true))
            store.dispatch(pos_view_btn(true))
        }
    })
    .catch((err)=>{
        store.dispatch(get_orderlist_basedon_customer(undefined))
        store.dispatch(setSubmitLoading(false))
       })
}