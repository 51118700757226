import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { open_dialog, switch_to_pos_screen } from '../PosNewSlice';
import './neworder.css'
import HomeIcon from '@mui/icons-material/Home';
import { Autocomplete, Dialog, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AddCustomer } from '../AddCustomer/AddCustomer';
import EditIcon from '@mui/icons-material/Edit';
import {AddWorkOrderAPICall, generateWorkOrderNoAPICall, viewRelationNewAPICall, viewSingleCustomerAPICall } from './NewOrderAPI';
import { get_single_customer } from './neworderSlice';
import store from '../../../../../Redux/store';
import { filterOptionsPaginated } from '../../PosGeneralFunctions';
import { filterObjFromList } from '../../../../../Js/generalFunctions';
import { addProductColor, deleteProductColor, viewOrderItemTypeAPI, viewOrderModelAPI, viewProductColor } from './MeasurmentsAPI';
import { getAllTaxSettingsListAPICall } from '../../../../../API/Settings/TaxSettings/taxSettingsAPI';
import { dateAfterFiveDays, today } from '../../../../../Js/Date';


export const NewOrder = () => {
    const dispatch = useDispatch();
    const navigate=useNavigate()
    const openDialog=useSelector((state)=>state.posNewSlice.openDialog)
    const {
      customerList,
      relationList,
      customerSingleView,
      productListByStock,
      generateWoNo
    } = useSelector((state) => state.neworderslice);
    const {orderTypes,itemType,model,productColor}=useSelector((state)=>state.measurementsslice)
    const allTaxList = useSelector((state) => state.taxSettingsSlice.value);
    const allTaxListSalesType = allTaxList?.filter((item) => item?.taxType === "Sales" && item?.status === true)
    const loginResponse = useSelector((state) => state.loginResponseSlice.value);
    const shiftStatus = useSelector((state) => state.shiftStatusSlice.value);

  
  
    const formValueInitialState={
        orderType:orderTypes !== undefined ? orderTypes[0]?._id:null,
        orderDate:today,
        deliveryDate:dateAfterFiveDays,
        customer:null,
        customerAddress:"",
        contactNumber:"",
        relation:null,
        barcode:"",
        product:null,
        productColor:null,
        type:null,
        model:null,
        qty:"",
        itemRate:"",
        stitchRate:"",
        taxes:[],
        amount:"",
        inclusive:"",
        taxTotal:"",
        subTotal:""
    }
    const [FormValues,setFormValues]=useState(formValueInitialState)
    const [orderNo,setOrderNo]=useState()
    const [isRelation,setIsRelation]=useState(false)
    const [editType, setEditType] = useState(null);
    const [orderItems, setOrderItems] = useState([]);
    const [editingIndex, setEditingIndex] = useState(null);
    const [filteredTaxoptions,setFilteredTaxoptions]=useState([])

    const productColorinitial={
       colorName:"",
       colorCode:""
    }
    const [productColour,setProductColour]=useState(productColorinitial)
    

    const homeBtnClickFn=()=>{
      navigate("/userdashboard/posnew")
    }
    const createNewCustomer=()=>{
      dispatch(open_dialog({openDialogState:"Customer"}))
      setIsRelation(false)
      setEditType('customer');
    }
    const createNewRelation=()=>{
      dispatch(open_dialog({openDialogState:"Customer"}))
      setIsRelation(true)
      setEditType('relation');
    }
    const createProductColor=()=>{
      dispatch(open_dialog({openDialogState:"ProductColor"}))
    }
    const closeDialog=()=>{
      dispatch(open_dialog({openDialogState:false}))
    }
    const productColorPlusBtnFn=()=>{
      console.log('qq');
      addProductColor(
        {colorName:productColour?.colorName,colorCode:productColour?.colorCode},
        setProductColour,productColour
      )
    }

 
    const changeFormValues = (key, value) => {
      if(key ==="customer"){
        setFormValues(prevState => ({
          ...prevState,
          customer: value,
          contactNumber:value !== null ? value?.mobileNo:""
      }));
      }
      else if (key === "product") {
        const filteredTaxes = value?.taxes
        ? allTaxList.filter(tax => value.taxes.some(t => t._id === tax._id)) // Assuming taxes is an array of tax objects with _id property
        : [];
        const selectedTax= filteredTaxes[0]?.includedPrice
        const filteredOption=selectedTax !== undefined ? allTaxListSalesType?.filter((option)=>option.includedPrice===selectedTax):allTaxListSalesType
        setFilteredTaxoptions(filteredOption)  
        setFormValues(prevState => ({
              ...prevState,
              product: value,
              qty:value?.stock||"",
              itemRate: value?.calculatedPrice||"",
              stitchRate: value?.stitchRate||"",
              taxes:filteredTaxes||[],
              amount:parseFloat(value?.stock||0)*(parseFloat(value?.calculatedPrice||0)+parseFloat(value?.stitchRate||0))||0,
              subTotal:parseFloat(value?.stock||0)*(parseFloat(value?.calculatedPrice||0)+parseFloat(value?.stitchRate||0))||0,
              inclusive:selectedTax
          }));
      } 
      else if(key === "qty"){
        setFormValues(prevState => ({
          ...prevState,
          qty:value,
          amount:parseFloat(value)*(parseFloat(prevState?.itemRate||0)+parseFloat(prevState?.stitchRate||0))||0,
          subTotal:parseFloat(value)*(parseFloat(prevState?.itemRate||0)+parseFloat(prevState?.stitchRate||0))||0

      }));
      }
      else if(key === "itemRate"){
        setFormValues(prevState => ({
          ...prevState,
          itemRate:value,
          amount:parseFloat(prevState?.qty||0)*(parseFloat(value||0)+parseFloat(prevState?.stitchRate||0))||0,
          subTotal:parseFloat(prevState?.qty||0)*(parseFloat(value||0)+parseFloat(prevState?.stitchRate||0))||0

      }));
      }
      else if(key === "stitchRate"){
        setFormValues(prevState => ({
          ...prevState,
          stitchRate:value||0,
          amount:parseFloat(prevState?.qty||0)*(parseFloat(prevState?.itemRate||0)+parseFloat(value||0))||0,
          subTotal:parseFloat(prevState?.qty||0)*(parseFloat(prevState?.itemRate||0)+parseFloat(value||0))||0
      }));
      }
      else if(key==="taxes"){
        const selectedTax= value[0]?.includedPrice
        const filteredOption=selectedTax !== undefined ? allTaxListSalesType?.filter((option)=>option.includedPrice===selectedTax):allTaxListSalesType
        setFilteredTaxoptions(filteredOption)
        setFormValues(prevState => ({
          ...prevState,
          taxes: value,
          inclusive:selectedTax
      }));
      }

      else {
          setFormValues(prevState => ({
              ...prevState,
              [key]: value
          }));
      }
  };

  const calculateTax=()=>{
   
     if(FormValues?.inclusive){
         
          let totalOfTax = FormValues?.taxes.reduce((sum, item) => sum + item?.amount, 0);
          let taxableAmount=(((FormValues?.itemRate)*(FormValues?.qty))/(100+totalOfTax))*100
          let calculatedTaxes = FormValues?.taxes.map(tax => ({
            ...tax,
            taxAmount: (taxableAmount * tax.amount) / 100
             }));
           let totalTax=calculatedTaxes.reduce((sum, item) => sum + item?.taxAmount, 0)
          console.log(totalTax);
          setFormValues(prevState => ({
            ...prevState,
            amount:prevState?.amount,
            taxTotal:totalTax
    
        }));
        
     }
     if(!FormValues?.inclusive){
      let taxableAmount=((FormValues?.itemRate)*(FormValues?.qty))
      let calculatedTaxes = FormValues?.taxes.map(tax => ({
        ...tax,
        taxAmount: (taxableAmount * tax.amount) / 100
         }));
       let totalTax=calculatedTaxes.reduce((sum, item) => sum + item?.taxAmount, 0)
       console.log(calculatedTaxes);
       console.log(totalTax);
       setFormValues(prevState => ({
        ...prevState,
        amount:prevState?.amount+totalTax,
        taxTotal:totalTax

    }));
     }
  

  }

  const addbuttonClearState=()=>{
    setFormValues({
      ...FormValues,
      relation:null,
      barcode:"",
      product:null,
      productColor:null,
      type:null,
      model:null,
      qty:"",
      itemRate:"",
      stitchRate:"",
      taxes:[],
      amount:"",
      inclusive:""
    })
  }

    const addOrderItem = () => {
      if (editingIndex !== null) {
          const updatedItems = orderItems.map((item, index) =>
              index === editingIndex ? FormValues : item
          );
          setOrderItems(updatedItems);
          setEditingIndex(null);
      } else {
          setOrderItems([...orderItems, FormValues]);
      }
      // setFormValues(formValueInitialState); 
      addbuttonClearState()
  };

  const editOrderItem = (index) => {
      setFormValues(orderItems[index]);
      setEditingIndex(index);
  };

  const deleteOrderItem = (index) => {
      const updatedItems = orderItems.filter((item, i) => i !== index);
      setOrderItems(updatedItems);
  };
  const clearButtonFn=()=>{
    setFormValues(formValueInitialState);
  }

  const calculateTotal = (key) => {
    return orderItems.reduce((total, item) => total + (parseFloat(item[key]) || 0), 0);
  };

  const tableDataToBackend=orderItems?.map((obj)=>{
    return{
      workorderTypeId:obj?.orderType,
      modelId:obj?.model?._id,
      itemType:obj?.type?._id,
      itemId:obj?.product?._id,
      customerId:obj?.relation===null ?FormValues?.customer?._id:obj?.relation?._id,
      color:obj?.productColor?._id,
      orderQuantity:Number(obj?.qty),
      itemRate:Number(obj?.itemRate),
      stitchRate:Number(obj?.stitchRate),
      amount:obj?.amount,
      taxes:obj?.taxes,
      taxAmount:obj?.taxTotal,
      netAmount:obj?.subTotal
    }
  })

  const bodyToApi={
    orderDate:FormValues?.orderDate,
    deliveryDate:FormValues?.deliveryDate,
    shiftId:shiftStatus?._id,
    employeeId:loginResponse?.data?._id,
    customerId:FormValues?.customer?._id,
    branchId:loginResponse?.branchPk,
    total:calculateTotal('amount'),
    items:tableDataToBackend
  }

  const placeOrderFn=()=>{
    AddWorkOrderAPICall(bodyToApi,navigate)
    // navigate("/userdashboard/pos/measurment")
    // console.log(bodyToApi);
    
  }


 

     useEffect(() => {
        dispatch(switch_to_pos_screen({ posScreenState: true }));
        getAllTaxSettingsListAPICall();
        generateWorkOrderNoAPICall({branchId:loginResponse?.branchPk})
        viewProductColor()
      }, []);

      useEffect(()=>{
        if(generateWoNo !== undefined){
          setOrderNo(`${generateWoNo?.prefix}${generateWoNo?.transNo}`)
        }
      },[generateWoNo])

      useEffect(()=>{
        calculateTax()
      },[FormValues?.taxes,FormValues?.itemRate,FormValues?.stitchRate,FormValues?.qty])

      useEffect(() => {
       
        setFilteredTaxoptions(
            allTaxListSalesType?.map((obj) => {
                return { ...obj };
            })
        );
    }, [allTaxList]);

      useEffect(()=>{
           viewRelationNewAPICall({_id:FormValues?.customer?._id})
            if (editType === 'customer' && FormValues?.customer) {
            
              viewSingleCustomerAPICall({ _id: FormValues.customer._id });
             
            } else if (editType === 'relation' && FormValues?.relation) {
              viewSingleCustomerAPICall({ _id: FormValues.relation?._id });
              
            } 
          
          else{
            store.dispatch(get_single_customer({singleCustomerData:undefined}))
          }
      },[FormValues?.customer,FormValues?.relation,editType,])

      useEffect(()=>{
         if(orderTypes !== undefined){
          setFormValues({...FormValues,orderType:orderTypes[0]?._id})
         }
      },[orderTypes])

      useEffect(()=>{
        viewOrderItemTypeAPI({workorderTypeId:FormValues?.orderType})
        viewOrderModelAPI({workorderTypeId:FormValues?.orderType})
      },[FormValues?.orderType])

      // useEffect(()=>{
      //    if(isRelation){
      //     setFormValues({...FormValues,
      //       relation:filterObjFromList("name",relationList,"name",customerSingleView),
      //     })
      //    }
      //    else{
      //     setFormValues({...FormValues,
      //       customer:filterObjFromList("name",customerList,"name",customerSingleView),
      //     })
      //    }
     
      // },[isRelation])
    

      // console.log(FormValues);
      // console.log(orderItems);
      
  return (
    <div className='pos-conatiner'>
      <div className='new-order-top-container'>
        <div className='new-order-top-left'>
           <div className='new-order-top-left-home' onClick={homeBtnClickFn}>
             <HomeIcon/> 
           </div>
           <p className='new-order-top-left-p'>NEW ORDER</p>
        </div>
        <div className='new-order-top-right'>
          <div className='new-order-top-right-radio'>
          {orderTypes?.map((r, index) => (
        <div key={index} className='new-order-top-right-radio-single'>
          <input 
            type="radio"
            id={`radio-${index}`} 
            name="types" 
            onChange={(e) => changeFormValues("orderType", r?._id)}
            value={r?._id}
            checked={r?._id === FormValues?.orderType}
          />
          <label htmlFor={`radio-${index}`}>
            {r?.typeName}
          </label>
        </div>
      ))}
           
          </div>
          <div className='new-order-top-right-btn'>
            <button className='pos-status-blue-btn'>Payment</button>
            <button onClick={placeOrderFn} className='pos-status-blue-btn'>Place Order</button>
            <button onClick={clearButtonFn} className='pos-status-blue-btn pos-return-btn'>Clear</button>
          </div>
        </div>
      </div>
      <hr className='pos-hr' />
      <div className='new-order-second-container'>
         <div className='new-order-second-left' >
            <div className="new-global-single-input" style={{width:"45%",margin:"12px"}}>
              <TextField
                id="outlined-basic"
                label="Order No"
                variant="outlined"
                focused
                value={orderNo}
              />
            </div>
            <div className="new-global-single-input" style={{width:"45%",margin:"12px"}}>
              <TextField
                type='date'
                id="outlined-basic"
                label="Order Date"
                variant="outlined"
                focused
                value={FormValues?.orderDate}
                onChange={(e)=>changeFormValues("orderDate",e.target.value)}
              />
            </div>
            <div className="new-global-single-input" style={{width:"45%",margin:"12px"}}>
              <TextField
                type='date'
                id="outlined-basic"
                label="Delivery Date"
                variant="outlined"
                focused
                value={FormValues?.deliveryDate}
                onChange={(e)=>changeFormValues("deliveryDate",e.target.value)}
              />
            </div>
            <div className="new-global-single-input" style={{width:"45%",margin:"12px"}}>
              <div style={{display:"flex",alignItems:"center"}}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={customerList?.slice(0)?.reverse()||[]}
                getOptionLabel={(option) => option?.name}
                filterOptions={filterOptionsPaginated}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Customer" focused />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    <span>{option?.name}</span>
                  </li>
                )}
                value={FormValues?.customer}
                onChange={(e,newValue)=>changeFormValues("customer",newValue)}
              />
              <button onClick={createNewCustomer} className='add-customer-plus-btn'>
                {FormValues?.customer===null? "+":<EditIcon sx={{fontSize:"1rem"}}/>}
              </button>
              </div>
             
              {/* <p onClick={createNewCustomer} className="doc-validation-alert" style={{color:"blue",cursor:"pointer"}}>Add Customer</p> */}
            </div>
            <div className="new-global-single-input" style={{width:"45%",margin:"12px"}}>
              <TextField
                type='text'
                id="outlined-basic"
                label="Customer Address"
                variant="outlined"
                focused
                value={FormValues?.customerAddress}
                onChange={(e)=>changeFormValues("customerAddress",e.target.value)}
              />
            </div>
            <div className="new-global-single-input" style={{width:"45%",margin:"12px"}}>
              <TextField
                type='text'
                id="outlined-basic"
                label="Contact Number"
                variant="outlined"
                focused
                value={FormValues?.contactNumber}
                // onChange={(e)=>changeFormValues("contactNumber",e.target.value)}
              />
            </div>
         </div>
         <div className='border-right'></div>
         <div className='new-order-second-right'>
            <div className="new-global-single-input new-order-second-right-input">
              <div style={{display:"flex",alignItems:"center"}}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={relationList?.slice(0)?.reverse()||[]}
                getOptionLabel={(option) =>
                  option?.name
                }
                filterOptions={filterOptionsPaginated}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Relation" focused />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    <span>{option?.name}</span>
                  </li>
                )}
                value={FormValues?.relation}
                onChange={(e,newValue)=>changeFormValues("relation",newValue)}

              />
              {
                FormValues?.customer===null?null :
                <button onClick={createNewRelation} className='add-customer-plus-btn'>
                {FormValues?.relation===null? "+":<EditIcon sx={{fontSize:"1rem"}}/>}
              </button>
              }
               
              </div>
            
               {/* <p className="doc-validation-alert" style={{color:"blue",cursor:"pointer",top:"84%"}}>Add Relation</p> */}
            </div>
            <div className="new-global-single-input new-order-second-right-input">
              <TextField
                type='text'
                id="outlined-basic"
                label="Barcode"
                variant="outlined"
                focused
                value={FormValues?.barcode}
                onChange={(e)=>changeFormValues("barcode",e.target.value)}
              />
            </div>
            <div className="new-global-single-input new-order-second-right-input">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={productListByStock||[]}
                getOptionLabel={(option) =>
                  option?.productName
                }
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Product" focused />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    <span>{option?.productName}</span>
                  </li>
                )}
                value={FormValues?.product}
                onChange={(e,newValue)=>changeFormValues("product",newValue)}
              />
               {FormValues?.product !== null && 
               <p className="doc-validation-alert" style={{color:"blue",cursor:"pointer",top:"84%",fontSize:"0.6rem"}}>
                {`Available Qty ${FormValues?.product?.stock} ${FormValues?.product?.unitName}`} 
                </p>}
            </div>
            <div className="new-global-single-input new-order-second-right-input">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={productColor||[]}
                getOptionLabel={(option) =>
                  option?.colorName
                }
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Product Color" focused />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    <span>{option?.colorName}</span>
                  </li>
                )}
                value={FormValues?.productColor}
                onChange={(e,newValue)=>changeFormValues("productColor",newValue)}
              />
                 <p onClick={createProductColor} className="doc-validation-alert" style={{color:"blue",cursor:"pointer",top:"84%",fontSize:"0.6rem"}}>
                   Add
                </p>
              
            </div>
            <div className="new-global-single-input new-order-second-right-input">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={itemType||[]}
                getOptionLabel={(option) =>
                  option?.typeName
                }
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Type" focused />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    <span>{option?.typeName}</span>
                  </li>
                )}
                value={FormValues?.type}
                onChange={(e,newValue)=>changeFormValues("type",newValue)}
              />
              
            </div>
            <div className="new-global-single-input new-order-second-right-input">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={model||[]}
                getOptionLabel={(option) =>
                  option?.modelName
                }
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Model" focused />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    <span>{option?.modelName}</span>
                  </li>
                )}
                value={FormValues?.model}
                onChange={(e,newValue)=>changeFormValues("model",newValue)}
              />
              
            </div>
            <div className="new-global-single-input new-order-second-right-input">
              <TextField
                type='text'
                id="outlined-basic"
                label="Qty"
                variant="outlined"
                focused
                value={FormValues?.qty}
                onChange={(e)=>changeFormValues("qty",e.target.value)}
              />
            </div>
            <div className="new-global-single-input new-order-second-right-input">
              <TextField
                type='text'
                id="outlined-basic"
                label="Item Rate"
                variant="outlined"
                focused
                value={FormValues?.itemRate}
                onChange={(e)=>changeFormValues("itemRate",e.target.value)}
              />
            </div>
            <div className="new-global-single-input new-order-second-right-input">
              <TextField
                type='text'
                id="outlined-basic"
                label="Stitch Rate"
                variant="outlined"
                focused
                value={FormValues?.stitchRate}
                onChange={(e)=>changeFormValues("stitchRate",e.target.value)}
              />
            </div>
            <div className="new-global-single-input new-order-second-right-input new-order-tax-input">
              <Autocomplete
                multiple
                disablePortal
                id="combo-box-demo"
                options={filteredTaxoptions||[]}
                getOptionLabel={(option) =>
                  option?.taxName
                }
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField 
                     {...params} 
                     label="Taxes" 
                     focused 
                     />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    <span>{option?.taxName}</span>
                  </li>
                )}
                value={FormValues?.taxes}
                onChange={(e,newValue)=>changeFormValues("taxes",newValue)}
              />
               
            </div>
            <div className="new-global-single-input new-order-second-right-input">
              <TextField
                type='text'
                id="outlined-basic"
                label="Amount"
                variant="outlined"
                focused
                value={FormValues?.amount}
                // onChange={(e)=>changeFormValues("amount",e.target.value)}
              />
            </div>
            <button 
                onClick={addOrderItem} 
                className={FormValues.customer===null || FormValues?.product===null?"new-order-second-right-add-btn disabled":'new-order-second-right-add-btn'}
                disabled={FormValues.customer===null || FormValues?.product===null}
                >
                {editingIndex !== null ? "Update" : "Add"}
            </button>
         </div>
      </div>
      <div className="new-global-table-container pos-table work-order-tab-table" style={{ paddingTop: "0 42px",maxHeight:"56vh" }}>
       <table>
        <thead>
            <tr>
                <th>CUSTOMER</th>
                <th>PRODUCT</th>
                <th>COLOR</th>
                <th>TYPE</th>
                <th>MODEL</th>
                <th>QTY</th>
                <th>ITEM RATE</th>
                <th>STITCH RATE</th>
                <th>TAX</th>
                <th>AMOUNT</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
        {
            orderItems?.length !== 0 ?orderItems?.map((r,i)=>(
              <tr key={i} style={{ backgroundColor: i % 2 === 0 ? "white" : "#e9e7e7" }}>
                <td>{r?.customer?.name}</td>
                <td>{r?.product?.productName}</td>
                <td>{r?.productColor?.colorName}</td>
                <td>{r?.type?.typeName}</td>
                <td>{r?.model?.modelName}</td>
                <td>{r?.qty}</td>
                <td>{r?.itemRate}</td>
                <td>{r?.stitchRate}</td>
                <td>{r?.taxes?.map((obj)=>obj?.taxName)}</td>
                <td>{r?.amount}</td>
                <td>
                   <i onClick={() => editOrderItem(i)} class="bi bi-pencil-square edit-icon"></i>
                   
                   <i onClick={() => deleteOrderItem(i)} class="bi bi-trash3 delete-icon"></i>
                </td>
            </tr>
            ))
            :
            <tr>
               <td colSpan={11}>No Data</td>
            </tr>
          }

        </tbody>
        <tfoot style={{backgroundColor:"#b9b9b9"}}>
           <tr className="total-row">
           <td style={{ border: "none", fontSize: "1rem", fontWeight: 600 }}>Total</td>
             <td style={{ border: "none" }} colSpan="4"></td>
             <td style={{ border: "none" }}>{calculateTotal('qty')}</td>
             <td style={{ border: "none" }}>{calculateTotal('itemRate').toFixed(2)}</td>
             <td style={{ border: "none" }}>{calculateTotal('stitchRate').toFixed(2)}</td>
             <td style={{ border: "none" }}></td>
             <td style={{ border: "none" }}>{calculateTotal('amount').toFixed(2)}</td>
             <td style={{ border: "none" }}></td>

           </tr>
        </tfoot>
       
          
           
         </table>
       </div>

       <Dialog open={openDialog==="Customer"} maxWidth="lg">
           <AddCustomer  isRelation={isRelation} formData={FormValues}/>
       </Dialog>
       <Dialog open={openDialog==="ProductColor"} maxWidth="lg">
          <div className='add-measurment-dialog' style={{width:"40vw"}}>
              <h4>Add Color</h4>
              <div className="add-measurment-input">
                    <input 
                       type="text" 
                       placeholder='Colour Name'
                       value={productColor?.colorName}
                       onChange={(e)=>setProductColour({...productColour,colorName:e.target.value})}
                       />
                    <input  
                       type="color"
                       value={productColor?.colorCode}
                       onChange={(e)=>setProductColour({...productColour,colorCode:e.target.value})}
                       />
                    <button onClick={productColorPlusBtnFn}  className="btn">+</button>
              </div>
              <div className="new-global-table-container add-measurment-dialog-table" style={{maxHeight:"40vh"}}>
       <table>
        <thead>
            <tr> 
                <th>No</th>
                <th>Name</th>
                <th></th>
                
            </tr>
        </thead>
        <tbody>
        {
            productColor?.map((r,i)=>(
              <tr key={i}>
                 <td >{i+1}</td>
                <td >
                  <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                      {r?.colorName}
                      <div
                        style={{
                          backgroundColor: r?.colorCode,
                          width: "15px",
                          height: "15px",
                          marginLeft: "4px",
                          borderRadius: "4px",
                        }}
                  ></div>
                  </div>
               
                
                  </td>
                <td >
                   
                    <i  onClick={()=>deleteProductColor({_id:r?._id})} class="bi bi-trash3 delete-icon"></i>
                </td>
            </tr>
            ))
          }
        </tbody>
          
           
         </table>
              </div>
              <div className="add-customer-dialog-btn" style={{justifyContent:"center"}}>
                    <button
                       onClick={closeDialog}
                       className="btn-secondary-outlined btn">Close</button>
                  

                  
                  </div>
          </div>
       </Dialog>


    </div>
  )
}
