import React from 'react'
import { useSelector } from 'react-redux'

export const WOConsumption = () => {
   const singleList = useSelector((state)=>state?.workOrderNewList?.workorderConsumptionData)
  console.log(singleList);
  const hasData = singleList?.list && singleList.list.length > 0;
  
  
   return (
    <div className="new-global-table-container work-order-tab-table" style={{maxHeight:"48vh",border:"1px solid #000" }}>
    <table>
     <thead>
         <tr>
             <th>Product</th>
             <th>Type</th>
             <th>Model</th>
             <th>UOM</th>
             <th>Consumed Qty</th>
             <th>Reff</th>
          </tr>
     </thead>
     <tbody>
     {
        hasData ? singleList?.list?.map((r,i)=>(
           <tr>
             <td>{r?.productName}</td>
             <td>{r?.typeName}</td>
             <td>{r?.customer}</td>
             <td>{r?.uom}</td>
             <td>{r?.consumedQuantity}</td>
             <td>{r?.customer}</td>
         </tr>
         )):(
          <tr>
            <td colSpan={6}>NO DATA</td>
          </tr>
         )
       }
    

     </tbody>
    
       
        
      </table>
    </div>
  )
}
