import React, { useEffect, useState } from "react";
import CategorySearchandFilter from "../../../../Single Components/CategorySearchandFilter";
import CustomPagination from "../../../../Single Components/CustomPagination";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { listShiftAPI } from "../GeneralAPI";

export const PosShift = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [FormDataInfo, setFormDataInfo] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);

  //redux store
  const shiftListData = useSelector((state) => state.GeneralSlice);
  const filtereDataOptions = useSelector((state) => state.listCategory);
  const totalPages =shiftListData?.shiftListData?.pages ;

  // console.log(shiftListData?.shiftListData);
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch);                                         
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };

  const handleClickRow = (row) => {
    // console.log(row)
    // navigate("/userdashboard/pos/general/shiftsingleview");
    navigate("/userdashboard/pos/general/shiftsingleview", {
      state: { viewId: row._id } 
    })
  };

  //initial render
  useEffect(() => {
    if(FormDataInfo.length!==0){ 
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
      !loadData && listShiftAPI({   
    branchList: FormDataInfo?.branchList,
    search: FormDataInfo?.search,
    index: currentPage-1,
  });
  }

  }, [FormDataInfo, currentPage]);

  return (
    <div className="pos-conatiner">
      <div className="new-order-top-container">
        <div className="new-order-top-left">
          <p className="shift-label">Shift</p>
        </div>
        <div className="new-order-top-right" style={{ alignItems: "center" }}>
          <div
            className="new-order-top-right-btn"
            style={{ alignItems: "center" }}
          >
            <div className="pos-print-btn">
              <i class="bi bi-printer"></i>
            </div>
            <div className="pos-download-btn">
              <i class="bi bi-arrow-down-circle"></i>
            </div>
            {/* <div className="new-global-single-input" style={{width:"10%",flexDirection:"row",justifyContent:"end"}}> */}
            <CategorySearchandFilter
            
              onData={handleDataFromChild}
              // isCreatedBy={}
              // isStatus={}
              onBranchSelect={handleBranchSelect}
              isSearch={true}
              isDate={false}
              //  isPos={true}
            />
            {/* </div> */}
          </div>
        </div>
      </div>
      <hr className="pos-hr" />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <CustomPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          setFormDataInfo={setFormDataInfo}
        />
      </div>

      <div
        className="new-global-table-container shift-table"
        style={{ paddingTop: "0 42px" }}
      >
        <table>
          <thead>
            <tr>
              {/* <th>Date</th> */}
              <th>Shift No</th>
              <th>Branch</th>
              <th>Employee</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            { shiftListData?.shiftListData?.list?.length!==0 ? shiftListData?.shiftListData?.list?.map((r, i) => (
              <tr onClick={() => handleClickRow(r)}>
                {/* <td>{r?.customer}</td> */}
                <td>{r?.shiftNo}</td>
                <td>{r?.branchName}</td>
                <td>{r?.employee}</td>
                <td>{r?.startDate}</td>
                <td>{r?.endDate}</td>
                <td>
                  <p
                    className={
                      r?.status === "Active"
                        ? "shift-status-active-table"
                        : "shift-status-end"
                    }
                  >
                    {r?.status === "Active" ? "Active" : "Ended"}
                  </p>
                </td>
              </tr>
             
            ))
            :
            <tr>
            <td colSpan={6}>NO DATA</td>
        </tr>}
          </tbody>
        </table>
      </div>
    </div>
  );
};
