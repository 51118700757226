import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import "../../../../../css/Purchase Manager/GRN/GRNsingleView.css";
import postRibbon from "../../../../../Assets/statusRibbon/post.png";
import Draft from "../../../../../Assets/statusRibbon/draft.png";
import returnRibbon from "../../../../../Assets/statusRibbon/return.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { companyProfileInfoForUser, viewCompanyProfileInfoAPICall } from "../../../../../API/Settings/Company Settings/companySettingsAPI";
import { useRef } from "react";
import ReactToPrint from "react-to-print";
import { convertDateFormat, today } from "../../../../../Js/Date";
import { getGRNsingleViewAPICall } from "./grnListingAPI";
import { FormatAlignCenter } from "@mui/icons-material";
import Template1 from "../../../SalesNew/SalesOrders/SalesQuotation/Template1";
import Template2 from "../../../SalesNew/SalesOrders/SalesQuotation/Template2";
import { getTemplateAPI } from "../../../SalesNew/SalesOrders/SalesQuotation/salesQuatationAPI";
import { set_Back_button_action } from "../../../../Single Components/listSlice/filterCategorySlice";
import { InvoiceContent } from "../../../Accounts/PrintComponents/printTemplates/common/InvoiceContent";
import { printSection } from "../../../Accounts/PrintComponents/printTemplates/common/enum";
const GoodsReceivedNotesSingleView = () => {
  let paymentsPrint = useRef();
   let navigate = useNavigate();
  let paymentsPrintNew = useRef();
  const componentRef = useRef();

  const dispatch = useDispatch();

   const[path,setPath] = useState(false);
  const [selectedHeading, setSelectedHeading] = useState("Single View");
 
  const GRNid =localStorage.getItem("singleViewByIdgrn")
  const template =useSelector((state)=>state?.salesQuatationSlice?.setTemplate)

  const singleViewID=localStorage.getItem("singleViewById")
  const singleViewId=localStorage.getItem("singleViewPoById")
 // console.log(singleViewID);
  //data of selected list
  const singleList = useSelector(
    (state) => state?.goodsReceivedNoteUpdatedSlice?.rowData
  );
console.log(singleList);

  // company details and info
  const companyProfileData =useSelector(
    (state) => state?.companyProfileSlice?.userValue)

  const formValuesInitialState = {
    status: "",
    companyLogo: "",
    companyName: "",
    companyAddress: "",
    companyContact: "",

    vendorName: "",
    vendorAddress: "",
    vendorContact: "",

    customerName: "",
    customerAddress: "",
    customerContact: "",
    customerEmail:"",
    trn:"",

    address:"Vendor",
    dateN1:"Return Date",
    dateN2:"Invoice Date",

    termsAndCondition:"",

    refN:"PO No",
    lpo:"",
    ref:"",
    sequence:"GRN No",

    purchaseOrderName:"",

    name: "",
    firstDate: "",
    secondDate: "",
    subtotal:"",
    total: "",
    totalOrderQty:"",
    totalReceivedQty:"",
    totalAmount:"0",
    payTerm:"",
    label:"GRN",
  };
  const [formValues, setFormValues] = useState(formValuesInitialState);
  const [data, setData] = useState([]);
  const [tax, setTax] = useState([]);
  const [domain, setDomain] = useState("");

const [head,setHead] = useState("")
const sequence = localStorage.getItem("sequence")
  const handleBackpage=()=>{
    localStorage.removeItem("singleViewByIdgrn")
    navigate("/userdashboard/purchaseManager/NewPurchaseOrder");
  }
  const handleEdit=()=>{
    localStorage.setItem("singleViewById",singleList?.purchase_id)
    navigateTo("/userdashboard/purchaseManager/CreateNewPurchaseOrder")
  }
  const changeColor = (heading) => {
    setSelectedHeading(heading);
  };
  useEffect(() => {
    setHead(sequence);
    getTemplateAPI();
    companyProfileInfoForUser();
    const idToUse = singleViewID || singleViewId; // Use singleViewId if singleViewID is undefined
    getGRNsingleViewAPICall({ _id: idToUse });

    
  }, [singleViewID, singleViewId]);
 
  useEffect(() => {
    setFormValues({
      ...formValues,
      status: singleList?.status,
      companyLogo: companyProfileData?.logo || "",
      companyName: companyProfileData?.companyName || "",
      companyAddress: companyProfileData?.companyAddress || "",
      companyContact: companyProfileData?.contactNumber || "",

      vendorName: singleList?.supplierName || "",
      vendorAddress: singleList?.address || "",
      vendorContact: singleList?.contact || "",

      customerName: singleList?.vendorAddress?.vendorName || "",
      customerAddress: singleList?.vendorAddress?.streetName || "",
      customerContact: singleList?.vendorAddress?.mobileNo|| "",
      customerEmail: singleList?.vendorAddress?.email || "",
      trn: singleList?.vendorAddress?.TRN || "",
      payTerm:singleList?.paymentTerms|| "",
      purchaseOrderName:singleList?.purchaseOrderName  || "",

      termsAndCondition:singleList?.termsAndConditions || "",

      ref: singleList?.reference ||"",
      lpo: singleList?.LpoNo ||"",
      name: singleList?.name || "",
      firstDate: singleList?.date || "",
      secondDate: singleList?.dueDate || "",
      total: singleList?.total || "",
    });
    setData(singleList?.productInfo);
    setTax(singleList?.taxDetails)

  }, [singleList, companyProfileData]);

  
  

  useEffect(() => {
    if (singleViewId !== null || GRNid !== null ) {
      setPath(true)
    }
    else{
      setPath(false)
    }
  }, []);

  useEffect(()=>{
    let filteredArray=[]
    if (data !==undefined && data.length !== 0) {
       filteredArray = data?.filter((item) => (
        item.line_type === "lines"
      ))
    }
    let totalOrderQty=filteredArray.length!==0?filteredArray.reduce((sum,item)=>sum+item.orderQuantity,0):0
    let totalReceivedQty=filteredArray.length!==0?filteredArray.reduce((sum,item)=>sum+(item.returnQuantity || item.recievedQuantity),0):0
    setFormValues({...formValues,totalOrderQty:totalOrderQty,totalReceivedQty:totalReceivedQty})
  },[data])
  let navigateTo = useNavigate();
  return (
    <div className="main-container">
      {path && (
       <div className="first-main-container-div">
        <div className="first-main-heading-div" id="headingDiv">
          <p
            style={{
              marginLeft: "14px",borderRight:"2px solid #abaebb",
              color: selectedHeading === "Purchase Order" ?"black" : "gray",
            }}
            onClick={() => {changeColor("Purchase Order");handleBackpage()}}
          >
           Purchase Order
          </p>
          {/* <p
            style={{
              color: selectedHeading === "purchase Order GRN" ? "black" : "gray",
            }}
           // onClick={() => changeColor("purchase Order GRN")}
          >
           GRN purchase Order
          </p> */}
          <p
            style={{borderRight:"2px solid #abaebb",
              color: selectedHeading === "purchase" ?  "black" : "gray",
            }}
            onClick={() => {changeColor("purchase");handleEdit()}}
          >
          {head}
          </p>
           <p
            style={{borderRight:"2px solid #abaebb",
              color: selectedHeading === "Single View" ? "black" : "gray",
            }}
           // onClick={() => changeColor("purchase Order GRN")}
          >
         {formValues?.name}
          </p>
        </div>
        </div>
      )}
      <div className="first-div-container">
        <div className="post-ribbon">
          {formValues?.status === "Drafted" && <img src={Draft} alt="" />}
          {formValues?.status === "Posted" && <img src={postRibbon} alt="" />}
          {formValues?.status === "Returned" && <img src={returnRibbon} alt="" />}
        </div>
        <Tooltip title="Back">
          <IconButton>
            <i
              className="bi bi-chevron-left"
              style={{ fontSize: "30px", color: "black", cursor: "pointer" }}
              onClick={() =>
                {navigateTo("/userdashboard/purchaseManager/GoodsReceivedNote")
                dispatch(set_Back_button_action({ backButtonClickAction: true }));}
              }
            ></i>
          </IconButton>
        </Tooltip>
        <Tooltip title="Edit">
          <IconButton>
            <i
              class="bi bi-pencil-square edit-icon1"
              onClick={() =>
               {
                path &&  localStorage.setItem("singleViewById",singleList?._id)
                navigateTo(
                  "/userdashboard/purchaseManager/GoodsReceivedNote/add"
                )}
              }
            ></i>
          </IconButton>
        </Tooltip>
        <ReactToPrint
          trigger={() => (
            <IconButton className="printer-div">
              <i style={{ color: "black" }} class="bi bi-printer"></i>
            </IconButton>
          )}
          content={() => paymentsPrint}
        />
        <Tooltip title="Share">
          <IconButton>
            <i class="bi bi-share" style={{ backgroundColor:"#9797e9", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
          </IconButton>
        </Tooltip>

        {/* <ReactToPrint
          trigger={() => (
            <IconButton className="printer-div" style={{ padding: "1px" }}>
              <i style={{ color: "white", backgroundColor: " #d787e7", fontSize: "medium", padding: "8px", borderRadius: "2px" }} class="bi bi-printer"></i>
            </IconButton>
          )}
          content={() => paymentsPrintNew}
        /> */}
                <ReactToPrint
            // The print button
            trigger={() => (
              <IconButton className="printer-div" style={{ padding: "1px" }}>
                <i
                  style={{
                    color: "white",
                    backgroundColor: " #d787e7",
                    fontSize: "medium",
                    padding: "8px",
                    borderRadius: "2px",
                  }}
                  class="bi bi-printer"
                ></i>
              </IconButton>
            )}
            // The content to be printed (InvoiceContent component)
            content={() => componentRef.current}
            // Set the page style for printing
            pageStyle="@page { size: A4; margin: 0; }"
          />


        <Tooltip title="Download">
          <IconButton>
            <i class="bi bi-arrow-down-circle" style={{ backgroundColor: "rgb(255 118 133 / 69%)", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
          </IconButton>
        </Tooltip>
      </div>
      <hr className="line" />
      <div className="second-main-container">
        <div className="left-side-div">
          <img src={formValues?.companyLogo} alt={formValues?.companyName} />
          <p>{formValues?.companyAddress}</p>
          <p>{formValues?.companyContact}</p>

          <h1 className="vendor-head">Vendor Address</h1>
          <p>{formValues?.customerName}</p>
          <p>{formValues?.customerEmail}</p>
          <p>{formValues?.customerAddress}</p>
          <p>{formValues?.customerContact}</p>

          
        </div>
        <div className="right-side-div">
          <h1>GRN</h1>
          <p>{formValues?.name}</p>
          <div className="date-div" style={{ width: "171px" }}>
            <p>
              <span class="date-container-single"> Date:</span>
              {formValues?.firstDate}
            </p>
            {/* <p>--</p> */}
          </div>
        </div>
      </div>

      <div className="Table-container">
        <div className="Table-data">
          <table>
            <thead>
              <tr style={{ backgroundColor: "#eaeef9" }}>
                <th>No</th>
                <th>Product</th>
                <th>UOM</th>
                <th>Order Qty</th>
                {formValues.status === "Returned"? <th>Return Quantity </th>  :<th>Received Quantity</th>}
              </tr>
            </thead>
            <tbody>

           {data?.filter((item) => item['line_type'] === 'lines')?.map((item, index) => (
                <tr
                  key={index}
                  style={{
                    backgroundColor: index % 2 === 0 ? "#f1f2f5" : "white",
                  }}
                >
                  <td>{index + 1}</td>
                  <td>{item.product}</td>
                  <td>{item.purchaseUomName}</td>
                  <td>{item.orderQuantity}</td>
                  <td>{ formValues?.status === "Returned" ? item.returnQuantity  :item.recievedQuantity}</td>
                </tr>
              ))}
              <tr>
                <td colSpan={3} style={{textAlign:"end",backgroundColor:"rgb(234, 238, 249",fontWeight:"600"}}>Total</td>
                <td style={{backgroundColor:"rgb(234, 238, 249"}}>{formValues.totalOrderQty}</td>
                <td style={{backgroundColor:"rgb(234, 238, 249"}}>{formValues.totalReceivedQty}</td>
              </tr>
            </tbody>
          </table>

          {/* <div className="last-total-div">
            <div className="Total-div">
              <div>
                <h1>Total</h1>
              </div>
              <div className="sub-total">
                <h1>--</h1>
              </div>
              <div className="sub-total-amount">
                <h1>--</h1>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      {/* payments print view */}
      <div style={{ display: "none" }}>
        <div ref={(el) => (paymentsPrint = el)}>
          <div className="print-view-top-container">
            <div
              className="print-view-top-left-container"
              style={{ width: "25%" }}
            >
              <p></p>
              <p></p>
            </div>
            <div
              className="print-view-top-middle-container"
              style={{ width: "50%" }}
            >
              {/* <h3>--</h3>

              <h3>--</h3> */}

              <p>GRN</p>
            </div>

            <div
              className="print-view-top-right-container"
              style={{ width: "25%" }}
            >
              <p>{convertDateFormat(today)}</p>
              <p>{""}</p>
            </div>
          </div>

          <div className="second-main-container">
            <div className="left-side-div">
              <img
                src={formValues?.companyLogo}
                alt={formValues?.companyName}
              />
              <p>{formValues?.companyAddress}</p>
              <p>{formValues?.companyContact}</p>

              <h1 className="vendor-head">Vendor Address</h1>
              <p>{formValues?.vendorName}</p>
              {/* <p>--</p>
              <p>--</p> */}

              <h1 className="vendor-head">Deliver To</h1>
              {/* <p>--</p>
              <p>--</p>
              <p>--</p> */}
            </div>
            <div className="right-side-div">
              <h1>GRN</h1>
              <p>{formValues?.name}</p>
              <div className="date-div" style={{ width: "171px" }}>
                <p>
                  <span class="date-container-single">Date:</span>
                  {formValues?.firstDate}
                </p>
                {/* <p>--</p> */}
              </div>
            </div>
          </div>

          <div className="Table-container" style={{ padding: "0px 35px" }}>
            <div className="Table-datas">
              <table style={{ overflow: "none" }}>
                <thead>
                  <tr style={{ backgroundColor: "#eaeef9" }}>
                    <th>No</th>
                    <th>Product</th>
                    <th>UOM</th>
                    <th>Order Qty</th>
                    <th>Received Qty</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((item, index) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: index % 2 === 0 ? "#f1f2f5" : "white",
                      }}
                    >
                      <td>{index + 1}</td>
                      <td>{item.product}</td>
                      <td>{item.uomName}</td>
                      <td>{item.orderQuantity}</td>
                      <td>{item.recievedQuantity}</td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={3} style={{ textAlign: "end", backgroundColor: "rgb(234, 238, 249", fontWeight: "600" }}>Total</td>
                    <td style={{ backgroundColor: "rgb(234, 238, 249" }}>{formValues.totalOrderQty}</td>
                    <td style={{ backgroundColor: "rgb(234, 238, 249" }}>{formValues.totalReceivedQty}</td>
                  </tr>
                </tbody>
              </table>
              {/* <div className="last-total-div">
                <div className="Total-div">
                  <div>
                    <h1>Total</h1>
                  </div>
                  <div className="sub-total">
                    <h1>--</h1>
                  </div>
                  <div className="sub-total-amount">
                    <h1>--</h1>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="terms">
            <h1>Terms & Conditions</h1>
            <hr className="lines" />
          </div>
        </div>
      </div>
      <div style={{ display: "none" }}>
  {/* <div ref={(el) => (paymentsPrintNew = el)}>
    {template?.templateModel === 'template1' ? (
      <Template1 data={data} formValues={formValues} tax={tax} />
    ) : (
      <Template2 data={data} formValues={formValues} tax={tax}/>
    )}
  </div> */}
 <div ref={(el) => (paymentsPrintNew = el)}>
          <InvoiceContent
            invoiceDetails={{
              ...formValues,
              printSection: printSection.goodsReceiveNote,
            }}
            ref={componentRef}
            printData={data}
            taxDetails={tax}
            qrData={domain}
          />
        </div>
</div>
    </div>
  );
};

export default GoodsReceivedNotesSingleView;
