import { IconButton, Tooltip } from "@mui/material";
//import  "../../../../css/Purchase Manager/StockAdjustment/requestForQuatationNw.css"
import React from "react";
import postRibbon from "../../../../../Assets/statusRibbon/post.png";
import draftRibbon from "../../../../../Assets/statusRibbon/draft.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { companyProfileInfoForUser, viewCompanyProfileInfoAPICall } from "../../../../../API/Settings/Company Settings/companySettingsAPI";
import { useRef } from "react";
import ReactToPrint from "react-to-print";
import { convertDateFormat, today } from "../../../../../Js/Date";
import { purchaseWoSoSingleViewApi } from "./purchaseWPOAPI";
import paidRibbon from "../../../../../Assets/statusRibbon/paid.png"
import { getTemplateAPI } from "../../../SalesNew/SalesOrders/SalesQuotation/salesQuatationAPI";
import Template1 from "../../../SalesNew/SalesOrders/SalesQuotation/Template1";
import Template2 from "../../../SalesNew/SalesOrders/SalesQuotation/Template2";
import { set_Back_button_action } from "../../../../Single Components/listSlice/filterCategorySlice";
import { InvoiceContent } from "../../PrintComponents/printTemplates/common/InvoiceContent";
import { printSection } from "../../PrintComponents/printTemplates/common/enum";

export const PurchaseWOPOsingleView = () => {
  let singleViewId = localStorage.getItem("singleViewById");
  let paymentsPrint = useRef();
  let paymentsPrintNew = useRef();
  const componentRef = useRef();

  //data of selected List
  const singleList = useSelector((state) => state?.purchaseWPOSlice?.rowData);
  const decimalPosition = localStorage.getItem("decimalPosition");
  // company details and info
  const companyProfileData =useSelector(
    (state) => state?.companyProfileSlice?.userValue)
  const template =useSelector((state)=>state?.salesQuatationSlice?.setTemplate)

  const dispatch = useDispatch();

console.log(singleList);
  //initial value
  const formValuesInitialState = {
    status: "",
    companyLogo: "",
    companyName: "",
    companyAddress: "",
    companyContact: "",
    companyTRN: "",

    customerName: "",
    customerAddress: "",
    customerContact: "",
    customerEmail: "",
    trn:"",

    dateN1:"Return Date",
    dateN2:"Invoice Date",

    termsAndCondition:"",

    name: "",
    firstDate: "",
    secondDate: "",
    expiry:"",
    
    address:"Vendor ",
    sequence:"Bill No",
    lpo:"",
    ref:"",
    subtotal: "",
    total: "",
    totalAmount:"",
    payTerm:"",
    label:"PURCHASE W/PO"
  };
  const [formValues, setFormValues] = useState(formValuesInitialState);
  const [tax, setTax] = useState([]);
  const [data, setData] = useState([]);
  const [domain, setDomain] = useState("");


  useEffect(() => {
    setFormValues({
      ...formValues,
      status: singleList?.status,
      companyLogo: companyProfileData?.logo || "",
      companyName: companyProfileData?.companyName || "",
      companyAddress: companyProfileData?.companyAddress || "",
      companyContact: companyProfileData?.contactNumber || "",
      companyTRN: companyProfileData?.companyTRN || "",

      customerName: singleList?.vendorAddress?.vendorName|| "",
      customerAddress: singleList?.vendorAddress?.streetName || "",
      customerContact: singleList?.vendorAddress?.mobileNo || "",
      customerEmail: singleList?.vendorAddress?.email || "",
      trn: singleList?.vendorAddress?.TRN || "",

      termsAndCondition:singleList?.termsAndCondition || "",


      lpo: singleList?.LpoNo ||"",
      ref: singleList?.billRef ||"",      
      subtotal: singleList?.subTotal || "",
      name: singleList?.NAME || "",
      expiry: singleList?.accountingDate|| "",
      secondDate: singleList?.billDate || "",
      totalAmount: singleList?.totalAmount || "",
      total: singleList?.total || "",
      payTerm: singleList?.PAYMENTTERM || "",
    });
    setData(singleList?.productInfo);
    setTax(singleList?.taxDetails);
  }, [singleList, companyProfileData]);

  // api to fetch companyprofile data
  useEffect(() => {
    companyProfileInfoForUser();
    getTemplateAPI();
    if(singleViewId){
      purchaseWoSoSingleViewApi({_id:singleViewId})
    }
  }, []);

  let navigateTo = useNavigate();
  return (
    <div className="main-container">
      <div className="first-div-container">
        <div className="post-ribbon">
          {formValues?.status === "DRAFT" && <img src={draftRibbon} alt="" />}
          {formValues?.status === "POSTED" && <img src={postRibbon} alt="" />}
          {formValues?.status==="PAID" && <img src={paidRibbon} alt="" />}

        </div>
        <Tooltip title="Back">
          <IconButton>
            <i
              className="bi bi-chevron-left"
              style={{ fontSize: "30px", color: "black", cursor: "pointer" }}
              onClick={() =>{
                localStorage.removeItem("singleViewById");
                navigateTo("/userdashboard/accounts/vendor/purchasewopo")
                dispatch(set_Back_button_action({ backButtonClickAction: true }));
              }}
            ></i>
          </IconButton>
        </Tooltip>
        <Tooltip title="Edit">
          <IconButton>
            <i
              class="bi bi-pencil-square edit-icon1"
              onClick={() =>
                navigateTo("/userdashboard/accounts/vendor/purchasewopo/create")
              }
            ></i>
          </IconButton>
        </Tooltip>
        {/* <ReactToPrint
          trigger={() => (
            <IconButton className="printer-div">
              <i style={{ color: "black" }} class="bi bi-printer"></i>
            </IconButton>
          )}
          content={() => paymentsPrint}
        /> */}
       <Tooltip title="Share">
          <IconButton>
            <i class="bi bi-share" style={{ backgroundColor: "#9797e9", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
          </IconButton>
        </Tooltip>

        {/* <ReactToPrint
          trigger={() => (
            <IconButton className="printer-div" style={{ padding: "1px" }}>
              <i style={{ color: "white", backgroundColor: " #d787e7", fontSize: "medium", padding: "8px", borderRadius: "2px" }} class="bi bi-printer"></i>
            </IconButton>
          )}
          content={() => paymentsPrintNew}
        /> */}
        <ReactToPrint
            // The print button
            trigger={() => (
              <IconButton className="printer-div" style={{ padding: "1px" }}>
                <i
                  style={{
                    color: "white",
                    backgroundColor: " #d787e7",
                    fontSize: "medium",
                    padding: "8px",
                    borderRadius: "2px",
                  }}
                  class="bi bi-printer"
                ></i>
              </IconButton>
            )}
            // The content to be printed (InvoiceContent component)
            content={() => componentRef.current}
            // Set the page style for printing
            pageStyle="@page { size: A4; margin: 0; }"
          />

        <Tooltip title="Download">
          <IconButton>
            <i class="bi bi-arrow-down-circle" style={{ backgroundColor: "rgb(255 118 133 / 69%)", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
          </IconButton>
        </Tooltip>
      </div>
      <hr className="line" />
      <div className="second-main-container">
        <div className="left-side-div">
          <img src={formValues?.companyLogo} alt=" " />
          <p>{formValues?.companyName}</p>
          <p>{formValues?.companyAddress}</p>
          <p>{formValues?.companyContact}</p>

          <h1 className="vendor-head">Vendor Address</h1>
          <p>{formValues?.customerName}</p>
          <p>{formValues?.customerAddress}</p>
          <p>{formValues?. customerContact}</p>
          <p>{formValues?.customerEmail}</p>
          {/* <p>--</p>
          <p>--</p> */}
        </div>
        <div className="right-side-div">
          <h1>Purchase W/PO </h1>
          <p>{formValues?.name}</p>
          <div className="date-div">
            <p>
              <span class="date-container-single">Date:</span>
              {formValues?.expiry}
            </p>
          </div>
        </div>
      </div>

      <div className="Table-container">
        <div className="Table-data">
          <table>
            <thead>
              <tr style={{ backgroundColor: "#eaeef9" }}>
                <th>No</th>
                <th>Product</th>
                <th>UOM</th>
                <th>QTY</th>
                <th>Unit Price</th>
                <th>Discount</th>
                <th>Tax</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => (
                <tr
                  key={index}
                  style={{
                    backgroundColor: index % 2 === 0 ? "#f1f2f5" : "white",
                  }}
                >
                  <td>{index + 1}</td>
                  <td>{item.product}</td>
                  <td>{item.uomName}</td>
                  <td>{item.qty}</td>
                  <td>{item.unitPrice}</td>
                  <td>{item.discount}</td>
                  <td
                    style={{
                      display: "flex",
                      placeContent: "center",
                      marginTop: "10px",
                    }}
                  >
                    {item.taxes?.map((r, i) => (
                      <p key={i} className="tax" style={{ margin: "1px" }}>
                        {r?.amount}%
                      </p>
                    ))}
                  </td>
                  <td>{item.lineTotal?.toFixed(decimalPosition)}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="last-total-div">
            <div className="sub-total">
              {/* <h1>Sub Total</h1>
                <p>Discount(2.00%)</p>
                <p>standard Rate(2.00%)</p>
                <hr className='line-2' /> */}
              <h1>Total</h1>
            </div>
            <div className="sub-total-amount">
              {/* <h1>$124,754.00</h1>
                <p>4000</p>
                <p>5000</p>
                <hr className='line-3' /> */}
              <h1>{formValues.total}</h1>
            </div>
          </div>
        </div>
      </div>

      {/* payments print view */}
      <div style={{ display: "none" }}>
        <div ref={(el) => (paymentsPrint = el)}>
          <div className="print-view-top-container">
            <div
              className="print-view-top-left-container"
              style={{ width: "25%" }}
            >
              <p></p>
              <p></p>
            </div>
            <div
              className="print-view-top-middle-container"
              style={{ width: "50%" }}
            >
              {/* <h3>--</h3>

              <h3>--</h3> */}

              <p>Purchase W/PO</p>
            </div>

            <div
              className="print-view-top-right-container"
              style={{ width: "25%" }}
            >
              <p>{convertDateFormat(today)}</p>
              <p>{""}</p>
            </div>
          </div>

          <div className="second-main-container">
            <div className="left-side-div">
              <img
                src={formValues?.companyLogo}
                alt={formValues?.companyName}
              />
              <p>{formValues?.companyAddress}</p>
              <p>{formValues?.companyContact}</p>

              <h1 className="vendor-head">Vendor Address</h1>
              <p>{formValues?.vendorName}</p>
              {/* <p>--</p>
              <p>--</p> */}
            </div>
            <div className="right-side-div">
              <h1>Purchase W/PO </h1>
              <p>{formValues?.name}</p>
              <div className="date-div">
                <p>
                  <span class="date-container-single">Date:</span>
                  {formValues?.Date}
                </p>
              </div>
            </div>
          </div>

          <div className="Table-container" style={{ padding: "0px 35px" }}>
            <div className="Table-datas">
              <table style={{ overflow: "none" }}>
                <thead>
                  <tr style={{ backgroundColor: "#eaeef9" }}>
                    <th>No</th>
                    <th>Product</th>
                    <th>Account</th>
                    <th>UOM</th>
                    <th>QTY</th>
                    <th>Unit Price</th>
                    <th>Discount</th>
                    <th>Tax</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((item, index) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: index % 2 === 0 ? "#f1f2f5" : "white",
                      }}
                    >
                      <td>{index + 1}</td>
                      <td>{item.product}</td>
                      <td>{item.account}</td>
                      <td>{item.uomName}</td>
                      <td>{item.qty}</td>
                      <td>{item.unitPrice}</td>
                      <td>{item.discount}</td>
                      <td
                        style={{
                          display: "flex",
                          placeContent: "center",
                          marginTop: "10px",
                        }}
                      >
                        {item.taxes?.map((r, i) => (
                          <p key={i} className="tax" style={{ margin: "1px" }}>
                            {r?.amount}%
                          </p>
                        ))}
                      </td>
                      <td>{item.cost}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="last-total-div">
                <div className="sub-total">
                  {/* <h1>Sub Total</h1>
            <p>Discount(2.00%)</p>
            <p>standard Rate(2.00%)</p>
            <hr className='line-2' /> */}
                  <h1>Total</h1>
                </div>
                <div className="sub-total-amount">
                  {/* <h1>$124,754.00</h1>
            <p>4000</p>
            <p>5000</p>
            <hr className='line-3' /> */}
                  <h1>{formValues.total}</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="terms">
            <h1>Terms & Conditions</h1>
            <hr className="lines" />
          </div>
        </div>
      </div>
      <div style={{ display: "none" }}>

  {/* <div ref={(el) => (paymentsPrintNew = el)}>
    {template?.templateModel === 'template1' ? (
      <Template1 data={data} formValues={formValues} tax={tax} />
    ) : (
      <Template2 data={data} formValues={formValues} tax={tax}/>
    )}
  </div> */}
  <div ref={(el) => (paymentsPrintNew = el)}>
          <InvoiceContent
            invoiceDetails={{ ...formValues, printSection: printSection.purchaseWPO }}
            ref={componentRef}
            printData={data}
            taxDetails={tax}
            qrData={domain}
          />
        </div>
</div>
    </div>
  );
};
