import React from 'react'
import { useSelector } from 'react-redux'

export const WOMeasurments = () => {
  const singleList = useSelector((state)=>state?.workOrderNewList?.workorderMeasurementData)
//  console.log(singleList);
 const hasDAta =singleList?.measurementList?.length >0 && singleList?.measurementList
  return (
    <>
    <div className="new-global-table-container work-order-tab-table" style={{maxHeight:"48vh",border:"1px solid #000" }}>
    <table>
     <thead>
         <tr>
             <th>Product</th>
             <th>Customer</th>
             <th>Type</th>
             <th>Model</th>
             <th>Length</th>
             <th>Shoulder</th>
             <th>Sleeve</th>
             <th>Chest</th>
             <th>Stomach</th>
             <th>S.L Chest</th>
             <th>S.L So</th>
             <th>Neck</th>
             <th>Bottom</th>
             <th>Mar Size</th>
             <th>Mar Model</th>
             <th>Cuff</th>
          </tr>
     </thead>
     <tbody>
     { hasDAta ? singleList?.measurementList?.map((r,i)=>(
           <tr key={i}>
             <td>{r?.product}</td>
             <td>{r?.customerName}</td>
             <td>{r?.itemType}</td>
             <td>{r?.modelType}</td>
             <td>{r?.length}</td>
             <td>{r?.shoulder}</td>
             <td>{r?.sleeve}</td>
             <td>{r?.chest}</td>
             <td>{r?.stomach}</td>
             <td>{r?.slChest}</td>
             <td>{r?.slSo}</td>
             <td>{r?.neck}</td>
             <td>{r?.bottom}</td>
             <td>{r?.marSize}</td>
             <td>{r?.marModel}</td>
             <td>{r?.cuff}</td>
         </tr>
         )): (
          <tr><td colSpan={16} >NO DATA</td></tr>
         )
       }
    

     </tbody>
    
       
        
      </table>
    </div>
   
     </>
  )
}
