import { Autocomplete, TextField } from "@mui/material";

const commonStyle = { width: '30%' };
export const AutocompleteField = ({ label, options, getOptionLabel,value,onChange,onAdd,fieldKey,isAddBtn}) => (
    <div className="new-global-single-input" style={commonStyle}>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={options||[]}
        getOptionLabel={getOptionLabel}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label={label} focused />}
        renderOption={(props, option) => (
          <li {...props} key={option._id}>
            <span>{getOptionLabel(option)}</span>
          </li>
        )}
        value={value}
        onChange={(event,newValue) => onChange(fieldKey,newValue)}
      />
      {
        isAddBtn === false ? "" :
        <p 
        className="doc-validation-alert" 
        style={{ color: 'blue', cursor: 'pointer' }}
        onClick={() => onAdd(label)}
        >Add</p>
      }
      
    </div>
  );


  export const TextFieldComponent = ({ label, sx, style, fieldKey, value, onChange }) => (
    <div className="new-global-single-input" style={style || { width: '30%' }}>
      <TextField
        id={`outlined-${label}`}
        label={label}
        variant="outlined"
        focused
        sx={sx}
        value={value}
        onChange={(event) => onChange(fieldKey,event.target.value)}
      />
    </div>
  );