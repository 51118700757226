import React, { useEffect } from 'react'
import { switch_to_pos_screen } from '../PosNewSlice';
import { useDispatch } from 'react-redux';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter';

export const AlterationNew = () => {
  const dispatch = useDispatch();
  const navigate=useNavigate()

  const demo=[
    {customer:"Nihad",mobile:"1111",lastOrder:""},
    {customer:"Nihad",mobile:"1111",lastOrder:""},
    {customer:"Nihad",mobile:"1111",lastOrder:""},
    {customer:"Nihad",mobile:"1111",lastOrder:""},
    {customer:"Nihad",mobile:"1111",lastOrder:""},
    {customer:"Nihad",mobile:"1111",lastOrder:""},
    {customer:"Nihad",mobile:"1111",lastOrder:""},
    {customer:"Nihad",mobile:"1111",lastOrder:""},
    {customer:"Nihad",mobile:"1111",lastOrder:""},
    {customer:"Nihad",mobile:"1111",lastOrder:""},
    {customer:"Nihad",mobile:"1111",lastOrder:""},
    {customer:"Nihad",mobile:"1111",lastOrder:""},
    {customer:"Nihad",mobile:"1111",lastOrder:""},
    {customer:"Nihad",mobile:"1111",lastOrder:""},
    {customer:"Nihad",mobile:"1111",lastOrder:""},
    {customer:"Nihad",mobile:"1111",lastOrder:""},
  ]

  const homeBtnClickFn=()=>{
    navigate("/userdashboard/posnew")
  }
  const handleDataFromChild = (data) => {
    
  };
  const createAlteration=()=>{
    navigate("/userdashboard/pos/alteration/create")
  }
  useEffect(() => {
    dispatch(switch_to_pos_screen({ posScreenState: true }));
  }, []);
  return (
    <div className='pos-conatiner'>
         <div className='new-order-top-container'>
        <div className='new-order-top-left'>
           <div className='new-order-top-left-home' onClick={homeBtnClickFn}>
             <HomeIcon/> 
           </div>
           <p className='new-order-top-left-p'>ALTERATION ORDER</p>
        </div>
        <div className='new-order-top-right' style={{alignItems:"center"}}>
        
          <div className='new-order-top-right-btn' style={{alignItems:"center"}}>
            <button onClick={createAlteration} className='new-order-btn'>New Order</button>
            <div className='pos-print-btn'>
               <i class="bi bi-printer"></i>
            </div>
            <div className='pos-download-btn'>
               <i class="bi bi-arrow-down-circle"></i>
            </div>
            {/* <div className="new-global-single-input" style={{width:"10%",flexDirection:"row",justifyContent:"end"}}> */}
               <CategorySearchandFilter
                 statusOptions={[]}
                 onData={handleDataFromChild}
                 // isCreatedBy={}
                 // isStatus={}
                 // onBranchSelect={}
                 isSearch={true}
                //  isPos={true}
          />
            {/* </div> */}
            
          </div>
        </div>
      </div>
      <hr className='pos-hr' />
       <div className="new-global-table-container" style={{ paddingTop: "0 42px" }}>
       <table>
        <thead>
            <tr>
                <th>Date</th>
                <th>Order No</th>
                <th>Shift</th>
                <th>Employee</th>
                <th>Customer</th>
                <th>Mobile</th>
                <th>Order Amount</th>
                <th>Amount Due</th>
                <th>Status</th>
            </tr>
        </thead>
        <tbody>
        {
            demo?.map((r,i)=>(
              <tr key={i} style={{ backgroundColor: i % 2 === 0 ? "white" : "#e9e7e7" }}>
                <td>{r?.customer}</td>
                <td>{r?.customer}</td>
                <td>{r?.customer}</td>
                <td>{r?.customer}</td>
                <td>{r?.customer}</td>
                <td>{r?.customer}</td>
                <td>{r?.customer}</td>
                <td>{r?.customer}</td>
                <td>{r?.customer}</td>
            </tr>
            ))
          }
        </tbody>
          
           
         </table>
       </div>
    </div>
  )
}
