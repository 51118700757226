import axios from "axios";
import store from "../../../../Redux/store";
import {
  get_reward_list,
  get_reward_list_singleView,
  setAlterationList,
  setBillingList,
  setBillingSingleView,
  setPaymentList,
  setShiftList,
  setShiftSingleViewData,
  setworkorderAccessories,
  setworkorderConsumption,
  setworkorderCutter,
  setworkorderDelivery,
  setworkorderMeasurement,
  setworkorderpagenation,
  setworkorderPayments,
  setworkorderSingleView,
  setworkorderTailor,
  showAlert,
} from "./GeneralSlice";
import { HEADERS } from "../../../../API/UrlAndPaths";
import { fetchAllApis } from "../../../../util/utilityFunctions";
import { errorSnackMsg } from "../../../Custom Hooks/SnackBarUtilities";

const snackbarSeverity = {
  success: "success",
  error: "error",
  warning: "warning",
  info: "info",
};

// list of reward points
export const ListRewardPointAPI = async () => {
  await axios
    .post("rewards/customerRewardPointList", HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(get_reward_list(res.data));
        console.log(res.data);
      }
    })
    .catch((err) => {
      store.dispatch(get_reward_list(undefined));
      errorSnackMsg(err.response.data || "Network Failed");
    });
};
export const SingleViewRewardPointAPI = async (body) => {
  await axios
    .post("rewards/customerRewardPointsById", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(get_reward_list_singleView(res.data));
      }
    })
    .catch((err) => {
      store.dispatch(get_reward_list_singleView(undefined));
    });
};

//shift list API
export const listShiftAPI = async (data) => {
  try {
    const res = await axios.post("workorder/viewshiftlist", data, HEADERS);
    if (res.status === 200) {
      console.log(res.data);
      store.dispatch(setShiftList(res.data));
    }
  } catch (error) {
    store.dispatch(setShiftList(null));
  }
};

//shift single view api
export const singleViewShiftAPI = async (data) => {
  try {
    const res = await axios.post(
      "workorder/viewshiftsingle",
      data.body,
      HEADERS
    );
    if (res.status === 200) {
      console.log(res.data);
      store.dispatch(setShiftSingleViewData(res.data));
    }
  } catch (error) {
    store.dispatch(setShiftSingleViewData(null));
  }
};

// alteration list api
export const listAlterationAPI = async (data) => {
  try {
    const res = await axios.post(
      "workorder/viewAlteration",
      data.body,
      HEADERS
    );
    if (res.status === 200) {
      data.setLoadingPage(false);
      // console.log(res.data);
      store.dispatch(setAlterationList(res.data));
    }
  } catch (error) {
    // store.dispatch(setAlterationList(null));
  }
};

// add alteration api
export const addAlterationAPI = async (data) => {
  try {
    const res = await axios.post("workorder/addAlteration", data.body, HEADERS);
    if (res.status === 200) {
      data.handleClear();
      // console.log(data.payload);
      const res = await getAlterationOrderNoAPI({ body: data.payload });
      res.status === 200 && data.setCommonField(res.data);
      data.isLoading(false);
      // console.log(res.data);
      store.dispatch(
        showAlert({
          severity: snackbarSeverity.success,
          message: "Your Work Order has been successfully created",
        })
      );
    }
  } catch (e) {
    console.log(e);
    data.isLoading(false);
    store.dispatch(
      showAlert({
        severity: snackbarSeverity.error,
        message: "Unable to create Work Order. Please try again",
      })
    );
  }
};

// Get new  alteration order no  api

export const getAlterationOrderNoAPI = async (data) => {
  try {
    const res = await axios.post(
      "workorder/generateAltNumber",
      data.body,
      HEADERS
    );
    if (res.status === 200) {
      return res;
    }
  } catch (e) {
    throw e;
  }
};

// list workOrder api
export const listWorkOrderAPI = async (data) => {
  try {
    const res = await axios.post(
      "workorder/viewworkorderforalteration",
      data.body,
      HEADERS
    );
    if (res.status === 200) {
      return res;
    }
  } catch (e) {
    throw e;
  }
};

// list all product color api
export const listProductColorAPI = async (data) => {
  try {
    const res = await axios.post("readymade/color/view", data.body, HEADERS);
    if (res.status === 200) {
      return res;
    }
  } catch (e) {
    throw e;
  }
};

// list all product api
export const listProductAPI = async (data) => {
  try {
    const res = await axios.post(
      "readymade/filterproductbystock",
      data.body,
      HEADERS
    );
    if (res.status === 200) {
      return res;
    }
  } catch (e) {
    throw e;
  }
};

//list customers api
export const listCustomerAPI = async (data) => {
  try {
    const res = await axios.post(
      "account/viewCustomerForDropdown",
      data.body,
      HEADERS
    );
    if (res.status === 200) {
      return res;
    }
  } catch (e) {
    throw e;
  }
};

// list all taxes api
export const listTaxesAPI = async (data) => {
  try {
    const res = await axios.post("settings/taxList", data.body, HEADERS);
    if (res.status === 200) {
      return res;
    }
  } catch (e) {
    throw e;
  }
};

// get active shift
export const getActiveShift = async (data) => {
  try {
    const res = await axios.post(
      "settings/viewactiveshift",
      data.body,
      HEADERS
    );
    if (res.status === 200) {
      console.log(res);
      return res;
    }
  } catch (e) {
    throw e;
  }
};

// single view of alteration API
export const singleViewAlterationAPI = async (data) => {
  try {
    const res = await axios.post("workorder/viewAlterationById", data.body);
    if (res.status === 200) {
      data.setSingleViewData(res.data[0]);

      const payload = {
        ...data.body,
      };

      const apiCalls = [
        { api: singleWorkOrderConceptionAPI, key: "conceptionDetails" },
        { api: singleWorkOrderPaymentAPI, key: "paymentDetails" },
        { api: singleWorkOrderCutterDetailsAPI, key: "cutterDetails" },
        { api: singleWorkOrderTailerDetailsAPI, key: "tailerDetails" },
        { api: singleWorkOrderAccessoryDetailsAPI, key: "accessoriesDetails" },
      ];
      const allResponse = await fetchAllApis(apiCalls, payload);
      // console.log("all response", allResponse);

      const sortedList = () => {
        const finalData = {
          Products: res.data[0].items,
          Payments: [],
          Consumption: [],
          "Cutter Details": [],
          "Tailor Details": [],
          "Accessories Details": [],
        };

        const detailsMapping = {
          conceptionDetails: "Consumption",
          paymentDetails: "Payments",
          cutterDetails: "Cutter Details",
          tailerDetails: "Tailor Details",
          accessoriesDetails: "Accessories Details",
        };

        Object.keys(detailsMapping).forEach((detailKey) => {
          const keyName = detailsMapping[detailKey];

          // Check if the detailKey exists and contains a list
          const detailValue = allResponse[detailKey];

          if (detailValue) {
            // Handle the case when detailValue is an array
            if (Array.isArray(detailValue)) {
              detailValue.forEach((item) => {
                if (item?.list) {
                  finalData[keyName].push(...item.list);
                }
              });
            }
            // Handle the case when detailValue is an object with a list
            else if (detailValue.list) {
              finalData[keyName] = detailValue.list;
            }
          }
        });

        return finalData;
      };

      // console.log("sortedList", sortedList());
      data.setRenderTableData(sortedList());

      data.setIsLoading(false);
      return res;
    }
  } catch (e) {
    console.log(e);
    data.setError(e);
    data.setIsLoading(false);
    throw e;
  }
};

//conception
export const singleWorkOrderConceptionAPI = async (data) => {
  try {
    const response = await axios.post(
      "workorder/workorderconsumptiondetailsview",
      data.body
    );
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    if (error.response && error.response.status === 404) {
      // Handle the 404 error specifically
      return { data: { list: [] } };
    }
    console.log(error);
    throw error;
  }
};
//payments
export const singleWorkOrderPaymentAPI = async (data) => {
  try {
    const response = await axios.post(
      "workorder/workorderpaymentdetailsview",
      data.body
    );
    if (response.status === 200) {
      const modifiedResponse = {
        data: {
          ...response.data,
          list: response.data.paymentList,
        },
        status: 200,
      };
      delete modifiedResponse.data.paymentList;
      return modifiedResponse;
    }
  } catch (error) {
    if (error.response && error.response.status === 404) {
      // Handle the 404 error specifically
      return { data: { list: [] } };
    }
    console.log(error);
    throw error;
  }
};

//cutter details
export const singleWorkOrderCutterDetailsAPI = async (data) => {
  try {
    const response = await axios.post(
      "workorder/workordercutterdetailsview",
      data.body
    );
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    if (error.response && error.response.status === 404) {
      // Handle the 404 error specifically
      return { data: { list: [] } };
    }
    console.log(error);
    throw error;
  }
};
//tailer details
export const singleWorkOrderTailerDetailsAPI = async (data) => {
  try {
    const response = await axios.post(
      "workorder/workordertailordetailsview",
      data.body
    );
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    if (error.response && error.response.status === 404) {
      // Handle the 404 error specifically
      return { data: { list: [] } };
    }
    console.log(error);
    throw error;
  }
};
//accessory details
export const singleWorkOrderAccessoryDetailsAPI = async (data) => {
  try {
    const response = await axios.post(
      "workorder/workorderaccessoriesdetailsview",
      data.body
    );
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    if (error.response && error.response.status === 404) {
      // Handle the 404 error specifically
      return { data: { list: [] } };
    }
    console.log(error);
    throw error;
  }
};

// GeneralBilling
export const listBillingAPI = async (data, setIsLoading) => {
  setIsLoading !== undefined && setIsLoading(true);

  try {
    const res = await axios.post("sales/viewBillOrders", data, HEADERS);
    if (res.status === 200) {
      // console.log(res.data);
      store.dispatch(setBillingList(res.data));
      setIsLoading !== undefined && setIsLoading(false);
    }
  } catch (error) {
    store.dispatch(setBillingList(null));
    errorSnackMsg(error.response.data || "Network Failed");
    setIsLoading !== undefined && setIsLoading(false);
  }
};

// GeneralBillingSingle View
export const listBillingSingleViewAPI = async (data) => {
  try {
    const res = await axios.post("sales/viewBillOrderById", data, HEADERS);
    if (res.status === 200) {
      console.log(res.data);
      store.dispatch(setBillingSingleView(res.data));
    }
  } catch (error) {
    store.dispatch(setBillingSingleView(null));
    errorSnackMsg(error.response.data || "Network Failed");
  }
};
// GeneralPayments
export const listPaymentAPI = async (setIsLoading) => {
  setIsLoading !== undefined && setIsLoading(true);

  try {
    const res = await axios.post("credit/viewPosPayments");
    if (res.status === 200) {
      console.log(res.data);
      store.dispatch(setPaymentList(res.data));
      setIsLoading !== undefined && setIsLoading(false);
    }
  } catch (error) {
    store.dispatch(setPaymentList(null));
    errorSnackMsg(error.response.data || "Network Failed");
    setIsLoading !== undefined && setIsLoading(false);
  }
};

// GeneralWallet
export const listWalletAPI = async (setIsLoading) => {
  setIsLoading !== undefined && setIsLoading(true);

  try {
    const res = await axios.post("credit/viewPosWallet");
    if (res.status === 200) {
      console.log(res.data);
      store.dispatch(setPaymentList(res.data));
      setIsLoading !== undefined && setIsLoading(false);
    }
  } catch (error) {
    store.dispatch(setPaymentList(null));
    errorSnackMsg(error.response.data || "Network Failed");
    setIsLoading !== undefined && setIsLoading(false);
  }
};

// fetch tailor   api
const fetchTailorListApi = async () => {
  try {
    const response = await axios.post("employee/getalltailor");
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    if (error.response && error.response.status === 404) {
      // Handle the 404 error specifically
      return { data: [] };
    }
    console.log(error);
    throw error;
  }
};
// fetch cutter   api
const fetchCutterListApi = async () => {
  try {
    const response = await axios.post("employee/getallcutters");
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    if (error.response && error.response.status === 404) {
      // Handle the 404 error specifically
      return { data: [] };
    }
    console.log(error);
    throw error;
  }
};

// fetch product  api
export const fetchProductListApi = async (data) => {
  try {
    const response = await axios.post(
      "workorder/viewaccessoriesforjobcompletion",
      data.body
    );
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    if (error.response && error.response.status === 404) {
      // Handle the 404 error specifically
      return { data: [] };
    }
    console.log(error);
    throw error;
  }
};

//list all uom
export const fetchUOMListApi = async (data) => {
  try {
    const response = await axios.post("purchase/listAllUoms", data.body);
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    if (error.response && error.response.status === 404) {
      // Handle the 404 error specifically
      return { data: [] };
    }
    console.log(error);
    throw error;
  }
};

// fetch job completion single view
export const jobCompletionSingleView = async (data) => {
  try {
    const response = await axios.post(
      "workorder/jobcompletionsingleview",
      data.body
    );
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return { data: null };
    }
    console.log(error);
    throw error;
  }
};

// fetch all job completion  list api
export const jobCompletionInitialAPI = async (data) => {
  try {
    const apiCalls = [
      { api: fetchTailorListApi, key: "tailor" },
      { api: fetchCutterListApi, key: "cutter" },
      { api: fetchProductListApi, key: "product" },
      { api: fetchUOMListApi, key: "uomList" },
      { api: jobCompletionSingleView, key: "jobCompletionSingleView" },
    ];
    const payload = {
      ...data.body,
    };
    const allResponse = await fetchAllApis(apiCalls, payload);
    data.setJobCompletionListData(allResponse);
    data.setIsLoading(false);
    // data.setError(null)
  } catch (error) {
    data.setIsLoading(false);
    console.log(error);
    data.setError(error);
  }
};

// upload job completion
export const addJobCompletionAPI = async (data) => {
  try {
    const response = await axios.post("workorder/newjobcompletion", data.body);
    if (response.status === 200) {
      data.setAddLoading(false);
      data.resetState();
      data.onClose();
      store.dispatch(
        showAlert({
          severity: snackbarSeverity.success,
          message: "Your Job completion has been successfully added",
        })
      );
      return response;
    }
  } catch (error) {
    data.setAddLoading(false);
    data.setError(true);
    // console.log(error);
    // store.dispatch(
    //   showAlert({
    //     severity: snackbarSeverity.error,
    //     message: "Unable to add job completion. Please try again",
    //   })
    // );
  }
};
