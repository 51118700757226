import { configureStore } from "@reduxjs/toolkit";

import searchUserSliceReducer from "../Redux/Login/searchUserSlice";
import loginResponseSliceReducer from "../Redux/Login/loginResponseSlice";
import allReadymadeCategoriesReducer from "../Redux/Product Master/allReadymadeCategoriesSlice";
import allReadymadeSubCategorySliceReducer from "../Redux/Product Master/allReadymadeSubCategorySlice";
import readymadeOccasionSliceReducer from "../Redux/Product Master/readymadeOccasionSlice";
import productIdSliceReducer from "../Redux/Product Master/productIDSlice";
import colorListSliceReducer from "../Redux/Product Master/colorListSlice";
import readymadeBrandSliceReducer from "../Redux/Product Master/allReadymadeBrandsSlice";
import allFittingsSliceReducer from "../Redux/Product Master/allFittingsSlice";
import productCareSliceReducer from "../Redux/Product Master/productCareSlice";
import neckStyleSlicereducer from "../Redux/Product Master/neckStyleSlice";
import unitsSliceReducer from "../Redux/Product Master/unitsSlice";
import readymadeStyleSliceReducer from "../Redux/Product Master/readymadeStyleSlice";
import patternSliceReducer from "../Redux/Product Master/patternSlice";
import materialSliceReducer from "../Redux/Product Master/materialSlice";
import allMaterialCategorySliceReducer from "../Redux/Product Master/allMaterialCategorySlice";
import allAccessoryCategorySliceReducer from "../Redux/Product Master/allAccessoryCategorySlice";
import allAccessorySubCategorySliceReducer from "../Redux/Product Master/allAccessorySubCategorySlice";
import sizeChartSliceReducer from "../Redux/Product Master/sizeChartSlice";
import companyProfileSliceReducer from "../Redux/Settings/Company Settings/companyProfileSlice";
import allLocationsSliceReducer from "../Redux/Settings/Company Settings/allLocationsSlice";
import allBranchesSliceReducer from "../Redux/Settings/Company Settings/allBranchesSlice";
import allDepartmentSliceReducer from "../Redux/Staff/HRM/Department/allDepartmentSlice";
import allSupplierListSliceReducer from "../Redux/Prchase Manager/Supplier/allSupplierList";
import supplierLedgerSliceReducer from "../Redux/Prchase Manager/Supplier/supplierLedgerSlice";
import allDesignationListSliceReducer from "../Redux/Staff/HRM/Designation/allDesignationList";
import allInactiveEmployeeSliceReducer from "../Redux/Staff/HRM/Employee/allIncativeEmployeeSlice";
import employeeCompanyInfoFormSliceReducer from "../Redux/Staff/HRM/Employee/employeeCompanyInfoFormSlice";
import empQrSliceReducer from "../Redux/Staff/HRM/Employee/empQrSlice";
import allActiveEmployeeSliceReducer from "../Redux/Staff/HRM/Employee/allActiveEmployeesSlice";
import singleEmployeeSliceReducer from "../Redux/Staff/HRM/Employee/singleEmployeeSlice";
import allProductsListSliceReducer from "../Redux/Product List/allProductsListSlice";
import stockAdjIdSliceReducer from "../Redux/Prchase Manager/StockAdjustment/stockAdjId";
import glLinkCodeSliceReducer from "../Redux/Prchase Manager/StockAdjustment/glLinkCodeSlice";
import glCodeSliceReducer from "../Redux/Prchase Manager/StockAdjustment/glCodeSlice";
import slCodeSliceReducer from "../Redux/Prchase Manager/StockAdjustment/slCodeSlice";
import toLocationStockSliceReducer from "../Redux/Prchase Manager/StockAdjustment/toLocationStockSlice";
import draftedStockAdjSliceReducer from "../Redux/Prchase Manager/StockAdjustment/draftedStockAdjListSlice";
import allStockAdjSliceReducer from "../Redux/Prchase Manager/StockAdjustment/allStockAdjSlice";
import productSingleViewSliceReducer from "../Redux/Product List/productSingleViewSlice";
import singleDraftSliceReducer from "../Redux/Prchase Manager/StockAdjustment/singleDraftViewSlice";
import userRoleSliceReducer from "../Redux/Login/userRoleSlice";
import selectShiftReducer from "../Redux/Settings/Shift/SelectShiftSlice";
import employeeGenderCountSliceReducer from "../Redux/Dashboard/employeeGenderCountSlice";
import thobModelDataSliceReducer from "../Redux/Orders/WorkOrder/thobModelDataSlice";
import lengthCommissionSliceReducer from "../Redux/Orders/WorkOrder/lengthCommissionSlice";
import shiftReopenDialogReducer from "../Redux/Settings/Shift/shiftReopenDialogSlice";
import marModelDataSliceReducer from "../Redux/Orders/WorkOrder/marModelDataSlice";
import cuffDataSliceReducer from "../Redux/Orders/WorkOrder/cuffDataSlice";
import cuffClothSliceReducer from "../Redux/Orders/WorkOrder/cuffClothSlice";
import cuffModelSliceReducer from "../Redux/Orders/WorkOrder/cuffModelSlice";
import collarSliceReducer from "../Redux/Orders/WorkOrder/collarSlice";
import shiftDenominationDialogReducer from "../Redux/Settings/Shift/shiftDenominationDialogSlice";
import collarClothSliceReducer from "./Orders/WorkOrder/collarClothSlice";
import collarModelSliceReducer from "./Orders/WorkOrder/collarModelSlice";
import penSliceReducer from "./Orders/WorkOrder/penSlice";
import sidePTModelSliceReducer from "./Orders/WorkOrder/sidePtModelSlice";
import stitchingSliceReducer from "./Orders/WorkOrder/stitchingSlice";
import buttonSliceReducer from "./Orders/WorkOrder/buttonSlice";
import jalabiaModelSliceReducer from "./Orders/WorkOrder/jalabiaModelSlice";
import neckModelSliceReducer from "./Orders/WorkOrder/neckModelSlice";
import modelSliceReducer from "./Orders/WorkOrder/modelSlice";
import fpModelSliceReducer from "./Orders/WorkOrder/fpModelSlice";
import elasticWidthSliceReducer from "./Orders/WorkOrder/elasticWidthSlice";
import cupSliceReducer from "./Orders/WorkOrder/cupSlice";
import bottomModelSliceReducer from "./Orders/WorkOrder/bottomModelSlice";
import workOrderIdSliceReducer from "./Orders/WorkOrder/workOrderId";
import viewRolesAndPermissionSliceReducer from "./Settings/Roles and permission/viewRolesAndPermissionSlice";
import stockReportReducer from "./Report/stockReportSlice";
import allCustomerListSliceReducer from "./Customer/allCustomerListSlice";
import customerIdSliceReducer from "./Customer/customerIdSlice";
import salesReportReducer from "./Report/salesReportSlice";
import dailyReportReducer from "./Report/dailyReportSlice";
import modelsFromUserSideSliceReducer from "./Orders/WorkOrder/modelsFromUserSide";
import relationshipSliceReducer from "./Customer/relationshipSlice";
import viewShiftReducer from "./Settings/Shift/viewShiftSlice";
import materialProductSliceReducer from "./Product List/materialProductsSlice";
import viewOrderInvoiceNoReducer from "./Sales/viewOrderInvoiceNoSlice";
import stockSummaryReportReducer from "./Report/stockSummaryReportSlice";
import customerRelationIdSliceReducer from "./Customer/customerRelationIdSlice";
import alterationIdSliceReducer from "./Orders/Alteration/alterationIdSlice";
import allAlterationSliceReducer from "./Orders/Alteration/allAlterationSlice";
import stockAdjTransNoReducer from "./Report/StockAdjustmentReport/stockAdjustmenTransNoSlice";
import stockAdjustmentReducer from "./Report/StockAdjustmentReport/stockAdjustmentSlice";
import stockAdjSingleViewReducer from "./Report/StockAdjustmentReport/stockAdjSingleViewSlice";
import purchaseOrderIdSliceReducer from "./Prchase Manager/Purchase Order/purchaseOrderIdSlice";
import purchaseReportReducer from "./Report/PurchaseReport/purchaseReportSlice";
import purchaseReportSingleViewReducer from "./Report/PurchaseReport/purchaseReportSingleViewSlice";
import taxSliceReducer from "./Tax/taxSlice";
import expenseTypeReducer from "./Expense/AddExpenseType/addExpenseTypeSlice";
import addExpenseTypeDocNoReducer from "./Expense/AddExpenseType/addExpenseTypeDocNoSlice";
import workOrdersListSliceReducer from "./Orders/View Orders/workOrdersListSlice";
import creditToSupplierSliceReducer from "./Credit and Wallet/creditToSupplierSlice";
import walletOfCustomerSliceReducer from "./Credit and Wallet/walletOfCustomerSlice";
import workOrderSingleDataSliceReducer from "./Orders/View Orders/workOrderSingleDataSlice";
import rackListSliceReducer from "./Orders/Rack/rackListSlice";
import staffExpenseReducer from "./Expense/StaffExpense/staffExpenseSlice";
import printCuttingSlipFilteredDataReducer from "./Orders/Print Cutting slip/printCuttingSlipFilteredDataSlice";
import workOrderNosSliceReducer from "./Orders/Print Cutting slip/workOrderNosSlice";
import singleCustomerCreditSliceReducer from "./Customer/singleCustomerCreditSlice";
import outletExpenseSlice from "./Expense/outletExpense/outletExpenseSlice";
import outletExpenseDocNoReducer from "./Expense/outletExpense/outletExpenseDocNoSlice";
import singleCustomerWalletSliceReducer from "./Customer/singleCustomerWalletSlice";
import pettyCashBalanceReducer from "./Expense/outletExpense/viewPettyCashBalanceSlice";
import pettyCashReceiptReducer from "./Expense/PettyCashReceipt/pettyCashReceiptSlice";
import pettyCashDocNoReducer from "./Expense/PettyCashReceipt/pettyCashReciptDocNoSlice";
import cardManualReducer from "./Settings/CardAndUpiType/cardManualSlice";
import chequeManualReducer from "./Settings/CardAndUpiType/chequeManualSlice"
import upiManualReducer from "./Settings/CardAndUpiType/upiManualSlice";
import allEmployeeDocSliceReducer from "./Staff/HRM/Documents/allEmployeeDocSlice";
import singleCustomerOrderReducer from "./Customer/singleCustomerOrderSlice";
import allUpiProviderReducer from "./Settings/Company Settings/allUpiProviderSlice";
import allProductIndexReducer from "./Product List/allProductListIndexSlice";
import dashboardProductListReducer from "./Dashboard/dashboardProductListSlice";
import invoiceSliceReducer from "./Orders/Invoice/invoiceSlice";
import newWorkOrderResponseSliceReducer from "./Orders/WorkOrder/newWorkOrderResponse";
import recentSaleReducer from "./Dashboard/recentSaleSlice";
import totalRevenueGraphReducer from "./Dashboard/totalRevenueSlice";
import creditDetailsReducer from "./Dashboard/creditDetailsSlice";
import quickAccessReducer from "./Dashboard/quickAccessSlice";
import expiredDocumentReducer from "./Dashboard/expiredDocumentSlice";
import qrForPcsSliceReducer from "./Orders/Print Cutting slip/qrForPCSSlice";
import shiftStatusSliceReducer from "./Settings/Shift/shiftStatusSlice";
import dailyCashCardReducer from "./Report/dailyCashCardSummarySlice";
import orderNosForJobCompletionSliceReducer from "./Orders/Job completion/orderNosForJobCompletion";
import branchWiseRackListSliceReducer from "./Orders/Rack/branchWiseRackList";
import jobCompletionFilterResSliceReducer from "./Orders/Job completion/jobCompletionFilterResSlice";
import jobCompletionSingleViewSliceReducer from "./Orders/Job completion/jcSingleViewSlice";
import deliveryIdSliceReducer from "./Orders/Delivery/deliveryIdSlice";
import deliveryFilterDataSliceReducer from "./Orders/Delivery/deliveryFilterDataSlice";
import deliveryIdListSliceReducer from "./Orders/Delivery/deliveryIdListslice";
import deliveryPaymentDetailsSliceReducer from "./Orders/Delivery/deliveryPaymentDetailsSlice";
import deliveryOldBalanceSliceReducer from "./Orders/Delivery/deliveryOldBalanceSlice";
import commissionReportReducer from "./Report/commissionReportSlice";
import productsWithStockSliceReducer from "./Product List/productsWithStockSlice";
import singleAlterationReducer from "./Orders/Alteration/singleAlterationSlice";
import allAlterationIdReducer from "./Orders/Alteration/allAlterationIdSlice";
import salesOrderListReducer from "./Sales/OrderList/viewOrderListSlice";
import singleViewOrderListReducer from "./Sales/OrderList/singleViewOrderListSlice";
import viewPaymentsReducer from "./Sales/Payments/viewPaymentsSlice";
import viewQuotationReducer from "./Sales/Quotation/viewQuotationSlice";
import generateQuotationIdReducer from "./Sales/Quotation/generateQuotationIdSlice";
import salesInvoiceListReducer from "./Sales/Payments/invoiceNoListSlice";
import QuotationIdListReducer from "./Sales/Quotation/viewQuotationIdSlice";
import customerSuppListReducer from "./Sales/Payments/customerSupplierListSlice";
import creditListReducer from "./Sales/Credit/viewCreditSlice";
import viewOldStockReducer from "./Sales/OldStock/viewOldStockSlice";
import oldStockSingleViewReducer from "./Sales/OldStock/oldStockSingleViewSlice";
import damagedGoodsReducer from "./Sales/DamagedGoods/damagedGoodsSlice";
import damagedGoodsSingleViewReducer from "./Sales/DamagedGoods/damagedGoodsSingleViewSlice";
import generateTransferIdReducer from "./Prchase Manager/Transfer/generateTransferIdSlice";
import customerMeasurementSliceReducer from "./Orders/WorkOrder/customerMeasurmentsSlice";
import allTransferListReducer from "./Prchase Manager/Transfer/viewAllTransferSlice";
import transferSingleViewReducer from "./Prchase Manager/Transfer/transferSingleViewSlice";
import receivedTransferReducer from "./Prchase Manager/Transfer/receivedTransferSlice";
import receivedTransferSingleViewReducer from "./Prchase Manager/Transfer/receivedTransferSingleViewSlice";
import utilityMenuSliceReducer from "./Settings/Utility/utilityMenuSlice";
import allStockOutSliceReducer from "./Prchase Manager/StockOut Products/allStockOutSlice";
import paymentVoucherSliceReducer from "./Prchase Manager/Payment Voucher/paymentVoucherSlice";
import pendingPaymentVoucherSliceReducer from "./Prchase Manager/Payment Voucher/pendingPaymentVoucherSlice";
import allStockOutApproveSliceReducer from "./Prchase Manager/StockOut Products/allStockOutApproveSlice";
import stockOutListSliceReducer from "./Prchase Manager/StockOut Products/stockOutListSlice";

import viewPurchaseOrderReducer from "./Prchase Manager/Purchase Order/viewPurchaseOrderSlice";
import purchaseOrderSingleViewReducer from "./Prchase Manager/Purchase Order/singleViewPurchaseOrderSlice";
import transferReportReducer from "./Report/TransferReport/transferReportSlice";
import purchaseWoPoIdReducer from "./Prchase Manager/PurchaseWOPO/generatePurchasewopoIdSlice";
import confirmTransferReducer from "./Prchase Manager/Transfer/confirmTransferListSlice";
import invoiceNumberReducer from "./Prchase Manager/GRN/invoiceNumberSlice";
import viewPurchaseByIdReducer from "./Prchase Manager/GRN/viewPurchaseByIdSlice";
import orderNoListReducer from "./Orders/WorkOrder/orderNoListSlice";
import generateGrnIdReducer from "./Prchase Manager/GRN/generateGrnIdSlice";
import grnPostedListReducer from "./Prchase Manager/GRN/viewPostedListSlice";
import grnDraftedListReducer from "./Prchase Manager/GRN/viewDraftedSlice";
import purchaseReturnIdReducer from "./Prchase Manager/PurchaseReturn/generatePurchaseReturnIdSlice";
import purchaseAndPurchaseWOPOByIdReducer from "./Prchase Manager/PurchaseReturn/viewPOAndPWOPOByIdSlice";
import purchseReturnReducer from "./Prchase Manager/PurchaseReturn/viewPurchaseReturnListSlice";
import allEmployeeLoanSliceReducer from "./Staff/HRM/Employee/allEmployeeLoanSlice";
import stockAdjustmentConfirmListSliceReducer from "./Prchase Manager/StockAdjustment/confirmStockAdjustmentSlice";
import singleEmployeeLoanSliceReducer from "./Staff/HRM/Employee/singleEmployeeLoanSlice";
import paymentReportReducer from "./Report/paymentReportSlice";
import purchaseWoPoReducer from "./Report/purchaseWoPoSlice";
import grnReportReducer from "./Report/grnReportSlice";
import expenseReportReducer from "./Report/expenseReportSlice";
import creditLimitReducer from "./Prchase Manager/Supplier/viewCreditLimitSlice";
import allTransferNoReducer from "./Report/TransferReport/getAllTransferNoSlice";
import allGrnNoReducer from "./Prchase Manager/GRN/getAllGrnNoSlice";
import employeeDocViewSliceReducer from "./Staff/HRM/Documents/employeeDocViewSlice";
import allPurchsewopoIdReducer from "./Prchase Manager/PurchaseWOPO/getAllPurchasewopoIdSlice";

import rewardsAndPointsReducer from "./RewardsAndPoints/rewardsAndPointsSlice";
import smsHeaderReducer from "./Settings/SmsSettings/viewSmsHeaderSlice";

import allLeaveTypeSliceReducer from "./Staff/LEAVE/allLeaveTypeSlice";

import getAllSalesReturnReducer from "./Sales/SalesReturn/getAllSalesReturnSlice";
import getAllSalesReturnInvoiceNoReducer from "./Sales/SalesReturn/getAllSalesReturnInvoiceNoSlice";
import generateSalesReturnIdReducer from "./Sales/SalesReturn/generateSalesRetunIdSlice";
import getSalesReturnInvoiceNoForDeliveryReducer from "./Sales/SalesReturn/getInvoiceForDeliverySlice";
import salesReturnInvoiceNoForBillReturnReducer from "./Sales/SalesReturn/getInvoiceNoForBillReturnSlice";
import invoiceNoForWorkOrderReducer from "./Sales/SalesReturn/getInvoiceNoForWorkOrderSlice";
import workOrderDetailsForSalesReturnReducer from "./Sales/SalesReturn/getWorkOrderDetailsForSalesReturnSlice";
import orderDetailsForSalesReturnReducer from "./Sales/SalesReturn/getOrderDetailsForSalesReturnSlice";
import allOfferListReducer from "./Offer/allOfferListSlice";

import salesReturnSingleViewReducer from "./Sales/SalesReturn/salesReturnSingleViewSlice";
import viewCurrencyReducer from "./Settings/Currency/viewCurrencySlice";
import getDiscountReducer from "./Settings/Discount/getDiscountSlice";

import allOfferListSliceReducer from "./Offer/allOfferListSlice";
import allCustomerListWithFilterReducer from './Customer/allCustomerListWithFilterSlice'
import docTypeSliceReducer from "./Staff/HRM/Documents/docTypeViewSlice";
import logReducer from './Settings/Log/LogSlice'
import shiftTransferDialogReducer from './Settings/Utility/shiftTransferReopenDialogSlice'
import shiftTransferOrderListReducer from './Settings/Utility/ShiftTransfer/shiftTransferOrderListSlice'
import allOrdersInvoiceNoReducer from './Settings/Utility/ShiftTransfer/shiftTransferOrderListInvoiceNoSlice'

import utilityPaymentListReducer from './Settings/Utility/PaymentTypeAndAmount/paymentListSlice'
import paymentInvoiceNoListReducer from './Settings/Utility/PaymentTypeAndAmount/paymetsInvoiceNoListSlice'

import allLeaveApplicationSliceReducer from "./Staff/LEAVE/allLeaveApplicationSlice";
import fastSellingSliceReducer from "./Dashboard/fastSellingSlice";
import creditSummaryReducer from './Credit and Wallet/creditSummarySlice'

import generateGrnTransactionIdSliceReducer from "./Prchase Manager/GRN/generateGrnTransactionIdSlice";

import productListReducer from './Product List/allProductListWithBranchSlice'
import expenseGraphSliceReducer from './Staff/Staff Dashboard/expenseGraphSlice'
import empCountGraphSliceReducer from './Staff/Staff Dashboard/empCountGraphSlice'
import noticeboardSliceReducer from './Staff/Staff Dashboard/noticeboardSlice'
import holidayTypeViewSliceReducer from "./Staff/LEAVE/holidayTypeViewSlice";
import jobCompletionWorkorderListSliceReducer from "./Settings/Utility/JobCompletion/jobCompletionWorkorderListSlice";
import utilityJobCompletionListSliceReducer from "./Settings/Utility/JobCompletion/utilityJobCompletionListSlice";
import heldWorkOrderSliceReducer from './Orders/Held work order/heldWorkOrderSlice'
import billOrderHeldProductSliceReducer from './Sales/Billing/billOrderHeldProductsSlice'
import cuttingSlipModelReducer from './Settings/CuttingSlipModel/cuttingSlipModelSlice'
import stockOrderListSliceReducer from "./Settings/Utility/stockCorrection/stockOrderListSlice";
import allWorkOrderListReducer from "./Orders/View Orders/allWorkOrdersListSlice"
import staffAllPayrollSliceReducer from "./Staff/Payroll/staffAllPayrollSlice";
import staffSinglePayslipSliceReducer from "./Staff/Payroll/staffSinglePayslipSlice";
import generatePayrollVoucherSliceReducer from "./Staff/Payroll/generatePayrollVoucherSlice";
import viewOrderSearchRelationSliceReducer from "./Orders/View Orders/viewOrderSearchRelationSlice";
import configurationSlice from ".././component/Modules/Payroll/configuration/configurationSlice"
import purchseReturnInvoiceNoReducer from './Prchase Manager/PurchaseReturn/viewInvoiceNoListAddPurchaseReturnSlice'

import jobCompletionReportSliceReducer from "./Report/jobCompletionReportSlice";

// import fixedAssetSlice from "./Account/fixedAssetSlice";
// import bankAccountSlice from "./Account/bankAccountSlice";
// import bankOverDraftSlice from "./Account/bankOverDraftSlice";
// import branchOrDivisionSlice from "./Account/branchOrDivisionSlice";
// import currentLiabilitySlice from "./Account/currentLiabilitySlice";
// import cashInHandSlice from "./Account/cashInHandSlice";
// import directExpensesSlice from "./Account/directExpensesSlice";
// import dutiesAndTaxesSlice from "./Account/dutiesAndTaxesSlice";
// import indirectIncomeSlice from "./Account/indirectIncomeSlice";
// import longTermInvestmentSlice from "./Account/longTermInvestmentSlice";
// import reserveAndSurplusSlice from "./Account/reserveAndSurplusSlice";
// import retainedEarningsSlice from "./Account/retainedEarningsSlice";
// import securedLoansSlice from "./Account/securedLoansSlice";
// import capitalAccountSlice from "./Account/capitalAccountSlice";
// import depositAssetSlice from "./Account/depositAssetSlice";
// import indirectExpensesSlice from "./Account/indirectExpensesSlice";
// import loansLiabilitySlice from "./Account/loansLiabilitySlice";
// import miscExpensesSlice from "./Account/miscExpensesSlice";
// import provisionsSlice from "./Account/provisionsSlice";
// import costOfGoodsSoldSlice from "./Account/costOfGoodsSoldSlice";
// import salesAccountSlice from "./Account/salesAccountSlice";
// import loansAndAdvanceSlice from "./Account/loansAndAdvanceSlice";
// import customerSlice from "./Account/customerSlice";
// import suppliersSlice from "./Account/suppliersSlice";
// import currentAssetSlice from "./Account/currentAssetSlice";
// import longTermLiabilitySlice from "./Account/longTermLiabilitySlice";
// import directIncomeSlice from "./Account/directIncomeSlice"
import journalSlice from "./AccountUpdated/journalSlice";
import chartOfAccountsSlice from "./AccountUpdated/chartOfAccountsSlice"

import viewUpdatedCurrency from "./Settings/Currency/viewCurrencyUpdatedSlice"

import journalEntrySliceReducer from "../component/Modules/Accounts/Accounting/JournalEntry/journalEntrySlice";
import unitOfMeasurmentSliceReducer from "../component/Modules/Purchase Manager/Pages/UnitOfMeasurment/unitOfMeasurmentSlice";

import glConfigurationSliceReducer from "../component/Modules/Accounts/Configuration/glConfigurationSlice";

import ConfigurationSlice from "./Prchase Manager/Configuration/ConfigurationSlice"

import taxSettingsSlice from "./Settings/TaxSettings/taxSettingsSlice"
import PurchaseTaxListSlice from './Prchase Manager/Configuration/PurchaseTaxListSlice'

import vendorBillSliceReducer from "../component/Modules/Accounts/Vendor/Slice/vendorBillSlice";

import vendorPaymentSliceReducer from "../component/Modules/Accounts/Vendor/Slice/vendorPaymentSlice";
import vendorsListSliceReducer from "../component/Modules/Accounts/Vendor/Slice/vendorsListSlice";

import sequenceSlice from "../component/Modules/Settings/Sequence/sequenceSlice"
import wareHouseSlice from "../component/Modules/Inventory/Configuration/wareHouseSlice"
import stockMoveSliceReducer from "../component/Modules/Inventory/Operations/Stock Moves/stockMovesSlice";
import wareHouseLocationSlice from "../component/Modules/Inventory/Configuration/wareHouseLocationSlice"
import internalTransferSlice from "../component/Modules/Inventory/Operations/InternalTransfer/InternalTransferSlice"
import branchTransferSlice from "../component/Modules/Inventory/Operations/BranchTransfer/BranchTransferSlice"
import goodsReceivedNoteUpdatedSliceReducer from "../component/Modules/Purchase Manager/Pages/GRNUpdated/grnListingSlice";
import RfqSlice from "./Prchase Manager/RFQ/RfqSlice";
import posCategorySlice from "../component/Modules/Inventory/Configuration/POSCategory/posCategorySlice"
import categorySlice from "../component/Modules/Inventory/Configuration/Category/categorySlice"
import attributeSlice from "../component/Modules/Inventory/Configuration/Attribute/AttributeSlice"
import salesQuatationSliceReducer  from "../component/Modules/SalesNew/SalesOrders/SalesQuotation/salesQuatationSlice";
import salesOrderSliceReducer from "../component/Modules/SalesNew/SalesOrders/SalesOrder/salesOrderSlice";
import inventoryConfigSettingSliceReducer from "../component/Modules/Inventory/Configuration/inventoryConfigurationSettingsSlice";
import inventoryProductSliceReducer  from "../component/Modules/Inventory/Products/inventoryProductsSlice";
import branchSettingsSliceReducer  from "../component/Modules/Settings/BranchSettings/branchSettingSlice";
import allAllowedBranchesSlice from "../Redux/Settings/Company Settings/allAllowedBranchesSlice"
import customerInvoiceSliceReducer from "../component/Modules/Accounts/Customers/CustomerInvoice/customerInvoiceSlice";
import customerCreditNoteSliceReducer from "../component/Modules/Accounts/Customers/CreditNotes/customerCreditNotesSlice";

import salesWSOSliceReducer from "../component/Modules/Accounts/Customers/SalesWSO/SalesWSOSlice";
import purchaseWPOSliceReducer from "../component/Modules/Accounts/Vendor/purchaseWPO/purchaseWPOlist";

import BranchReceiptSlice from "../component/Modules/Inventory/Operations/BranchReceipt/BranchReceiptSlice"
import InventoryAdjustmentSlice from "../component/Modules/Inventory/Operations/InventoryAdjustment/InventoryAdjustmentSlice"

import deliveryNoteSliceReducer from "../component/Modules/SalesNew/SalesOrders/DeliveryNote/deliveryNoteSlice";

import customerPaymentSlice from "../component/Modules/Accounts/Customers/Payments/customerPaymentSlice"
import debitNoteSlice from "../component/Modules/Accounts/Vendor/Slice/debitNoteSlice";

import landedCostSlice from "../component/Modules/Inventory/Operations/LandedCost/LandedCostSlice";

import posSettingSlice from "../component/Modules/PointOfSale/Configuration/Settings/PosSettingsSlice"
import newBillingSliceReducer from "../component/Modules/Sales/Billing/newBillingSlice";
import priceListSlice from "../component/Modules/SalesNew/SalesOrders/priceList/priceListSlice";
import cashInOrOutSlice from "../Redux/Dashboard/cashInOrOutSlice"
import trailbalance from "../component/Modules/Accounts/ReportsNew/FinancialReports/TrailBalanceSlice"
import branchSettingsSliceNew from "../component/Modules/PointOfSale/Configuration/BranchSettings/BranchSettingsSlice"
import partnerReportSlice from "../component/Modules/Accounts/ReportsNew/PartnerReport/partnerReportSlice";
import GenerateQrBarcodeSlice from "../component/Modules/GenerateQrOrBarcode/GenerateQrBarcodeSlice"
import GeneralReportSlice from "../component/Modules/Accounts/ReportsNew/General Report/GeneralReportSlice";
import SalesReportSlice from "../component/Modules/SalesNew/SalesReport/SalesReportSlice";
import ReportSlice from "../component/Modules/Inventory/Reports/ReportSlice";
import posReportSlice  from "../component/Modules/Report/POSSalesReport/posSalesReportAPI";
import purchaseReportNewSlice from "../component/Modules/Purchase Manager/Pages/PurchaseReport.jsx/purchaseReportNewSlice";
import BankReconciliationSlice from "../component/Modules/Accounts/Reconciliation/BankReconciliationSlice";
import EstablishmentIdSlice from "../component/Modules/Payroll/Establishment/EstablishmentIdSlice";

import contractSlice from "../component/Modules/Payroll/contractSlice";
import OvertimeSlice from "../component/Modules/Payroll/Attendance/Overtime/OvertimeSlice";
import viewWorkOrderSettingsSlice from '../Redux/Orders/WorkOrder/viewWorkOrderSettingsSlice'
import vatReportSlice from "../component/Modules/Accounts/VatReport/vatReportSlice";
import SettingsSlice from "../component/Modules/Settings/SettingsSlice";
import filterCategorySlice from "../component/Single Components/listSlice/filterCategorySlice";
import Openingbalance from "../component/Modules/Accounts/Accounting/OpeningBalanceSlice"
import employeeForHrm from "./Staff/HRM/Employee/employeeForHrm";
import CustomerCreditReportSlice from "../component/Modules/Report/POSSalesReport/CustomerCreditReportSlice";
import payHeadSlice from "../component/Modules/Payroll/configuration/payHead/payHeadSlice";
import ShiftReportSlice from "../component/Modules/Report/shiftReport/ShiftReportSlice";
import mainDashBoardSlice from "./Dashboard/mainDashBoardSlice";
import posNewSlice from "../component/Modules/PosNew/PosNew/PosNewSlice"
import topBarSliceBilling from "../component/Modules/PosNew/PosNew/Billing/Components/topBarSliceBilling"
import billingScreenSlice from "../component/Modules/PosNew/PosNew/Billing/Slice/billingScreenSlice"
import viewOrdersBillingSlice from "../component/Modules/PosNew/PosNew/Billing/Slice/viewOrdersBillingSlice"
import arrowPathSlice from "../component/Modules/PosNew/PosNew/Billing/Utilities/ArrowPathSlice"
import filterButtonSlice from "../component/Modules/PosNew/PosNew/Billing/Utilities/filterButtonSlice"
import posReceiptSlice from "../component/Modules/Settings/posReceipt/posReceiptSlice";
import GeneralSlice from "../component/Modules/PosNew/General/GeneralSlice";
import PromotionSlice from "../component/Modules/PosNew/Promotions/PromotionSlice"
import workOrderNewList from "../component/Modules/PosNew/General/Workorder/workOrderNewSlice"
///new pos slice
import neworderslice from "../component/Modules/PosNew/PosNew/NewOrder/neworderSlice"
import measurementsslice from "../component/Modules/PosNew/PosNew/NewOrder/Measurements"

// billing changes

import customKeyBoardSlice from "../component/Single Components/customKeyBoardSlice"
import  calculatorSlice  from "../component/Modules/Sales/Billing/Slice/calculatorSlice";
export default configureStore({

  reducer: {
    //List of reducers
    searchUserSlice: searchUserSliceReducer,
    loginResponseSlice: loginResponseSliceReducer,
    allReadymadeCategories: allReadymadeCategoriesReducer,
    allReadymadeSubCategorySlice: allReadymadeSubCategorySliceReducer,
    readymadeOccasionSlice: readymadeOccasionSliceReducer,
    productIdSlice: productIdSliceReducer,
    colorListSlice: colorListSliceReducer,
    readymadeBrandSlice: readymadeBrandSliceReducer,
    allFittingsSlice: allFittingsSliceReducer,
    productCareSlice: productCareSliceReducer,
    neckStyleSlice: neckStyleSlicereducer,
    unitsSlice: unitsSliceReducer,
    readymadeStyleSlice: readymadeStyleSliceReducer,
    patternSlice: patternSliceReducer,
    materialSlice: materialSliceReducer,
    allMaterialCategorySlice: allMaterialCategorySliceReducer,
    allAccessoryCategorySlice: allAccessoryCategorySliceReducer,
    allAccessorySubCategorySlice: allAccessorySubCategorySliceReducer,
    sizeChartSlice: sizeChartSliceReducer,
    companyProfileSlice: companyProfileSliceReducer,
    allLocationsSlice: allLocationsSliceReducer,
    allBranchesSlice: allBranchesSliceReducer,
    allDepartmentSlice: allDepartmentSliceReducer,
    allSupplierListSlice: allSupplierListSliceReducer,
    supplierLedgerSlice: supplierLedgerSliceReducer,
    allDesignationListSlice: allDesignationListSliceReducer,
    allInactiveEmployeeSlice: allInactiveEmployeeSliceReducer,
    employeeCompanyInfoFormSlice: employeeCompanyInfoFormSliceReducer,
    empQrSlice: empQrSliceReducer,
    allActiveEmployeeSlice: allActiveEmployeeSliceReducer,
    singleEmployeeSlice: singleEmployeeSliceReducer,
    allProductsListSlice: allProductsListSliceReducer,
    stockAdjIdSlice: stockAdjIdSliceReducer,
    glLinkCodeSlice: glLinkCodeSliceReducer,
    glCodeSlice: glCodeSliceReducer,
    slCodeSlice: slCodeSliceReducer,
    toLocationStockSlice: toLocationStockSliceReducer,
    draftedStockAdjSlice: draftedStockAdjSliceReducer,
    allStockAdjSlice: allStockAdjSliceReducer,
    productSingleViewSlice: productSingleViewSliceReducer,
    singleDraftSlice: singleDraftSliceReducer,
    userRoleSlice: userRoleSliceReducer,
    selectShiftSlice: selectShiftReducer,
    employeeGenderCountSlice: employeeGenderCountSliceReducer,
    thobModelDataSlice: thobModelDataSliceReducer,
    lengthCommissionSlice: lengthCommissionSliceReducer,
    shiftReopenDialog: shiftReopenDialogReducer,
    marModelDataSlice: marModelDataSliceReducer,
    cuffDataSlice: cuffDataSliceReducer,
    cuffClothSlice: cuffClothSliceReducer,
    cuffModelSlice: cuffModelSliceReducer,
    collarSlice: collarSliceReducer,
    shiftDenominationDialog: shiftDenominationDialogReducer,
    collarClothSlice: collarClothSliceReducer,
    collarModelSlice: collarModelSliceReducer,
    penSlice: penSliceReducer,
    sidePTModelSlice: sidePTModelSliceReducer,
    stitchingSlice: stitchingSliceReducer,
    buttonSlice: buttonSliceReducer,
    jalabiaModelSlice: jalabiaModelSliceReducer,
    neckModelSlice: neckModelSliceReducer,
    modelSlice: modelSliceReducer,
    fpModelSlice: fpModelSliceReducer,
    elasticWidthSlice: elasticWidthSliceReducer,
    cupSlice: cupSliceReducer,
    bottomModelSlice: bottomModelSliceReducer,
    workOrderIdSlice: workOrderIdSliceReducer,
    viewRolesAndPermissionSlice: viewRolesAndPermissionSliceReducer,
    stockReportSlice: stockReportReducer,
    allCustomerListSlice: allCustomerListSliceReducer,
    customerIdSlice: customerIdSliceReducer,
    salesReportSlice: salesReportReducer,
    dailyReportSlice: dailyReportReducer,
    modelsFromUserSideSlice: modelsFromUserSideSliceReducer,
    relationshipSlice: relationshipSliceReducer,
    viewShiftSlice: viewShiftReducer,
    materialProductSlice: materialProductSliceReducer,
    viewOrderInvoiceNoSlice: viewOrderInvoiceNoReducer,
    stockSummarySlice: stockSummaryReportReducer,
    customerRelationIdSlice: customerRelationIdSliceReducer,
    alterationIdSlice: alterationIdSliceReducer,
    allAlterationSlice: allAlterationSliceReducer,
    stockAdjTransNo: stockAdjTransNoReducer,
    stockAdjustmentSlice: stockAdjustmentReducer,
    stockAdjSingleView: stockAdjSingleViewReducer,
    purchaseOrderIdSlice: purchaseOrderIdSliceReducer,
    purchaseReportSlice: purchaseReportReducer,
    purchaseReportSingleView: purchaseReportSingleViewReducer,
    taxSlice: taxSliceReducer,
    addExpenseType: expenseTypeReducer,
    addExpenseTypeDocNo: addExpenseTypeDocNoReducer,
    workOrdersListSlice: workOrdersListSliceReducer,
    creditToSupplierSlice: creditToSupplierSliceReducer,
    walletOfCustomerSlice: walletOfCustomerSliceReducer,
    workOrderSingleDataSlice: workOrderSingleDataSliceReducer,
    rackListSlice: rackListSliceReducer,
    staffExpenseSlice: staffExpenseReducer,
    printCuttingSlipFilteredData: printCuttingSlipFilteredDataReducer,
    workOrderNosSlice: workOrderNosSliceReducer,
    singleCustomerCreditSlice: singleCustomerCreditSliceReducer,
    outletExpenseSlice: outletExpenseSlice,
    outletExpenseDocNoSlice: outletExpenseDocNoReducer,
    singleCustomerWalletSlice: singleCustomerWalletSliceReducer,
    pettyCashBalanceSlice: pettyCashBalanceReducer,
    pettyCashReceiptSlice: pettyCashReceiptReducer,
    pettyCashReceiptDocNoSlice: pettyCashDocNoReducer,
    cardManualSlice: cardManualReducer,
    upiManualSlice: upiManualReducer,
    allEmployeeDocSlice: allEmployeeDocSliceReducer,
    singleCustomerOrderSlice: singleCustomerOrderReducer,
    upiProviderSlice: allUpiProviderReducer,
    allProductIndexSlice: allProductIndexReducer,
    dashboardProductListSlice: dashboardProductListReducer,
    invoiceSlice: invoiceSliceReducer,
    newWorkOrderResponseSlice: newWorkOrderResponseSliceReducer,
    recentSaleSlice: recentSaleReducer,
    totalRevenueGraph: totalRevenueGraphReducer,
    creditDetails: creditDetailsReducer,
    quickAccessSlice: quickAccessReducer,
    expiredDocumentSlice: expiredDocumentReducer,
    qrForPcsSlice: qrForPcsSliceReducer,
    shiftStatusSlice: shiftStatusSliceReducer,
    dailyCashCardSummary: dailyCashCardReducer,
    orderNosForJobCompletionSlice: orderNosForJobCompletionSliceReducer,
    branchWiseRackListSlice: branchWiseRackListSliceReducer,
    jobCompletionFilterResSlice: jobCompletionFilterResSliceReducer,
    jobCompletionSingleViewSlice: jobCompletionSingleViewSliceReducer,
    deliveryIdSlice: deliveryIdSliceReducer,
    deliveryFilterDataSlice: deliveryFilterDataSliceReducer,
    deliveryIdListSlice: deliveryIdListSliceReducer,
    deliveryPaymentDetailsSlice: deliveryPaymentDetailsSliceReducer,
    deliveryOldBalanceSlice: deliveryOldBalanceSliceReducer,
    commissionReportSlice: commissionReportReducer,
    productsWithStockSlice: productsWithStockSliceReducer,
    singleAlterationSlice: singleAlterationReducer,
    allAlterationId: allAlterationIdReducer,
    salesOrderList: salesOrderListReducer,
    singleViewOrderList: singleViewOrderListReducer,
    viewPayments: viewPaymentsReducer,
    viewQuotation: viewQuotationReducer,
    generateQuotationId: generateQuotationIdReducer,
    invoiceNoList: salesInvoiceListReducer,
    quotationId: QuotationIdListReducer,
    customerSuppList: customerSuppListReducer,
    creditSlice: creditListReducer,
    viewOldStockSlice: viewOldStockReducer,
    oldStockSingleView: oldStockSingleViewReducer,
    damagedGoodSlice: damagedGoodsReducer,
    damagedGoodsSingle: damagedGoodsSingleViewReducer,
    transferIdSlice: generateTransferIdReducer,
    customerMeasurementSlice: customerMeasurementSliceReducer,
    allTransferListSlice: allTransferListReducer,
    transferSingleViewSlice: transferSingleViewReducer,
    receivedTransferSlice: receivedTransferReducer,
    receivedTransferSingleViewSlice: receivedTransferSingleViewReducer,
    utilityMenuSlice: utilityMenuSliceReducer,

    allStockOutSlice: allStockOutSliceReducer,
    paymentVoucherSlice: paymentVoucherSliceReducer,
    pendingPaymentVoucherSlice: pendingPaymentVoucherSliceReducer,

    allStockOutApproveSlice: allStockOutApproveSliceReducer,
    stockOutListSlice: stockOutListSliceReducer,

    viewPurchaseOrderSlice: viewPurchaseOrderReducer,
    singleViewPurchaseOrder: purchaseOrderSingleViewReducer,
    transferReportSlice: transferReportReducer,
    generatePurchsewopoId: purchaseWoPoIdReducer,
    confirmTransfer: confirmTransferReducer,
    invoiceNumberSlice: invoiceNumberReducer,
    viewPurchaseByIdSlice: viewPurchaseByIdReducer,
    orderNoListSlice: orderNoListReducer,
    generateGrnSlice: generateGrnIdReducer,
    grnPostedListSlice: grnPostedListReducer,
    grnDraftedList: grnDraftedListReducer,
    purchaseReturnIdSlice: purchaseReturnIdReducer,
    purchaseAndPurchaseWOPOByIdSlice: purchaseAndPurchaseWOPOByIdReducer,
    viewPurchaseReturnList: purchseReturnReducer,
    allEmployeeLoanSlice: allEmployeeLoanSliceReducer,

    singleEmployeeLoanSlice: singleEmployeeLoanSliceReducer,

    viewPurchaseOrderSlice: viewPurchaseOrderReducer,
    singleViewPurchaseOrder: purchaseOrderSingleViewReducer,
    transferReportSlice: transferReportReducer,
    generatePurchsewopoId: purchaseWoPoIdReducer,
    confirmTransfer: confirmTransferReducer,
    invoiceNumberSlice: invoiceNumberReducer,
    viewPurchaseByIdSlice: viewPurchaseByIdReducer,
    orderNoListSlice: orderNoListReducer,
    generateGrnSlice: generateGrnIdReducer,
    grnPostedListSlice: grnPostedListReducer,
    grnDraftedList: grnDraftedListReducer,
    purchaseReturnIdSlice: purchaseReturnIdReducer,
    purchaseAndPurchaseWOPOByIdSlice: purchaseAndPurchaseWOPOByIdReducer,
    viewPurchaseReturnList: purchseReturnReducer,
    paymentReportSlice: paymentReportReducer,
    purchaseWoPoSlice: purchaseWoPoReducer,
    grnReportSlice: grnReportReducer,

    viewPurchaseOrderSlice: viewPurchaseOrderReducer,
    singleViewPurchaseOrder: purchaseOrderSingleViewReducer,
    transferReportSlice: transferReportReducer,
    generatePurchsewopoId: purchaseWoPoIdReducer,
    confirmTransfer: confirmTransferReducer,
    invoiceNumberSlice: invoiceNumberReducer,
    viewPurchaseByIdSlice: viewPurchaseByIdReducer,
    orderNoListSlice: orderNoListReducer,
    generateGrnSlice: generateGrnIdReducer,
    grnPostedListSlice: grnPostedListReducer,
    grnDraftedList: grnDraftedListReducer,
    stockAdjustmentConfirmListSlice: stockAdjustmentConfirmListSliceReducer,

    expenseReportSlice: expenseReportReducer,

    creditLimitSlice: creditLimitReducer,
    allTransferNoSlice: allTransferNoReducer,
    allGrnNoSlice: allGrnNoReducer,
    employeeDocViewSlice: employeeDocViewSliceReducer,
    purchasewopoIdSlice: allPurchsewopoIdReducer,

    rewardsAndPointsSlice: rewardsAndPointsReducer,
    smsHeaderSlice: smsHeaderReducer,

    allLeaveTypeSlice: allLeaveTypeSliceReducer,
    AllSalesReturn: getAllSalesReturnReducer,
    getAllSalesReturnNo: getAllSalesReturnInvoiceNoReducer,
    generateSalesReturnId: generateSalesReturnIdReducer,
    deliveryInvoiceNoList: getSalesReturnInvoiceNoForDeliveryReducer,
    billReturnInvoiceNoList: salesReturnInvoiceNoForBillReturnReducer,
    workOrderInvoiceNoList: invoiceNoForWorkOrderReducer,
    workOrderDetailsForSalesReturn: workOrderDetailsForSalesReturnReducer,
    orderDetailsForSalesReturn: orderDetailsForSalesReturnReducer,
    allOfferListSlice: allOfferListReducer,

    salesReturnSingleView: salesReturnSingleViewReducer,
    viewCurrencySlice: viewCurrencyReducer,
    discountSlice: getDiscountReducer,

    allOfferListSlice:allOfferListSliceReducer,
    allCustomerWithFilter:allCustomerListWithFilterReducer,
    docTypeSlice:docTypeSliceReducer,
    logSlice:logReducer,
    shiftTransferDialog:shiftTransferDialogReducer,
    shiftTransferOrderList:shiftTransferOrderListReducer,
    shiftTransferOrderListInvoiceNo:allOrdersInvoiceNoReducer,

    utilityPaymentList:utilityPaymentListReducer,
    paymentInvoiceNoList:paymentInvoiceNoListReducer,


    allLeaveApplicationSlice:allLeaveApplicationSliceReducer,
    fastSellingSlice:fastSellingSliceReducer,
    creditSummary:creditSummaryReducer,

    generateGrnTransactionIdSlice:generateGrnTransactionIdSliceReducer,

    allProductListWithBranch:productListReducer,
    expenseGraphSlice:expenseGraphSliceReducer,
    empCountGraphSlice:empCountGraphSliceReducer,
    noticeboardSlice:noticeboardSliceReducer,
    holidayTypeViewSlice:holidayTypeViewSliceReducer,
    jobCompletionWorkorderListSlice:jobCompletionWorkorderListSliceReducer,
    utilityJobCompletionListSlice:utilityJobCompletionListSliceReducer,
    heldWorkOrderSlice:heldWorkOrderSliceReducer,
    billOrderHeldProductSlice:billOrderHeldProductSliceReducer,
    cuttingSlipSlice:cuttingSlipModelReducer,
    stockOrderListSlice:stockOrderListSliceReducer,
    allWorkOrderListSlice:allWorkOrderListReducer,
    chequeManualSlice:chequeManualReducer,
    staffAllPayrollSlice:staffAllPayrollSliceReducer,
    staffSinglePayslipSlice:staffSinglePayslipSliceReducer,
    generatePayrollVoucherSlice:generatePayrollVoucherSliceReducer,
    viewOrderSearchRelationSlice:viewOrderSearchRelationSliceReducer,

    addPurchseReturnInvoiceList:purchseReturnInvoiceNoReducer,

    jobCompletionReportSlice:jobCompletionReportSliceReducer,
    journalEntrySlice:journalEntrySliceReducer,
    unitOfMeasurmentSlice:unitOfMeasurmentSliceReducer,
    glConfigurationSlice:glConfigurationSliceReducer,
    vendorBillSlice:vendorBillSliceReducer,
    vendorPaymentSlice:vendorPaymentSliceReducer,
    vendorsListSlice:vendorsListSliceReducer,
    stockMoveSlice:stockMoveSliceReducer,
    goodsReceivedNoteUpdatedSlice:goodsReceivedNoteUpdatedSliceReducer,
    salesQuatationSlice:salesQuatationSliceReducer,
    salesOrderSlice:salesOrderSliceReducer,
    inventoryConfigSettingSlice:inventoryConfigSettingSliceReducer,
    inventoryProductSlice:inventoryProductSliceReducer,
    branchSettingsSlice:branchSettingsSliceReducer,
    customerInvoiceSlice:customerInvoiceSliceReducer,
    customerCreditNoteSlice:customerCreditNoteSliceReducer,
    salesWSOSlice:salesWSOSliceReducer,
    purchaseWPOSlice:purchaseWPOSliceReducer,
    deliveryNoteSlice:deliveryNoteSliceReducer,
    newBillingSlice:newBillingSliceReducer,
    PartnerReport:partnerReportSlice,
    generalReport:GeneralReportSlice,
    salesReport:SalesReportSlice,
    posReports:posReportSlice,
    purchaseRport:purchaseReportNewSlice,
    bankReconciliation:BankReconciliationSlice,
    establishmentId:EstablishmentIdSlice,
    overtime:OvertimeSlice,
    settingReport:SettingsSlice,
    listCategory:filterCategorySlice,
    empHrmSlice:employeeForHrm,
    customerCreditReport:CustomerCreditReportSlice,
    payHead:payHeadSlice,
    shiftReport:ShiftReportSlice,
    // fixedAssetSlice,
    // bankAccountSlice,
    // bankOverDraftSlice,
    // branchOrDivisionSlice,
    // currentLiabilitySlice,
    // cashInHandSlice,
    // directExpensesSlice,
    // dutiesAndTaxesSlice,
    // indirectIncomeSlice,
    // longTermInvestmentSlice,
    // reserveAndSurplusSlice,
    // retainedEarningsSlice,
    // securedLoansSlice,
    // capitalAccountSlice,
    // depositAssetSlice,
    // indirectExpensesSlice,
    // loansLiabilitySlice,
    // miscExpensesSlice,
    // provisionsSlice,
    // costOfGoodsSoldSlice,
    // salesAccountSlice,
    // loansAndAdvanceSlice,
    // customerSlice,
    // suppliersSlice,
    // currentAssetSlice,
    // longTermLiabilitySlice,
    // directIncomeSlice,

    journalSlice,
    chartOfAccountsSlice,
    viewUpdatedCurrency,
    ConfigurationSlice,
    taxSettingsSlice,
    PurchaseTaxListSlice,
    sequenceSlice,
    wareHouseSlice,
    wareHouseLocationSlice,
    internalTransferSlice,
    branchTransferSlice,
    RfqSlice:RfqSlice,
    posCategorySlice,
    categorySlice,
    attributeSlice,
    allAllowedBranchesSlice,
    BranchReceiptSlice,
    InventoryAdjustmentSlice,
    customerPaymentSlice,
    debitNoteSlice,
    landedCostSlice,
    posSettingSlice,
    priceListSlice,
    cashInOrOutSlice,
    trailbalance,
    ReportSlice,
    contractSlice,
    configurationSlice,
    
    branchSettingsSliceNew,
    GenerateQrBarcodeSlice,
    viewWorkOrderSettingsSlice,

    vatReportSlice,
    Openingbalance,
    mainDashBoardSlice,
    posNewSlice,
    topBarSliceBilling,
    billingScreenSlice,
    viewOrdersBillingSlice,
    arrowPathSlice,
    filterButtonSlice,
    posReceiptSlice,
    GeneralSlice,
    workOrderNewList,
    ///new pos slice
    neworderslice,
    measurementsslice,
    PromotionSlice,
    customKeyBoardSlice,
    calculatorSlice

  },
});
