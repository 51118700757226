import { Autocomplete, Dialog, IconButton, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AutocompleteField, TextFieldComponent } from "./CustomFields";
import { open_dialog } from "../../PosNewSlice";
import { useDispatch, useSelector } from "react-redux";
import { CreateTypeDialog } from "./CreateTypeDialog";
import { addElasticWidthAPICall, addOrderModelAPI, deleteElasticWidthAPICall, deleteOrderModelAPI, viewElasticWidthAPICall, viewOrderModelAPI } from "../MeasurmentsAPI";
import { filterObjFromList } from "../../../../../../Js/generalFunctions";
import { CreateModelDialog } from "./CreateModelDialog";

export const SalwarForm = ({onFormDataChange,selectedType,editData}) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState();
  const { openDialog, isModify } = useSelector((state) => state.posNewSlice);
  const {elasticWidth,model} = useSelector((state) => state.measurementsslice);
  const [modelInputValues, setModelInputValues] = useState({});

  const formInitialState={
    modelId:null,
    length: "",
    waist:"",
    bottom:"",
    elasticSize:"",
    elasticWidth:null,
    ply:"",
    thighloose:"",
    remark:""
  }
  const [formValues, setFormValues] = useState(formInitialState);
  const handleFormChange = (key,value) => {
    const updatedData = { ...formValues, [key]: value };
    setFormValues(updatedData);
    onFormDataChange(updatedData);
  };
  const handleAdd = (label) => {
    dispatch(open_dialog({ openDialogState: label }));
  };
  const closeDialog = () => {
    dispatch(open_dialog({ openDialogState: false }));
  };
  const handleGetFormData = (data) => {
    setInputValue(data);
  };
  const handleGetModelFormData = (data) => {
    setModelInputValues(data);
  };

  

  const handleAddData = (data) => {
    if (openDialog === "Elastic Width") {
     
      addElasticWidthAPICall({elasticWidth:inputValue},setInputValue);
    } 
  };
  const handleDelete = (id,type) => {
    if (type === "Elastic Width") {
      deleteElasticWidthAPICall({elasticWidthId:id})
    }
  };

  const handleAddModel = () => {
    if (openDialog === "Model") {
      addOrderModelAPI(
        {
          modelName: modelInputValues?.modelName,
          fromLength: modelInputValues?.fromLength,
          toLength: modelInputValues?.toLength,
          minRate: modelInputValues?.minRate,
          commission: modelInputValues?.commission,
          workorderTypeId: selectedType,
        },
        setModelInputValues
      );
    }
  };
  const handleDeleteModel = (id, type) => {
    if (type === "Model") {
      deleteOrderModelAPI({ thobId: id });
    }
  };


  const renderRows = (data, type) =>
    data
      ?.slice(0)
      ?.reverse()
      ?.map((r, i) => (
        <tr key={i}>
          <td>
            {r?.elasticWidth || r?.model
              }
          </td>
          <td onClick={() => handleDelete(r?._id, type)}>
            <IconButton>
              <i class="bi bi-trash3 delete-icon"></i>
            </IconButton>
          </td>
        </tr>
      )) || (
      <tr>
        <td colSpan={3}>No data</td>
      </tr>
    );


    useEffect(() => {
      viewOrderModelAPI({ workorderTypeId: selectedType });
    }, [selectedType, isModify]);
    useEffect(()=>{
      if(editData!== undefined){
        setFormValues({
          ...formValues,
          modelId: filterObjFromList("_id", model, "modelId", editData),
          length:editData?.length,
          waist:editData?.waist,
          bottom:editData?.bottom,
          elasticSize:editData?.elasticSize,
          elasticWidth:filterObjFromList("_id",elasticWidth,"elasticWidth",editData),
          ply:editData?.ply,
          thighloose:editData?.thighloose,
          remark:editData?.remark,
        })
      }
    },[editData,model,elasticWidth])

  useEffect(()=>{
    viewElasticWidthAPICall()
  },[])

  return (
    <div className="thob-form-container">
      <div className="thob-model-left-container">
        <AutocompleteField
          label="Model"
          options={model}
          getOptionLabel={(option) => option.modelName}
          onAdd={handleAdd}
          fieldKey="modelId"
          value={formValues?.modelId}
          onChange={handleFormChange}
      
        />
          <TextFieldComponent 
            label="Length"
            fieldKey="length"
            value={formValues?.length}
            onChange={handleFormChange}
            />
          <TextFieldComponent 
             label="Waist"
             fieldKey="waist"
             value={formValues?.waist}
             onChange={handleFormChange}
             />
           <TextFieldComponent 
              label="Bottom"
              fieldKey="bottom"
              value={formValues?.bottom}
              onChange={handleFormChange}
              />
            <TextFieldComponent 
              label="Elastic Size"
              fieldKey="elasticSize"
              value={formValues?.elasticSize}
              onChange={handleFormChange}
              />
           <AutocompleteField
             label="Elastic Width"
             options={elasticWidth}
             getOptionLabel={(option) => option.elasticWidth}
             onAdd={handleAdd}
             fieldKey="elasticWidth"
             value={formValues?.elasticWidth}
             onChange={handleFormChange}
        />
        <TextFieldComponent 
          label="Ply"
          fieldKey="ply"
          value={formValues?.ply}
          onChange={handleFormChange}
          />
        <TextFieldComponent 
           label="Thigh Loose"
           fieldKey="thighloose"
           value={formValues?.thighloose}
           onChange={handleFormChange}
           />
        <TextFieldComponent 
           label="Notes"
           fieldKey="remark"
           value={formValues?.remark}
           onChange={handleFormChange}
           />
      </div>
      <div className="border-right"></div>
      <div className="thob-model-right-container"></div>

    

      <CreateTypeDialog
        open={openDialog === "Elastic Width"}
        label="Add Elastic Width"
        closeDialog={closeDialog}
        tablelabel="Elastic Width"
        addBtnFn={handleAddData}
        formData={handleGetFormData}
        clearFormData={inputValue}
      >
        {renderRows(elasticWidth,"Elastic Width")}
      </CreateTypeDialog>
       {/* create salwar model pop up */}
       <Dialog open={openDialog === "Model"} maxWidth="lg">
        <CreateModelDialog
          closeDialog={closeDialog}
          addBtnFn={handleAddModel}
          modelFormData={handleGetModelFormData}
          clearFormData={modelInputValues}
        >
          {model
            ?.slice(0)
            ?.reverse()
            ?.map((r, i) => (
              <tr key={i}>
                <td>{r?.modelName}</td>
                <td>{`${r?.fromLength}-${r?.toLength}`}</td>
                <td>{r?.minRate}</td>
                <td>{r?.commission}</td>
                <td>
                  <IconButton
                    onClick={() => handleDeleteModel(r?._id, "Model")}
                  >
                    <i class="bi bi-trash3 delete-icon"></i>
                  </IconButton>
                </td>
              </tr>
            )) || (
            <tr>
              <td colSpan={5}>No Data</td>
            </tr>
          )}
        </CreateModelDialog>
      </Dialog>
    </div>
  );
};
