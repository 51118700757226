import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Autocomplete,TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch } from "react-redux";
import { categoryListAPICall } from "../Modules/Inventory/Configuration/Category/CategoryAPI";
import { viewAllAllowedBranchesAPICall } from "../../API/Settings/Company Settings/companySettingsAPI";
import { vieWareHouseLocationAPICall } from "../Modules/Inventory/Configuration/wareHouseLocationAPI";
import equal from "../../Assets/Images/Icon awesome-equals.png"
import gte from "../../Assets/Images/Icon awesome-greater-than-equal.png"
import gt from "../../Assets/Images/Icon awesome-greater-than.png"
import lt from "../../Assets/Images/Icon awesome-less-than.png"
import lte from "../../Assets/Images/Icon awesome-less-than-equal.png"
import {setFilterActive,setFilterSearch,set_Filter_CategoryList } from "./listSlice/filterCategorySlice";
import { previousDate, today } from "../../Js/Date";
import { getAllDesignationAPICall,getAllDepartmentAPICall } from "../../API/Staff/HRM/departmentAndDesignationAPI";
import { journalListApiCall } from "../../API/AccountUpdated/journalAPI";

function CategorySearchandFilter(props) {
  const {
    isPaymentType,
    isOrderType,
    isSearch = true,
    typeList,
    isFromLocation,
    isToLocation,
    isProduct,
    statusOptions,
    isInvoiceNo,
    isCustomer,
    isShiftCode,
    isLocation,
    isCreatedBy,
    isStatus,
    isFromBranch,
    isDestinationBranch,
    isBranch,
    isDate,
    isToDate,
    isFromDate,
    isType,
    isJournal,
    isCategory,
    isDepartment,
    isDesignation,
    isposCategory,
    isFilter,
    isPosSalesReportPage,
    page,
    isExpiryDate,
    isExpFromDate,
    shouldFetchData = true,
    isBalance,
    isPos,
    onFilterChange
  } = props;

  const dispatch = useDispatch();
  const allDesignationList = useSelector((state) => state.allDesignationListSlice.value);
  const allDepartmentList = useSelector((state) => state.allDepartmentSlice.value);
  const allowedBranchList = useSelector((state) => state.allAllowedBranchesSlice.value);
  const customerList = useSelector((state) => state.allCustomerListSlice.value);
  const posSalesReportsList = useSelector((state) => state.posReports.reportsList)
  const allActiveEmpList = useSelector((state) => state.allActiveEmployeeSlice.value);
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);
  const posCategoryList = useSelector((state) => state?.posCategorySlice?.value);
  const CategoryList = useSelector((state) => state.categorySlice.value);
  const wareHouseLocationList = useSelector((state) => state.wareHouseLocationSlice.value);
  const allNewProductList = useSelector((state) => state.vendorBillSlice.NewProductList);
  const filtereDataOptions = useSelector((state) => state.listCategory);
  const journalList=useSelector((state)=>state.journalSlice.value)

  const dropDownCtnRef = useRef(null);
  const [dropdownState, setDropdownState] = useState({
    date: false,
    expiryDate: false,
    branch: false,
    type: false,
    location: false,
    designation: false,
    category: false,
    createdBy: false,
    posCategory: false,
    status: false,
    department: false,
    product: false,
    fromLocation: false,
    toLocation: false,
    fromBranch: false,
    destinationBranch: false,
    invoiceNo: false,
    customer: false,
    shiftCode: false,
    Ordertype:false,
    PaymentType:false,
    journal: false,
    balance: false,
    balanceOperator: false,
    balanceValue: false,
  });
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [clearClicked, setClearClicked] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedDateOption, setSelectedDateOption] = useState("from");
  const [selectedExpiryDateOption, setSelectedExpiryDateOption] = useState("expryfrom");
  const filteredItemsInitial = {
    expiryfromDate: '',
    fromDate: '',
    toDate: '',
    branchList: null,
    category: null,
    location: null,
    journal: null,
    designation: null,
    invoiceNo: null,
    customer: null,
    shiftCode: null,
    posCategory: null,
    type: null,
    createdBy: null,
    status: null,
    department: null,
    search: "",
    product: null,
    fromLocation: null,
    toLocation: null,
    fromBranch: null,
    destinationBranch: null,
    searchQuery: "",
    Ordertype:null,
    PaymentType:null,
    balance: '',
    balanceOperator: "",
    balanceValue: "",
  };
  const [filteredItems, setfilteredItems] = useState(filteredItemsInitial);

  const options = [
    { label: "Receivables", type: "subheading" },
    { label: "Bank and cash", type: "subheading" },
    { label: "Current asset", type: "subheading" },
    { label: "Non current asset", type: "subheading" },
    { label: "Prepayments", type: "subheading" },
    { label: "Fixed assets", type: "subheading" },
    { label: "Payable", type: "subheading" },
    { label: "Credit card", type: "subheading" },
    { label: "Current liability", type: "subheading" },
    { label: "Non current liability", type: "subheading" },
    { label: "EQUITY", type: "heading" },
    { label: "Equity", type: "subheading" },
    { label: "Current year earnings", type: "subheading" },
    { label: "Income", type: "subheading" },
    { label: "Other Income", type: "subheading" },
    { label: "Expenses", type: "subheading" },
    { label: "Depriciations", type: "subheading" },
    { label: "Cost of revenue", type: "subheading" },
    { label: "Off -balance sheet", type: "subheading" },
  ];
  const OrderTypeoptions = [
    { label: "DINEIN" },
    { label: "TAKE AWAY" },
    { label: "DELIVERY" },
  ];
  const PaymentType=[
    {label:"card"},
    {label:"cash"},
    {label:"upi"},
    {label:"cheque"},
  ]
 

  // console.log("previous date",previousDate)
  const invoiceNoArray = posSalesReportsList?.report?.map(reportItem => reportItem?.data?.map(order => order?.orderNo)).flat()
  const shiftCodeArray = posSalesReportsList?.report?.map(reportItem => reportItem?.data?.map(order => order?.shiftId)).flat()
  const duplicateshift = [...new Set(shiftCodeArray)]
  const handleOptionClick = (option) => {
    dispatch(setFilterActive(false));
    setDropdownState((prevState) => ({
      ...Object.fromEntries(Object.keys(prevState).map(key => [key, key === option ? !prevState[key] : false]))
    }));
  };



  const handleFromDateChange = (event) => {
    dispatch(setFilterActive(false));
    const newFromDate = event.target.value;
    // setfilteredItems((prevItems) => ({
    //   ...prevItems,
    //   fromDate: newFromDate,
    // }));

    setfilteredItems((prevItems) => {
      let updatedItems;
        updatedItems = {
          ...prevItems,
          fromDate: newFromDate
        };
      // Dispatch action to update Redux store with the updated state
      dispatch(set_Filter_CategoryList({ filterCategorylistData: updatedItems }));
      if (onFilterChange) {
        onFilterChange(updatedItems); // Notify parent about the filter change
      }
  
      return updatedItems;
    });
  };

  const handleExpiryFromDateChange = (event) => {
    dispatch(setFilterActive(false));
    const newexpiryFromDate = event.target.value;
    // setfilteredItems((prevItems) => ({
    //   ...prevItems,
    //   expiryfromDate: newexpiryFromDate,
    // }));

    setfilteredItems((prevItems) => {
      let updatedItems;
        updatedItems = {
          ...prevItems,
          expiryfromDate: newexpiryFromDate
        };
      // Dispatch action to update Redux store with the updated state
      dispatch(set_Filter_CategoryList({ filterCategorylistData: updatedItems }));
  
      return updatedItems;
    });
  };

  const handleToDateChange = (event) => {
    dispatch(setFilterActive(false));
    const newToDate = event.target.value;
    // setfilteredItems((prevItems) => ({
    //   ...prevItems,
    //   toDate: newToDate,
    // }));
    
    setfilteredItems((prevItems) => {
      let updatedItems;
        updatedItems = {
          ...prevItems,
          toDate: newToDate
        };
      // Dispatch action to update Redux store with the updated state
      dispatch(set_Filter_CategoryList({ filterCategorylistData: updatedItems }));
      if (onFilterChange) {
        onFilterChange(updatedItems); // Notify parent about the filter change
      }
      return updatedItems;
    });
  };
  const handleDropdownChange = (key) => (event, value) => {
    dispatch(setFilterActive(false));
  
    // Update filteredItems state based on the key
    setfilteredItems((prevItems) => {
      let updatedItems;
  
      if (key === "department") {
        updatedItems = {
          ...prevItems,
          department: value,
          designation: null,
        };
      } 
      else if (key === "branchList") {
        updatedItems = {
          ...prevItems,
          branchList: value,
          location: null,
        };

        vieWareHouseLocationAPICall({ branchId: value?._id });
      }  else if (key === "balanceValue") {
        setfilteredItems((prevItems) => ({
          ...prevItems,
          balanceValue: event.target.value,
        }));
      } else if (key === "balanceOperator") {
        setfilteredItems((prevItems) => ({
          ...prevItems,
          balanceOperator: value,
        }));
      }
      else {
        updatedItems = {
          ...prevItems,
          [key]: value,
        };
      }
      dispatch(set_Filter_CategoryList({ filterCategorylistData: updatedItems }));
      if (onFilterChange) {
        onFilterChange(updatedItems); // Notify parent about the filter change
      }
  
      return updatedItems; // Return the updated state
    });
  }
  const balanceOptions = [
    { value: "Greater", icon: gt, iconWidth: "18px", iconHeight: "15px" },
    { value: "Greater than equal", icon: gte, iconWidth: "20px", iconHeight: "20px" },
    { value: "Less", icon: lt, iconWidth: "18px", iconHeight: "15px" },
    { value: "Less than equal", icon: lte, iconWidth: "20px", iconHeight: "20px" },
    { value: "Equal", icon: equal, iconWidth: "18px", iconHeight: "13px" },
  ];


  const generateFilteredPayloadDatas = (filteredItems) => {
    return {
      expiryfromDate: filteredItems.expiryfromDate,
      fromDate: filteredItems.fromDate,
      toDate: filteredItems.toDate,
      journal:filteredItems.journal === null ? [] : [filteredItems.journal?._id],
      createdBy:
        filteredItems.createdBy === null ? [] : [filteredItems.createdBy?._id],
      branchList:
        filteredItems.branchList === null ? [] : [filteredItems.branchList?._id],
      location:
        filteredItems.location === null ? [] : [filteredItems.location?._id],
      category:
        filteredItems.category === null ? [] : [filteredItems.category?._id],
      posCategory:
        filteredItems.posCategory === null ? [] : [filteredItems.posCategory?._id],
      type:
        filteredItems.type === null ? [] : [filteredItems.type?.label],
      fromBranch:
        filteredItems.fromBranch === null ? [] : [filteredItems.fromBranch?._id],
      destinationBranch:
        filteredItems.destinationBranch === null ? [] : [filteredItems.destinationBranch?._id],
      status:
        filteredItems.status === null ? [] : [filteredItems.status],
      department:
        filteredItems.department === null ? '' : filteredItems.department?._id,
      designation:
        filteredItems.designation === null ? '' : filteredItems.designation?._id,
      search: filteredItems.search,
      product: filteredItems.product,
      invoiceNo: filteredItems.invoiceNo,
      customer: filteredItems.customer,
      shiftCode: filteredItems.shiftCode,
      fromLocation: filteredItems.fromLocation,
      toLocation: filteredItems.toLocation,
      searchQuery: filteredItems.searchQuery,
      Ordertype: filteredItems.Ordertype?.label,
      PaymentType: filteredItems.PaymentType?.label,
      balanceOperator: filteredItems.balanceOperator?.value,
      balanceValue: filteredItems.balanceValue,
    };
  };
  const filteredPayloadDatas = generateFilteredPayloadDatas(filteredItems);

  const filterSelected = [
    // filteredItems.fromDate !== '' && "true",
    // filteredItems.toDate !== '' && "true",
    filteredItems.branchList !== null && "true",
    filteredItems.createdBy !== null && "true",
    filteredItems.journal !== null && "true",
    filteredItems.status !== null && "true",
    filteredItems.department !== null && "true",
    filteredItems.location !== null && "true",
    filteredItems.designation !== null && "true",
    filteredItems.product !== null && "true",
    filteredItems.invoiceNo !== null && "true",
    filteredItems.customer !== null && "true",
    filteredItems.shiftCode !== null && "true",
    filteredItems.fromLocation !== null && "true",
    filteredItems.toLocation !== null && "true",
    filteredItems.category !== null && "true",
    filteredItems.posCategory !== null && "true",
    filteredItems.type !== null && "true",
    filteredItems.fromBranch !== null && "true",
    filteredItems.destinationBranch !== null && "true",
    filteredItems.Ordertype !== null && "true",
    filteredItems.PaymentType !== null && "true",
    ((filteredItems.fromDate !== "" || filteredItems.toDate!=="" ) && "true"),
    filteredItems.balanceOperator !== "" && "true", 
  ].filter(Boolean);

  const handleApplyFilter = () => {
    // console.log(filteredPayloadDatas, '....filteredPayloadDatas')
    props.onData(filteredPayloadDatas);
    props?.onBranchSelect(filteredItems?.branchList);

    if (!filtereDataOptions?.backButtonClicked) {
      dispatch(set_Filter_CategoryList({ filterCategorylistData: filteredItems }))
    }
    setShowFilterDropdown(!showFilterDropdown)
  };
  const handleClearAllFilters = () => {
    dispatch(setFilterActive(false));
    setShowFilterDropdown(!showFilterDropdown)
    setfilteredItems(filteredItemsInitial);
    setClearClicked(true)
    props?.onBranchSelect(null);

  };

  const handleSearchClick = () => {
    dispatch(setFilterActive(false));
    props?.onBranchSelect(filteredItems?.branchList);
    if (searchQuery.trim() !== "") {
      props.onData({...filteredPayloadDatas, search: searchQuery});
    } else {
      setfilteredItems(filteredItemsInitial)
      props.onData(filteredPayloadDatas);
    }
  };
  
    useEffect(() => {

      props.onData(filteredPayloadDatas);
      CategoryList === undefined && categoryListAPICall();
      allDepartmentList === undefined && getAllDepartmentAPICall();
      allDesignationList === undefined && getAllDesignationAPICall();
      allowedBranchList === undefined && viewAllAllowedBranchesAPICall({ employeeId: loginResponse?.data?._id });
      journalList === undefined && journalListApiCall();

      const handleClickOutside = (event) => {
        if (
          dropDownCtnRef.current &&
          !dropDownCtnRef.current.contains(event.target)
        ) {
          setShowFilterDropdown(false);
        }
      };
  
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };

      
    }, []);
    
  
    useEffect(() => { 
  
      // let loadData = window.location.pathname === filtereDataOptions?.filterActive 

      // if (!loadData) {
      //   dispatch(set_Filter_CategoryList({ filterCategorylistData: filteredItems }))
      // }
      if(clearClicked){
        props.onData(filteredPayloadDatas);
        dispatch(set_Filter_CategoryList({ filterCategorylistData: filteredItems }));
        setClearClicked(false)
      }
    }, [clearClicked]);
  

  
    useEffect(() => {
      if (isPosSalesReportPage) {
        setfilteredItems(prevState => ({
          ...prevState,
          fromDate: previousDate,
          toDate: today
        }));
      } else {
        setfilteredItems(prevState => ({
          ...prevState,
          fromDate: "",
          toDate: ""
        }));
      }
    }, [isPosSalesReportPage]);

  
    const dateLabel = page === 'employeeListing' ? 'Joining Date' : 'Date';
  
    useEffect(() => {
      let loadData = window.location.pathname === filtereDataOptions?.filterActive 
      if (loadData) {
        let payLoadData=generateFilteredPayloadDatas(filtereDataOptions?.filterCategorylist || filteredItemsInitial)
        setfilteredItems(filtereDataOptions?.filterCategorylist || filteredItemsInitial)
        
        props.onData(payLoadData);
        setSearchQuery(filtereDataOptions?.searchKeyWord || '')
        props?.onBranchSelect(filtereDataOptions?.filterCategorylist?.branchList || null);
      }
    }, [filtereDataOptions])
  return (
    <div
      className="right-side-search-div"
      style={{ width: "50%",maxWidth:isPos !== true && "350px",minWidth:isPos !== true &&"270px", justifyContent: "end", position: "relative", }}
    >
      {isFilter === undefined && (
        <div
          className="view-order-search-container justify-center"
          onClick={() => setShowFilterDropdown(!showFilterDropdown)}
          style={{
            border: "none",
            width: "40px",
            display: "flex",
            flexDirection:"column",
            cursor: "pointer",
            backgroundColor: "#DEE3F8",
            height:"36px",
            padding:"3px 0"
          }}
        >
          <i
            className="bi bi-funnel"
            style={{ color: " #5c5ce5", fontSize: "0.7rem",margin:"0" }}
          ></i>
          <p
            style={{
              color: "#5c5ce5",
              fontSize: "0.7rem",margin:"0"
            }}
          >
            Filter
          </p>
          {filterSelected.length !== 0 && (
            <span
              style={{
                position: "absolute",
                top: "-10px",
                right: "0",
                borderRadius: "50%",
                height: "20px",
                width: "20px",
                textAlign: "center",
                backgroundColor: "#0500a4",
                color: "#fff",
                fontSize: "0.8rem",
              }}
            >
              {filterSelected.length}
            </span>
          )}
        </div>
      )}
      {showFilterDropdown ? (
        <div ref={dropDownCtnRef} className={`filter-ctn-dropdown `} style={{ left: !isSearch && "44%" }} >
          {isDate === undefined && (
            <div
              className={`justify-space-between align-items-center filter-dropdown-menu ${selectedOption === "date" ? "selected" : ""
                }`}
              onClick={() => handleOptionClick("date")}
            >
              <p style={{ marginLeft: "10px" }}>
                {" "}
                {dateLabel}
                {filteredItems.fromDate.length > 0 && (
                  <sup>
                    <FiberManualRecordIcon
                      sx={{ color: "#0500a4", fontSize: "0.7rem" }}
                    />
                  </sup>
                )}
              </p>
              <ChevronRightIcon />
            </div>
          )}
          {dropdownState.date && (
            <div className="filter-ctn-dropdowndate">
              {isFromDate === undefined && (
                <div
                  className={`date-optns ${selectedDateOption === "from" ? "selected" : ""
                    }`}
                  onClick={() => setSelectedDateOption("from")}
                >
                  <label style={{ marginLeft: "15px" }} htmlFor="startdate">
                    From:{" "}
                  </label>
                  <input
                    type="date"
                    className={`date-optns ${selectedDateOption === "from" ? "selected" : ""
                      }`}
                    onChange={handleFromDateChange}
                    value={filteredItems.fromDate}
                  />
                </div>
              )}

              {isToDate === undefined && (
                <div
                  className={`date-optns ${selectedDateOption === "to" ? "selected" : ""
                    }`}
                  onClick={() => setSelectedDateOption("to")}
                >
                  <label style={{ marginLeft: "15px" }} htmlFor="startdate">
                    To:{" "}
                  </label>
                  <input
                    type="date"
                    className={`date-optns ${selectedDateOption === "to" ? "selected" : ""
                      }`}
                    onChange={handleToDateChange}
                    value={filteredItems.toDate}
                  />
                </div>
              )}
            </div>
          )}
          {isExpiryDate && (
            <div
              className={`justify-space-between align-items-center filter-dropdown-menu ${selectedOption === "expiryDate" ? "selected" : ""
                }`}
              onClick={() => handleOptionClick("expiryDate")}
            >
              <p style={{ marginLeft: "10px" }}>
                {" "}
               Expiry Date
                {filteredItems.expiryfromDate.length > 0 && (
                  <sup>
                    <FiberManualRecordIcon
                      sx={{ color: "#0500a4", fontSize: "0.7rem" }}
                    />
                  </sup>
                )}
              </p>
              <ChevronRightIcon />
            </div>
          )}
          {dropdownState.expiryDate && (
            <div className="filter-ctn-dropdowndateExp">
            
                <div
                  className={`date-optns ${selectedExpiryDateOption === "expryfrom" ? "selected" : ""
                    }`}
                  onClick={() => setSelectedExpiryDateOption("expryfrom")}
                >
                  <label style={{ marginLeft: "15px" }} htmlFor="startdate">
                    Expire From:{" "}
                  </label>
                  <input
                    type="date"
                    className={`date-optns ${selectedExpiryDateOption === "expryfrom" ? "selected" : ""
                      }`}
                    onChange={handleExpiryFromDateChange}
                    value={filteredItems.expiryfromDate}
                  />
                </div>
            

              {/* {isToDate === undefined && (
                <div
                  className={`date-optns ${selectedDateOption === "to" ? "selected" : ""
                    }`}
                  onClick={() => setSelectedDateOption("to")}
                >
                  <label style={{ marginLeft: "15px" }} htmlFor="startdate">
                    To:{" "}
                  </label>
                  <input
                    type="date"
                    className={`date-optns ${selectedDateOption === "to" ? "selected" : ""
                      }`}
                    onChange={handleToDateChange}
                    value={filteredItems.toDate}
                  />
                </div>
              )} */}
            </div>
          )}
          {isBranch === undefined && (
            <div
              className={`justify-space-between align-items-center filter-dropdown-menu ${selectedOption === "branch" ? "selected" : ""
                }`}
              onClick={() => handleOptionClick("branch")}
            >
              <p style={{ marginLeft: "10px" }}>
                {" "}
                Branch
                {filteredItems?.branchList !== null && (
                  <sup>
                    <FiberManualRecordIcon
                      sx={{ color: "#0500a4", fontSize: "0.7rem" }}
                    />
                  </sup>
                )}
              </p>
              {dropdownState.branch ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            </div>
          )}
          {dropdownState.branch && (
            <div
              style={{ width: "100%", margin: "8px 0" }}
              className="new-global-single-input auto-complete-new"
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={allowedBranchList || []}
                getOptionLabel={(option) =>
                  `${option?.storeCode}-${option?.branchName}`
                }
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} focused />}
                value={filteredItems.branchList}
                onChange={handleDropdownChange("branchList")}
              />
              <p className="doc-validation-alert"></p>
            </div>
          )}
             {isBalance && <div className="filter-inp-box-cntr">
            <>
              <div
                className={`justify-space-between align-items-center filter-dropdown-menu ${selectedOption === "balance" ? "selected" : ""
                  }`}
                onClick={() => handleOptionClick("balance")}
              >
                <p style={{ marginLeft: "10px" }}>
                  {" "}
                  Balance
                  {filteredItems?.balanceOperator !== '' && (
                    <sup>
                      <FiberManualRecordIcon
                        sx={{ color: "#0500a4", fontSize: "0.7rem" }}
                      />
                    </sup>
                  )}
                </p>
                <ChevronRightIcon />
              </div>
            </>
            {dropdownState.balance && <div className="filter-right-container">
              {/* <Autocomplete
                size="small"
                disablePortal
                id="combo-box-demo"
                options={balanceOptions || []}
                sx={{ width: 200 }}
                getOptionLabel={(option) => (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src={option.icon}
                      alt={option.value}
                      style={{ width: '20px', height: '20px', marginRight: '5px' }}
                    />
                    <span>{option.value}</span>
                  </div>
                )}
                value={balanceOptions.find((option) => option.value === filteredItems.balanceOperator) || null}
                onChange={handleDropdownChange("balanceOperator")}
                renderInput={(params) => <TextField {...params} label="Movie" />
              }
              /> */}
              <Autocomplete
                size="small"
                disablePortal
                id="combo-box-demo"
                options={balanceOptions || []}
                sx={{ width: 200 }}
                getOptionLabel={(option) => option?.value}
                // getOptionLabel={(option) => (
                //   <div style={{ display: 'flex', alignItems: 'center' }}>
                //     <img
                //       src={option.icon}
                //       alt={option.value}
                //       style={{ width: '20px', height: '20px', marginRight: '5px' }}
                //     />
                //     <span>{option.value}</span>
                //   </div>
                // )}
                // value={balanceOptions.find((option) => option.value === filteredItems.balanceOperator) || null}
                value={filteredItems.balanceOperator||null}
                onChange={handleDropdownChange("balanceOperator")}
                renderInput={(params) => <TextField {...params}
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    <span>{option.value}</span>
                  </li>
                )}
                label=""
                       
                />
              
              }

              />

              <TextField 
              id="outlined-basic" 
              size="small" 
              label="" 
              variant="outlined"         
              placeholder="0.00"       
              type="number"
              focused
              value={filteredItems.balanceValue}
              onChange={handleDropdownChange("balanceValue")} />
            </div>}

          </div>}
          {isFromBranch && (
            <div
              className={`justify-space-between align-items-center filter-dropdown-menu ${selectedOption === "fromBranch" ? "selected" : ""
                }`}
              onClick={() => handleOptionClick("fromBranch")}
            >
              <p style={{ marginLeft: "10px" }}>
                {" "}
                From Branch
                {filteredItems?.fromBranch !== null && (
                  <sup>
                    <FiberManualRecordIcon
                      sx={{ color: "#0500a4", fontSize: "0.7rem" }}
                    />
                  </sup>
                )}
              </p>
              {dropdownState.fromBranch ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            </div>
          )}
          {dropdownState.fromBranch && (
            <div
              style={{ width: "100%", margin: "8px 0" }}
              className="new-global-single-input auto-complete-new"
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={allowedBranchList || []}
                getOptionLabel={(option) =>
                  `${option?.storeCode}-${option?.branchName}`
                }
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} focused />}
                value={filteredItems.fromBranch}
                onChange={handleDropdownChange("fromBranch")}
              />
              <p className="doc-validation-alert"></p>
            </div>
          )}
          {isDestinationBranch && (
            <div
              className={`justify-space-between align-items-center filter-dropdown-menu ${selectedOption === "destinationBranch" ? "selected" : ""
                }`}
              onClick={() => handleOptionClick("destinationBranch")}
            >
              <p style={{ marginLeft: "10px" }}>
                {" "}
                Destination Branch
                {filteredItems?.destinationBranch !== null && (
                  <sup>
                    <FiberManualRecordIcon
                      sx={{ color: "#0500a4", fontSize: "0.7rem" }}
                    />
                  </sup>
                )}
              </p>
              {dropdownState.destinationBranch ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            </div>
          )}
          {dropdownState.destinationBranch && (
            <div
              style={{ width: "100%", margin: "8px 0" }}
              className="new-global-single-input auto-complete-new"
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={allBranchesList || []}
                getOptionLabel={(option) =>
                  `${option?.storeCode}-${option?.branchName}`
                }
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} focused />}
                value={filteredItems.destinationBranch}
                onChange={handleDropdownChange("destinationBranch")}
              />
              <p className="doc-validation-alert"></p>
            </div>
          )}
          {isLocation && (
            <div
              className={`justify-space-between align-items-center filter-dropdown-menu ${selectedOption === "location" ? "selected" : ""
                }`}
              onClick={() => handleOptionClick("location")}
            >
              <p style={{ marginLeft: "10px" }}>
                {" "}
                Location
                {filteredItems?.location !== null && (
                  <sup>
                    <FiberManualRecordIcon
                      sx={{ color: "#0500a4", fontSize: "0.7rem" }}
                    />
                  </sup>
                )}
              </p>
              {dropdownState.location ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            </div>
          )}
          {dropdownState.location && (
            <div
              style={{ width: "100%", margin: "8px 0" }}
              className="new-global-single-input auto-complete-new"
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={wareHouseLocationList || []}
                getOptionLabel={(option) => option?.name}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} focused />}
                value={filteredItems.location}
                onChange={handleDropdownChange("location")}
              />
              <p className="doc-validation-alert"></p>
            </div>
          )}
        
          {isInvoiceNo && (
            <div
              className={`justify-space-between align-items-center filter-dropdown-menu ${selectedOption === "invoiceNo" ? "selected" : ""
                }`}
              onClick={() => handleOptionClick("invoiceNo")}
            >
              <p style={{ marginLeft: "10px" }}>
                {" "}
                Invoice No
                {filteredItems?.invoiceNo?.length > 0 && (
                  <sup>
                    <FiberManualRecordIcon
                      sx={{ color: "#0500a4", fontSize: "0.7rem" }}
                    />
                  </sup>
                )}
              </p>
              {dropdownState.invoiceNo ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            </div>
          )}
          {dropdownState.invoiceNo && (
            <div
              style={{ width: "100%", margin: "8px 0" }}
              className="new-global-single-input auto-complete-new"
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={invoiceNoArray || []}
                getOptionLabel={(option) => option}
                sx={{ width: 300 }}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    <span>{option}</span>
                  </li>
                )}
                renderInput={(params) => <TextField {...params} focused />}
                value={filteredItems.invoiceNo}
                onChange={handleDropdownChange("invoiceNo")}
              />
              <p className="doc-validation-alert"></p>
            </div>
          )}
          {isCustomer && (
            <div
              className={`justify-space-between align-items-center filter-dropdown-menu ${selectedOption === "customer" ? "selected" : ""
                }`}
              onClick={() => handleOptionClick("customer")}
            >
              <p style={{ marginLeft: "10px" }}>
                {" "}
                Customer
                {filteredItems?.customer?.length > 0 && (
                  <sup>
                    <FiberManualRecordIcon
                      sx={{ color: "#0500a4", fontSize: "0.7rem" }}
                    />
                  </sup>
                )}
              </p>
              {dropdownState.customer ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            </div>
          )}
          {dropdownState.customer && (
            <div
              style={{ width: "100%", margin: "8px 0" }}
              className="new-global-single-input auto-complete-new"
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={customerList || []}
                getOptionLabel={(option) => option?.name}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} focused />}
                value={filteredItems.customer}
                // renderOption={(props, option) => (
                //   <li {...props} key={option._id}>
                //     <span>{option.name}</span>
                //   </li>
                // )}
                onChange={handleDropdownChange("customer")}
              />
              <p className="doc-validation-alert"></p>
            </div>
          )}

          {isShiftCode && (
            <div
              className={`justify-space-between align-items-center filter-dropdown-menu ${selectedOption === "shiftCode" ? "selected" : ""
                }`}
              onClick={() => handleOptionClick("shiftCode")}
            >
              <p style={{ marginLeft: "10px" }}>
                {" "}
                Shift code
                {filteredItems?.shiftCode?.length > 0 && (
                  <sup>
                    <FiberManualRecordIcon
                      sx={{ color: "#0500a4", fontSize: "0.7rem" }}
                    />
                  </sup>
                )}
              </p>
              {dropdownState.shiftCode ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            </div>
          )}
          {dropdownState.shiftCode && (
            <div
              style={{ width: "100%", margin: "8px 0" }}
              className="new-global-single-input auto-complete-new"
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={duplicateshift || []}
                getOptionLabel={(option) => option}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} focused />}
                value={filteredItems?.shiftCode}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    <span>{option}</span>
                  </li>
                )}
                onChange={handleDropdownChange("shiftCode")}
              />
              <p className="doc-validation-alert"></p>
            </div>
          )}
          {isCategory && (
            <div
              className={`justify-space-between align-items-center filter-dropdown-menu ${selectedOption === "category" ? "selected" : ""
                }`}
              onClick={() => handleOptionClick("category")}
            >
              <p style={{ marginLeft: "10px" }}>
                {" "}
                Category{" "}
                {filteredItems?.category !== null && (
                  <sup>
                    <FiberManualRecordIcon
                      sx={{ color: "#0500a4", fontSize: "0.7rem" }}
                    />
                  </sup>
                )}
              </p>
              {dropdownState.category ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            </div>
          )}
          {dropdownState.category && (
            <div
              style={{ width: "100%", margin: "8px 0" }}
              className="new-global-single-input auto-complete-new"
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={CategoryList || []}
                getOptionLabel={(option) => option?.categoryName}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} focused />}
                value={filteredItems.category}
                onChange={handleDropdownChange("category")}
              />
              <p className="doc-validation-alert"></p>
            </div>
          )}
          {isposCategory && (
            <div
              className={`justify-space-between align-items-center filter-dropdown-menu ${selectedOption === "posCategory" ? "selected" : ""
                }`}
              onClick={() => handleOptionClick("posCategory")}
            >
              <p style={{ marginLeft: "10px" }}>
                {" "}
                POS Category
                {filteredItems?.posCategory !== null && (
                  <sup>
                    <FiberManualRecordIcon
                      sx={{ color: "#0500a4", fontSize: "0.7rem" }}
                    />
                  </sup>
                )}
              </p>
              {dropdownState.posCategory ? (
                <ExpandMoreIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </div>
          )}
          {dropdownState.posCategory && (
            <div
              style={{ width: "100%", margin: "8px 0" }}
              className="new-global-single-input auto-complete-new"
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={posCategoryList || []}
                getOptionLabel={(option) => option?.name}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} focused />}
                value={filteredItems.posCategory}
                onChange={handleDropdownChange("posCategory")}
              />
              <p className="doc-validation-alert"></p>
            </div>
          )}
          {isType && (
            <div
              className={`justify-space-between align-items-center filter-dropdown-menu ${selectedOption === "type" ? "selected" : ""
                }`}
              onClick={() => handleOptionClick("type")}
            >
              <p style={{ marginLeft: "10px" }}>
                {" "}
                Type
                {filteredItems?.type !== null && (
                  <sup>
                    <FiberManualRecordIcon
                      sx={{ color: "#0500a4", fontSize: "0.7rem" }}
                    />
                  </sup>
                )}
              </p>
              {dropdownState.type ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            </div>
          )}
          {dropdownState.type && (
            <div
              style={{ width: "100%", margin: "8px 0" }}
              className="new-global-single-input auto-complete-new"
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={typeList || options || []}
                getOptionLabel={(option) => option?.label}
                sx={{ width: 300 }}
                renderOption={(props, option) => (
                  <li {...props} key={option?._id}>
                    <span>{option?.label}</span>
                  </li>
                )}
                renderInput={(params) => <TextField {...params} focused />}
                value={filteredItems.type}
                onChange={handleDropdownChange("type")}
              />
              <p className="doc-validation-alert"></p>
            </div>
          )}

{isJournal && (
            <div
              className={`justify-space-between align-items-center filter-dropdown-menu ${selectedOption === "journal" ? "selected" : ""
                }`}
              onClick={() => handleOptionClick("journal")}
            >
              <p style={{ marginLeft: "10px" }}>
                {" "}
                Journal
                {filteredItems?.journal !== null && (
                  <sup>
                    <FiberManualRecordIcon
                      sx={{ color: "#0500a4", fontSize: "0.7rem" }}
                    />
                  </sup>
                )}
              </p>
              {dropdownState.journal ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            </div>
          )}
          {dropdownState.journal && (
            <div
              style={{ width: "100%", margin: "8px 0" }}
              className="new-global-single-input auto-complete-new"
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={journalList  || []}
                getOptionLabel={(option) => option?.journalName}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} focused />}
                value={filteredItems.journal}
                onChange={handleDropdownChange("journal")}
              />
              <p className="doc-validation-alert"></p>
            </div>
          )}
{isOrderType && (
            <div
              className={`justify-space-between align-items-center filter-dropdown-menu ${selectedOption === "Ordertype" ? "selected" : ""
                }`}
              onClick={() => handleOptionClick("Ordertype")}
            >
              <p style={{ marginLeft: "10px" }}>
                {" "}
               Order Type
                {filteredItems?.Ordertype !== null && (
                  <sup>
                    <FiberManualRecordIcon
                      sx={{ color: "#0500a4", fontSize: "0.7rem" }}
                    />
                  </sup>
                )}
              </p>
              {dropdownState.Ordertype ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            </div>
          )}
          {dropdownState.Ordertype && (
            <div
              style={{ width: "100%", margin: "8px 0" }}
              className="new-global-single-input auto-complete-new"
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={ OrderTypeoptions || []}
                getOptionLabel={(option) => option?.label}
                sx={{ width: 300 }}
                renderOption={(props, option) => (
                  <li {...props} key={option?._id}>
                    <span>{option?.label}</span>
                  </li>
                )}
                renderInput={(params) => <TextField {...params} focused />}
                value={filteredItems.Ordertype}
                onChange={handleDropdownChange("Ordertype")}
              />
              <p className="doc-validation-alert"></p>
            </div>
          )}

{isPaymentType && (
            <div
              className={`justify-space-between align-items-center filter-dropdown-menu ${selectedOption === "PaymentType" ? "selected" : ""
                }`}
              onClick={() => handleOptionClick("PaymentType")}
            >
              <p style={{ marginLeft: "10px" }}>
                {" "}
               payment Type
                {filteredItems?.PaymentType !== null && (
                  <sup>
                    <FiberManualRecordIcon
                      sx={{ color: "#0500a4", fontSize: "0.7rem" }}
                    />
                  </sup>
                )}
              </p>
              {dropdownState.PaymentType ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            </div>
          )}
          {dropdownState.PaymentType && (
            <div
              style={{ width: "100%", margin: "8px 0" }}
              className="new-global-single-input auto-complete-new"
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={ PaymentType || []}
                getOptionLabel={(option) => option?.label}
                sx={{ width: 300 }}
                renderOption={(props, option) => (
                  <li {...props} key={option?._id}>
                    <span>{option?.label}</span>
                  </li>
                )}
                renderInput={(params) => <TextField {...params} focused />}
                value={filteredItems.PaymentType}
                onChange={handleDropdownChange("PaymentType")}
              />
              <p className="doc-validation-alert"></p>
            </div>
          )}


          {isCreatedBy && (
            <div
              className={`justify-space-between align-items-center filter-dropdown-menu ${selectedOption === "createdBy" ? "selected" : ""
                }`}
              onClick={() => handleOptionClick("createdBy")}
            >
              <p style={{ marginLeft: "10px" }}>
                {" "}
                Created By
                {filteredItems?.createdBy !== null && (
                  <sup>
                    <FiberManualRecordIcon
                      sx={{ color: "#0500a4", fontSize: "0.7rem" }}
                    />
                  </sup>
                )}
              </p>
              {dropdownState.createdBy ? (
                <ExpandMoreIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </div>
          )}
          {dropdownState.createdBy && (
            <div
              style={{ width: "100%", margin: "8px 0" }}
              className="new-global-single-input auto-complete-new"
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={allActiveEmpList || []}
                getOptionLabel={(option) => option?.staff_name}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} focused />}
                value={filteredItems.createdBy}
                onChange={handleDropdownChange("createdBy")}
              />
              <p className="doc-validation-alert"></p>
            </div>
          )}
          {isStatus && (
            <div
              className={`justify-space-between align-items-center filter-dropdown-menu ${selectedOption === "status" ? "selected" : ""
                }`}
              onClick={() => handleOptionClick("status")}
            >
              <p style={{ marginLeft: "10px" }}>
                Status
                {filteredItems?.status !== null && (
                  <sup>
                    <FiberManualRecordIcon
                      sx={{ color: "#0500a4", fontSize: "0.7rem" }}
                    />
                  </sup>
                )}
              </p>
              {dropdownState.status ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            </div>
          )}
          {dropdownState.status && (
            <div
              style={{ width: "100%", margin: "8px 0" }}
              className="new-global-single-input auto-complete-new"
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={statusOptions}
                getOptionLabel={(option) => option}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} focused />}
                value={filteredItems.status}
                onChange={handleDropdownChange("status")}
              />
              <p className="doc-validation-alert"></p>
            </div>
          )}
          {isDepartment && (
            <div
              className={`justify-space-between align-items-center filter-dropdown-menu ${selectedOption === "department" ? "selected" : ""
                }`}
              onClick={() => handleOptionClick("department")}
            >
              <p style={{ marginLeft: "10px" }}>
                Department
                {filteredItems?.department !== null && (
                  <sup>
                    <FiberManualRecordIcon
                      sx={{ color: "#0500a4", fontSize: "0.7rem" }}
                    />
                  </sup>
                )}
              </p>
              {dropdownState.department ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            </div>
          )}
          {dropdownState.department && (
            <div
              style={{ width: "100%", margin: "8px 0" }}
              className="new-global-single-input auto-complete-new"
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={allDepartmentList || []}
                getOptionLabel={(option) => option?.departmentName}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} focused />}
                value={filteredItems.department}
                onChange={handleDropdownChange("department")}
              />
              <p className="doc-validation-alert"></p>
            </div>
          )}
            {isDesignation && (
            <div
              className={`justify-space-between align-items-center filter-dropdown-menu ${selectedOption === "designation" ? "selected" : ""
                }`}
              onClick={() => handleOptionClick("designation")}
            >
              <p style={{ marginLeft: "10px" }}>
                {" "}
                Designation
                {filteredItems?.designation !== null && (
                  <sup>
                    <FiberManualRecordIcon
                      sx={{ color: "#0500a4", fontSize: "0.7rem" }}
                    />
                  </sup>
                )}
              </p>
              {dropdownState.designation ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            </div>
          )}
          {dropdownState.designation && (
            <div
              style={{ width: "100%", margin: "8px 0" }}
              className="new-global-single-input auto-complete-new"
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={allDesignationList || []}
                getOptionLabel={(option) => option?.position}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} focused />}
                value={filteredItems.designation}
                onChange={handleDropdownChange("designation")}
              />
              <p className="doc-validation-alert"></p>
            </div>
          )}
          {isProduct && (
            <div
              className={`justify-space-between align-items-center filter-dropdown-menu ${selectedOption === "product" ? "selected" : ""
                }`}
              onClick={() => handleOptionClick("product")}
            >
              <p style={{ marginLeft: "10px" }}>
                {" "}
                Product
                {filteredItems?.product !== null && (
                  <sup>
                    <FiberManualRecordIcon
                      sx={{ color: "#0500a4", fontSize: "0.7rem" }}
                    />
                  </sup>
                )}
              </p>
              {dropdownState.product ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            </div>
          )}
          {dropdownState.product && (
            <div
              style={{ width: "100%", margin: "8px 0" }}
              className="new-global-single-input auto-complete-new"
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={allNewProductList || []}
                getOptionLabel={(option) => option?.productName}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} focused />}
                value={filteredItems.product}
                onChange={handleDropdownChange("product")}
              />
              <p className="doc-validation-alert"></p>
            </div>
          )}

          {/* from location  */}
          {isFromLocation && (
            <div
              className={`justify-space-between align-items-center filter-dropdown-menu ${selectedOption === "fromLocation" ? "selected" : ""
                }`}
              onClick={() => handleOptionClick("fromLocation")}
            >
              <p style={{ marginLeft: "10px" }}>
                {" "}
                From Location
                {filteredItems?.fromLocation !== null && (
                  <sup>
                    <FiberManualRecordIcon
                      sx={{ color: "#0500a4", fontSize: "0.7rem" }}
                    />
                  </sup>
                )}
              </p>
              {dropdownState.fromLocation ? (
                <ExpandMoreIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </div>
          )}
          {dropdownState.fromLocation && (
            <div
              style={{ width: "100%", margin: "8px 0" }}
              className="new-global-single-input auto-complete-new"
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={wareHouseLocationList || []}
                getOptionLabel={(option) => option?.name}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} focused />}
                value={filteredItems.fromLocation}
                onChange={handleDropdownChange("fromLocation")}
              />
              <p className="doc-validation-alert"></p>
            </div>
          )}
          {/* to location  */}
          {isToLocation && (
            <div
              className={`justify-space-between align-items-center filter-dropdown-menu ${selectedOption === "toLocation" ? "selected" : ""
                }`}
              onClick={() => handleOptionClick("toLocation")}
            >
              <p style={{ marginLeft: "10px" }}>
                {" "}
                To location
                {filteredItems?.toLocation !== null && (
                  <sup>
                    <FiberManualRecordIcon
                      sx={{ color: "#0500a4", fontSize: "0.7rem" }}
                    />
                  </sup>
                )}
              </p>
              {dropdownState.toLocation ? (
                <ExpandMoreIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </div>
          )}
          {dropdownState.toLocation && (
            <div
              style={{ width: "100%", margin: "8px 0" }}
              className="new-global-single-input auto-complete-new"
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={wareHouseLocationList || []}
                getOptionLabel={(option) => option?.name}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} focused />}
                value={filteredItems.toLocation}
                onChange={handleDropdownChange("toLocation")}
              />
              <p className="doc-validation-alert"></p>
            </div>
          )}
          <div
            className="justify-center"
            style={{ justifyContent: "space-between", paddingRight: "8px" }}
          >
            <button onClick={handleApplyFilter} className='filter-apply-btn'>Apply</button>
            <p
              // onClick={() => {
              //   dispatch(set_Back_button_action({ backButtonClickAction: false }));
              //   setfilteredItems(filteredItemsInitial);
              //   setShowFilterDropdown(false);
              //   props.onData(filteredPayloadDatas)
              //   props.onBranchSelect(filteredItemsInitial);
              // }}
              onClick={handleClearAllFilters}
              style={{ color: "#e8a990", margin: "7px 0", cursor: "pointer" }}
            >
              Clear All
            </p>
          </div>
        </div>
      ) : null}
      {isSearch &&
        //    <div className="view-order-search-container" style={{ width: "35%", position: "relative" }}>
        //   <p className="search-head">Search</p>
        //   <input
        //     value={search}
        //     onChange={(e) => {
        //       setfilteredItems((prev)=>({...prev,search:e.target.value}))
        //       setSearch(e.target.value)
        //       // setSearchQuery(e.target.value)
        //       if(e.target.value===""){
        //         handleSearchClick()
        //       }
        //     }}
        //     onKeyDown={(e)=>e.key==="Enter" && handleSearchClick()}
        //   />
        //   <SearchIcon
        //     className="billing-search-icon"
        //     style={{ color: "#736ff3" }}
        //     onClick={() => handleSearchClick()}
        //   />
        // </div>
        <div className="view-order-search-container" style={{ flex:"1", position: "relative" }}>
          <p className="search-head">Search</p>
          <input
            value={searchQuery}
            onChange={(e) => {
              dispatch(setFilterSearch(e.target.value))
              dispatch(setFilterActive(false));
              setSearchQuery(e.target.value)
              if (e.target.value === "") {
                props.onData(filteredPayloadDatas);
                // handleSearchClick()
              }
            }}
            onKeyDown={(e) =>
               e.key === "Enter" && handleSearchClick()
              }
          />
          <SearchIcon
            className="billing-search-icon"
            style={{ color: "#736ff3" }}
            onClick={() => handleSearchClick()}
          />
        </div>
      }
    </div>
  );
}

export default CategorySearchandFilter;