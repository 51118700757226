import axios from "axios"
import { HEADERS, HEADER_QUERY } from "../../../../../API/UrlAndPaths"
import store from "../../../../../Redux/store";
import { get_button, get_collar, get_collar_cloth, get_collar_model, get_cuff, get_cuff_cloth, get_cuff_model, get_elastic_width, get_fp_model, get_jalabia_model, get_mar_model, get_model, get_neck_model, get_order_itemType, get_order_types, get_pen, get_product_color, get_side_pt_model, get_stitching } from "./Measurements";
import { setModify } from "../PosNewSlice";


// add order type
export const addOrderTypeAPI=(body)=>{
    axios.post("workorder/addworkordertypes",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            console.log('add');
        }
    })
    .catch((err)=>{
        console.log(err.response);
    })
}

// get order type
export const viewOrderTypeAPI=()=>{
    axios.get("workorder/getworkordertypes",{},HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_order_types({orderTypesData:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(get_order_types({orderTypesData:undefined}))

    })
}

// add item type
export const addOrderItemTypeAPI=(body,setInputValue)=>{
    axios.post("workorder/addworkorderitemtypes",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            console.log('add');
            store.dispatch(setModify())
            setInputValue("")
        }
    })
    .catch((err)=>{
        console.log(err.response);
    })
}
// view item type
export const viewOrderItemTypeAPI=(body)=>{
    axios.post("workorder/getworkorderitemtypes",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_order_itemType({itemTypeData:res.data}))
        }
    })
    .catch((err)=>{
            store.dispatch(get_order_itemType({itemTypeData:undefined}))

    })
}
export const deleteOrderItemTypeAPI=(body)=>{
   axios.delete("")
}

// add model
export const addOrderModelAPI=(body,setModelInputValues)=>{
    axios.post("workorder/addThobModel",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
          setModelInputValues({})
            // viewOrderModelAPI({workorderTypeId:res?.data?.workorderTypeId})
            store.dispatch(setModify())
        }
    })
    .catch((err)=>{
        console.log(err.response);
    })
}
// view model
export const viewOrderModelAPI=(body)=>{
    axios.post("workorder/getAllThobModels",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_model({modelData:res.data}))
        }
    })
    .catch((err)=>{
            store.dispatch(get_model({modelData:undefined}))

    })
}
export const deleteOrderModelAPI=(body)=>{
    axios.delete("workorder/deleteThobModel",{headers:HEADER_QUERY,data:body})
    .then((res)=>{
        console.log("success");
        store.dispatch(setModify())
    })
    .catch((err)=>{
        console.log(err.response);
    })
}
// add color
export const addProductColor=(body,setState,state)=>{
   axios.post("readymade/color/add",body,HEADERS)
   .then((res)=>{
    if(res.status===200){
        console.log('add');
        setState(state)
        viewProductColor()
    }
   })
    .catch((err)=>{
        console.log(err);
    })

}
// view color
export const viewProductColor=()=>{
    axios.post("readymade/color/view",{},HEADERS)
    .then((res)=>{
        store.dispatch(get_product_color(res.data))
       
    })
    .catch((err)=>{
        store.dispatch(get_product_color(undefined))
    })
}
// delete product color 
export const deleteProductColor=(body)=>{
    axios.delete("readymade/color/delete",{
        headers:HEADER_QUERY,
        data:body
    })
    .then((res)=>{
        if(res.status===200){
            viewProductColor()
        }

    })
    .catch((err)=>{
        console.log(err);
    })
}
//*mar model
//Add mar model
export const addMarModelAPICall = async (body,setInputValue) => {
    await axios.post("workorder/addMarModel", body, HEADERS)
      .then((res) => {
        // store.dispatch(setModify())
        viewMarModelAPICall()
        setInputValue("")
      })
      .catch((err) => {
        console.error(err.response);
      });
  };
 //View mar Model
  export const viewMarModelAPICall = async () => {
    await axios.get("workorder/getAllMarModels", HEADERS)
      .then((res) => {
       store.dispatch(get_mar_model(res.data))
      })
      .catch((err) => {
        store.dispatch(get_mar_model(undefined))
        console.error(err.response);
      });
  };
  //Delete Mar model
  export const deleteMarModelAPICall = async (body) => {
    await axios.delete("workorder/deleteMarModel", { headers: HEADER_QUERY, data: body })
      .then((res) => {
        // store.dispatch(setModify())
        viewMarModelAPICall()
      })
      .catch((err) => {
        console.error(err.response);
      });
  };

//*Cuff
//Add Cuff
export const addCuffAPICall = async (body,setInputValue) => {
    await axios
      .post("workorder/addCuff", body, HEADERS)
      .then((res) => {
        setInputValue("")
        viewCuffAPICall()
      })
      .catch((err) => {
        
        console.error(err.response);
      });
  };
  //View Cuff
  export const viewCuffAPICall = async () => {
    await axios.get("workorder/getAllCuffs", HEADERS)
      .then((res) => {
         store.dispatch(get_cuff(res.data))
      })
      .catch((err) => {
        store.dispatch(get_cuff(undefined))
      });
  };
  //Delete Cuff
  export const deleteCuffAPICall = async (body) => {
    await axios
      .delete("workorder/deleteCuff", { headers: HEADER_QUERY, data: body })
      .then((res) => {
        viewCuffAPICall()
      })
      .catch((err) => {
       
        console.error(err.response);
      });
  };

  //*Cuff Cloth
//Add Cuff Cloth
export const addCuffClothAPICall = async (body,setInputValue) => {
    await axios
      .post("workorder/addCuffCloth", body, HEADERS)
      .then((res) => {
        setInputValue("")
        viewCuffClothAPICall()
      })
      .catch((err) => {
       
        console.error(err.response);
      });
  };
  //View Cuff Cloth
  export const viewCuffClothAPICall = async () => {
    await axios
      .get("workorder/getAllCuffCloths", HEADERS)
      .then((res) => {
         store.dispatch(get_cuff_cloth(res.data))
      })
      .catch((err) => {
        store.dispatch(get_cuff_cloth(undefined))
        console.error(err.response);
      });
  };
  //Delete Cuff Cloth
  export const deleteCuffClothAPICall = async (
    body,
  ) => {
    await axios
      .delete("workorder/deleteCuffCloth", { headers: HEADER_QUERY, data: body })
      .then((res) => {
        viewCuffClothAPICall()
      })
      .catch((err) => {
       
        console.error(err.response);
      });
  };
  
  //*Cuff Model
  //Add Cuff Model
  export const addCuffModelAPICall = async (
    body,setInputValue
  ) => {
    await axios
      .post("workorder/addCuffModel", body, HEADERS)
      .then((res) => {
        setInputValue("")
        viewCuffModelAPICall()
      })
      .catch((err) => {
      
        console.error(err.response);
      });
  };
  //View Cuff Model
  export const viewCuffModelAPICall = async () => {
    await axios
      .get("workorder/getAllCuffModels", HEADERS)
      .then((res) => {
        store.dispatch(get_cuff_model(res.data))
      })
      .catch((err) => {
        store.dispatch(get_cuff_model(undefined))
        console.error(err.response);
      });
  };
  //Delete Cuff Model
  export const deleteCuffModelAPICall = async (
    body,
  ) => {
    await axios
      .delete("workorder/deleteCuffModel", { headers: HEADER_QUERY, data: body })
      .then((res) => {
        viewCuffModelAPICall()
      })
      .catch((err) => {
        console.error(err.response);
      });
  };

  //*Collar
//Add Collar
export const addCollarAPICall = async (
  body,
  setInputValue
) => {
  await axios
    .post("workorder/addCollar", body, HEADERS)
    .then((res) => {
      setInputValue("")
      viewCollarAPICall()
    })
    .catch((err) => {
      
      console.error(err.response);
    });
};
//View Collar
export const viewCollarAPICall = async () => {
  await axios
    .get("workorder/getAllCollars", HEADERS)
    .then((res) => {
        store.dispatch(get_collar(res.data))
    })
    .catch((err) => {
      store.dispatch(get_collar(undefined))
      console.error(err.response);
    });
};
//Delete Collar
export const deleteCollarAPICall = async (
  body
) => {
  await axios
    .delete("workorder/deleteCollar", { headers: HEADER_QUERY, data: body })
    .then((res) => {
      viewCollarAPICall()
    })
    .catch((err) => {
      
      console.error(err.response);
    });
};

//*Collar Cloth
//Add Collar Cloth
export const addCollarClothAPICall = async (
  body,
  setInputValue
) => {
  await axios
    .post("workorder/addCollarCloth", body, HEADERS)
    .then((res) => {
      setInputValue("")
      viewCollarClothAPICall()
    })
    .catch((err) => {
     
      console.error(err.response);
    });
};
//View Collar Cloth
export const viewCollarClothAPICall = async () => {
  await axios
    .get("workorder/getAllCollarCloths", HEADERS)
    .then((res) => {
       store.dispatch(get_collar_cloth(res.data))
    })
    .catch((err) => {
      store.dispatch(get_collar_cloth(undefined))
      console.error(err.response);
    });
};
//Delete Collar Cloth
export const deleteCollarClothAPICall = async (
  body
) => {
  await axios
    .delete("workorder/deleteCollarCloth", {
      headers: HEADER_QUERY,
      data: body,
    })
    .then((res) => {
      viewCollarClothAPICall()
    })
    .catch((err) => {
      console.error(err.response);
    });
};

//*Collar Model
//Add Collar Model
export const addCollarModelAPICall = async (
  body,
  setInputValue
) => {
  await axios
    .post("workorder/addCollarModel", body, HEADERS)
    .then((res) => {
      setInputValue("")
      viewCollarModelAPICall()
    })
    .catch((err) => {
      console.error(err.response);
    });
};
//View Collar Model
export const viewCollarModelAPICall = async () => {
  await axios
    .get("workorder/getAllCollarModels", HEADERS)
    .then((res) => {
      store.dispatch(get_collar_model(res.data))
    })
    .catch((err) => {
      store.dispatch(get_collar_model(undefined))
      console.error(err.response);
    });
};
//Delete Collar Model
export const deleteCollarModelAPICall = async (
  body
) => {
  await axios
    .delete("workorder/deleteCollarModel", {
      headers: HEADER_QUERY,
      data: body,
    })
    .then((res) => {
      viewCollarModelAPICall()
    })
    .catch((err) => {
      console.error(err.response);
    });
};

//* FP Model
//Add  Model
export const addFpModelAPICall = async (
  body,
  setInputValue
) => {
  await axios
    .post("workorder/addFPModel", body, HEADERS)
    .then((res) => {
      setInputValue("")
      viewFpModelAPICall()
    })
    .catch((err) => {
      console.error(err.response);
    });
};
//View  Model
export const viewFpModelAPICall = async () => {
  await axios
    .get("workorder/getallFPModels", HEADERS)
    .then((res) => {
       store.dispatch(get_fp_model(res.data))
    })
    .catch((err) => {
      store.dispatch(get_fp_model(undefined))
      console.error(err.response);
    });
};
//Delete Model
export const deleteFpModelAPICall = async (
  body
) => {
  await axios
    .delete("workorder/deleteFPModel", { headers: HEADER_QUERY, data: body })
    .then((res) => {
      viewFpModelAPICall()
    })
    .catch((err) => {
      console.error(err.response);
    });
};

//*Pen
//Add Pen
export const addPenAPICall = async (
  body,
  setInputValue
) => {
  await axios
    .post("workorder/addPen", body, HEADERS)
    .then((res) => {
      setInputValue("")
      viewPenAPICall()
    })
    .catch((err) => {
      console.error(err.response);
    });
};
//View Pen
export const viewPenAPICall = async () => {
  await axios
    .get("workorder/getAllPens", HEADERS)
    .then((res) => {
       store.dispatch(get_pen(res.data))
    })
    .catch((err) => {
      store.dispatch(get_pen(undefined))
      console.error(err.response);
    });
};
//Delete Pen
export const deletePenAPICall = async (
  body,
) => {
  await axios
    .delete("workorder/deletePen", { headers: HEADER_QUERY, data: body })
    .then((res) => {
      viewPenAPICall()
    })
    .catch((err) => {
      console.error(err.response);
    });
};

//*Side PT Model
//Add Side PT Model
export const addSidePtModelAPICall = async (
  body,
  setInputValue
) => {
  await axios
    .post("workorder/addSidePT", body, HEADERS)
    .then((res) => {
      setInputValue("")
      viewSidePtModelAPICall()
    })
    .catch((err) => {
     
      console.error(err.response);
    });
};
//View Side PT Model
export const viewSidePtModelAPICall = async () => {
  await axios
    .get("workorder/getAllSidePTs", HEADERS)
    .then((res) => {
        store.dispatch(get_side_pt_model(res.data))
    })
    .catch((err) => {
      store.dispatch(get_side_pt_model(undefined))
      console.error(err.response);
    });
};
//Delete Side PT Model
export const deleteSidePtModelAPICall = async (
  body
) => {
  await axios
    .delete("workorder/deletesidePT", { headers: HEADER_QUERY, data: body })
    .then((res) => {
      viewSidePtModelAPICall()
    })
    .catch((err) => {
      console.error(err.response);
    });
};

//*Stitching
//Add Stitching
export const addStitchingAPICall = async (
  body,
  setInputValue
) => {
  await axios
    .post("workorder/addStitching", body, HEADERS)
    .then((res) => {
      setInputValue("")
      viewStitchingAPICall()
    })
    .catch((err) => {
      console.error(err.response);
    });
};
//View Stitching
export const viewStitchingAPICall = async () => {
  await axios
    .get("workorder/getAllStitchings", HEADERS)
    .then((res) => {
        store.dispatch(get_stitching(res.data))
    })
    .catch((err) => {
      store.dispatch(get_stitching(undefined))
      console.error(err.response);
    });
};
//Delete Stitching
export const deleteStitchingAPICall = async (
  body
) => {
  await axios
    .delete("workorder/deleteStitching", { headers: HEADER_QUERY, data: body })
    .then((res) => {
      viewStitchingAPICall()
    })
    .catch((err) => {
      console.error(err.response);
    });
};

//*Button
//Add Button
export const addButtonAPICall = async (
  body,
  setInputValue
) => {
  await axios
    .post("workorder/addButton", body, HEADERS)
    .then((res) => {
      setInputValue("")
      viewButtonAPICall()
    })
    .catch((err) => {
      
      console.error(err.response);
    });
};
//View Button
export const viewButtonAPICall = async () => {
  await axios
    .get("workorder/getAllButtons", HEADERS)
    .then((res) => {
         store.dispatch(get_button(res.data))
    })
    .catch((err) => {
        store.dispatch(get_button(undefined))
        console.error(err.response);
    });
};
//Delete Button
export const deleteButtonAPICall = async (
  body
) => {
  await axios
    .delete("workorder/deleteButton", { headers: HEADER_QUERY, data: body })
    .then((res) => {
      viewButtonAPICall()
    })
    .catch((err) => {
      console.error(err.response);
    });
};

//* Neck Model
//Add Neck Model
export const addNeckModelAPICall = async (
  body,
  setInputValue
) => {
  await axios
    .post("workorder/addNeckModel", body, HEADERS)
    .then((res) => {
      setInputValue("")
      viewNeckModelAPICall()
    })
    .catch((err) => {
      console.error(err.response);
    });
};

//View Neck Model
export const viewNeckModelAPICall = async () => {
  await axios
    .get("workorder/getAllNeckModels", HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(get_neck_model(res.data ));
      }
    })
    .catch((err) => {
       console.error(err.response);
       store.dispatch(get_neck_model(undefined));
    });
};

//Delete Neck Model
export const deleteNeckModelAPICall = async (
  body,
 
) => {
  await axios
    .delete("workorder/deleteNeckModel", { headers: HEADER_QUERY, data: body })
    .then((res) => {
      viewNeckModelAPICall()
    })
    .catch((err) => {
    
      console.error(err.response);
    });
};

//* Model
//Add  Model
export const addModelAPICall = async (
  body,
  setInputValue
) => {
  await axios
    .post("workorder/addModel", body, HEADERS)
    .then((res) => {
      console.log('add');
      setInputValue("")
      viewModelAPICall()
    })
    .catch((err) => {
      console.error(err.response);
    });
};
//View  Model
export const viewModelAPICall = async () => {
  await axios
    .get("workorder/getAllModels", HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(get_jalabia_model(res.data))
      }
    })
    .catch((err) => {
       store.dispatch(get_jalabia_model(undefined))
    });
};
//Delete Model
export const deleteModelAPICall = async (
  body,
 
) => {
  await axios
    .delete("workorder/deleteModel", { headers: HEADER_QUERY, data: body })
    .then((res) => {
      if (res.status === 200) {
        viewModelAPICall()
      }
    })
    .catch((err) => {
      
    });
};

//* Elastic Width
//Add  Elastic width
export const addElasticWidthAPICall = async (
  body,
  setInputValue
  ) => {
  await axios
    .post("workorder/addElasticWidth",body,HEADERS)
    .then((res) => {
      setInputValue("")
      viewElasticWidthAPICall()
    })
    .catch((err) => {
      
      console.error(err.response);
    });
};
//View  Elastic width
export const viewElasticWidthAPICall = async () => {
  await axios
    .get("workorder/getAllElasticWidths", HEADERS)
    .then((res) => {
       store.dispatch(get_elastic_width(res.data));
    })
    .catch((err) => {
      store.dispatch(get_elastic_width(undefined));
      console.error(err.response);
    });
};
//Delete Elastic Width
export const deleteElasticWidthAPICall = async (
  body
) => {
  await axios
    .delete("workorder/deleteElasticWidth", {
      headers: HEADER_QUERY,
      data:body,
    })
    .then((res) => {
      viewElasticWidthAPICall()
    })
    .catch((err) => {
     
      console.error(err.response);
    });
};


