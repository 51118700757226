import axios from "axios"
import store from "../../../../../Redux/store"
import { setBillLoading, setDialogBox, setErrorSnack, setKOTManualDialog, setKOTSingleInfo, setOrderIdInPOS, setSingleOrderInfo, setSnackMsg, setSuccessSnack } from "../Slice/billingScreenSlice"
import { HEADERS } from "../../../../../API/UrlAndPaths"
import { setAggregator, setAggregatorRef, setBtnSelected, setSingleCusInfo } from "../Components/topBarSliceBilling"
import { setCartLoading } from "../Slice/viewOrdersBillingSlice"
import { setAuthPassword } from "../newBillingSlice"
import { deliveryReceiptInfo } from "./billingPaymentSection"
import { errorSnackMsg } from "../../../../Custom Hooks/SnackBarUtilities"




// add order from pos
export const addPOSOrdertoKOT=async(body,clearFn,orderType,printReceipt)=>{
    store.dispatch(setBillLoading(true))
    const printSettingsString=localStorage.getItem('PrinterSettings')
    const printSettings=JSON.parse(printSettingsString)
    await axios.post("pos/postOrderFromFloorPlan",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(setBillLoading(false))                  

                store.dispatch(setSnackMsg("Order Placed Successfully"))
                store.dispatch(setSuccessSnack(true))
                store.dispatch(setAggregator(null))
                store.dispatch(setAggregatorRef(null))
        
                if(orderType==="paymentOrder"){  
                    store.dispatch(setBtnSelected("payment"))
                    singlePOSOrderInfoAPICall({id:res?.data?.data?._id})   
                }
                else{
                    store.dispatch(setOrderIdInPOS(null))

                    if(res?.data?.kotData===null){
                        store.dispatch(setDialogBox(false))        
                    }else{
                        store.dispatch(setDialogBox(false))        
                        (printSettings?.kotAuto)?printReceipt(res?.data?.kotData) : store.dispatch(setKOTManualDialog("kotPrint"))           
                    }    
                    store.dispatch(setKOTSingleInfo(res?.data?.kotData || null))
                }
                clearFn()
                body.ordType==="DELIVERY" && deliveryReceiptInfo({orderId:res?.data?.data?._id})

            }
        })
        .catch((err)=>{
            let message=err.response.status===500?"Internal Server Error !!":err.response.status===400?err.response.data[0]?.msg:err.response.data
                store.dispatch(setSnackMsg(message))
                store.dispatch(setBillLoading(false))                
                store.dispatch(setErrorSnack(true))
        })
}

// add pos order without chair selected
export const addPOSOrderWoFloorSelectionAPI=async(body,clearFn,orderType,printReceipt)=>{
    const printSettingsString=localStorage.getItem('PrinterSettings')
    const printSettings=JSON.parse(printSettingsString)
    store.dispatch(setBillLoading(true))
    await axios.post("pos/addPosOrder",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(setBillLoading(false))                
                store.dispatch(setSnackMsg("Order Placed Successfully"))
                store.dispatch(setSuccessSnack(true))
                if(orderType==="paymentOrder"){
                    store.dispatch(setOrderIdInPOS(res.data?.data?._id))   
                    store.dispatch(setBtnSelected("payment"))   
                    }
                    else{

                    if(res?.data?.kotData===null){
                        store.dispatch(setDialogBox(null))        
                    }else{
                        store.dispatch(setDialogBox(null))        
                        // (printSettings?.kotAuto)?printReceipt(res?.data?.kotData) : store.dispatch(setDialogBox("kotPrint"))           
                        // store.dispatch(setDialogBox(printSettings?.kotAuto?"autoPrintKot":"kotPrint"))    
                        store.dispatch(printSettings?.kotAuto?setDialogBox("autoPrintKot"):setKOTManualDialog("kotPrint"))

                    }
                    store.dispatch(setKOTSingleInfo(res?.data?.kotData || null))  
                    store.dispatch(setOrderIdInPOS(null))
                }
                clearFn()
                store.dispatch(setAggregator(null))
                store.dispatch(setAggregatorRef(null))
                body.ordType==="DELIVERY" && deliveryReceiptInfo({orderId:res?.data?.data?._id})
            }
        })
        .catch((err)=>{
            let message=err.response.status===500?"Internal Server Error !!":err.response.status===400?"Delivery partner not selected!!":err.response.data
                store.dispatch(setSnackMsg(message))
                store.dispatch(setBillLoading(false))                
                store.dispatch(setErrorSnack(true))
        })
}
export const quickBillOrderAPI=async(body,clearFn)=>{
    store.dispatch(setBillLoading(true))
    await axios.post("pos/addQuickBill",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                const currentOrderId = store.getState().billingScreenSlice.orderIdForPos
                currentOrderId===res.data?.data?._id?singlePOSOrderInfoAPICall({ id: currentOrderId }) :store.dispatch(setOrderIdInPOS(res.data?.data?._id))

                store.dispatch(setBillLoading(false))  
                store.dispatch(setAggregator(null))
                store.dispatch(setAggregatorRef(null))
                clearFn()
            }
        })
        .catch((err)=>{
            let message=err.response.status===500?"Internal Server Error !!":err.response.status===400?"Delivery partner not selected!!":err.response.data
                errorSnackMsg(message)
                store.dispatch(setBillLoading(false))  
        })
}
// update an existing order

export const updatePosOrderAPICall = async (body, clearFn, orderType, printReceipt) => {
    try {
        store.dispatch(setBillLoading(true));

        // Retrieve and parse printer settings
        const printSettingsString = localStorage.getItem('PrinterSettings');
        const printSettings = JSON.parse(printSettingsString);

        // Make the API request to update the order
        const response = await axios.post('pos/addItemsToExistingOrders', body, HEADERS);

        const { status, data } = response;
        if (status === 200) {
            const orderId = data?.data?._id;
            const kotData = data?.kotData;

            // Resetting order and UI states
            store.dispatch(setOrderIdInPOS(null));
            store.dispatch(setSnackMsg('Order Updated 👍'));
            store.dispatch(setSuccessSnack(true));
            store.dispatch(setBillLoading(false));

            // Handling different order types
            if (orderType === 'paymentOrder') {
                store.dispatch(setBtnSelected('payment'));
                // Ensure the new orderId is set after updating
                setTimeout(() => {
                    store.dispatch(setOrderIdInPOS(orderId));
                }, 0);
            } else {
                // Handle KOT (Kitchen Order Tickets) and dialog state
                if (!kotData) {
                    store.dispatch(setDialogBox(null));
                } else {
                    store.dispatch(setDialogBox(null));
                    if (printSettings?.kotAuto) {
                        printReceipt(kotData); // Automatically print if setting is enabled
                    } else {
                        store.dispatch(setKOTManualDialog('kotPrint')); // Open manual print dialog
                    }
                }
                store.dispatch(setKOTSingleInfo(kotData || null));
            }

            // Reset aggregator and authentication-related states
            store.dispatch(setAggregator(null));
            store.dispatch(setAggregatorRef(null));
            store.dispatch(setAuthPassword(''));
            store.dispatch(setDialogBox(null));

            // Clear any temporary form data and handle delivery-specific receipts
            clearFn();
            if (body.ordType === 'DELIVERY') {
                deliveryReceiptInfo({ orderId });
            }
        }
    } catch (error) {
        // Handle API errors gracefully
        const message =
            error?.response?.status === 500
                ? 'Internal Server Error !!'
                : error?.response?.data || 'Error occurred during order update';

        // Set error state
        store.dispatch(setSnackMsg(message));
        store.dispatch(setBillLoading(false));
        store.dispatch(setErrorSnack(true));
    }
};

// get single order info

export const singlePOSOrderInfoAPICall=async(body)=>{
    store.dispatch(setCartLoading(true))
    store.dispatch(setBillLoading(true))
    await axios.post("pos/viewPosOrderById",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
                store.dispatch(setBillLoading(false))
                store.dispatch(setSingleOrderInfo(res.data))
                store.dispatch(setCartLoading(false))
            }
        })
        .catch((err)=>{
            store.dispatch(setBillLoading(false))
            store.dispatch(setSingleOrderInfo(null))
                store.dispatch(setCartLoading(false))
        })
}


// hold order
export const holdPOSOrderAPI=async(body,clearFn,orderType)=>{
    store.dispatch(setBillLoading(true))
    await axios.post("pos/addPosOrderHold",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(setBillLoading(false))                
                store.dispatch(setDialogBox(false))                
                store.dispatch(setSnackMsg("Order Holded"))
                store.dispatch(setSuccessSnack(true))
                clearFn()
                store.dispatch(setAggregator(null))
                store.dispatch(setAggregatorRef(null))
            }
        })
        .catch((err)=>{
            let message=err.response.status===500?"Internal Server Error !!":err.response.status===400?"Wrong data passed!!":err.response.data
                store.dispatch(setSnackMsg(message))
                store.dispatch(setBillLoading(false))                
                store.dispatch(setErrorSnack(true))
        })
}

// get customer single info

export const getSingleCustomerInfoAPICall=async(body)=>{
    await axios.post("customer/getSingleVendorDetails",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(setSingleCusInfo(res.data))
            }
            store.dispatch(setSingleCusInfo(res.data))
        })
        .catch((err)=>{
            store.dispatch(setSingleCusInfo(null))
        })
}