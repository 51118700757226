import { setErrorSnack, setSnackMsg, setSuccessSnack } from '../../Redux/Dashboard/mainDashBoardSlice';
import store from '../../Redux/store';

const dispatch = store.dispatch;



export const successSnackMsg = (message) => {
    dispatch(setSnackMsg(message));
    dispatch(setSuccessSnack(true));
};
export const errorSnackMsg = (message) => {
    dispatch(setSnackMsg(typeof(message)==="object"?"Something went wrong":message));
    dispatch(setErrorSnack(true));
};
