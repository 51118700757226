import React from 'react'
import { useSelector } from 'react-redux'

export const WOAccessoresDetails = () => {
  const singleList = useSelector((state)=>state?.workOrderNewList?.workorderAccessoriesData)
  console.log(singleList);
  const hasData = singleList?.list && singleList.list.length > 0;
  
  return (
    <div className="new-global-table-container work-order-tab-table" style={{maxHeight:"48vh",border:"1px solid #000" }}>
       <table>
     <thead>
         <tr>
             <th>Product</th>
             <th>Type</th>
             <th>Model</th>
             <th>Customer</th>
             <th>Product</th>
             <th>Qty</th>
             <th>UOM</th>
          </tr>
     </thead>
     <tbody>
     {
        hasData? singleList?.list?.map((r,i)=>(
           <tr >
             <td>{r?.productName}</td>
             <td>{r?.woType}</td>
             <td>{r?.modelType}</td>
             <td>{r?.customer}</td>
             <td>{r?.customer}</td>
             <td>{r?.customer}</td>
             <td>{r?.customer}</td>
         </tr>
         )):(
          <tr>
            <td colSpan={7}>NO DATA</td>
          </tr>
         )
       }
    

     </tbody>
    
       
        
      </table>
    </div>
  )
}
