import { createSlice } from "@reduxjs/toolkit";

export const posNewSlice=createSlice({
    name:"posNewSlice",
    initialState:{
        posScreenView:false,
        openDialog:false,
        isModify:false,
        posEditBtn:false,
        posPayBtn:false,
        posViewBtn:false,
        posPrintBtn:false,
        posjobBtn:false,
        posdeliveryBtn:false,
        posreturnBtn:false
    },
    reducers:{
        switch_to_pos_screen:(state,action)=>{
            state.posScreenView=action.payload.posScreenState
        },
        open_dialog:(state,action)=>{
            state.openDialog=action.payload.openDialogState
        },
        setModify:(state)=>{
           state.isModify=!state.isModify
        },
        pos_edit_btn:(state,action)=>{
            state.posEditBtn=action.payload
        },
        pos_pay_btn:(state,action)=>{
            state.posPayBtn=action.payload
        },
        pos_view_btn:(state,action)=>{
            state.posViewBtn=action.payload
        },
        pos_print_btn:(state,action)=>{
            state.posPrintBtn=action.payload
        },
        pos_job_btn:(state,action)=>{
            state.posjobBtn=action.payload
        },
        pos_delivery_btn:(state,action)=>{
            state.posdeliveryBtn=action.payload
        },
        pos_return_btn:(state,action)=>{
            state.posreturnBtn=action.payload
        }
      
    }
})
export const {
    switch_to_pos_screen,
    open_dialog,
    setModify,
    pos_edit_btn,
    pos_pay_btn,
    pos_view_btn,
    pos_print_btn,
    pos_job_btn,
    pos_delivery_btn,
    pos_return_btn
}=posNewSlice.actions;
export default posNewSlice.reducer