import { Autocomplete, Dialog, IconButton, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AutocompleteField, TextFieldComponent } from "./CustomFields";
import { CreateModelDialog } from "./CreateModelDialog";
import { open_dialog } from "../../PosNewSlice";
import { useDispatch, useSelector } from "react-redux";
import { CreateTypeDialog } from "./CreateTypeDialog";
import {
  addButtonAPICall,
  addCollarAPICall,
  addCollarClothAPICall,
  addCollarModelAPICall,
  addCuffAPICall,
  addCuffClothAPICall,
  addCuffModelAPICall,
  addFpModelAPICall,
  addMarModelAPICall,
  addOrderItemTypeAPI,
  addOrderModelAPI,
  addPenAPICall,
  addSidePtModelAPICall,
  addStitchingAPICall,
  deleteButtonAPICall,
  deleteCollarAPICall,
  deleteCollarClothAPICall,
  deleteCollarModelAPICall,
  deleteCuffAPICall,
  deleteCuffClothAPICall,
  deleteCuffModelAPICall,
  deleteFpModelAPICall,
  deleteMarModelAPICall,
  deleteOrderModelAPI,
  deletePenAPICall,
  deleteSidePtModelAPICall,
  deleteStitchingAPICall,
  viewButtonAPICall,
  viewCollarAPICall,
  viewCollarClothAPICall,
  viewCollarModelAPICall,
  viewCuffAPICall,
  viewCuffClothAPICall,
  viewCuffModelAPICall,
  viewFpModelAPICall,
  viewMarModelAPICall,
  viewOrderItemTypeAPI,
  viewOrderModelAPI,
  viewPenAPICall,
  viewSidePtModelAPICall,
  viewStitchingAPICall,
} from "../MeasurmentsAPI";
import { filterObjFromList } from "../../../../../../Js/generalFunctions";

export const ThobForm = ({ onFormDataChange, selectedType, editData }) => {
  console.log(editData);
  const dispatch = useDispatch();
  const commonStyle = { width: "30%" };

  const { openDialog, isModify } = useSelector((state) => state.posNewSlice);
  const {
    orderTypes,
    itemType,
    model,
    productColor,
    marModel,
    cuff,
    cuffCloth,
    cuffModel,
    collar,
    collarCloth,
    collarModel,
    fpModel,
    pen,
    sidePtModel,
    stitching,
    button,
  } = useSelector((state) => state.measurementsslice);
  const mobilePocketData = [{ type: "YES" }, { type: "NO" }];
  const formInitialState = {
    modelId: null,
    itemType: null,
    length: "",
    shoulder: "",
    sleeve: "",
    chest: "",
    stomach: "",
    slChest: "",
    slSo: "",
    neck: "",
    bottom: "",
    marSize: "",
    marModel: null,
    cuff: null,
    cuffSize1: "",
    cuffSize_2: "",
    cuffCloth: null,
    cuffModel: null,
    neckDownButton: "",
    sidePtSize: "",
    collar: null,
    collarSize: "",
    collarCloth: null,
    collarModel: null,
    regalSize: "",
    kneeLoose: "",
    fpDown: "",
    fpModel: null,
    fpSize1: "",
    fpSize2: "",
    pen: null,
    sidePtModel: null,
    stitching: null,
    button: null,
    ButtonNo: "",
    mobilePocket: null,
    remark: "",
  };
  const [formValues, setFormValues] = useState(formInitialState);
  const items = [
    { label: "Length", value: formValues.length, field: "length" },
    { label: "Shoulder", value: formValues.shoulder, field: "shoulder" },
    { label: "Sleeve", value: formValues.sleeve, field: "sleeve" },
    { label: "Chest", value: formValues.chest, field: "chest" },
    { label: "Stomach", value: formValues.stomach, field: "stomach" },
    { label: "S.L Chest", value: formValues.slChest, field: "slChest" },
    { label: "S.L So", value: formValues.slSo, field: "slSo" },
    { label: "Neck", value: formValues.neck, field: "neck" },
    { label: "Bottom", value: formValues.bottom, field: "bottom" },
    { label: "Mar Size", value: formValues.marSize, field: "marSize" },
  ];
  const [inputValue, setInputValue] = useState();
  const [modelInputValues, setModelInputValues] = useState({});
  const handleFormChange = (key, value) => {
    console.log(key, value);
    const updatedData = { ...formValues, [key]: value };
    setFormValues(updatedData);
    onFormDataChange(updatedData);
  };
  // console.log(formValues);
  const handleAdd = (label) => {
    dispatch(open_dialog({ openDialogState: label }));
  };
  const closeDialog = () => {
    dispatch(open_dialog({ openDialogState: false }));
  };

  const handleGetFormData = (data) => {
    setInputValue(data);
  };
  const handleGetModelFormData = (data) => {
    setModelInputValues(data);
  };

  useEffect(() => {
    if (editData !== undefined) {
      setFormValues({
        ...formValues,
        modelId: filterObjFromList("_id", model, "modelId", editData),
        itemType: filterObjFromList("_id", itemType, "itemType", editData),
        length: editData?.length,
        shoulder: editData?.shoulder,
        sleeve: editData?.sleeve,
        chest: editData?.chest,
        stomach: editData?.stomach,
        slChest: editData?.slChest,
        slSo: editData?.slSo,
        neck: editData?.neck,
        bottom: editData?.bottom,
        marSize: editData?.marSize,
        marModel: filterObjFromList("_id",marModel, "marModel", editData),
        cuff: filterObjFromList("_id",cuff, "cuff", editData),
        cuffSize1: editData?.cuffSize1,
        cuffSize_2: editData?.cuffSize2,
        cuffCloth: filterObjFromList("_id", cuffCloth, "cuffCloth", editData),
        cuffModel: filterObjFromList("_id", cuffModel, "cuffModel", editData),
        neckDownButton: editData?.neckDownButton,
        sidePtSize: editData?.sidePtSize,
        collar: filterObjFromList("_id", collar, "collar", editData),
        collarSize: editData?.collarSize,
        collarCloth: filterObjFromList(
          "_id",
          collarCloth,
          "collarCloth",
          editData
        ),
        collarModel: filterObjFromList(
          "_id",
          collarModel,
          "collarModel",
          editData
        ),
        regalSize: editData?.regalSize,
        kneeLoose: editData?.kneeLoose,
        fpDown: editData?.fpDown,
        fpModel: filterObjFromList("_id", fpModel, "fpModel", editData),
        fpSize1: editData?.fpSize1,
        fpSize2: editData?.fpSize2,
        pen: filterObjFromList("_id", pen, "pen", editData),
        sidePtModel: filterObjFromList(
          "_id",
          sidePtModel,
          "sidePtModel",
          editData
        ),
        stitching: filterObjFromList("_id", stitching, "stitching", editData),
        button: filterObjFromList("_id", button, "button", editData),
        ButtonNo: editData?.ButtonNo,
        mobilePocket: filterObjFromList("type",mobilePocketData,"mobilePocket",editData
        ),
        remark: editData?.remark,
      });
    }
  }, [
    editData,
    model,
    itemType,
    marModel,
    cuff,
    cuffCloth,
    cuffModel,
    collar,
    collarCloth,
    collarModel,
    fpModel,
    pen,
    sidePtModel,
    stitching,
    button,
  ]);

  const handleAddModel = () => {
    if (openDialog === "Model") {
      addOrderModelAPI(
        {
          modelName: modelInputValues?.modelName,
          fromLength: modelInputValues?.fromLength,
          toLength: modelInputValues?.toLength,
          minRate: modelInputValues?.minRate,
          commission: modelInputValues?.commission,
          workorderTypeId: selectedType,
        },
        setModelInputValues
      );
    }
  };
  const handleDeleteModel = (id, type) => {
    if (type === "Model") {
      deleteOrderModelAPI({ thobId: id });
    }
  };

  const handleAddData = (data) => {
    if (openDialog === "Type") {
      addOrderItemTypeAPI(
        { typeName: inputValue, workorderTypeId: selectedType },
        setInputValue
      );
    } else if (openDialog === "Mar Model") {
      addMarModelAPICall({ marName: inputValue }, setInputValue);
    } else if (openDialog === "Cuff") {
      addCuffAPICall({ cuff: inputValue }, setInputValue);
    } else if (openDialog === "Cuff Cloth") {
      addCuffClothAPICall({ cuffCloth: inputValue }, setInputValue);
    } else if (openDialog === "Cuff Model") {
      addCuffModelAPICall({ cuffModel: inputValue }, setInputValue);
    } else if (openDialog === "Collar") {
      addCollarAPICall({ collar: inputValue }, setInputValue);
    } else if (openDialog === "Collar Cloth") {
      addCollarClothAPICall({ collarCloth: inputValue }, setInputValue);
    } else if (openDialog === "Collar Model") {
      addCollarModelAPICall({ collarModel: inputValue }, setInputValue);
    } else if (openDialog === "FP Model") {
      addFpModelAPICall({ fpModel: inputValue }, setInputValue);
    } else if (openDialog === "Pen") {
      addPenAPICall({ pen: inputValue }, setInputValue);
    } else if (openDialog === "Side PT Model") {
      addSidePtModelAPICall({ sidePT: inputValue }, setInputValue);
    } else if (openDialog === "Stitching") {
      addStitchingAPICall({ stitching: inputValue }, setInputValue);
    } else if (openDialog === "Button") {
      addButtonAPICall({ button: inputValue }, setInputValue);
    }
  };
  const handleDelete = (id, type) => {
    if (type === "Type") {
    } else if (type === "Mar Model") {
      deleteMarModelAPICall({ marId: id });
    } else if (type === "Cuff") {
      deleteCuffAPICall({ cuffId: id });
    } else if (type === "Cuff Cloth") {
      deleteCuffClothAPICall({ cuffClothId: id });
    } else if (type === "Cuff Model") {
      deleteCuffModelAPICall({ cuffModelId: id });
    } else if (type === "Collar") {
      deleteCollarAPICall({ collarId: id });
    } else if (type === "Collar Cloth") {
      deleteCollarClothAPICall({ collarClothId: id });
    } else if (type === "Collar Model") {
      deleteCollarModelAPICall({ collarModelId: id });
    } else if (type === "FP Model") {
      deleteFpModelAPICall({ fpModelId: id });
    } else if (type === "Pen") {
      deletePenAPICall({ penId: id });
    } else if (type === "Side PT Model") {
      deleteSidePtModelAPICall({ sidePTId: id });
    } else if (type === "Stitching") {
      deleteStitchingAPICall({ stitchingId: id });
    } else if (type === "Button") {
      deleteButtonAPICall({ buttonId: id });
    }
  };

  const renderRows = (data, type) =>
    data
      ?.slice(0)
      ?.reverse()
      ?.map((r, i) => (
        <tr key={i}>
          <td>
            {r?.typeName ||
              r?.marName ||
              r?.cuff ||
              r?.cuffCloth ||
              r?.cuffModel ||
              r?.collar ||
              r?.collarCloth ||
              r?.collarModel ||
              r?.fpModel ||
              r?.pen ||
              r?.sidePT ||
              r?.stitching ||
              r?.button}
          </td>
          <td onClick={() => handleDelete(r?._id, type)}>
            <IconButton>
              <i class="bi bi-trash3 delete-icon"></i>
            </IconButton>
          </td>
        </tr>
      )) || (
      <tr>
        <td colSpan={3}>No data</td>
      </tr>
    );

  useEffect(() => {
    viewOrderItemTypeAPI({ workorderTypeId: selectedType });
    viewOrderModelAPI({ workorderTypeId: selectedType });
  }, [selectedType, isModify]);
  useEffect(() => {
    viewMarModelAPICall();
    viewCuffAPICall();
    viewCuffClothAPICall();
    viewCuffModelAPICall();
    viewCollarAPICall();
    viewCollarClothAPICall();
    viewCollarModelAPICall();
    viewFpModelAPICall();
    viewPenAPICall();
    viewSidePtModelAPICall();
    viewStitchingAPICall();
    viewButtonAPICall();
  }, []);

  return (
    <div className="thob-form-container">
      <div className="thob-model-left-container">
        <AutocompleteField
          label="Model"
          options={model}
          getOptionLabel={(option) => option.modelName}
          onAdd={handleAdd}
          fieldKey="modelId"
          value={formValues?.modelId}
          onChange={handleFormChange}
        />
        <AutocompleteField
          label="Type"
          options={itemType}
          getOptionLabel={(option) => option.typeName}
          onAdd={handleAdd}
          fieldKey="itemType"
          value={formValues?.itemType}
          onChange={handleFormChange}
        />

        {items.map((r, i) => (
          <TextFieldComponent
            key={i}
            label={r?.label}
            fieldKey={r?.field}
            value={r?.value}
            onChange={handleFormChange}
          />
        ))}
        <AutocompleteField
          label="Mar Model"
          options={marModel}
          getOptionLabel={(option) => option.marName}
          onAdd={handleAdd}
          fieldKey="marModel"
          value={formValues?.marModel}
          onChange={handleFormChange}
        />
        <AutocompleteField
          label="Cuff"
          options={cuff}
          getOptionLabel={(option) => option.cuff}
          onAdd={handleAdd}
          fieldKey="cuff"
          value={formValues?.cuff}
          onChange={handleFormChange}
        />
        <div style={{ display: "flex", width: "31%" }}>
          <TextFieldComponent
            label="Cuff Size"
            style={{ width: "64%" }}
            fieldKey="cuffSize1"
            value={formValues?.cuffSize1}
            onChange={handleFormChange}
          />
          <TextFieldComponent
            label=""
            style={{ width: "36%", margin: "12px 0px 12px 8px" }}
            fieldKey="cuffSize_2"
            value={formValues?.cuffSize_2}
            onChange={handleFormChange}
          />
        </div>
        <AutocompleteField
          label="Cuff Cloth"
          options={cuffCloth}
          getOptionLabel={(option) => option.cuffCloth}
          onAdd={handleAdd}
          fieldKey="cuffCloth"
          value={formValues?.cuffCloth}
          onChange={handleFormChange}
        />
        <AutocompleteField
          label="Cuff Model"
          options={cuffModel}
          getOptionLabel={(option) => option.cuffModel}
          onAdd={handleAdd}
          fieldKey="cuffModel"
          value={formValues?.cuffModel}
          onChange={handleFormChange}
        />
        <div style={{ display: "flex", width: "31%" }}>
          <TextFieldComponent
            label="Neck D Button"
            style={{ width: "49%", margin: "12px 2px 12px 8px" }}
            fieldKey="neckDownButton"
            value={formValues?.neckDownButton}
            onChange={handleFormChange}
          />
          <TextFieldComponent
            label="Side PT Size"
            style={{ width: "49%", margin: "12px 0px 12px 8px" }}
            fieldKey="sidePtSize"
            value={formValues?.sidePtSize}
            onChange={handleFormChange}
          />
        </div>
      </div>
      <div className="border-right"></div>
      <div className="thob-model-right-container">
        <AutocompleteField
          label="Collar"
          options={collar}
          getOptionLabel={(option) => option.collar}
          onAdd={handleAdd}
          fieldKey="collar"
          value={formValues?.collar}
          onChange={handleFormChange}
        />
        <TextFieldComponent
          label="Collar Size"
          fieldKey="collarSize"
          value={formValues?.collarSize}
          onChange={handleFormChange}
        />
        <AutocompleteField
          label="Collar Cloth"
          options={collarCloth}
          getOptionLabel={(option) => option.collarCloth}
          onAdd={handleAdd}
          fieldKey="collarCloth"
          value={formValues?.collarCloth}
          onChange={handleFormChange}
        />
        <AutocompleteField
          label="Collar Model"
          options={collarModel}
          getOptionLabel={(option) => option.collarModel}
          onAdd={handleAdd}
          fieldKey="collarModel"
          value={formValues?.collarModel}
          onChange={handleFormChange}
        />
        <TextFieldComponent
          label="Regal Size"
          fieldKey="regalSize"
          value={formValues?.regalSize}
          onChange={handleFormChange}
        />
        <TextFieldComponent
          label="Knee Loose"
          fieldKey="kneeLoose"
          value={formValues?.kneeLoose}
          onChange={handleFormChange}
        />
        <TextFieldComponent
          label="FP Down"
          fieldKey="fpDown"
          value={formValues?.fpDown}
          onChange={handleFormChange}
        />
        <AutocompleteField
          label="FP Model"
          options={fpModel}
          getOptionLabel={(option) => option.fpModel}
          onAdd={handleAdd}
          fieldKey="fpModel"
          value={formValues?.fpModel}
          onChange={handleFormChange}
        />
        <div style={{ display: "flex", width: "31%" }}>
          <TextFieldComponent
            label="FP Size"
            style={{ width: "64%" }}
            fieldKey="fpSize1"
            value={formValues?.fpSize1}
            onChange={handleFormChange}
          />
          <TextFieldComponent
            label=""
            style={{ width: "36%", margin: "12px 0px 12px 8px" }}
            fieldKey="fpSize2"
            value={formValues?.fpSize2}
            onChange={handleFormChange}
          />
        </div>
        <AutocompleteField
          label="Pen"
          options={pen}
          getOptionLabel={(option) => option.pen}
          onAdd={handleAdd}
          fieldKey="pen"
          value={formValues?.pen}
          onChange={handleFormChange}
        />
        <AutocompleteField
          label="Side PT Model"
          options={sidePtModel}
          getOptionLabel={(option) => option.sidePT}
          onAdd={handleAdd}
          fieldKey="sidePtModel"
          value={formValues?.sidePtModel}
          onChange={handleFormChange}
        />
        <AutocompleteField
          label="Stitching"
          options={stitching}
          getOptionLabel={(option) => option.stitching}
          onAdd={handleAdd}
          fieldKey="stitching"
          value={formValues?.stitching}
          onChange={handleFormChange}
        />
        <AutocompleteField
          label="Button"
          options={button}
          getOptionLabel={(option) => option.button}
          onAdd={handleAdd}
          fieldKey="button"
          value={formValues?.button}
          onChange={handleFormChange}
        />
        <TextFieldComponent
          label="Button No"
          fieldKey="ButtonNo"
          value={formValues?.ButtonNo}
          onChange={handleFormChange}
        />
        <AutocompleteField
          label="Mobile Pocket"
          options={mobilePocketData}
          getOptionLabel={(option) => option.type}
          fieldKey="mobilePocket"
          value={formValues?.mobilePocket}
          onChange={handleFormChange}
        />
        <TextFieldComponent
          label="Notes"
          fieldKey="remark"
          value={formValues?.remark}
          onChange={handleFormChange}
        />
      </div>

      {/* create thob model pop up */}
      <Dialog open={openDialog === "Model"} maxWidth="lg">
        <CreateModelDialog
          closeDialog={closeDialog}
          addBtnFn={handleAddModel}
          modelFormData={handleGetModelFormData}
          clearFormData={modelInputValues}
        >
          {model
            ?.slice(0)
            ?.reverse()
            ?.map((r, i) => (
              <tr key={i}>
                <td>{r?.modelName}</td>
                <td>{`${r?.fromLength}-${r?.toLength}`}</td>
                <td>{r?.minRate}</td>
                <td>{r?.commission}</td>
                <td>
                  <IconButton
                    onClick={() => handleDeleteModel(r?._id, "Model")}
                  >
                    <i class="bi bi-trash3 delete-icon"></i>
                  </IconButton>
                </td>
              </tr>
            )) || (
            <tr>
              <td colSpan={5}>No Data</td>
            </tr>
          )}
        </CreateModelDialog>
      </Dialog>

      {/* create type pop up */}
      <CreateTypeDialog
        open={openDialog === "Type"}
        label="Add Type"
        closeDialog={closeDialog}
        tablelabel="Type"
        addBtnFn={handleAddData}
        formData={handleGetFormData}
        clearFormData={inputValue}
      >
        {renderRows(itemType, "Type")}
      </CreateTypeDialog>

      {/* create mar model */}
      <CreateTypeDialog
        open={openDialog === "Mar Model"}
        label="Add Mar Model"
        closeDialog={closeDialog}
        tablelabel="Mar Model"
        addBtnFn={handleAddData}
        formData={handleGetFormData}
        clearFormData={inputValue}
      >
        {renderRows(marModel, "Mar Model")}
      </CreateTypeDialog>

      {/* create cuff */}
      <CreateTypeDialog
        open={openDialog === "Cuff"}
        label="Add Cuff"
        closeDialog={closeDialog}
        tablelabel="Cuff"
        addBtnFn={handleAddData}
        formData={handleGetFormData}
        clearFormData={inputValue}
      >
        {renderRows(cuff, "Cuff")}
      </CreateTypeDialog>

      {/* create cuff model */}
      <CreateTypeDialog
        open={openDialog === "Cuff Cloth"}
        label="Add Cuff Cloth"
        closeDialog={closeDialog}
        tablelabel="Cuff Cloth"
        addBtnFn={handleAddData}
        formData={handleGetFormData}
        clearFormData={inputValue}
      >
        {renderRows(cuffCloth, "Cuff Cloth")}
      </CreateTypeDialog>

      {/* create cuff model */}
      <CreateTypeDialog
        open={openDialog === "Cuff Model"}
        label="Add Cuff Model"
        closeDialog={closeDialog}
        tablelabel="Cuff Model"
        addBtnFn={handleAddData}
        formData={handleGetFormData}
        clearFormData={inputValue}
      >
        {renderRows(cuffModel, "Cuff Model")}
      </CreateTypeDialog>
      {/* create collar */}
      <CreateTypeDialog
        open={openDialog === "Collar"}
        label="Add Collar"
        closeDialog={closeDialog}
        tablelabel="Collar Name"
        addBtnFn={handleAddData}
        formData={handleGetFormData}
        clearFormData={inputValue}
      >
        {renderRows(collar, "Collar")}
      </CreateTypeDialog>

      {/* create collar cloth*/}
      <CreateTypeDialog
        open={openDialog === "Collar Cloth"}
        label="Add Collar Cloth"
        closeDialog={closeDialog}
        tablelabel="Collar Cloth"
        addBtnFn={handleAddData}
        formData={handleGetFormData}
        clearFormData={inputValue}
      >
        {renderRows(collarCloth, "Collar Cloth")}
      </CreateTypeDialog>
      {/* create collar model*/}
      <CreateTypeDialog
        open={openDialog === "Collar Model"}
        label="Add Collar Model"
        closeDialog={closeDialog}
        tablelabel="Collar Model"
        addBtnFn={handleAddData}
        formData={handleGetFormData}
        clearFormData={inputValue}
      >
        {renderRows(collarModel, "Collar Model")}
      </CreateTypeDialog>
      {/* create fp model*/}
      <CreateTypeDialog
        open={openDialog === "FP Model"}
        label="Add FP Model"
        closeDialog={closeDialog}
        tablelabel="FP Model"
        addBtnFn={handleAddData}
        formData={handleGetFormData}
        clearFormData={inputValue}
      >
        {renderRows(fpModel, "FP Model")}
      </CreateTypeDialog>

      {/* create pen*/}
      <CreateTypeDialog
        open={openDialog === "Pen"}
        label="Add Pen"
        closeDialog={closeDialog}
        tablelabel="Pen"
        addBtnFn={handleAddData}
        formData={handleGetFormData}
        clearFormData={inputValue}
      >
        {renderRows(pen, "Pen")}
      </CreateTypeDialog>

      {/* create side pt model*/}
      <CreateTypeDialog
        open={openDialog === "Side PT Model"}
        label="Add Side PT Model"
        closeDialog={closeDialog}
        tablelabel="Side PT Model"
        addBtnFn={handleAddData}
        formData={handleGetFormData}
        clearFormData={inputValue}
      >
        {renderRows(sidePtModel, "Side PT Model")}
      </CreateTypeDialog>

      {/* create stitching*/}
      <CreateTypeDialog
        open={openDialog === "Stitching"}
        label="Add Stitching"
        closeDialog={closeDialog}
        tablelabel="Stitching"
        addBtnFn={handleAddData}
        formData={handleGetFormData}
        clearFormData={inputValue}
      >
        {renderRows(stitching, "Stitching")}
      </CreateTypeDialog>

      {/* create button*/}
      <CreateTypeDialog
        open={openDialog === "Button"}
        label="Add Button"
        closeDialog={closeDialog}
        tablelabel="Button"
        addBtnFn={handleAddData}
        formData={handleGetFormData}
        clearFormData={inputValue}
      >
        {renderRows(button, "Button")}
      </CreateTypeDialog>
    </div>
  );
};
