import { IconButton, Tooltip } from "@mui/material";
//import  "../../../../css/Purchase Manager/StockAdjustment/requestForQuatationNw.css"
import React, { useRef } from "react";
//import postRibbon from "../../../../../Assets/statusRibbon/post.png";
//import Draft from "../../../../../Assets/statusRibbon/draft.png"
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import {
  companyProfileInfoForUser,
  viewCompanyProfileInfoAPICall,
} from "../../../../API/Settings/Company Settings/companySettingsAPI";
import PO from "../../../../Assets/statusRibbon/purchaseorder.png";
import Cancel from "../../../../Assets/statusRibbon/cancel.png";
import Done from "../../../../Assets/statusRibbon/done.png";
import { convertDateFormat, today } from "../../../../Js/Date";
import ReactToPrint from "react-to-print";
import { viewPurchaseOrderSingleView } from "../../../../API/Purchase Manager/NewPurchaseOrder/PurchaseOrder";
import { set_Back_button_action } from "../../../Single Components/listSlice/filterCategorySlice";
import Template1 from "../../SalesNew/SalesOrders/SalesQuotation/Template1";
import Template2 from "../../SalesNew/SalesOrders/SalesQuotation/Template2";
import { getTemplateAPI } from "../../SalesNew/SalesOrders/SalesQuotation/salesQuatationAPI";
import { InvoiceContent } from "../../Accounts/PrintComponents/printTemplates/common/InvoiceContent";
import { printSection } from "../../Accounts/PrintComponents/printTemplates/common/enum";import * as XLSX from "xlsx";


export const PurchaseOrderSingleView = () => {
  let navigateTo = useNavigate();
  let paymentsPrint = useRef();
  let paymentsPrintNew = useRef();
  const componentRef = useRef();

  const dispatch = useDispatch();
  const decimalPosition = localStorage.getItem("decimalPosition");
  //data of selected List
  // const singleList =useSelector((state)=>state?.Rfq?.purchaseOrderSingleView)
  //  console.log(singleList);
  const singleViewID = localStorage.getItem("singleViewById");

  const singleList = useSelector(
    (state) => state?.RfqSlice?.purchaseOrderSingleView
  );
  console.log(singleList);
  // company details and info
  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );

  const template =useSelector((state)=>state?.salesQuatationSlice?.setTemplate)

  const formValuesInitialState = {
    status: "",
    companyLogo: "",
    companyName: "",
    companyAddress: "",
    companyContact: "",
    companyTRN: "",

    customerName: "",
    customerAddress: "",
    customerContact: "",

    dateN1:"Quotation Date",
    dateN2:"Expiry Date",

    lpo:"",
    ref:"",
    name: "",
    firstDate: "",
    secondDate: "",

    termsAndCondition:"",

     address:"Vendor ",
    sequence:"PO No",
    subtotal: 0,
    inclusiveTax: 0,
    exclusiveTax: 0,
    total: 0,
    totalAmount:"",
    payTerm:"",
    label:"PURCHASE ORDER"
  };
  const [formValues, setFormValues] = useState(formValuesInitialState);
  const [data, setData] = useState([]);
  const [tax, setTax] = useState([]);
  const [domain, setDomain] = useState("");

//  console.log(formValues);
  // api to fetch companyprofile data
  useEffect(() => {
    companyProfileInfoForUser();
    getTemplateAPI();
    viewPurchaseOrderSingleView({ _id: singleViewID });
  }, []);

  const downloadList = () => {
    const worksheet = XLSX.utils.table_to_sheet(document.getElementById("table_data"));
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Purchase Order.xlsx");
  }


  useEffect(() => {
    setFormValues({
      ...formValues,
      status: singleList?.type,
      companyLogo: companyProfileData?.logo || "",
      companyName: companyProfileData?.companyName || "",
      companyAddress: companyProfileData?.companyAddress || "",
      companyContact: companyProfileData?.contactNumber || "",
      companyTRN: companyProfileData?.companyTRN || "",

      ref: singleList?.reference ||"",
      lpo: singleList?.LpoNo ||"",

      customerName: singleList?.vendorAddress?.vendorName  || "",
      customerAddress: singleList?.vendorAddress?.streetName || "",
      customerContact: singleList?.vendorAddress ?.mobileNo || "",
      trn: singleList?.vendorAddress ?.TRN|| "",
      name: singleList?.sequence || "",
      firstDate: singleList?.quotationDate || "",
      secondDate: singleList?.expiryDate || "",
      payTerm:singleList?.PAYMENTTERM || "",
      
      termsAndCondition:singleList?.termsAndConditions || "",
      
      subtotal: singleList?.subTotal || 0,
      inclusiveTax: singleList?.totalTaxInclusive || 0,
      exclusiveTax: singleList?.totalTaxExclusive || 0,
      total: singleList?.total || 0,
      totalAmount: singleList?.totalAmount || "",

    });
    setData(singleList?.productInfo);
    setTax(singleList?.taxDetails);
  }, [singleList, companyProfileData]);

  return (
    <div className="main-container">
      <div className="first-div-container">
        <div className="post-ribbon">
          {formValues?.status === "PURCHASE ORDER" && <img src={PO} alt="" />}
          {formValues?.status === "DONE" && <img src={Done} alt="" />}
          {formValues?.status === "CANCEL" && <img src={Cancel} alt="" />}
        </div>
        <Tooltip title="Back">
          <IconButton
            onClick={() =>
            {navigateTo("/userdashboard/purchaseManager/NewPurchaseOrder")
            dispatch(set_Back_button_action({ backButtonClickAction: true }));
          }
            }
          >
            <i class="bi bi-chevron-left"></i>
          </IconButton>
        </Tooltip>
        <Tooltip title="Edit">
          <IconButton>
            <i
              class="bi bi-pencil-square edit-icon1"
              onClick={() =>
                navigateTo(
                  "/userdashboard/purchaseManager/CreateNewPurchaseOrder"
                )
              }
            ></i>
          </IconButton>
        </Tooltip>
        <ReactToPrint
          trigger={() => (
            <IconButton className="printer-div">
              <i style={{ color: "black" }} class="bi bi-printer"></i>
            </IconButton>
          )}
          content={() => paymentsPrint}
        />
        <Tooltip title="Share">
          <IconButton>
            <i class="bi bi-share" style={{ backgroundColor:"#9797e9", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
          </IconButton>
        </Tooltip>

        {/* <ReactToPrint
          trigger={() => (
            <IconButton className="printer-div" style={{ padding: "1px" }}>
              <i style={{ color: "white", backgroundColor: " #d787e7", fontSize: "medium", padding: "8px", borderRadius: "2px" }} class="bi bi-printer"></i>
            </IconButton>
          )}
          content={() => paymentsPrintNew}
        /> */}
        <ReactToPrint
            // The print button
            trigger={() => (
              <IconButton className="printer-div" style={{ padding: "1px" }}>
                <i
                  style={{
                    color: "white",
                    backgroundColor: " #d787e7",
                    fontSize: "medium",
                    padding: "8px",
                    borderRadius: "2px",
                  }}
                  class="bi bi-printer"
                ></i>
              </IconButton>
            )}
            // The content to be printed (InvoiceContent component)
            content={() => componentRef.current}
            // Set the page style for printing
            pageStyle="@page { size: A4; margin: 0; }"
          />

        <Tooltip title="Download">
          <IconButton onClick={downloadList}>
            <i class="bi bi-arrow-down-circle" style={{ backgroundColor: "rgb(255 118 133 / 69%)", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
          </IconButton>
        </Tooltip>
      </div>
      <hr className="line" />
      <div className="second-main-container">
        <div className="left-side-div">
          <img src={formValues?.companyLogo} alt=""/>
          <p>{formValues?.companyName}</p>
          <p>{formValues?.companyAddress}</p>
          <p>{formValues?.companyContact}</p>

          <h1 className="vendor-head">Vendor Address</h1>
          <p>{formValues?.customerName}</p>
          <p>{formValues?. customerContact}</p>
          {/* <p>--</p>
          <p>--</p> */}
        </div>
        <div className="right-side-div">
          <h1>Purchase Order</h1>
          <p>/{formValues?.name}</p>

          <div className="date-div">
            <p>
              <span class="date-container-single">Date:</span>
              {formValues?.firstDate}
            </p>
            {/* <p>Order Date:{formValues?.secondDate} </p> */}
          </div>
        </div>
      </div>

      <div className="Table-container">
        <div className="Table-data">
          <table id="table_data">
            <thead>
              <tr style={{ backgroundColor: "#eaeef9" }}>
                <th>No</th>
                <th>Product</th>
                <th>QTY</th>
                <th>Rate</th>
                <th>Tax%</th>
                <th>dis%</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => (
                <tr
                  key={index}
                  style={{
                    backgroundColor: index % 2 === 0 ? "#f1f2f5" : "white",
                  }}
                >
                  <td>{index + 1}</td>
                  <td>{item.product}</td>
                  <td>{item.qty}</td>
                  <td>{item.unitPrice}</td>
                  <td
                    style={{
                      display: "flex",
                      placeContent: "center",
                      marginTop: "10px",
                    }}
                  >
                    {item.taxes?.map((r, i) => (
                      <p key={i} className="tax" style={{ margin: "1px" }}>
                        {r?.amount || 0}%
                      </p>
                    ))}
                  </td>
                  <td>{item.discount || 0}</td>
                  <td>{item.lineTotal?.toFixed(decimalPosition) || 0} </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="last-total-div">
            <div className="sub-total">
              <h1>Sub Total</h1>
              {formValues?.inclusiveTax > 0 && (
              <p>Total tax Inclusive</p>
              )}
              {formValues?.exclusiveTax  > 0 && (
              <p>Total tax Exclusive</p>
              )}
              <hr className="line-2" />
              <h1>Total</h1>
            </div>
            <div className="sub-total-amount">
              <h1>{formValues?.subtotal?.toFixed(decimalPosition) || 0}</h1>
              {formValues?.inclusiveTax?.toFixed(decimalPosition) > 0 && (
                <p>{formValues?.inclusiveTax?.toFixed(decimalPosition)}</p>
              )}
             {formValues?.exclusiveTax  > 0 && (
                <p>{formValues?.exclusiveTax?.toFixed(decimalPosition) }</p>
              )}
              <hr className="line-3" />
              <h1>{formValues?.total?.toFixed(decimalPosition)}</h1>
            </div>
          </div>
        </div>
      </div>

      {/* payments print view */}
      <div style={{ display: "none" }}>
        <div ref={(el) => (paymentsPrint = el)}>
          <div className="print-view-top-container">
            <div
              className="print-view-top-left-container"
              style={{ width: "25%" }}
            >
              <p></p>
              <p></p>
            </div>
            <div
              className="print-view-top-middle-container"
              style={{ width: "50%" }}
            >
              {/* <h3>--</h3>

              <h3>--</h3> */}

              <p>Purchase Order</p>
            </div>

            <div
              className="print-view-top-right-container"
              style={{ width: "25%" }}
            >
              <p>{convertDateFormat(today)}</p>
              <p>{""}</p>
            </div>
          </div>

          <div className="second-main-container">
            <div className="left-side-div">
              <img
                src={formValues?.companyLogo}
                alt={formValues?.companyName}
              />
              <p>{formValues?.companyAddress}</p>
              <p>{formValues?.companyContact}</p>

              <h1 className="vendor-head">vendor Address</h1>
              <p>{formValues?.vendorName}</p>
              {/* <p>--</p>
              <p>--</p> */}
            </div>
            <div className="right-side-div">
              <h1>Purchase Order</h1>
              <p>{formValues?.name}</p>
              <div className="date-div">
                <p>
                  <span class="date-container-single">Date:</span>
                  {formValues?.firstDate}
                </p>
                {/* <p>Order Date:{formValues?.secondDate} </p> */}
              </div>
            </div>
          </div>

          <div className="Table-container" style={{ padding: "0px 35px" }}>
            <div className="Table-datas">
              <table style={{ overflow: "none" }} id="table_data">
                <thead>
                  <tr style={{ backgroundColor: "#eaeef9" }}>
                    <th>No</th>
                    <th>Product</th>
                    <th>QTY</th>
                    <th>Rate</th>
                    <th>Tax%</th>
                    <th>dis%</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((item, index) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: index % 2 === 0 ? "#f1f2f5" : "white",
                      }}
                    >
                      <td>{index + 1}</td>
                      <td>{item.product}</td>
                      <td>{item.qty}</td>
                      <td>{item.unitPrice}</td>
                      <td
                        style={{
                          display: "flex",
                          placeContent: "center",
                          marginTop: "10px",
                        }}
                      >
                        {item.taxes?.map((r, i) => (
                          <p key={i} className="tax" style={{ margin: "1px" }}>
                            {r?.amount}%
                          </p>
                        ))}
                      </td>
                      <td>{item.discount}</td>
                      <td>{item.lineTotal?.toFixed(decimalPosition) || 0} </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="last-total-div">
            <div className="sub-total">
              <h1>Sub Total</h1>
              {formValues?.inclusiveTax > 0 && (
              <p>Total tax Inclusive</p>
              )}
              {formValues?.exclusiveTax  > 0 && (
              <p>Total tax Exclusive</p>
              )}
              <hr className="line-2" />
              <h1>Total</h1>
            </div>
            <div className="sub-total-amount">
            <h1>{formValues?.subtotal?.toFixed(decimalPosition) || 0}</h1>
              {formValues?.inclusiveTax?.toFixed(decimalPosition) > 0 && (
                <p>{formValues?.inclusiveTax?.toFixed(decimalPosition)}</p>
              )}
             {formValues?.exclusiveTax  > 0 && (
                <p>{formValues?.exclusiveTax?.toFixed(decimalPosition) }</p>
              )}
              <hr className="line-3" />
              <h1>{formValues?.total?.toFixed(decimalPosition)}</h1>
            </div>
          </div>
            </div>
          </div>
          <div className="terms">
            <h1>Terms & Conditions</h1>
            <hr className="lines" />
          </div>
        </div>
      </div>
      <div style={{ display: "none" }}>
  {/* <div ref={(el) => (paymentsPrintNew = el)}>
   
    {template?.templateModel === 'template1' ? (
      <Template1 data={data} formValues={formValues} tax={tax} />
    ) : (
      <Template2 data={data} formValues={formValues} tax={tax}/>
    )}
  </div> */}
    <div ref={(el) => (paymentsPrintNew = el)}>
          {/* <Printing /> */}
          <InvoiceContent
            invoiceDetails={{ ...formValues, printSection: printSection.purchaseOrder }}
            ref={componentRef}
            printData={data}
            taxDetails={tax}
            qrData={domain}
          />
        </div>
</div>
    </div>
  );
};
