import { createSlice } from "@reduxjs/toolkit";

export const measurements=createSlice({
    name:"measurementsslice",
    initialState:{
        orderTypes:undefined,
        itemType:undefined,
        model:undefined,
        productColor:undefined,
        marModel:undefined,
        cuff:undefined,
        cuffCloth:undefined,
        cuffModel:undefined,
        collar:undefined,
        collarCloth:undefined,
        collarModel:undefined,
        fpModel:undefined,
        pen:undefined,
        sidePtModel:undefined,
        stitching:undefined,
        button:undefined,
        neckModel:undefined,
        elasticWidth:undefined,
        jalabiaModel:undefined
      
    },
    reducers:{
         get_order_types:(state,action)=>{
          state.orderTypes=action.payload.orderTypesData
          },
          get_order_itemType:(state,action)=>{
          state.itemType=action.payload.itemTypeData
          },
          get_model:(state,action)=>{
          state.model=action.payload.modelData
          },
          get_product_color:(state,action)=>{
            state.productColor=action.payload
          },
          get_mar_model:(state,action)=>{
            state.marModel=action.payload
          },
          get_cuff:(state,action)=>{
            state.cuff=action.payload
          },
          get_cuff_cloth:(state,action)=>{
            state.cuffCloth=action.payload
          },
          get_cuff_model:(state,action)=>{
            state.cuffModel=action.payload
          },
          get_collar:(state,action)=>{
            state.collar=action.payload
          },
          get_collar_cloth:(state,action)=>{
            state.collarCloth=action.payload
          },
          get_collar_model:(state,action)=>{
            state.collarModel=action.payload
          },
          get_fp_model:(state,action)=>{
            state.fpModel=action.payload
          },
          get_pen:(state,action)=>{
            state.pen=action.payload
          },
          get_side_pt_model:(state,action)=>{
            state.sidePtModel=action.payload
          },
          get_stitching:(state,action)=>{
            state.stitching=action.payload
          },
          get_button:(state,action)=>{
            state.button=action.payload
          },
          get_neck_model:(state,action)=>{
            state.neckModel=action.payload
          },
          get_elastic_width:(state,action)=>{
            state.elasticWidth=action.payload
          },
          get_jalabia_model:(state,action)=>{
            state.jalabiaModel=action.payload
          }


    }

})
export const {
    get_order_types,
    get_order_itemType,
    get_model,
    get_product_color,
    get_mar_model,
    get_cuff,
    get_cuff_cloth,
    get_cuff_model,
    get_collar,
    get_collar_cloth,
    get_collar_model,
    get_fp_model,
    get_pen,
    get_side_pt_model,
    get_stitching,
    get_button,
    get_neck_model,
    get_elastic_width,
    get_jalabia_model
}=measurements.actions
export default measurements.reducer