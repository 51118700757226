import { Autocomplete, TextField } from "@mui/material";
import React, { useState } from "react";
import { FilePicker } from "../../../Single Components/filePicker";
import countryList from "../../../../Assets/JSON/countryStates.json"
import { addNewCustomerPopupAPICall } from "./newBillingAPI";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import { useEffect } from "react";
import { viewAllAllowedBranchesAPICall, viewAllBranchesAPICall } from "../../../../API/Settings/Company Settings/companySettingsAPI";
import { useSelector } from "react-redux";
import { filterObjFromList } from "../../../../Js/generalFunctions";
import {LoadingForm} from "../../../Single Components/LoadingForm"
export const AddCustomerBilling=(props)=>{

    const {closeDialog}=props;
    const countryWithState=countryList.countries

    const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
    const {customer} = useSelector((state) => state.topBarSliceBilling);
    const allowedBranchList = useSelector(
      (state) => state.allAllowedBranchesSlice.value
    );
    const loginResponse = useSelector((state) => state.loginResponseSlice.value);

    const [loadingForm, setLoadingForm] = useState(false);

    const customerFormValuesInitial={
        name:"",
        country:null,
        state:null,
        city:"",
        street:"",
        zipCode:"",
        taxId:"",
        mobile:"",
        email:"",
        paymentTerms:null,
        supplierCurrency:"",
        contactPerson:"",
        contactPersonMobile:"",
        companyCode:"",
        branch:null,
        customerGroup:null,
        phone:"",
        inactive:true,
      }
    const [customerFormValues, setCustomerFormValues] = useState(customerFormValuesInitial);
    const [validationAlert, setValidationAlert] = useState({});
    const [customerImg, setCustomerImg] = useState(null);
    // snackbar states and function
    const [snackBarStates, setSnackBarStates] = useState({
        success: false,
        error: false,
        message: "",
        alert: false
    });
    const closeSnackbar = () => {
        setSnackBarStates({
        ...snackBarStates,
        success: false,
        error: false,
        alert: false,
        message: "",
        });
    };

    const closeCustomerDialog=()=>{
        setCustomerFormValues(customerFormValuesInitial)
        closeDialog()
      }
    
      const customerFormData=new FormData()
      customerFormData.append("name",customerFormValues?.name)
      customerFormData.append("mobileNo",customerFormValues?.mobile)
      customerFormData.append("branchId",customerFormValues?.branch?._id)
      customerFormData.append("status",customerFormValues?.inactive)
      customerFormData.append("isCustomer",true)
      customerFormData.append("isVendor",false)
      customerFormValues?.customerGroup!==null && customerFormData.append("customerGroup",customerFormValues?.customerGroup)
      customerFormData.append("phone",customerFormValues?.phone)
      customerFormData.append("companyCode",customerFormValues?.companyCode)
      customerFormData.append("contactPersonMobile",customerFormValues?.contactPersonMobile)
      customerFormData.append("contactPerson",customerFormValues?.contactPerson)
      customerFormData.append("supplierCurrency",customerFormValues?.supplierCurrency)
      customerFormValues?.paymentTerms!==null && customerFormData.append("payTerm",customerFormValues?.paymentTerms)
      customerFormData.append("city",customerFormValues?.city)
      customerFormData.append("zipCode",customerFormValues?.zipCode)
      customerFormData.append("taxId",customerFormValues?.taxId)
      customerFormData.append("email",customerFormValues?.email)
      customerFormData.append("streetName",customerFormValues?.street)

      customerImg!==null && customerFormData.append("file",customerImg)
      customerFormValues.country!==null && customerFormData.append("country",customerFormValues.country?.country)
      customerFormValues.state!==null && customerFormData.append("state",customerFormValues.state)
      
      const addCustomerSubmit=()=>{
        if(customerFormValues?.name===""){
          setValidationAlert({...validationAlert,name:"fill this field !!"})
        }
        else if(customerFormValues?.mobile===""){
          setValidationAlert({...validationAlert,mobile:"fill this field !!"})
        }
        else if(customerFormValues?.branch===null){
          setValidationAlert({...validationAlert,branch:"fill this field !!"})
        }
        else{
        
          addNewCustomerPopupAPICall(customerFormData,closeCustomerDialog,setSnackBarStates,snackBarStates,setLoadingForm)
        }
      }
    // customer details onchange
  const getCustomerFormInfo=(key)=>(e,newValue)=>{
    const{value}=e.target;
    setValidationAlert({})
    setCustomerFormValues({...customerFormValues,[key]:value===0?newValue:value})
  }

  useEffect(()=>{
    viewAllBranchesAPICall()
    viewAllAllowedBranchesAPICall({ employeeId: loginResponse?.data?._id })
  },[])

  useEffect(()=>{
    allBranchesList!==undefined && setCustomerFormValues({...customerFormValues,branch:filterObjFromList("_id",allBranchesList,"branchPk",loginResponse) || null})
  },[allBranchesList])

  useEffect(()=>{
    if(customer){
      console.log(customer);
    }else{
      setCustomerFormValues(customerFormValuesInitial)
    }
  },[customer])
    return(
        <div className="billing-customer-ctn">
          <h4>Customer</h4>
          <div className="billing-customer-ctn-fields">
            <div className='new-global-single-input'>
              <TextField
                value={customerFormValues?.name}
                onChange={getCustomerFormInfo("name")}
                id="outlined-basic"
                label="Name*"
                variant="outlined"
                type="text"
                focused />
                <p
                style={{top:"45%"}}
                className="doc-validation-alert">{validationAlert?.name}</p>
            </div>
            <div className="global-single-input">
              <p>Image Upload</p>
              <FilePicker
                id="addVendorImage"
                uploadImageUrl={customerImg}
                setImage={setCustomerImg}
                onChange={(e) => {
                    setCustomerImg(e.target.files[0]);
                }}
                style={{marginLeft:"-12%"}}
              />
            </div>
            <div className='new-global-single-input auto-complete-new'>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={countryWithState || []}
                getOptionLabel={(option)=>option?.country}
                renderInput={(params)=>(
                  <TextField {...params} label="Country" focused />
                )}
                value={customerFormValues?.country}
                onChange={getCustomerFormInfo("country")}
              />
            </div>
            <div className='new-global-single-input auto-complete-new'>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={customerFormValues?.country?.states || []}
                getOptionLabel={(option)=>option}
                renderInput={(params) => <TextField {...params} label="State" focused />}
                value={customerFormValues?.state}
                onChange={getCustomerFormInfo("state")}
              />
            </div>

            <div className='new-global-single-input'>
              <TextField
                value={customerFormValues?.city}
                onChange={getCustomerFormInfo("city")}
                id="outlined-basic"
                label="City"
                variant="outlined"
                type="text"
                focused />
            </div>

            <div className='new-global-single-input'>
              <TextField
                value={customerFormValues?.street}
                onChange={getCustomerFormInfo("street")}
                id="outlined-basic"
                label="Street"
                variant="outlined"
                type="text"
                focused />
            </div>

            <div className='new-global-single-input'>
              <TextField
                value={customerFormValues?.zipCode}
                onChange={getCustomerFormInfo("zipCode")}
                id="outlined-basic"
                label="Zip Code"
                variant="outlined"
                type="text"
                focused />
            </div>

            <div className='new-global-single-input'>
              <TextField
                value={customerFormValues?.taxId}
                onChange={getCustomerFormInfo("taxId")}
                id="outlined-basic"
                label="Tax ID"
                variant="outlined"
                type="text"
                focused />
            </div>

            <div className='new-global-single-input'>
              <TextField
                value={customerFormValues?.mobile}
                onChange={getCustomerFormInfo("mobile")}
                id="outlined-basic"
                label="Mobile*"
                variant="outlined"
                type="text"
                focused />
                <p className="doc-validation-alert">{validationAlert?.mobile}</p>
            </div>

            <div className='new-global-single-input'>
              <TextField
                value={customerFormValues?.email}
                onChange={getCustomerFormInfo("email")}
                id="outlined-basic"
                label="Email"
                variant="outlined"
                type="text"
                focused />
            </div>
            
            <div className='new-global-single-input auto-complete-new'>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={[]}
                getOptionLabel={(option)=>option}
                renderInput={(params) => <TextField {...params} label="Payment Terms" focused />}
                value={customerFormValues?.paymentTerms}
                onChange={getCustomerFormInfo("paymentTerms")}
              />
            </div>
            <div className='new-global-single-input'>
              <TextField
                value={customerFormValues?.supplierCurrency}
                onChange={getCustomerFormInfo("supplierCurrency")}
                id="outlined-basic"
                label="Supplier Currency"
                variant="outlined"
                type="text"
                focused />
            </div>
            <div className='new-global-single-input'>
              <TextField
                value={customerFormValues?.contactPerson}
                onChange={getCustomerFormInfo("contactPerson")}
                id="outlined-basic"
                label="Contact Person"
                variant="outlined"
                type="text"
                focused />
            </div>
            <div className='new-global-single-input'>
              <TextField
                value={customerFormValues?.contactPersonMobile}
                onChange={getCustomerFormInfo("contactPersonMobile")}
                id="outlined-basic"
                label="Contact Person Mobile"
                variant="outlined"
                type="text"
                focused />
            </div>
            <div className='new-global-single-input'>
              <TextField
                value={customerFormValues?.companyCode}
                onChange={getCustomerFormInfo("companyCode")}
                id="outlined-basic"
                label="Company Code"
                variant="outlined"
                type="text"
                focused />
            </div>
            <div className='new-global-single-input auto-complete-new'>
              {/* <TextField
                value={customerFormValues?.branch}
                // onChange={getCustomerFormInfo("companyCode")}
                id="outlined-basic"
                label="Branch"
                variant="outlined"
                type="text"
                focused /> */}
                <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={allowedBranchList || []}
                getOptionLabel={(option)=>`${option?.storeCode}-${option?.branchName}`}
                renderInput={(params) => <TextField {...params} label="Branch*" focused />}
                value={customerFormValues?.branch}
                onChange={getCustomerFormInfo("branch")}
              />
                <p className="doc-validation-alert">{validationAlert?.branch}</p>

            </div>
            <div className='new-global-single-input auto-complete-new'>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={[]}
                getOptionLabel={(option)=>option}
                renderInput={(params) => <TextField {...params} label="Customer Group" focused />}
                value={customerFormValues?.customerGroup}
                onChange={getCustomerFormInfo("customerGroup")}
              />
            </div>
            <div className='new-global-single-input'>
              <TextField
                value={customerFormValues?.phone}
                onChange={getCustomerFormInfo("phone")}
                id="outlined-basic"
                label="Phone"
                variant="outlined"
                type="text"
                focused />
            </div>
            <div className="global-single-input" style={{alignItems:"start"}}>
              <p>In Active</p>
              <input 
              checked={customerFormValues?.inactive}
              onChange={(e)=>setCustomerFormValues({...customerFormValues,inactive:e.target.checked})}
              type="checkbox" className="toggle-checkbox"/>
            </div>
          </div>

          <div className="justify-center">
            <button
            onClick={closeCustomerDialog}
            className="btn btn-secondary-outlined">Cancel</button>
            <button className="btn btn-primary"
            onClick={addCustomerSubmit}
            >{customer?"Edit":"Submit"}</button>
          </div>
            <SuccessSnackbar
                open={snackBarStates.success}
                handleClose={closeSnackbar}
                message={snackBarStates.message}
            />
            <ErrorSnackbar
                open={snackBarStates.error}
                handleClose={closeSnackbar}
                message={snackBarStates.message}
            />
            <LoadingForm loading={loadingForm} />

        </div>
    )
}