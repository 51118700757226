import React, { useState } from "react";
import "./posReceiptTemplateStyle.css";
import { useSelector } from "react-redux";
// import language from "../../i18n/languages.json";
import { convertToArabicMobileNo, convertToArabicNumber, numToArabicWords, numToWords } from "../../../../../Js/generalFunctions";
import Barcode from "react-barcode";

const PosReceiptTemplate = (props) => {
   

    // const body = {
    //   query: "good!",
    //   sourceLang: language.English,
    //   targetLang: language.Arabic,
    // };

    const userInfo = useSelector((state) => state.loginResponseSlice.value);
    const { posTemplateSelected } = useSelector((state) => state.posReceiptSlice);
    const  receiptData  = null
    const companyProfileData =useSelector((state) => state?.companyProfileSlice?.userValue)
 
    const [tempalteType,setTemplateType]=useState(posTemplateSelected?.posReceiptTemplate)

    return (
      <div className="pos-receipt-template-container">
        {/* <button onClick={() => translateLang()}>translate </button> */}
        <div className="pos-receipt-template-header">
          {/* <!--Company-logo-> */}
          <div className="template-header-company-logo">
            <img src={companyProfileData?.logo} alt="logo" />
          </div>
          {/* <!--Bill-header->  */}
          <div className="template-header-company-info">
            <span className="template-restaurant-name">
              {companyProfileData?.companyName|| "---"}
            </span>
            <span>{companyProfileData?.companyAddress|| "---"} </span>
            <span>{companyProfileData?.country}</span>
            <span>{companyProfileData?.companyEmail|| "---"} </span>
      
            {/* <!--Bill-header-> arabic */}
            <>
              {/* <span>برج زي - مدينة دبي للإنترنت </span>
              <span>دبي الامارات العربية المتحدة</span> */}
              <span>{companyProfileData?.contactNumber|| "---"} </span>
            </>
            {
              companyProfileData?.VATNo &&
              <span className="template-transaction-number">TRN : {companyProfileData?.VATNo }</span>}
          </div>
        </div>
        <div className="pos-receipt-template-body">
          <hr className="template-dashed-line" />
          {/* <!--Bill-Details-> */}
          <div className="template-invoice-container">
            <div className="template-invoice-details">
              <span className="temp-bol-txt">BILL NO</span>:
              <span className="temp-bol-txt">{receiptData?.name || "---"}</span>
            </div>
            <div className="template-invoice-details temp-multi-line temp-text-end">
              <span className="temp-bol-txt ">{receiptData?.ordType}</span>
              {/* <span className="temp-bol-txt ">تناول الطعام في</span> */}
            </div>
            {/* { <div className="template-invoice-details">
            <span>CASHIER :</span>
            <span>BAAHU</span>
          </div>} */}
            {
              receiptData?.tables.length!==0 ?
              <div className="template-invoice-details">
                <span className="temp-bol-txt ">TABLE NO :</span>
                <span className="temp-bol-txt ">{receiptData?.tables}</span>
              </div>:<div></div>
            }
            <div className="template-invoice-details temp-text-end">
              <span>DATE & TIME :</span>
              <span>{receiptData?.orderDate}</span>
            </div>
            <div className="template-invoice-details">
              <span className="temp-bol-txt">KOT NO</span>:
              <span className="temp-bol-txt">{receiptData?.kotInfo || "-NIL-"}</span>
            </div>
            <div></div>
            <div className="template-invoice-details">
              <span>WAITER :</span>
              <span>{receiptData?.waiterName}</span>
            </div>
          </div>
          <hr className="template-dashed-line" />

          {/* <!--table--> */}
          <div className="template-table-container">
            <table>
              <thead>
                <tr>
                  <th>
                    <div className="temp-table-head">
                      <span>Item</span>
                      {/* <span>غرض</span> */}
                    </div>
                  </th>
                  <th>
                    <div className="temp-table-head">
                      <span>Qty</span>
                      {/* <span>الكمية</span> */}
                    </div>
                  </th>
                  <th>
                    <div className="temp-table-head">
                      <span>Price</span>
                      {/* <span>سعر</span> */}
                    </div>
                  </th>
                  <th>
                    <div className="temp-table-head">
                      <span>Amount</span>
                      {/* <span>كمية</span> */}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {receiptData?.orderInfo?.length > 0 ? (
                  receiptData?.orderInfo?.map((res, i) => (
                    <tr>
                      <td>
                        <div className="table-item-center">
                          <span className="temp-bol-txt">
                            {res?.productName}
                          </span>
                          {/* <span className="temp-bol-txt">دجاج طري</span> */}
                        </div>
                      </td>
                      <td>
                        <div className="table-item-center">
                          <span>{res?.qty}</span>
                        </div>
                      </td>
                      <td>
                        <div className="table-item-center">
                          <span>{res?.unitPrice}</span>
                        </div>
                      </td>
                      <td>
                        <div className="table-item-center">
                          <span>{res?.subTotalWoTax}</span>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>NO DATA</td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                <tr>
                  <td></td>
                  <td colSpan={2} className="table-item">
                    <span>Taxable Amount</span>
                    {
                      tempalteType===3&&
                    <span>المبلغ الخاضع للضريبة</span>
                    }
                  </td>
                  <td colSpan={2} className="temp-text-end">{tempalteType===3&&
                   <span>  {convertToArabicNumber(receiptData?.taxableAmt)} &nbsp;  &nbsp;&nbsp; </span>}
                   <span> {receiptData?.taxableAmt}</span>
                  </td>
                </tr>
                {
                  receiptData?.taxDetails.length!==0 &&
                  receiptData?.taxDetails.map((item,i)=>(
                    <tr key={i}>
                      <td></td>
                      <td colSpan={2}>
                        <span>{item?.taxName}</span>
                      </td>
                      <td className="temp-text-end">{tempalteType===3&&
                      <span> {convertToArabicNumber(item?.amount)} &nbsp; &nbsp;&nbsp;</span>}
                      <span>  {item?.amount}</span>
                      </td>
                    </tr>
                  ))
                }
                {
                receiptData?.discountAmt>0 &&
                <tr>
                  <td></td>
                  <td colSpan={2}>
                    <span>Discount</span>
                  </td>
                  <td className="temp-text-end">{tempalteType===3&&
                    <span> {convertToArabicNumber(receiptData?.discountAmt)} &nbsp; &nbsp;&nbsp;</span>}
                    <span>{receiptData?.discountAmt}</span>
                    </td>
                </tr>}
                <tr className="temp-net-amount">
                  <td></td>
                  <td colSpan={2} className="table-item">
                    <span className="temp-bol-txt">Net Amount</span>{tempalteType===3&&
                    <span className="temp-bol-txt">كمية الشبكة</span>}
                  </td>
                  <td colSpan={2} className="temp-text-end temp-bol-txt">
                    {" "}{tempalteType===3&&
                    <span>{convertToArabicNumber(receiptData?.amtTotal)} &nbsp;&nbsp;&nbsp;</span>}
                    <span>{receiptData?.amtTotal}</span>
                  </td>
                </tr>
                <tr className="temp-last-raw">
                  <td colSpan={4} className="temp-text-end temp-bol-txt">
                    <div>
                      {/* <span>{numToWords(receiptData?.amtTotal)}</span> */}
                      {/* <span>{numToArabicWords(receiptData?.amtTotal)}</span> */}
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <hr className="template-dashed-line" />
          {/* <!--payment-details--> */}
          <div className="template-payment-details">
            <div className="template-pax-count">
              <div>
                <span className="temp-bol-txt">PAX :</span>
                <span className="temp-bol-txt">{receiptData?.orderInfo?.length}</span>
              </div>
             {tempalteType===3&& 
             <div>
                <span className="temp-bol-txt">باكس :</span>
                <span className="temp-bol-txt">{convertToArabicNumber(receiptData?.orderInfo?.length)}</span>
              </div>}
            </div>
            <div className="template-payment-via">
              <div className="temp-text-end justify-space-between">
                <span className="temp-bol-txt ">PAYMENT</span>
               {tempalteType===3&& <span style={{marginLeft:"12px"}} className="temp-bol-txt ">قسط</span>}
              </div>
             {
             receiptData?.paymentInfo?.map((item,i)=>(
               <div key={i} className="temp-text-end justify-space-between">
                 <span className="temp-bol-txt ">{item.type?.toUpperCase()}</span>
                 {tempalteType===3&&
                 <span className="temp-bol-txt ">&nbsp; &nbsp;{convertToArabicNumber(item.amount)} &nbsp;</span>}
                 <span className="temp-bol-txt " style={{marginLeft:"12px"}}>{item.amount}</span>
               </div>
             ))
             }
            </div>
          </div>
          <hr className="template-dashed-line" />
          {/* <!--Token--> */}
          {tempalteType===3&&
          <>
          <div className="template-token-container">
            <div>
              <span className="temp-token-name">YOUR TOKEN NO</span>
              <span className="temp-token-no">007</span>
            </div>
          </div>

          <hr className="template-dashed-line" />
          </>  
          }
        </div>
        <div className="pos-receipt-template-footer">
          <div className="template-footer-content">
            <div className="temp-two-column">
              <span className="temp-bol-txt fr-greeting">THANK YOU</span>{
                tempalteType===3&&
              <span className="temp-bol-txt fr-greeting">شكرًا لك</span>}
            </div>
            <div className="template-ft-ph-container">
              <span className="temp-bol-txt">CALL FOR DELIVERY </span>
              <span className="temp-bol-txt">+91 4543 5544 77 /</span>
              <span className="temp-bol-txt">+91 8943 5544 78</span>
            </div>
            {
            tempalteType===3&&  
            <div className="template-ft-ph-container">
              <span className="temp-bol-txt">{convertToArabicMobileNo(` 23456789`)}</span>
              {/* <span className="temp-bol-txt">{convertToArabicMobileNo(`+91 8943 5544 78`)}</span> */}
              <span className="temp-bol-txt">اتصل للتسليم </span>
            </div>}
            <div className="temp-two-column">
              <span className="temp-bol-txt footer-summary fr-greeting">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Iusto,
                architecto!
              </span>
              {
                tempalteType===3&&
              <span className="temp-bol-txt footer-summary fr-greeting">
                لوريم إيبسوم دولور الجلوس، أميت كونسيكتور أديبيسينج إيليت.
                يوستو, مهندس معماري!
              </span>}
            </div>
            <div className="template-ft-qr-bar-code-container">
              {/* <div className="template-ft-qr">QR CODE</div> */}
              <div className="template-ft-bar">
                <Barcode
                  format="CODE128"
                  value={receiptData?.name || "null"}
                  width={2}
                  fontSize={"5em"}
                  height={60}
                  margin={0}
                />
              </div>
            </div>
            <hr className="template-dotted-line" />
            <div className="temp-two-column">
              <span>Join Us On WhatsApp </span>{
                tempalteType===3&&
              <span>انضم إلينا على الواتساب</span>}
            </div>
            <div className="temp-two-column">
              <span>Send Hi to {"+91 0004 5556 66"} get our latest offer</span>
              {
                tempalteType===3&&
                <span>
                Send Hi to {"+91 0004 5556 66"} احصل على أحدث عرض لدينا
              </span>}
            </div>
            <hr className="template-dashed-line" />
          </div>
        </div>
      </div>
    );
  }
;

export default PosReceiptTemplate;
