import React, { useEffect, useState } from "react";
import TempFooter from "./common/TempFooter";
import logo from "../../../../../Assets/Images/Logo.png";
import LanguageIcon from "@mui/icons-material/Language";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import { useSelector } from "react-redux";
import { printSection } from "./common/enum";

const PrintTempTwo = ({
  pageNumber,
  totalPages,
  items,
  isLastPage,
  tableTotal,
  allData,
  taxDetails,
  qrData,
  invoiceDetails,
}) => {
  const decimalPosition = localStorage.getItem("decimalPosition");
  const { currencyDetails } = useSelector((state) => state.mainDashBoardSlice);
  const [TotalValues, setTotalValues] = useState({
    ttBeforeVat: 0,
    ttIncVat: 0,
  });
  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );
  // console.log("company profile data", companyProfileData);
  const [headerImage, setHeaderImage] = useState(null);

  useEffect(() => {
    if (companyProfileData?.logo) {
      const img = new Image();
      img.onload = () => setHeaderImage(companyProfileData.logo);
      img.onerror = () => setHeaderImage(null);
      img.src = companyProfileData.logo;
    }
  }, [companyProfileData?.logo]);

  useEffect(() => {
    if (allData?.length > 0) {
      setTotalValues({
        ttBeforeVat: allData
          ?.reduce((total, item) => {
            const qty = typeof item.qty === "number" ? item.qty : 0;
            const unitPrice =
              typeof item.unitPrice === "number" ? item.unitPrice : 0;
            return total + qty * unitPrice;
          }, 0)
          ?.toFixed(decimalPosition),
        ttIncVat: allData
          ?.reduce((acc, row) => {
            if (row.includedPrice === false) {
              return acc + (row.lineTotal + row.taxAmt);
            } else {
              return acc + row.lineTotal;
            }
          }, 0.0)
          ?.toFixed(decimalPosition),
      });
    }
  }, [allData]);

  return (
    <div
      className="invoice-container-temp-2"
      style={{
        height: "350mm",
        width: "250mm",
        padding: "6mm",
        boxSizing: "border-box",
        position: "relative",
        pageBreakAfter: "always",
      }}
    >
      <header>
        <div className="print-header-temp-2-wrapper">
          <div className="invoice-header-temp-2">
            <div className="logo-temp-2">
              {headerImage && (
                <img
                  src={headerImage}
                  alt="Logo"
                  className="header-logo-temp-2"
                />
              )}
            </div>
            <div className="company-info-temp-2">
              <h2> {companyProfileData?.companyName || ""}</h2>
              <p>{companyProfileData?.companyAddress || "---"}</p>
              {/* <p>Post Code: {companyDetails.postCode}</p> */}
              {/* <p>TRN: {companyDetails.trn}</p> */}
            </div>
          </div>
          <div className="invoice-title-temp-2"></div>
          <h3 className="quotation-title-temp-2">QUOTATION</h3>

          <div className="invoice-details-temp-2">
            <div className="invoice-to-temp-2 rtlb-temp-2">
              <h3>Invoice To:</h3>
              <p>{invoiceDetails?.customerName || ""}</p>
              <p> {invoiceDetails?.customerEmail || ""}</p>
              <p> {invoiceDetails?.customerAddress || ""}</p>
              <p> {invoiceDetails?.customerContact || ""}</p>
              {/* <p>TRN: {invoiceDetails.trn}</p> */}
            </div>
            <div className="contact-info-temp-2 tb-temp-2 ">
              <p>
                <PhoneIphoneIcon />
                {companyProfileData?.contactNumber || ""}
              </p>
              <p>
                <EmailIcon />
                {companyProfileData?.companyEmail || ""}
              </p>
              <p>
                <LanguageIcon />
                {companyProfileData?.website || ""}
              </p>
            </div>
            <div className="quote-info-temp-2 rtlb-temp-2">
              <p>
              <strong>
                  {invoiceDetails?.printSection == printSection.rfq
                    ? "RFQ No"
                    : invoiceDetails?.printSection ===
                      printSection.purchaseOrder
                      ? "PO NO"
                      : invoiceDetails?.printSection ===
                        printSection.goodsReceiveNote
                        ? "GRN NO"
                        : invoiceDetails?.printSection ===
                          printSection.salesOrder
                          ? "Sales No"
                          : invoiceDetails?.printSection ===
                            printSection.deliveryNote
                            ? "DN No" : invoiceDetails?.printSection ===
                              printSection.customerInvoice
                              ? "invoice No" : invoiceDetails?.printSection ===
                                printSection.salesWSO
                                ? "invoice No" :invoiceDetails?.printSection ===
                                printSection.creditNote
                                ? "Invoice No" : invoiceDetails?.printSection ===
                                printSection.vendorBill
                                ? "Bill No" : invoiceDetails?.printSection ===
                                printSection.purchaseWPO
                                ? "Bill No" : invoiceDetails?.printSection ===
                                printSection.debitNote
                                ? "Return No" : ""}
                </strong>
                : {invoiceDetails?.name || ""}
              </p>
              {invoiceDetails?.printSection !==
                printSection.goodsReceiveNote && (
                <p>
                  <strong>Quotation Date</strong>:{" "}
                  {invoiceDetails?.firstDate || ""}
                </p>
              )}
              {invoiceDetails?.printSection !==
                printSection.goodsReceiveNote && (
                <p>
                  <strong>Expiry Date</strong>:{" "}
                  {invoiceDetails?.secondDate || ""}
                </p>
              )}
              {invoiceDetails?.printSection !==
                printSection.goodsReceiveNote && (
                <p>
                  <strong>LPO No</strong>: {invoiceDetails?.lpo || ""}
                </p>
              )}
              {invoiceDetails?.printSection !==
                printSection.goodsReceiveNote && (
                <p>
                  <strong>Payment Terms</strong>:{" "}
                  {invoiceDetails?.payTerm || ""}
                </p>
              )}
              {invoiceDetails?.printSection ===
                printSection.goodsReceiveNote && (
                <p>
                  <strong>Return Date</strong>: {invoiceDetails?.dateN1 || ""}
                </p>
              )}
            </div>
          </div>
        </div>
      </header>

      {items?.length > 0 && (
        <table
          className="invoice-table-temp-2 striped-table"
          style={{ marginTop: "1.5em" }}
        >
          <thead>
          <tr>
              <th>Sl.No</th>
              {invoiceDetails?.printSection !==
                printSection.rfq &&invoiceDetails?.printSection !==
                printSection.purchaseOrder&&<th>PRODUCT</th>}
              {invoiceDetails?.printSection !==
                printSection.goodsReceiveNote && invoiceDetails?.printSection !==
                printSection.deliveryNote && invoiceDetails?.printSection !== printSection.customerInvoice && invoiceDetails?.printSection !==
                printSection.vendorBill &&invoiceDetails?.printSection !==
                printSection.purchaseWPO &&invoiceDetails?.printSection !==
                printSection.purchaseWPO &&invoiceDetails?.printSection !==
                printSection.debitNote && <th>DESCRIPTION</th>}
              {invoiceDetails?.printSection ===
                printSection.customerInvoice || invoiceDetails?.printSection ===
                printSection.vendorBill && <th> ACCOUNT</th>}
              {invoiceDetails?.printSection !==
                printSection.goodsReceiveNote && invoiceDetails?.printSection !==
                printSection.deliveryNote && printSection.deliveryNote && invoiceDetails?.printSection !==
                printSection.deliveryNote && <th>QTY</th>}
              <th>UOM</th>
              {invoiceDetails?.printSection ===
                printSection.deliveryNote && <th>DEMAND QUANTITY</th>}
              {invoiceDetails?.printSection ===
                printSection.deliveryNote && <th>DONE QUANTITY</th>}
              {invoiceDetails?.printSection ===
                printSection.goodsReceiveNote && <th>ORDER QUANTITY</th>}
              {invoiceDetails?.printSection ===
                printSection.goodsReceiveNote && <th>DELIVER QUANTITY</th>}
              {invoiceDetails?.printSection !==
                printSection.goodsReceiveNote && printSection.deliveryNote && invoiceDetails?.printSection !==
                printSection.deliveryNote && <th>RATE</th>}
              {invoiceDetails?.printSection !==
                printSection.goodsReceiveNote && invoiceDetails?.printSection !==
                printSection.deliveryNote && invoiceDetails?.printSection !==
                printSection.customerInvoice && invoiceDetails?.printSection !==
                printSection.salesWSO && invoiceDetails?.printSection !==
                printSection.creditNote && invoiceDetails?.printSection !==
                printSection.vendorBill &&invoiceDetails?.printSection !==
                printSection.purchaseWPO &&invoiceDetails?.printSection !==
                printSection.debitNote &&  <th>AMOUNT</th>}
              {invoiceDetails?.printSection !==
                printSection.goodsReceiveNote && invoiceDetails?.printSection !==
                printSection.deliveryNote && <th>DISCOUNT</th>}
              {invoiceDetails?.printSection !==
                printSection.goodsReceiveNote && invoiceDetails?.printSection !==
                printSection.deliveryNote && invoiceDetails?.printSection !==
                printSection.customerInvoice && invoiceDetails?.printSection !==
                printSection.creditNote && invoiceDetails?.printSection !==
                printSection.purchaseWPO &&invoiceDetails?.printSection !==
                printSection.debitNote &&<th>VAT%</th>}
              {invoiceDetails?.printSection !==
                printSection.goodsReceiveNote && invoiceDetails?.printSection !==
                printSection.deliveryNote && <th>VAT</th>}
              {invoiceDetails?.printSection !==
                printSection.goodsReceiveNote && invoiceDetails?.printSection !==
                printSection.deliveryNote && <th>NET AMOUNT</th>}

            </tr>
          </thead>
          <tbody>
            {items?.length > 0 ? (
              <>
                {items.map((item, index) => (
                  <tr
                    key={index}
                    className={
                      index === items.length - 1
                        ? "invoice-table-btm-temp-1"
                        : ""
                    }
                  >
                    <td>{item?.id}</td>
                    {invoiceDetails?.printSection !==
                printSection.rfq &&invoiceDetails?.printSection !==
                printSection.purchaseOrder&&<td>{item?.product}</td>}
                    

                    {invoiceDetails?.printSection !== printSection.goodsReceiveNote &&
                      invoiceDetails?.printSection !== printSection.deliveryNote &&
                      invoiceDetails?.printSection !== printSection.customerInvoice && invoiceDetails?.printSection !==
                      printSection.vendorBill &&invoiceDetails?.printSection !==
                      printSection.purchaseWPO &&invoiceDetails?.printSection !==
                      printSection.debitNote && 
                      <td>{item?.label}</td>}
                    {invoiceDetails?.printSection ===
                      printSection.customerInvoice || invoiceDetails?.printSection ===
                      printSection.vendorBill && (
                        <td>{item?.accountName}</td>
                      )}
                    {invoiceDetails?.printSection !==
                      printSection.goodsReceiveNote && invoiceDetails?.printSection !==
                      printSection.deliveryNote && <td style={{textAlign:"center"}}>{item?.qty}</td>}
                    <td style={{textAlign:"center"}}>{item?.uomName}</td>
                    {invoiceDetails?.printSection ===
                      printSection.goodsReceiveNote && (
                        <td style={{textAlign:"center"}}>{item?.orderQuantity}</td>
                      )}
                    {invoiceDetails?.printSection ===
                      printSection.goodsReceiveNote && (
                        <td style={{textAlign:"center"}}>{item?.recievedQuantity}</td>
                      )}

                    {invoiceDetails?.printSection !==
                      printSection.goodsReceiveNote && printSection.deliveryNote && (
                        <td style={{textAlign:"right"}}>{item.unitPrice?.toFixed(decimalPosition)}</td>
                      )}
                    {invoiceDetails?.printSection !==
                      printSection.goodsReceiveNote && invoiceDetails?.printSection !==
                      printSection.customerInvoice && invoiceDetails?.printSection !==
                      printSection.salesWSO && invoiceDetails?.printSection !==
                      printSection.creditNote && invoiceDetails?.printSection !==
                      printSection.vendorBill &&invoiceDetails?.printSection !==
                      printSection.purchaseWPO &&invoiceDetails?.printSection !==
                      printSection.debitNote && (
                        <td style={{textAlign:"right"}}>
                          {(item.qty * item.unitPrice)?.toFixed(decimalPosition)}
                        </td>
                      )}
                    {invoiceDetails?.printSection !==
                      printSection.goodsReceiveNote && invoiceDetails?.printSection !==
                      printSection.deliveryNote && (
                        <td style={{textAlign:"right"}}>{item?.discount}%</td>
                      )}
                    {invoiceDetails?.printSection !==
                      printSection.goodsReceiveNote && invoiceDetails?.printSection !==
                      printSection.deliveryNote && invoiceDetails?.printSection !==
                      printSection.customerInvoice && invoiceDetails?.printSection !==
                      printSection.creditNote && invoiceDetails?.printSection !==
                      printSection.purchaseWPO &&invoiceDetails?.printSection !==
                      printSection.debitNote && (
                        <td style={{textAlign:"right"}}>
                          {item?.taxes?.map((r, i) => (
                            <p
                              key={i}
                              className="tax"
                              style={{ margin: "1px", border: "none" }}
                            >
                              {r?.amount}%
                            </p>
                          ))}
                        </td>
                      )}
                    {invoiceDetails?.printSection !==
                      printSection.goodsReceiveNote && invoiceDetails?.printSection !==
                      printSection.deliveryNote && (
                        <td style={{textAlign:"right"}}>{item.taxAmt?.toFixed(decimalPosition)}</td>
                      )}
                    {invoiceDetails?.printSection !==
                      printSection.goodsReceiveNote && invoiceDetails?.printSection !==
                      printSection.deliveryNote && (
                        <td style={{textAlign:"right"}}>
                          {item?.includedPrice === false
                            ? (item.lineTotal + item.taxAmt)?.toFixed(
                              decimalPosition
                            )
                            : item.lineTotal?.toFixed(decimalPosition)}
                        </td>
                      )}
                  </tr>
                ))}

                {invoiceDetails?.printSection !==
                  printSection.deliveryNote && invoiceDetails?.printSection !==
                  printSection.customerInvoice &&invoiceDetails?.printSection !==
                  printSection.vendorBill&& tableTotal && (
                    <tr className="table-tt-temp-1">
                      <td colSpan={
                        invoiceDetails?.printSection === printSection.goodsReceiveNote
                            ? 3
                            : invoiceDetails?.printSection === printSection.salesWSO ||invoiceDetails?.printSection === printSection.purchaseWPO ||invoiceDetails?.printSection === printSection.debitNote
                            ||invoiceDetails?.printSection ===
                printSection.purchaseOrder? 5
                            : 6
                      }>
                        Total
                      </td>

                      {invoiceDetails?.printSection !==
                        printSection.goodsReceiveNote && invoiceDetails?.printSection !==
                        printSection.deliveryNote && invoiceDetails?.printSection !==
                        printSection.creditNote && invoiceDetails?.printSection !==
                        printSection.purchaseWPO&&invoiceDetails?.printSection !==
                        printSection.debitNote &&(
                          <td style={{textAlign:"right"}}>
                            {currencyDetails?.symbol}
                            {TotalValues?.ttBeforeVat || "0.00"}
                          </td>
                        )}
                      {invoiceDetails?.printSection !==
                        printSection.goodsReceiveNote && <td colSpan={2}></td>}
                      {invoiceDetails?.printSection !==
                        printSection.goodsReceiveNote && invoiceDetails?.printSection !==
                        printSection.creditNote && invoiceDetails?.printSection !==
                        printSection.purchaseWPO&&invoiceDetails?.printSection !==
                        printSection.debitNote && (
                          <td style={{textAlign:"right"}}>
                            {" "}
                            {allData
                              ?.reduce((acc, row) => acc + row?.taxAmt, 0.0)
                              ?.toFixed(decimalPosition) || "0.00"}
                          </td>
                        )}
                      {invoiceDetails?.printSection !==
                        printSection.goodsReceiveNote &&(
                          <td style={{textAlign:"right"}}>
                            {currencyDetails?.symbol}
                            {TotalValues?.ttIncVat || "0.00"}
                          </td>
                        )}
                      {invoiceDetails?.printSection ===
                        printSection.goodsReceiveNote && (
                          <td>{invoiceDetails?.totalReceivedQty || ""}</td>
                        )}
                      {invoiceDetails?.printSection ===
                        printSection.goodsReceiveNote && (
                          <td style={{textAlign:"right"}}>{invoiceDetails?.totalReceivedQty || ""}</td>
                        )}
                    </tr>
                  )}
              </>
            ) : (
              <tr className="no-data-bt-temp-1">
                <td colSpan={11}>No Data</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
      {isLastPage && (
        <div
          className="end-page-temp-2"
          style={{ marginTop: items?.length > 0 ? "0" : "30.5em" }}
        >
          <div className="terms-conditions-temp-2">
            <h3>Terms and Conditions</h3>
            {/* <ol>
              <li>GOODS ONCE SOLD WILL NOT BE TAKEN BACK OR EXCHANGED</li>
              <li>ALL DISPUTES ARE SUBJECT TO CITY_NAME JURISDICTION</li>
            </ol> */}
          </div>

          <div className="authorized-signatory-temp-2">
            <p>AUTHORIZED SIGNATORY FOR</p>
            <div className="signature-line-temp-2"></div>
          </div>
        </div>
      )}
      <footer
        style={{
          position: "absolute",
          width: "100%",
          padding: "0 1em ",
          right: "0mm",
          bottom: "0mm",
        }}
      >
        <TempFooter
          template={2}
          totalPage={totalPages}
          currentPage={pageNumber}
          companyDetails={{
            phoneNumber: companyProfileData?.contactNumber,
            email: companyProfileData?.companyEmail,
            website: companyProfileData?.website,
          }}
        />
      </footer>
    </div>
  );
};
export default PrintTempTwo;
