import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  BranchTransferFilterAPICall,
  viewAllBranchTransferAPICall,
} from "./BranchTransferAPI";
import store from "../../../../../Redux/store";
import { get_branch_transfer_single_view } from "./BranchTransferSlice";
import { IconButton, Skeleton, Tooltip } from "@mui/material";
import CategorySearchandFilter from "../../../../Single Components/CategorySearchandFilter";
import CustomPagination from "../../../../Single Components/CustomPagination";
import { getCurrentTime } from "../../../../../Js/Date";
import axios from "axios";
import { Source } from "@mui/icons-material";
import { setFilterActive } from "../../../../Single Components/listSlice/filterCategorySlice";
import ReactToPrint from "react-to-print";
import ExcelJS from "exceljs"

export const BranchTransfer = () => {
  const filtereDataOptions = useSelector((state) => state.listCategory);
  let navigate = useNavigate();
  // login response
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);
  const branchTransferList = useSelector(
    (state) => state.branchTransferSlice.value
  );
  const createBranchTransfer = () => {
    navigate("/userdashboard/inventory/operation/branchtransfer/create");
  };
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = branchTransferList?.pages;
  const [FormDataInfo, setFormDataInfo] = useState([]);
  const [filteredData, setFilteredData] = useState({});
  const handleDataFromChild = (data) => {
    setFormDataInfo(data);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };
  const handleFilterChange = (updatedItems) => {
    setFilteredData(updatedItems);
  };
  const formatFilterInfo = (filters) => {
    const parts = [];

    // if (filters?.toDate && filters.fromDate) {
    //   parts.push(`As of ${filters.fromDate} to ${filters?.toDate}`);
    // }
    if (filters?.fromBranch) {
      parts.push(
        `From Branch: ${filters?.fromBranch?.branchName}`
      );
    }
    if (filters?.destinationBranch) {
      parts.push(
        `Destination Branch: ${filters?.destinationBranch?.branchName}`
      );
    }
    if (filters?.status) {
      parts.push(`Status: ${filters?.status}`);
    }
    // if (filters?.location) {
    //   parts.push(`location: ${filters?.location}`);
    // }
    return parts; // Return the array directly
  };
  const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Branch Transfer");
    sheet.properties.defaultRowHeight = 20;
  
    // Add header section
    const titleRow = sheet.addRow(["Branch Transfer"]);
    titleRow.font = { size: 15, bold: true };
    sheet.mergeCells('A1:F1');
    titleRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
  
    // Add additional header information
    const filterParts = formatFilterInfo(filteredData) || [];
  
    // Ensure filterParts is an array and iterate over it
    if (Array.isArray(filterParts)) {
      filterParts.forEach((part, index) => {
        const row = sheet.addRow([part]);
        row.font = { size: 11, bold: true };
        sheet.mergeCells(`A${index + 2}:F${index + 2}`);
        row.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
      });
    }
  
    // Add an empty row between header and table
    sheet.addRow([]);
  
    // Header row styling
    const headerRow = sheet.addRow(["Name", "From","To","Contact Person","GRN","Status"]);
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFedf2f9" }, // Use full hex code with "FF" prefix for better compatibility
    };
    const headerFont = {
      size: 12,
      bold: true,
      color: { argb: "FF000000" }, // White font color
    };
  
    headerRow.eachCell((cell, colNumber) => {
      cell.fill = headerFill;
      cell.font = headerFont;
      cell.alignment = { vertical: 'middle', horizontal: 'center' }; // Ensure header text is centered
    })
  
    sheet.columns = [
      { header: "Name", key: "transNo", width: 20 },
      { header: "From", key: "sourcename", width: 25 },
      { header: "To", key: "destinationname", width: 25 },
      { header: "Contact Person", key: "contactPerson", width: 25 },
      { header: "GRN", key: "grnNumber", width: 25 },
      { header: "Branch Transfer", key: "status", width: 15 },
  
    ];
  
    branchTransferList?.list?.forEach((item,index) => {
      const row = sheet.addRow({
        transNo:item?.transNo,
        sourcename: item.sourcename,
        destinationname: item?.destinationname,
        contactPerson: item?.contactPerson,
        grnNumber: item?.grnNumber,
        status: item?.status,
      
      });
  
      const rowFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFFFF" }, // Use full hex code with "FF" prefix for better compatibility
      };
      
      row.eachCell((cell, colNumber) => {
        cell.fill = rowFill;
        cell.border = {
        //   top: { style: "thin", color: { argb: "FF000000" } },
        //   left: { style: "thin", color: { argb: "FF000000" } },
          bottom: { style: "thin", color: { argb: "FFedf2f9" } },
        //   right: { style: "thin", color: { argb: "FFFFFFFF" } },
        };
        if (colNumber >= 1) { // Right align all columns except the first
            cell.alignment = { horizontal: 'center' };
          }
         
      });
    });
  
  
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Branch Transfer.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };
  useEffect(() => {
    // viewAllBranchTransferAPICall({ branchId: loginResponse?.data?.branchId }, setIsLoading)
    store.dispatch(
      get_branch_transfer_single_view({ singleBranchTransferData: undefined })
    );
    localStorage.removeItem("singleViewById");
  }, []);

  const singleViewFn = (data) => {
    store.dispatch( setFilterActive(window.location.pathname))
    localStorage.setItem("singleViewById", data._id);
    navigate("/userdashboard/inventory/operation/branchtransfer/create");
    // store.dispatch(get_branch_transfer_single_view({singleBranchTransferData:data}))
  };
  useEffect(() => {
    if (FormDataInfo.length !== 0) {
      let loadData =
        window.location.pathname === filtereDataOptions?.filterActive;
      !loadData &&
        BranchTransferFilterAPICall(
          {
            // fromDate: `${FormDataInfo.fromDate} ${getCurrentTime()}`,
            // toDate: `${FormDataInfo.toDate} ${getCurrentTime()}`,
            sourcebranchId: FormDataInfo.fromBranch,
            destinationBranchId: FormDataInfo.destinationBranch,
            status: FormDataInfo.status,
            search: FormDataInfo.search,
            index: currentPage - 1,
          },
          setIsLoading
        );
    }
  }, [FormDataInfo, currentPage]);
  return (
    <div className="global-page-parent-container">
      {/* table */}
      <div className="global-white-bg-container">
        <div
          className="justify-space-between"
          style={{ gap: "0 12px", justifyContent: "flex-end" }}
        >
           <div style={{ display: "flex", alignItems: "center" }}>
              <Tooltip title="Share">
                  <IconButton>
                    <i
                      class="bi bi-share"
                      style={{
                        backgroundColor: "#9797e9",
                        color: "white",
                        fontSize: "medium",
                        padding: "8px",
                        borderRadius: "2px",
                      }}
                    ></i>
                  </IconButton>
                </Tooltip>

                <ReactToPrint
                  trigger={() => (
                    <IconButton
                      className="printer-div"
                      style={{ padding: "1px" }}
                    >
                      <i
                        style={{
                          color: "white",
                          backgroundColor: " #d787e7",
                          fontSize: "medium",
                          padding: "8px",
                          borderRadius: "2px",
                        }}
                        class="bi bi-printer"
                      ></i>
                    </IconButton>
                  )}
                  // content={() => paymentsPrintNew}
                />
                <Tooltip title="Download">
                  <IconButton 
                  onClick={downloadList}
                  >
                    <i
                      class="bi bi-arrow-down-circle"
                      style={{
                        backgroundColor: "rgb(255 118 133 / 69%)",
                        color: "white",
                        fontSize: "medium",
                        padding: "8px",
                        borderRadius: "2px",
                      }}
                    ></i>
                  </IconButton>
                </Tooltip>
                <CategorySearchandFilter
            statusOptions={[
              "Completed",
              "Drafted",
              "INPROGRESS",
              "Cancelled",
              "REQUESTED",
              "APPROVED",
            ]}
            onData={handleDataFromChild}
            isStatus={true}
            isFromBranch={true}
            isDestinationBranch={true}
            isBranch={false}
            isDate={false}
            onBranchSelect={handleBranchSelect}
            onFilterChange={handleFilterChange}
          />
           
          </div>
          {/* <CategorySearchandFilter
            statusOptions={[
              "Completed",
              "Drafted",
              "INPROGRESS",
              "Cancelled",
              "REQUESTED",
              "APPROVED",
            ]}
            onData={handleDataFromChild}
            isStatus={true}
            isFromBranch={true}
            isDestinationBranch={true}
            isBranch={false}
            isDate={false}
            onBranchSelect={handleBranchSelect}
          />
          <button onClick={createBranchTransfer} className="create-button-blue">
            Create
          </button> */}
           <button onClick={createBranchTransfer} className="create-button-blue">
            Create
          </button>
        </div>
        <div className="create-button-blue-container">
          <h3>Branch Transfer</h3>
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            setFormDataInfo={setFormDataInfo}
          />
          {/* <button onClick={createBranchTransfer}  className='create-button-blue'>Create</button> */}
        </div>
        <div className="new-global-table-container">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>From</th>
                <th>To</th>
                <th>Contact Person</th>
                <th>GRN</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                [...Array(10)].map((r, i) => (
                  <tr key={i}>
                    <td colSpan={8}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                ))
              ) : branchTransferList?.list?.length !== 0 ? (
                branchTransferList?.list?.slice(0)?.map((r, i) => {
                  if (r?.status !== "Available") {
                    return (
                      <tr onClick={() => singleViewFn(r)}>
                        <td>{r?.transNo}</td>
                        <td>{r?.sourcename}</td>
                        <td>{r?.destinationname}</td>
                        <td>{r?.contactPerson}</td>
                        <td>{r?.grnNumber}</td>
                        <td className="table-data-box">
                          {r?.status === "Completed" ? (
                            <span className="post"> POST</span>
                          ) : r?.status === "Drafted" ? (
                            <span className="draft"> DRAFT</span>
                          ) : r?.status === "INPROGRESS" ? (
                            <span className="inprogress"> IN PROGRESS</span>
                          ) : r?.status === "Cancelled" ? (
                            <span className="cancel"> CANCEL</span>
                          ) : r?.status === "REQUESTED" ? (
                            <span className="requested"> REQUESTED</span>
                          ) : r?.status === "APPROVED" ? (
                            <span className="granted"> GRANTED</span>
                          ) : (
                            <span>{r?.status}</span>
                          )}
                        </td>
                      </tr>
                    );
                  }
                })
              ) : (
                <tr>
                  <td colSpan={7}>No Data</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
