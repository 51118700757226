import React, { useEffect, useState } from "react";
import { HomeIcon, Download, Printer, HelpCircle, Loader2 } from "lucide-react";
import "./generalAlterationStyle.css";
import "./../billing/generalBillingStyles.css";
import JobCompletionPopup from "./components/JobCompletionPopup";
import { useLocation, useNavigate } from "react-router-dom";
import { singleViewAlterationAPI } from "../GeneralAPI";
import { formatDateTime } from "../../../../../util/utilityFunctions";
import { useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import ErrorModal from "./components/errorDialog/ErrorModal";
import StyledSnackbar from "./components/StyledSnackbar";

// Reusable Table Component
const Table = ({
  headers,
  data,
  keyExtractor,
  subHead,
  tabDetails,
  setIsPopupOpen,
  setSelectedLineData,
}) => (
  <table className="table-alt229">
    <thead>
      <tr>
        {headers.map((header, index) => (
          <th
            colSpan={subHead && header === "Quantity" ? 2 : 0}
            key={index}
            style={{
              textAlign: subHead && header === "Quantity" ? "center" : "",
              backgroundColor:
                subHead && header === "Quantity" ? "#e1e9ff " : "",
            }}
          >
            {header}
          </th>
        ))}
      </tr>
      {subHead && (
        <tr>
          <th colSpan={3} style={{ borderRight: "1px solid #0000ff" }}></th>
          {subHead?.map((header, index) => (
            <th
              className={`sub-head-alt229`}
              style={{
                borderRight: index !== 2 && "1px solid #0000ff",
                borderTop: "1px solid #0000ff",
              }}
              key={index}
            >
              {header}
            </th>
          ))}
          <th colSpan={7}></th>
        </tr>
      )}
    </thead>
    <tbody>
      {data.map((row, rowIndex) => {
        return (
          <tr key={rowIndex}>
            {tabDetails?.no === 1 && (
              <>
                <td>{row?.product || ""}</td>
                <td>{row?.color || ""}</td>
                <td>{row?.qty || ""}</td>
                <td>{row?.orderQuantity || ""}</td>
                <td>{row?.completedQuantity || 0}</td>
                <td>{row?.stitchRate || ""}</td>
                <td>{row?.itemRate || ""}</td>
                <td>{row?.amount || ""}</td>
                <td>{row?.taxPercentage || ""}</td>
                <td>{row?.taxAmount || ""}</td>
                <td>{row?.netAmount || ""}</td>
                <td>
                  <IconButton
                    onClick={() => {
                      setIsPopupOpen(true);
                      setSelectedLineData(row);
                    }}
                  >
                    <i class="bi bi-arrow-right-circle round-circle-job-completion"></i>
                  </IconButton>
                </td>
              </>
            )}
            {tabDetails?.no === 2 && (
              <>
                <td>{row?.date || "d"}</td>
                <td>{row?.paymentMethod || ""}</td>
                <td>{row?.type || ""}</td>
                <td>{row?.amount || ""}</td>
              </>
            )}
            {tabDetails?.no === 3 && (
              <>
                <td>{row?.productName || ""}</td>
                <td>{row?.uom || ""}</td>
                <td>{row?.consumedQuantity || "0"}</td>
                {/* <td>{row?.ref || ""}</td> */}
              </>
            )}
            {tabDetails?.no === 4 && (
              <>
                <td>{row?.productName || ""}</td>
                <td>{row?.customer || ""}</td>
                <td>{row?.cutterName || ""}</td>
                <td>{row?.Quantity || ""}</td>
                {/* <td>{row?.stock || ""}</td> */}
                <td>{row?.consumedQuantity || ""}</td>
                {/* <td>{row?.wastage || ""}</td> */}
                {/* <td>{row?.total || ""}</td> */}
                <td>{row?.commission || ""}</td>
                <td>{row?.date || ""}</td>
              </>
            )}
            {tabDetails?.no === 5 && (
              <>
                <td>{row?.productName || ""}</td>
                <td>{row?.customer || ""}</td>
                <td>{row?.cutterName || ""}</td>
                <td>{row?.Quantity || ""}</td>
                <td>{row?.commission || ""}</td>
                <td>{row?.date || ""}</td>
              </>
            )}
            {tabDetails?.no === 6 && (
              <>
                <td>{row?.accessoryName || ""}</td>
                <td>{row?.customer || ""}</td>
                <td>{row?.Quantity || ""}</td>
                <td>{row?.uom || ""}</td>
              </>
            )}
          </tr>
        );
      })}
    </tbody>
  </table>
);

// Tab Content Components
const ProductsTab = ({ products, setIsPopupOpen, setSelectedLineData }) => (
  <Table
    setSelectedLineData={setSelectedLineData}
    setIsPopupOpen={setIsPopupOpen}
    headers={[
      "Product",
      "Color",
      "Qty",
      "Quantity",
      "Stitch Rate",
      "Item Rate",
      "Amount",
      "VAT%",
      "VAT",
      "Net Amount",
      "Action",
    ]}
    subHead={["Ordered", "Completed"]}
    data={products}
    keyExtractor={(product) => `${product.product}-${product.color}`}
    tabDetails={{ no: 1, name: "products" }}
  />
);

const PaymentsTab = ({ payments }) => (
  <Table
    headers={["Date", "Payment Method", "Type", "Amount"]}
    data={payments}
    tabDetails={{ no: 2, name: "payment" }}
  />
);

const ConsumptionTab = ({ consumption }) => (
  <Table
    headers={["Product", "UOM", "Consumed Qty"]}
    data={consumption}
    tabDetails={{ no: 3, name: "consumption" }}
  />
);

const CutterDetailsTab = ({ cutterDetails }) => (
  <Table
    headers={[
      "Product",
      "Customer",
      "Cutter",
      "Qty",
      // "Stock",
      "Consumed Qty",
      // "Wastage",
      // "Total",
      "Commission",
      "Date",
    ]}
    data={cutterDetails}
    tabDetails={{ no: 4, name: "cutterDetails" }}
  />
);

const TailorDetailsTab = ({ tailorDetails }) => (
  <Table
    headers={["Product", "Customer", "Tailor", "Qty", "Commission", "Date"]}
    data={tailorDetails}
    tabDetails={{ no: 5, name: "tailorDetails" }}
  />
);

const AccessoriesDetailsTab = ({ accessoriesDetails }) => (
  <Table
    headers={["Product", "Customer", "Qty", "UOM"]}
    data={accessoriesDetails}
    tabDetails={{ no: 6, name: "accessoriesDetails" }}
  />
);

const FullAlterationOrderUI = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const singleViewId = queryParams.get("id");

  const [singleViewData, setSingleViewData] = useState();
  const [loading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const loginResponse = useSelector((state) => state.loginResponseSlice.value);
  // console.log(singleViewData);

  const [activeTab, setActiveTab] = useState("Products");
  const [selectedLineData, setSelectedLineData] = useState(null);

  // Sample data for each tab
  const [renderTableData, setRenderTableData] = useState({
    Products: [],
    Payments: [],
    Consumption: [],
    "Cutter Details": [],
    "Tailor Details": [],
    "Accessories Details": [],
  });

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const renderTabContent = () => {
    switch (activeTab) {
      case "Products":
        return (
          <ProductsTab
            products={renderTableData.Products}
            setIsPopupOpen={setIsPopupOpen}
            setSelectedLineData={setSelectedLineData}
          />
        );
      case "Payments":
        return <PaymentsTab payments={renderTableData.Payments} />;
      case "Consumption":
        return <ConsumptionTab consumption={renderTableData.Consumption} />;
      case "Cutter Details":
        return (
          <CutterDetailsTab cutterDetails={renderTableData["Cutter Details"]} />
        );
      case "Tailor Details":
        return (
          <TailorDetailsTab tailorDetails={renderTableData["Tailor Details"]} />
        );
      case "Accessories Details":
        return (
          <AccessoriesDetailsTab
            accessoriesDetails={renderTableData["Accessories Details"]}
          />
        );

      default:
        return null;
    }
  };

  //initial api calls
  useEffect(() => {
    setIsLoading(true);
    singleViewAlterationAPI({
      body: { _id: singleViewId },
      setSingleViewData,
      setIsLoading,
      setError,
      setRenderTableData,
    });
  }, []);

  const handleBack = () => {
    // Navigate to home
    navigate("/userdashboard/pos/general/alteration");
  };
  if (!singleViewId) {
    navigate("/userdashboard/pos/general/alteration");
  }
  console.log(selectedLineData);
  

  if (loading) {
    return (
      <div className="in-spn-loader-container">
        <Loader2 className="in-spn-loader-icon" />
        <p className="in-spn-loader-text">Loading...</p>
      </div>
    );
  }

  // if (error) {
  //   return (
  //     <div style={{ padding: "1.5em" }}>
  //       <MessageComponent type="error" message="An error occurred!" />
  //       <button className="custom-back-button" onClick={handleBack}>
  //         Back
  //       </button>
  //     </div>
  //   );
  // }

  if (error) {
    return <ErrorModal back={handleBack} />;
  }
  return (
    <div className="container-alt229 order-details">
      <header className="header-alt229">
        <div className="left-icons-alt229 ">
          <HomeIcon onClick={() => handleBack()} className="icon icon-alt229" />
          {/* <ChevronLeftIcon
            className="icon icon-alt229"
            onClick={() => handleBack()}
          /> */}
          <span className="orders-text">ALTERATION</span>
          {/* <button
            className="print-button-alt229"
            onClick={() => setIsPopupOpen(true)}
          >
            <PrinterIcon className="icon-alt229" size={"16px"} />
            <span>Print</span>
          </button> */}
        </div>
        <div className="nav-right">
          <HelpCircle className="icon help" />
          <Printer className="icon print" />
          <Download className="icon download" />
        </div>
      </header>

      <div className="order-info-alt229">
        <div className="ord-top-cntr-alt229">
          <h2 className="order-number-alt229">{singleViewData?.orderNo}</h2>
          <span
            className={`booking-badge-alt229 ${
              singleViewData?.status === "pending"
                ? "pending-alt"
                : singleViewData?.status === "completed"
                ? "completed-alt"
                : singleViewData?.status === "booking"
                ? "booking-alt"
                : singleViewData?.status === "cancel"
                ? "cancel-alt"
                : ""
            } `}
            style={{ textTransform: "uppercase" }}
          >
            {singleViewData?.status}
          </span>
        </div>
        <div className="order-details-alt229">
          <div className="left-details-alt229">
            <p>
              <strong>Date:</strong>
              {formatDateTime(singleViewData?.date) || "---"}
            </p>
            <p>
              <strong>Delivery Date:</strong>{" "}
              {formatDateTime(singleViewData?.deliveryDate) || "---"}
            </p>
            <p>
              <strong>Branch:</strong> {singleViewData?.branch || "---"}
            </p>
            <p>
              <strong>Shirt NO:</strong> {singleViewData?.shift || "---"}
            </p>
            <p>
              <strong>Employee:</strong> {singleViewData?.employee || "---"}
            </p>
          </div>
          <div className="right-details-alt229">
            <p>
              <strong>Customer:</strong> {singleViewData?.customerName || "---"}
            </p>
            <p>
              <strong>Mobile:</strong> {singleViewData?.contactNumber || "---"}
            </p>
            <p>
              <strong>Order Type:</strong> Alteration
            </p>
            {/* <p>
              <strong>RACK:</strong> RACK12
            </p> */}
          </div>
        </div>
      </div>

      <div className="tabs-pos">
        {Object.keys(renderTableData).map((tab) => (
          <button
            key={tab}
            className={`tab-alt229 ${activeTab === tab ? "active-tabs" : ""}`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>

      <div className="tab-content-alt229">{renderTabContent()}</div>

      {activeTab === "Products" && (
        <div className="summary">
          <div className="summary-item">
            <strong>Total Before VAT</strong>
            <span>AED {singleViewData?.totalBeforeVat}</span>
          </div>
          <ul style={{ listStyle: "none" }}>
            {singleViewData?.totalTaxes.map((tax, index) => (
              <li key={index}>
                <div className="summary-item">
                  <span>{tax?.taxName}</span>
                  <span>AED {tax?.amount}</span>
                </div>
              </li>
            ))}
          </ul>
          <div className="summary-item">
            <strong>Discount</strong>
            <span>AED {singleViewData?.discount}</span>
          </div>
          <div className="summary-item">
            <strong>Delivery Charge</strong>
            <span>AED {singleViewData?.deliveryCharge}</span>
          </div>
          <div className="summary-item total-gsv">
            <span>Total Inc VAT</span>
            <span>AED {0.0}</span>
          </div>
          <div className="summary-item amount-due">
            <span>Amount Due</span>
            <span>AED {singleViewData?.amountDue}</span>
          </div>
        </div>
      )}
      <JobCompletionPopup
        isOpen={isPopupOpen}
        onClose={() => {
          setIsPopupOpen(false);
          setSelectedLineData(null);
        }}
        data={loginResponse}
        singleViewId={singleViewId}
        selectedLineData={selectedLineData}
      />
      <StyledSnackbar />
    </div>
  );
};

export default FullAlterationOrderUI;
