import { IconButton, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ReactToPrint from 'react-to-print'
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import HomeIcon from '@mui/icons-material/Home';
import { switch_to_pos_screen } from '../../PosNew/PosNewSlice'
import CustomPagination from '../../../../Single Components/CustomPagination'
import { listWorkOrderPaginationAPI } from './workOrderNewAPI'
// import { listWorkOrderPagination, listWorkOrderPaginationAPI } from '../GeneralAPI'
import TableLoading from "../../../../Single Components/TableLoding"

export const WorkOrderNew = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const workOrder = useSelector((state) => state.workOrderNewList?.workorderpagenationData);

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [FormDataInfo, setFormDataInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const totalPages = workOrder?.pages;
  const decimalPosition=localStorage.getItem("decimalPosition")

  const filtereDataOptions = useSelector((state) => state.listCategory);

  console.log(workOrder);

  const handleDataFromChild = (data) => {
    setFormDataInfo(data)

  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(); // Returns date in format: MM/DD/YYYY or based on locale settings
  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch);

  };
  const homeBtnClickFn = () => {
    navigate("/userdashboard/dashboardhome")
    dispatch(
      switch_to_pos_screen({ posScreenState: false })
    );
  }

  const handleClickRow = (row) => {
    navigate("/userdashboard/pos/general/workorder/singleview", { state: { singleViewId: row._id } });
  };

  useEffect(() => {
    if (FormDataInfo.length !== 0) {
      let loadData = window.location.pathname === filtereDataOptions?.filterActive
      !loadData &&
        listWorkOrderPaginationAPI({
          branchList: FormDataInfo?.branchList,
          search: FormDataInfo?.search,
          index: currentPage - 1,
        }, setIsLoading)
    }
  }, [FormDataInfo, currentPage]);
  return (
    <div className='pos-conatiner' style={{ backgroundColor: "unset", padding: "0" }}>
      <div className="new-order-top-container" style={{ padding: "0", backgroundColor: "white", paddingInline: "10px" }}>

        <div className='new-order-top-left' >
          {/* <div className='new-order-top-left-home' onClick={homeBtnClickFn}>
             <HomeIcon/> 
           </div> */}
          <p className='new-order-top-left-p'>WORK ORDERS</p>
        </div>

        {/* <h5 style={{ margin: "0" ,cursor:"pointer"}}onClick={handleback}>SHIFT</h5> */}

        <div className="justify-center" style={{ gap: "10px", width: "70%", justifyContent: "flex-end" }}>
          <ReactToPrint
            trigger={() => (
              <IconButton className="printer-div" style={{ color: "#fff", backgroundColor: "#002995", borderRadius: "0" }}>
                <i class="bi bi-printer"></i>
              </IconButton>
            )}
          //   content={() => paymentsPrint}
          />
          <Tooltip title="Download">
            <IconButton style={{ backgroundColor: "#ffd2d2", color: "#002995", borderRadius: "0" }}>
              <i class="bi bi-arrow-down-circle"></i>
            </IconButton>
          </Tooltip>
          <CategorySearchandFilter
            statusOptions={[]}
            onData={handleDataFromChild}
            // isCreatedBy={}
            // isStatus={}
            onBranchSelect={handleBranchSelect}
            isSearch={true}
            isDate={false}
          //  isPos={true}
          />

        </div>
      </div>
      {/* <hr className='pos-hr' /> */}

      <div className="flex-column table-div-container" >
        <div className='pagi-div'>
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            setFormDataInfo={setFormDataInfo}
          />
        </div>
        <div className="new-global-table-container pos-table" style={{ paddingTop: "0 42px", maxHeight: "calc(100vh - 196px)" }}>

          <table>
            <thead>
              <tr>
                <th>DATE</th>
                <th>ORDER NO</th>
                <th>SHIFT</th>
                <th>EMPLOYEE</th>
                <th>CUSTOMER</th>
                <th>MOBILE</th>
                <th>ORDER AMOUNT</th>
                <th>ORDER DUE</th>
                <th>STATUS</th>

              </tr>
            </thead>

            {isLoading ? (
              <TableLoading colSpan={9} rowcount={10} />
            ) : (
              <tbody>

                {workOrder?.list?.length !== 0 ?
                  workOrder?.list?.map((r, i) => (
                    <tr onClick={() => handleClickRow(r)} key={i} style={{ backgroundColor: i % 2 === 0 ? "white" : "#e9e7e7" }}>
                      <td>{formatDate(r?.date)}</td>
                      <td>{r?.orderId}</td>
                      <td>{r?.shiftNo}</td>
                      <td>{r?.employees}</td>
                      <td>{r?.customer}</td>
                      <td>{r?.mobile}</td>
                      <td>{r?.orderAmount?.toFixed(decimalPosition)}</td>
                      <td>{r?.amountdue?.toFixed(decimalPosition)}</td>
                      <td>{r?.status}</td>

                    </tr>
                  )) :
                  <tr>
                    <td colSpan={10}>NO DATA</td>
                  </tr>
                }
              </tbody>
            )}

          </table>
        </div>
      </div>
    </div>
  )
}
