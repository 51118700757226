import { createSlice } from "@reduxjs/toolkit";
export const PromotionSlice=createSlice({
    name:'PromotionSlice',
    initialState:{
        promotionAndOffer:undefined,
        singleViewPromotion:undefined,
    },
    reducers:{
       
        get_promotion_offer_list:(state,action)=>{
            state.promotionAndOffer=action.payload
        },
        get_Promotion_single_view:(state,action)=>{
            state.singleViewPromotion=action.payload
        },
       
      
    }
})
export const {get_order_list,
   
    get_Promotion_single_view,
    get_promotion_offer_list,
    }=PromotionSlice.actions
export default PromotionSlice.reducer