import axios from "axios"
import store from "../../../../Redux/store"
import { HEADERS } from "../../../../API/UrlAndPaths"
import { shift_report_list, shift_report_single_list } from "./ShiftReportSlice"
import { errorSnackMsg } from "../../../Custom Hooks/SnackBarUtilities"


export const ShiftReportAPIcall=async(body,setIsLoading)=>{
    setIsLoading!==undefined&&setIsLoading(false)
    await axios
    .post("sales/dayWiseShiftReport",body,{
        headers: {
          'Content-Type': 'application/json',
        },
        
      })
    .then((res)=>{
        if(res.status===200){
            store.dispatch(shift_report_list({shiftReportListData:res.data}))
            setIsLoading!==undefined&&setIsLoading(false)
        }
    })
    .catch((err)=>{
        store.dispatch(shift_report_list({shiftReportListData:undefined}))
        setIsLoading!==undefined&&setIsLoading(false)
     errorSnackMsg(err.response.data || "Network Failed")

        console.error(err.response);
    })
}
export const ShiftReportSingleViewAPIcall=async(body)=>{
    await axios
    .post("sales/dayWiseShiftReportById",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(shift_report_single_list({shiftReportSingleListData:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(shift_report_single_list({shiftReportSingleListData:undefined}))
        console.error(err.response);
    })
}
