//decode jwt token 
export function decodeJWTFromLocalStorage() {
    // Get the token from local storage
    const token = localStorage.getItem('userToken');
  
    if (!token) {
      console.error('No JWT token found in local storage');
      return null;
    }
  
    try {
      // Split the token into its three parts
      const [headerEncoded, payloadEncoded, signature] = token.split('.');
  
      // Decode the header and payload
      const header = JSON.parse(atob(headerEncoded));
      const payload = JSON.parse(atob(payloadEncoded));
  
      // Return the decoded token
      return {
        header,
        payload,
        signature
      };
    } catch (error) {
      console.error('Error decoding JWT token:', error);
      return null;
    }
  }