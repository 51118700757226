import React from 'react'
import { useSelector } from 'react-redux'

export const WODeliveryDetails = () => {
  const singleList = useSelector((state)=>state.workOrderNewList?.workorderDeliveryData)
  const hasData = singleList?.list?.length > 0 && singleList?.list

  return (
    <>
    <div className="new-global-table-container work-order-tab-table" style={{ paddingTop: "0 42px",maxHeight:"20vh" }}>
    <table>
     <thead>
         <tr>
             <th>Product</th>
             <th>Color</th>
             <th>Type</th>
             <th>Model</th>
             <th>Customer</th>
             <th>Date</th>
             <th>Delivery Qty</th>
          </tr>
     </thead>
     <tbody>
     {
       hasData ?  singleList?.list?.map((r,i)=>(
           <tr>
             <td>{r?.productName}</td>
             <td>{r?.colorName}</td>
             <td>{r?.woType}</td>
             <td>{r?.modelType}</td>
             <td>{r?.customer}</td>
             <td>{r?.date}</td>
             <td>{r?.Quantity}</td>
         </tr>
         )):
         (
          <tr>
            <td colSpan={7}>NO DATA</td>
          </tr>
         )
       }
    

     </tbody>
       <tfoot>
                    <tr className="total-row">
                        <td>Total</td>
                        <td colSpan="5"></td>
                        <td> 
                          {hasData
                  ? singleList.list.reduce((total, r) => total + (r.Quantity || 0), 0)
                  : 0}</td>
                       
                    </tr>
          </tfoot>
       
        
      </table>
    </div>
    
   
     </>
  )
}
