import React from 'react'
import "../Return/returnDialog.css"
import { open_dialog } from '../PosNewSlice'
import { useDispatch } from 'react-redux';

export const Return = () => {
    const dispatch = useDispatch();
    const demo=[
        {customer:"Nihad",mobile:"1111",lastOrder:""},
        {customer:"Nihad",mobile:"1111",lastOrder:""},
        {customer:"Nihad",mobile:"1111",lastOrder:""},
      ]
    const closeDialog=()=>{
        dispatch(open_dialog({openDialogState:undefined}))
    }
  return (
    <div className='return-dialog-container'>
       <div className='return-dialog-top-container'>
          <p className='return-p'>Return</p>
          <div className='return-dialog-top-right-container'>
            <div className='return-dialog-top-right-toggle'>
                <p>Delivery Return</p>
                <input
                  className="toggle-checkbox"
                  type={"checkbox"}
                />
            </div>
            <button  className='pos-status-blue-btn'>Return</button>
            <i onClick={closeDialog} class="bi bi-x-circle"></i>
          </div>
       </div>
       <hr className='pos-hr' />
       <div className='return-dialog-bottom-container'>
          <div className='return-dialog-bottom-one'>
             <p>BIL/229</p>
             <div className='return-dialog-bottom-one-radio'>
                 <div className='return-dialog-single-radio'>
                    <input type="radio" name="return-radio" id="createReadymade" />
                    <label htmlFor="createReadymade">Create Redaymade Thob</label>
                 </div>
                 <div className='return-dialog-single-radio'>
                    <input type="radio" name="return-radio" id="scrapmoves"/>
                    <label htmlFor="scrapmoves">Scrap Move</label>
                 </div>
             </div>
          </div>
          <div className='return-dialog-bottom-two'>
             <div className='return-dialog-bottom-left'>
                <div className='return-dialog-bottom-left-single'>
                    <p className='return-dialog-bottom-left-p'>Date</p>
                    <p>10/02/2024 10:30AM</p>
                </div>
                <div className='return-dialog-bottom-left-single'>
                    <p className='return-dialog-bottom-left-p'>Delivery Date</p>
                    <p>10/02/2024 10:30AM</p>
                </div>
                <div className='return-dialog-bottom-left-single'>
                    <p className='return-dialog-bottom-left-p'>Branch</p>
                    <p>Branch 1</p>
                </div>
                <div className='return-dialog-bottom-left-single'>
                    <p className='return-dialog-bottom-left-p'>Shift No</p>
                    <p>01</p>
                </div>
                <div className='return-dialog-bottom-left-single'>
                    <p className='return-dialog-bottom-left-p'>Employee</p>
                    <p>01</p>
                </div>
             </div>
             <div className='return-dialog-bottom-left'>
                <div className='return-dialog-bottom-left-single'>
                    <p className='return-dialog-bottom-left-p'>Customer</p>
                    <p className='return-dialog-bottom-right-p'>10/02/2024 10:30AM</p>
                </div>
                <div className='return-dialog-bottom-left-single'>
                    <p className='return-dialog-bottom-left-p'>Mobile</p>
                    <p className='return-dialog-bottom-right-p'>10/02/2024 10:30AM</p>
                </div>
                <div className='return-dialog-bottom-left-single'>
                    <p className='return-dialog-bottom-left-p'>Order Type</p>
                    <p className='return-dialog-bottom-right-p'>Work Order</p>
                </div>
                <div className='return-dialog-bottom-left-single'>
                    <p className='return-dialog-bottom-left-p'>Total Order Qty</p>
                    <p className='return-dialog-bottom-right-p'>01</p>
                </div>
                <div className='return-dialog-bottom-left-single'>
                    <p className='return-dialog-bottom-left-p'>Total Return Qty</p>
                    <p className='return-dialog-bottom-right-p'>01</p>
                </div>
             </div>
          </div>
          <div className="new-global-table-container return-table" style={{ paddingTop: "0 42px" }}>
       <table>
        <thead>
            <tr>
                <th>Product</th>
                <th>Color</th>
                <th>Type</th>
                <th>Model</th>
                <th>Customer</th>
                <th>Qty</th>
                <th>Return Qty</th>
                <th>Stitch Rate</th>
                <th>Item Rate</th>
                <th>Amount</th>
                <th>Tax</th>
                <th>Net Amount</th>
                <th>
                    <input type="checkbox" />
                </th>
            </tr>
        </thead>
        <tbody>
        {
            demo?.map((r,i)=>(
              <tr key={i} >
                <td>{r?.customer}</td>
                <td>{r?.customer}</td>
                <td>{r?.customer}</td>
                <td>{r?.customer}</td>
                <td>{r?.customer}</td>
                <td>{r?.customer}</td>
                <td><input type="text" /></td>
                <td>{r?.customer}</td>
                <td>{r?.customer}</td>
                <td>{r?.customer}</td>
                <td>{r?.customer}</td>
                <td>{r?.customer}</td>
                <td>
                   <input type="checkbox" />
                </td>
            </tr>
            ))
          }
            <tr style={{backgroundColor:"#f5f4f4"}}>
                <td style={{border:"none",fontSize:"1rem",fontWeight:600}}>Total</td>
                <td style={{border:"none"}}></td>
                <td style={{border:"none"}}></td>
                <td style={{border:"none"}}></td>
                <td style={{border:"none"}}></td>
                <td style={{border:"none"}}>10</td>
                <td style={{border:"none"}}>10</td>
                <td style={{border:"none"}}></td>
                <td style={{border:"none"}}></td>
                <td style={{border:"none"}}>10</td>
                <td style={{border:"none"}}>10</td>
                <td style={{border:"none"}}>10</td>
                <td style={{border:"none"}}></td>
            </tr>
        </tbody>
          
           
         </table>
       </div>
       </div>
    </div>
  )
}
