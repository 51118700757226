import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BranchReceiptFilterAPICall, viewAllBranchReceiptAPICall } from "./BranchReceiptAPI";
import { useState } from "react";
import { get_branch_receipt_single_view } from "./BranchReceiptSlice";
import store from "../../../../../Redux/store";
import CategorySearchandFilter from "../../../../Single Components/CategorySearchandFilter";
import CustomPagination from "../../../../Single Components/CustomPagination";
import { getCurrentTime } from "../../../../../Js/Date";
import axios from "axios";
import { setFilterActive } from "../../../../Single Components/listSlice/filterCategorySlice";
import { IconButton, Tooltip } from "@mui/material";
import ReactToPrint from "react-to-print";
import ExcelJS from "exceljs"

export const BranchReceipt = () => {
  let navigate = useNavigate();
  // login response
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);
  const branchReceiptList = useSelector(
    (state) => state.BranchReceiptSlice.value
  );
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredData, setFilteredData] = useState({});
  const totalPages = branchReceiptList?.pages;
  const filtereDataOptions = useSelector((state) => state.listCategory);
  
  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };
  const handlePageChange = (page) => {

    setCurrentPage(page);
  };
  const handleFilterChange = (updatedItems) => {
    setFilteredData(updatedItems);
  };
  const formatFilterInfo = (filters) => {
    const parts = [];

    // if (filters?.toDate && filters.fromDate) {
    //   parts.push(`As of ${filters.fromDate} to ${filters?.toDate}`);
    // }
    if (filters?.fromBranch) {
      parts.push(
        `From Branch: ${filters?.fromBranch}`
      );
    }
    if (filters?.destinationBranch) {
      parts.push(
        `Destination Branch: ${filters?.destinationBranch}`
      );
    }
    if (filters?.status) {
      parts.push(`Status: ${filters?.status}`);
    }
    // if (filters?.location) {
    //   parts.push(`location: ${filters?.location}`);
    // }
    return parts; // Return the array directly
  };
  const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Branch Receipt");
    sheet.properties.defaultRowHeight = 20;

    // Add header section
    const titleRow = sheet.addRow(["Branch Receipt"]);
    titleRow.font = { size: 15, bold: true };
    sheet.mergeCells("A1:H1");
    titleRow.getCell(1).alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    // Add additional header information
    const filterParts = formatFilterInfo(filteredData) || [];

    // Ensure filterParts is an array and iterate over it
    if (Array.isArray(filterParts)) {
      filterParts.forEach((part, index) => {
        const row = sheet.addRow([part]);
        row.font = { size: 11, bold: true };
        sheet.mergeCells(`A${index + 2}:H${index + 2}`);
        row.getCell(1).alignment = { vertical: "middle", horizontal: "center" };
      });
    }

    // Add an empty row between header and table
    sheet.addRow([]);

    // Header row styling
    const headerRow = sheet.addRow([
      "Date",
      "Name",
      "From",
      "To",
      "Contact Person",
      "GRN",
      "Create By",
      "Status",
    ]);
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFedf2f9" }, // Use full hex code with "FF" prefix for better compatibility
    };
    const headerFont = {
      size: 12,
      bold: true,
      color: { argb: "FF000000" }, // White font color
    };

    headerRow.eachCell((cell, colNumber) => {
      cell.fill = headerFill;
      cell.font = headerFont;
      cell.alignment = { vertical: "middle", horizontal: "center" }; // Ensure header text is centered
    });

    sheet.columns = [
      { header: "Date", key: "date", width: 20 },
      { header: "Name", key: "referenceIn", width: 20 },
      { header: "From", key: "sourcename", width: 25 },
      { header: "To", key: "destinationname", width: 25 },
      { header: "Contact Person", key: "contactPerson", width: 25 },
      { header: "GRN", key: "referenceName", width: 25 },
      { header: "Create By", key: "CREATEDBY", width: 25 },
      { header: "Branch Transfer", key: "status", width: 15 },
    ];

    branchReceiptList?.list?.forEach((item, index) => {
      const row = sheet.addRow({
        date: item?.date,
        referenceIn: item?.referenceIn,
        sourcename: item.sourcename,
        destinationname: item?.destinationname,
        contactPerson: item?.contactPerson,
        referenceName: item?.referenceName,
        CREATEDBY: item?.CREATEDBY,
        status: item?.status,
      });

      const rowFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFFFF" }, // Use full hex code with "FF" prefix for better compatibility
      };

      row.eachCell((cell, colNumber) => {
        cell.fill = rowFill;
        cell.border = {
          //   top: { style: "thin", color: { argb: "FF000000" } },
          //   left: { style: "thin", color: { argb: "FF000000" } },
          bottom: { style: "thin", color: { argb: "FFedf2f9" } },
          //   right: { style: "thin", color: { argb: "FFFFFFFF" } },
        };
        if (colNumber >= 1) {
          // Right align all columns except the first
          cell.alignment = { horizontal: "center" };
        }
      });
    });

    // Add row for totals
    // const totalRow = sheet.addRow([
    //   "Total",
    //   "",
    //   `${symbol} ${SingleListData?.sumOfCurrent.toFixed(2)}`,
    //   `${symbol} ${SingleListData?.sumOf30?.toFixed(2)}`,
    //   `${symbol} ${SingleListData?.sumOf60?.toFixed(2)}`,
    //   `${symbol} ${SingleListData?.sumOf90?.toFixed(2)}`,
    //   `${symbol} ${SingleListData?.sumOf120?.toFixed(2)}`,
    //   `${symbol} ${SingleListData?.sumOfAbove120?.toFixed(2)}`,
    //   `${symbol} ${SingleListData?.sumOfTotal?.toFixed(2)}`,
    // ]);

    // totalRow.eachCell((cell,colNumber) => {
    //   cell.font = { bold: true, color: { argb: "FF000000" } };
    //   cell.fill = headerFill;
    //   cell.border = {
    //     top: { style: "thin", color: { argb: "FFFFFFFF" } },
    //     left: { style: "thin", color: { argb: "FFFFFFFF" } },
    //     bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
    //     right: { style: "thin", color: { argb: "FFFFFFFF" } },
    //   };
    //   if (colNumber > 1) { // Right align all columns except the first
    //     cell.alignment = { horizontal: 'right' };
    //   }
    // });

    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Branch Receipt";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };
  useEffect(() => {
    localStorage.removeItem("singleViewById");
    // viewAllBranchReceiptAPICall(
    //   { branchId: loginResponse?.data?.branchPk },
    //   setIsLoading
    // );
    store.dispatch(
      get_branch_receipt_single_view({ singleBranchReceiptData: undefined })
    );
  }, []);

  const singleViewFn = (data) => {
    store.dispatch( setFilterActive(window.location.pathname))
    localStorage.setItem("singleViewById", data._id);
    navigate("/userdashboard/inventory/operation/branchreceipt/create");
    // store.dispatch(get_branch_receipt_single_view({singleBranchReceiptData:data}))
  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };
  useEffect(() => {
   
    if(FormDataInfo.length!==0){ 
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
      !loadData &&  BranchReceiptFilterAPICall({
      // fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} ${getCurrentTime()}`,
      // toDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} ${getCurrentTime()}`,
    sourcebranchId: FormDataInfo.fromBranch,
    destinationBranchId: FormDataInfo.destinationBranch,
    status: FormDataInfo.status,
    search: FormDataInfo.search,
    index: currentPage-1,
  },setIsLoading);
}

}, [FormDataInfo, currentPage]);

  return (
    <div className="global-page-parent-container">
      {/* table */}
      <div className="global-white-bg-container">
      <div className="justify-space-between"
          style={{ gap: "0 12px", justifyContent: "flex-end" }}
        >
           <div style={{ display: "flex", alignItems: "center" }}>
              <Tooltip title="Share">
                  <IconButton>
                    <i
                      class="bi bi-share"
                      style={{
                        backgroundColor: "#9797e9",
                        color: "white",
                        fontSize: "medium",
                        padding: "8px",
                        borderRadius: "2px",
                      }}
                    ></i>
                  </IconButton>
                </Tooltip>

                <ReactToPrint
                  trigger={() => (
                    <IconButton
                      className="printer-div"
                      style={{ padding: "1px" }}
                    >
                      <i
                        style={{
                          color: "white",
                          backgroundColor: " #d787e7",
                          fontSize: "medium",
                          padding: "8px",
                          borderRadius: "2px",
                        }}
                        class="bi bi-printer"
                      ></i>
                    </IconButton>
                  )}
                  // content={() => paymentsPrintNew}
                />
                <Tooltip title="Download">
                  <IconButton 
                  onClick={downloadList}
                  >
                    <i
                      class="bi bi-arrow-down-circle"
                      style={{
                        backgroundColor: "rgb(255 118 133 / 69%)",
                        color: "white",
                        fontSize: "medium",
                        padding: "8px",
                        borderRadius: "2px",
                      }}
                    ></i>
                  </IconButton>
                </Tooltip>
                <CategorySearchandFilter
            statusOptions={['Completed', 'Drafted','Available']}
            onData={handleDataFromChild}
            isStatus={true}
            isFromBranch={true}
            isDestinationBranch={true}
            isBranch={false}
            isDate={false}
            onBranchSelect={handleBranchSelect}
            onFilterChange={handleFilterChange}
          />
           
          </div>
          {/* <CategorySearchandFilter
            statusOptions={['Completed', 'Drafted','Available']}
            onData={handleDataFromChild}
            isStatus={true}
            isFromBranch={true}
            isDestinationBranch={true}
            isBranch={false}
            isDate={false}
            onBranchSelect={handleBranchSelect}
          /> */}
          {/* <button onClick={createBranchTransfer} className='create-button-blue'>Create</button> */}

        </div>
        <div className="create-button-blue-container">
          <h3>Branch Receipt</h3>
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            setFormDataInfo={setFormDataInfo}
          />
          {/* <button onClick={createBranchReceipt}  className='create-button-blue'>Create</button> */}
        </div>
        <div className="new-global-table-container">
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>From</th>
                <th>To</th>
                <th>Contact Person</th>
                <th>GRN</th>
                <th>Created By</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {branchReceiptList?.list?.length !== 0 ? (
                branchReceiptList?.list
                  ?.slice(0)
                 
                  ?.map((r, i) => (
                    <tr onClick={() => singleViewFn(r)}>
                      <td>{r?.date}</td>
                      <td>{r?.referenceIn}</td>
                      <td>{r?.sourceLocation}</td>
                      <td>{r?.destinationLocation}</td>
                      <td>{r?.contactPerson}</td>
                      <td>{r?.grnNumber}</td>
                      <td>{r.CREATEDBY}</td>
                      <td className="table-data-box">
                        {r?.status === "Completed" ? (
                          <span className="post"> POST</span>
                        ) : r?.status === "Drafted" ? (
                          <span className="draft"> DRAFT</span>
                        ) : r?.status==="Available"? (
                          <span className='available'> AVAILABLE</span>
                        ): (
                          <span>{r?.status}</span>
                        )}
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan={8}>No Data</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
