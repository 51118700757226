import axios from "axios"
import { HEADERS } from "../../../../../API/UrlAndPaths"
import store from "../../../../../Redux/store"
import { setworkorderAccessories, setworkorderConsumption, setworkorderCutter, setworkorderDelivery, setworkorderMeasurement, setworkorderpagenation, setworkorderPayments, setworkorderSingleView, setworkorderTailor } from "./workOrderNewSlice"
import { errorSnackMsg } from "../../../../Custom Hooks/SnackBarUtilities";


// export const workOrderGeneralList=()=>{
//     axios.post("workorder/viewusersideworkorder",body,HEADERS)
//     .then((res)=>{
//         store.dispatch(get_workorder_full_list(res.data))
//     })
//     .catch((err)=>{
//         store.dispatch(get_workorder_full_list(undefined))
//     })
// }
// workorderpagenation
export const listWorkOrderPaginationAPI = async (data,setIsLoading) => {
  setIsLoading!==undefined&&setIsLoading(true)

    try {
      const res = await axios.post(
        "workorder/workorderpagenation",
        data,
        HEADERS
      );
      if (res.status === 200) {
        store.dispatch(setworkorderpagenation(res.data));
        setIsLoading!==undefined&&setIsLoading(false)

      }
    } catch (error) {
      store.dispatch(setworkorderpagenation(null));
      errorSnackMsg(error.response.data || "Network Failed")
      setIsLoading!==undefined&&setIsLoading(false)

    }
  };
  
  // workorderpagenation single view
  export const listWorkOrderSingleViewAPI = async (data) => {
    try {
      const res = await axios.post(
        "workorder/workorderitemdetailsview",
        data,
        HEADERS
      );
      if (res.status === 200) {
        console.log(res.data);
        store.dispatch(setworkorderSingleView(res.data));
      }
    } catch (error) {
      store.dispatch(setworkorderSingleView(null));
      errorSnackMsg(error.response.data || "Network Failed")
    }
  };
   // workOrder singleView Payment
   export const listWorkOrderPaymentAPI = async (data) => {
    try {
      const res = await axios.post(
        "workorder/workorderpaymentdetailsview",
        data,
        HEADERS
      );
      if (res.status === 200) {
        console.log(res.data);
        store.dispatch(setworkorderPayments(res.data));
      }
    } catch (error) {
      store.dispatch(setworkorderPayments(null));
      
    }
  };
   // workOrder singleView measurement
   export const listWorkOrderMeasurementAPI = async (data) => {
    try {
      const res = await axios.post(
        "workorder/workorderemeasurementdetailsview",
        data,
        HEADERS
      );
      if (res.status === 200) {
        console.log(res.data);
        store.dispatch(setworkorderMeasurement(res.data));
      }
    } catch (error) {
      store.dispatch(setworkorderMeasurement(null));
    }
  };
   // workOrder singleView Consumption
   export const listWorkOrderConsumptionAPI = async (data) => {
    try {
      const res = await axios.post(
        "workorder/workorderconsumptiondetailsview",
        data,
        HEADERS
      );
      if (res.status === 200) {
        console.log(res.data);
        store.dispatch(setworkorderConsumption(res.data));
      }
    } catch (error) {
      store.dispatch(setworkorderConsumption(null));
    }
  };
   // workOrder singleView Cutter
   export const listWorkOrderCutterAPI = async (data) => {
    try {
      const res = await axios.post(
        "workorder/workordercutterdetailsview",
        data,
        HEADERS
      );
      if (res.status === 200) {
        console.log(res.data);
        store.dispatch(setworkorderCutter(res.data));
      }
    } catch (error) {
      store.dispatch(setworkorderCutter(null));
    }
  };
   // workOrder singleView Tailor
   export const listWorkOrderTailorAPI = async (data) => {
    try {
      const res = await axios.post(
        "workorder/workordertailordetailsview",
        data,
        HEADERS
      );
      if (res.status === 200) {
        console.log(res.data);
        store.dispatch(setworkorderTailor(res.data));
      }
    } catch (error) {
      store.dispatch(setworkorderTailor(null));
    }
  };
   // workOrder singleView Accessories
   export const listWorkOrderAccessoriesAPI = async (data) => {
    try {
      const res = await axios.post(
        "workorder/workorderaccessoriesdetailsview",
        data,
        HEADERS
      );
      if (res.status === 200) {
        console.log(res.data);
        store.dispatch(setworkorderAccessories(res.data));
      }
    } catch (error) {
      store.dispatch(setworkorderAccessories(null));
    }
  };
  
   // workOrder singleView Delivery
   export const listWorkOrderDeliveryAPI = async (data) => {
    try {
      const res = await axios.post(
        "workorder/workorderdeliverydetailsview",
        data,
        HEADERS
      );
      if (res.status === 200) {
        console.log(res.data);
        store.dispatch(setworkorderDelivery(res.data));
      }
    } catch (error) {
      store.dispatch(setworkorderDelivery(null));
    }
  };
  
  
  