import React from "react";
import { AlertCircle, Loader } from "lucide-react";
import "./ErrorModalStyle.css";

const ErrorModal = ({
  onClose,
  retry,
  loading,
  back,
  errorMessage = "An unexpected error occurred. Please try again.",
}) => {
  return (
    <div className="new-altr-cmnt-overlay">
      <div className="new-altr-cmnt-modal">
        <div className="new-altr-cmnt-content">
          <div className="new-altr-cmnt-header">
            <div className="new-altr-cmnt-title">
              <AlertCircle className="new-altr-cmnt-icon" size={24} />
              <h2 className="new-altr-cmnt-heading">Error</h2>
            </div>
          </div>
          <p className="new-altr-cmnt-message">{errorMessage}</p>
          <div className="new-altr-cmnt-actions">
           {back && <button
              onClick={back}
              className="new-altr-cmnt-button new-altr-cmnt-button-secondary"
            >
              Back
            </button>}
            {!back && (
              <>
                <button
                  onClick={onClose}
                  className="new-altr-cmnt-button new-altr-cmnt-button-secondary"
                >
                  Close
                </button>
                <button
                  onClick={retry}
                  disabled={loading}
                  className="new-altr-cmnt-button new-altr-cmnt-button-primary"
                >
                  {loading ? (
                    <Loader className="new-altr-cmnt-spinner" size={15} />
                  ) : (
                    "Retry"
                  )}
                </button>
                .
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorModal;
