import { createSlice } from "@reduxjs/toolkit";

export const GeneralSlice = createSlice({
  name: "generalSlice",
  initialState: {
    listOfReward: undefined,
    singleViewRewardPoint: undefined,
    shiftListData: null,
    shiftSingleViewData: null,
    alterationListData: null,
    alterationSingleViewData: null,
   
    billingListData:null,
    billingSingleData:null,
    paymentListData:null,
    walletListData:null,
    open: false,
    severity: 'success', 
    message: ''
  },
  reducers: {
    get_reward_list: (state, action) => {
      state.listOfReward = action.payload;
    },
    get_reward_list_singleView: (state, action) => {
      state.singleViewRewardPoint = action.payload;
    },
    setShiftList: (state, action) => {
      state.shiftListData = action.payload;
    },
    setShiftSingleViewData: (state, action) => {
      state.shiftSingleViewData = action.payload;
    },
    



    setAlterationList: (state, action) => {
      state.alterationListData = action.payload;
    },
    setBillingList: (state, action) => {
      state.billingListData = action.payload;
    },
    setBillingSingleView: (state, action) => {
      state.billingSingleData = action.payload;
    },
    setPaymentList: (state, action) => {
      state.paymentListData = action.payload;
    },
    setWalletList: (state, action) => {
      state.walletListData = action.payload;
    },
    setAlterationSingleViewData: (state, action) => {
      state.alterationSingleViewData = action.payload;
    }, 
    showAlert: (state, action) => {
      state.open = true;
      state.severity = action.payload.severity;
      state.message = action.payload.message;
    },
    hideAlert: (state) => {
      state.open = false;
    }
    
  },
});
export const {
  showAlert, hideAlert,
  get_reward_list,
  get_reward_list_singleView,
  setShiftList,
  setShiftSingleViewData,
  setAlterationList,
  setAlterationSingleViewData,
 
  setBillingList,
  setBillingSingleView,
  setPaymentList,
  setWalletList
} = GeneralSlice.actions;
export default GeneralSlice.reducer;
