import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setAggregator, setAggregatorRef, setBillType, setBtnSelected, setCustomer, setCustomerWallet, setOrderWaiter, setSearchBarInput, setSingleCusInfo} from './topBarSliceBilling'
import { Autocomplete, Dialog, IconButton, TextField, createFilterOptions } from '@mui/material'
import SearchIcon from "@mui/icons-material/Search";
// import { defaultCustomerForPOSAPI } from '../API/billingFloorSection'
import { setDialogBox, setErrorSnack, setOrderIdInPOS, setPageIndex, setSnackMsg } from '../Slice/billingScreenSlice'
import { findObjFromList } from '../../../../../../Js/generalFunctions'
import { listHeldOrders, listOrdersForBillingAPI } from '../API/viewOrderSection'
import { getCustomerWallet } from '../API/billingPaymentSection'
import { setApplyBtnClicked, setFilteredOrderType, setFilteredStatus } from '../Utilities/filterButtonSlice'
import EditIcon from '@mui/icons-material/Edit';
// import AddCustomerFromReservation from '../Utilities/AddCustomerFromReservation'
import { getSingleCustomerInfoAPICall } from '../API/billingScreenSection'
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';
function TopBar() {

    const navigate=useNavigate()
const userInfo=useSelector((state)=>state.loginResponseSlice.value)

// api responses
const customerList = useSelector((state) => state.allCustomerListSlice.value);

// store states
const {customer,btnClicked,defaultCustomer,searchBarInput}=useSelector((state)=>state.topBarSliceBilling)
const {heldOrders}=useSelector((state)=>state.viewOrdersBillingSlice)

const {singleOrderInfo,dialogOpen} = useSelector((state) => state.billingScreenSlice);
const allActiveEmpList = useSelector((state) => state.allActiveEmployeeSlice.value);
const dispatch=useDispatch()

// useStates
const [addCustomerDialog, setAddCustomerDialog] = useState(false);
// functions


const closeCustomerDialog=()=>{
    setAddCustomerDialog(false)
    dispatch(setDialogBox(null))
}

const buttonsOnClick=(option)=> {
    dispatch(setSearchBarInput(null))
    dispatch(setOrderIdInPOS(null))
    if(btnClicked===option){
        dispatch(setBtnSelected(null))
    }else{
        dispatch(setBtnSelected(option))
        if(option==="viewOrder"){
            dispatch(setFilteredOrderType(null))
            dispatch(setFilteredStatus(null))
            listOrdersForBillingAPI({branchId:userInfo?.branchPk,type:2})
        }
        if(option==="heldOrder"){
            dispatch(setFilteredStatus(null))
            listHeldOrders({branchId:userInfo?.branchPk,type:1})
        }

    }
}

const customerOnChange=(e,newValue)=>{
    if(singleOrderInfo?.type==="refund"){
        dispatch(setSnackMsg("Customer can't be changed on refund!!"))
        dispatch(setErrorSnack(true))
    }else{
        dispatch(setCustomer(newValue))
        newValue?getCustomerWallet({cusId:newValue?._id}):dispatch(setCustomerWallet(null))
    }
}

const searchInputOnChange=(e)=>{
    dispatch(setSearchBarInput(e.target.value))
    dispatch(setApplyBtnClicked("clicked"))
    dispatch(setPageIndex(0))
}
 const homeBtnClickFn=()=>{
    navigate("/userdashboard/posnew")
   }
// const filterOptions = createFilterOptions({
//     matchFrom: "any",
//     stringify: (option) => option?.name + option.mobileNo,
//   });
const filterOptions = createFilterOptions({
    ignoreCase: true,
    matchFrom: "end",
    limit: 20,
    stringify: (option) => option?.name + option.mobileNo,
  });
// useEffect(()=>{
//     defaultCustomer===null &&   defaultCustomerForPOSAPI({})
// },[])

useEffect(()=>{
    if(customer!==null){
        getSingleCustomerInfoAPICall({_id:customer?._id})
    }else{
        dispatch(setSingleCusInfo(null))
        dispatch(setCustomerWallet(null))
    }
},[customer])
useEffect(()=>{
    if(singleOrderInfo!==null){
        let cusObj=findObjFromList("_id",customerList,"cusId",singleOrderInfo)
        dispatch(setCustomer(cusObj))
        cusObj!==null &&getCustomerWallet({cusId:singleOrderInfo?.cusId})
        dispatch(setOrderWaiter(findObjFromList("_id",allActiveEmpList,"waiterId",singleOrderInfo)))
    }else{
        if(btnClicked!==null){
            dispatch(setCustomer(null))
        }
        dispatch(setSingleCusInfo(null))
        dispatch(setAggregator(null))
        dispatch(setAggregatorRef(null))
        dispatch(setOrderWaiter(null))
    }
},[singleOrderInfo])

console.log(btnClicked)


return (
        <div className="billing-left-first"
        style={{justifyContent:btnClicked &&"space-between"}}
        >    
         {/* <div className='new-order-top-left' style={{alignItems:"center"}}>
           <div className='new-order-top-left-home' onClick={homeBtnClickFn}>
             <HomeIcon/> 
           </div>
           <p className='new-order-top-left-p'>BILLING</p>
        </div> */}
        {
            btnClicked === null && 
            <div className='new-order-top-left' style={{alignItems:"center"}}>
            <div className='justify-center' style={{backgroundColor:"#fff",width:"40px"}}>
                  <IconButton
                   onClick={homeBtnClickFn}
                    >
                      <HomeIcon sx={{ color: "green" }} />
                  </IconButton>
                  
              </div>
              <p className='new-order-top-left-p'>BILLING</p>
         </div>

        }
       
            <div
                className="billing-left-secoundnav-container"
                style={{width:btnClicked&&"69%"}}
            >
                <button className="top-bar-buttons billing-btn-2">
                    <span
                        onClick={() =>dispatch(setDialogBox("addCustomer"))}
                        style={{minWidth:"30px"}}
                        className="circle-button justify-center">{customer===null?"+":<EditIcon/> }</span>
                    <div style={{ flex: "1" }}>
                        <Autocomplete
                            options={customerList || []}
                            getOptionLabel={(option) => option?.name}
                            value={customer}
                            filterOptions={filterOptions}
                            onChange={customerOnChange}
                            renderInput={(params) => (
                                <TextField {...params} placeholder="Customer" />
                            )}
                            renderOption={(props, option) => (
                                <li {...props} key={option?._id}>
                                  <span>{option?.name}</span>
                                </li>
                              )}
                        />

                    </div>
                </button>
                <button
                    onClick={() =>buttonsOnClick("viewOrder")}
                    className="top-bar-buttons billing-btn-3"
                    style={{
                        backgroundColor:(btnClicked==="viewOrder")?"#fff":"rgb(21 128 145)",
                        color:(btnClicked==="viewOrder")?"rgb(4 221 255)":"#fff",
                    }}
                    >View Order</button>
                <button
                    style={{
                        backgroundColor:(btnClicked==="heldOrder")?"#fff":"#cc5200",
                        color:(btnClicked==="heldOrder")?"#cc5200":"#fff",
                        position: "relative"
                    }}
                    onClick={() =>buttonsOnClick("heldOrder")}
                    className="top-bar-buttons billing-btn-4">Ongoing Orders
                        {
                        heldOrders.length!==0&&
                        <span
                            style={{
                                padding: "2px 5px",
                                backgroundColor: "#fff",
                                marginLeft:"4px",
                                color:"#cc5200",
                                border:"1px solid #cc5200"
                            }}
                        >
                        {heldOrders.length}
                        </span>}
                </button>
            </div>

            <div className="billing-search-bar">
                <SearchIcon
                sx={{cursor:"pointer"}}
                className="billing-search-icon" />
                <input
                    type="text"
                    placeholder={`Search ${btnClicked===null?"products":"orders"} by name..`}
                    className="search-bar"
                    value={searchBarInput}
                    onChange={searchInputOnChange}
                    onFocus={(e)=>e.target.select()}
                />
            </div>


            {/* add customer */}
        {/* <Dialog
            open={dialogOpen==="addCustomer"}
            onClose={closeCustomerDialog}
            maxWidth="fit-content"
        >
            <AddCustomerFromReservation setCustomerPopup={setAddCustomerDialog} />
        </Dialog> */}
        </div>
  )
}

export default TopBar