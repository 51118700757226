import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import store from "../../../../../Redux/store";
import { convertDateFormat, getCurrentTime } from "../../../../../Js/Date";
import { get_stock_move_row_data_list } from "./stockMovesSlice";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {
  sortViewStockMovesListAPICall,
  viewStockMovesListAPICall,
} from "./stockMovesAPI";
import { IconButton, Skeleton, Tooltip } from "@mui/material";
import CategorySearchandFilter from "../../../../Single Components/CategorySearchandFilter";
import CustomPagination from "../../../../Single Components/CustomPagination";
import axios from "axios";
import { setFilterActive } from "../../../../Single Components/listSlice/filterCategorySlice";
import ReactToPrint from "react-to-print";
import ExcelJS from "exceljs"
export const StockMoves = () => {
  let navigate = useNavigate();
  let navigateTo = useNavigate();
  const tableListData = useSelector((state) => state.stockMoveSlice.apiResList);
  const filtereDataOptions = useSelector((state) => state.listCategory);

  console.log("tableListData", tableListData);
  const [isLoading, setIsLoading] = useState(false);
  const [FormDataInfo, setFormDataInfo] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredData, setFilteredData] = useState({});
  const handleClickRow = (row) => {
    store.dispatch( setFilterActive(window.location.pathname))
    store.dispatch(get_stock_move_row_data_list({ stockMoveSingleData: row }));
    // navigateTo("/userdashboard/accounts/vendor/createvendorBills");
  };

  const handleFilterChange = (updatedItems) => {
    setFilteredData(updatedItems);
  };
  const formatFilterInfo = (filters) => {
    const parts = [];

    if (filters?.toDate && filters.fromDate) {
      parts.push(`As of ${filters.fromDate} to ${filters?.toDate}`);
    }
    if (filters?.branchList) {
      parts.push(
        `Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`
      );
    }
    if (filters?.type) {
      parts.push(`Type: ${filters?.type}`);
    }
    if (filters?.fromLocation) {
      parts.push(`From Location: ${filters?.fromLocation?.name
}`);
    }
    if (filters?.toLocation) {
      parts.push(`To Location: ${filters?.toLocation?.name}`);
    }
    if (filters?.product?._id) {
      parts.push(`Product: ${filters?.product?.productName}`);
    }
    return parts; // Return the array directly
  };
  const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Stock Moves");
    sheet.properties.defaultRowHeight = 20;

    // Add header section
    const titleRow = sheet.addRow(["Stock Moves"]);
    titleRow.font = { size: 15, bold: true };
    sheet.mergeCells("A1:I1");
    titleRow.getCell(1).alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    // Add additional header information
    const filterParts = formatFilterInfo(filteredData) || [];

    // Ensure filterParts is an array and iterate over it
    if (Array.isArray(filterParts)) {
      filterParts.forEach((part, index) => {
        const row = sheet.addRow([part]);
        row.font = { size: 11, bold: true };
        sheet.mergeCells(`A${index + 2}:I${index + 2}`);
        row.getCell(1).alignment = { vertical: "middle", horizontal: "center" };
      });
    }

    // Add an empty row between header and table
    sheet.addRow([]);

    // Header row styling
    const headerRow = sheet.addRow([
      "Date",
      "Reference",
      "Product",
      "From",
      "To",
      "Quantity",
      "UOM",
      "Branch",
      "Type",
    ]);
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFedf2f9" }, // Use full hex code with "FF" prefix for better compatibility
    };
    const headerFont = {
      size: 12,
      bold: true,
      color: { argb: "FF000000" }, // White font color
    };

    headerRow.eachCell((cell, colNumber) => {
      cell.fill = headerFill;
      cell.font = headerFont;
      cell.alignment = { vertical: "middle", horizontal: "center" }; // Ensure header text is centered
    });

    sheet.columns = [
      { header: "Date", key: "date", width: 20 },
      { header: "Reference", key: "reference", width: 20 },
      { header: "Product", key: "product", width: 25 },
      { header: "From", key: "from1", width: 25 },
      { header: "To", key: "to1", width: 25 },
      { header: "Quantity", key: "Quantity", width: 15 },
      { header: "UOM", key: "uom", width: 15 },
      { header: "Branch", key: "branchId", width: 20 },
      { header: "Stock Moves", key: "type", width: 30 },
    ];

    tableListData?.list?.forEach((item, index) => {
      const row = sheet.addRow({
        date: item?.date,
        reference: item?.reference,
        product: item.product,
        from1: item?.from1,
        to1: item?.to1,
        Quantity: item?.Quantity,
        uom: item?.uom,
        branchId: item?.branchId,
        type: item?.type,
      });

      const rowFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFFFF" }, // Use full hex code with "FF" prefix for better compatibility
      };

      row.eachCell((cell, colNumber) => {
        cell.fill = rowFill;
        cell.border = {
          //   top: { style: "thin", color: { argb: "FF000000" } },
          //   left: { style: "thin", color: { argb: "FF000000" } },
          bottom: { style: "thin", color: { argb: "FFedf2f9" } },
          //   right: { style: "thin", color: { argb: "FFFFFFFF" } },
        };
        if (colNumber >= 1) {
          // Right align all columns except the first
          cell.alignment = { horizontal: "center" };
        }
      });
    });


    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Stock Moves.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  useEffect(() => {
    store.dispatch(
      get_stock_move_row_data_list({ stockMoveSingleData: undefined })
    );
    // sortViewStockMovesListAPICall(
    //   {
    //     fromDate: `${FormDataInfo.fromDate} ${getCurrentTime()}`,
    //     toDate: `${FormDataInfo.toDate} ${getCurrentTime()}`,
    //   },
    //   setIsLoading
    // );
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };

  const handleDataFromChild = (data) => {
    setFormDataInfo(data);
  };
  useEffect(() => {
   
    if (FormDataInfo.length !== 0) {
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
      !loadData &&   sortViewStockMovesListAPICall(
        {
          fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} 00:00:00`,
          toDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} 23:59:59`,
          search: FormDataInfo?.search,
          index: currentPage - 1,
          type: FormDataInfo?.type,
          fromLocation: FormDataInfo?.fromLocation,
          toLocation: FormDataInfo?.toLocation,
          product: FormDataInfo?.product?._id,
          branchList: FormDataInfo.branchList,
        },
        setIsLoading
      );
    }
  
  }, [FormDataInfo, currentPage]);
  const typeList = [
    { label: "BRANCH TRANSFER", type: "subheading" },
    { label: "DELIVERY NOTE", type: "subheading" },
  ];
  return (
    <div className="global-page-parent-container">
      {/* <p className="breadcrumb">Inventory &gt; Operation &gt; Stock Moves</p> */}

      <div className="global-white-bg-container">
        {/* <div className="justify-end">&nbsp;</div> */}
        <div
          className="justify-space-between"
          style={{ gap: "0 12px", display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}
        >
           {FormDataInfo?.branchList?.length > 0 && (
              <div style={{ marginLeft: '1%' }}>
                <h3>Branch name: 
                  {selectedBranch?.storeCode||filtereDataOptions?.filterCategorylist?.branchList?.storeCode}
                  -{selectedBranch?.branchName||filtereDataOptions?.filterCategorylist?.branchList?.branchName}</h3>
              </div>
            )}
             <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              width: FormDataInfo?.branchList?.length ? '72%' : '100%'
            }}>
               <div style={{ display: "flex", alignItems: "center" }}>
              <Tooltip title="Share">
                  <IconButton>
                    <i
                      class="bi bi-share"
                      style={{
                        backgroundColor: "#9797e9",
                        color: "white",
                        fontSize: "medium",
                        padding: "8px",
                        borderRadius: "2px",
                      }}
                    ></i>
                  </IconButton>
                </Tooltip>

                <ReactToPrint
                  trigger={() => (
                    <IconButton
                      className="printer-div"
                      style={{ padding: "1px" }}
                    >
                      <i
                        style={{
                          color: "white",
                          backgroundColor: " #d787e7",
                          fontSize: "medium",
                          padding: "8px",
                          borderRadius: "2px",
                        }}
                        class="bi bi-printer"
                      ></i>
                    </IconButton>
                  )}
                  // content={() => paymentsPrintNew}
                />
                <Tooltip title="Download">
                  <IconButton onClick={downloadList}>
                    <i
                      class="bi bi-arrow-down-circle"
                      style={{
                        backgroundColor: "rgb(255 118 133 / 69%)",
                        color: "white",
                        fontSize: "medium",
                        padding: "8px",
                        borderRadius: "2px",
                      }}
                    ></i>
                  </IconButton>
                </Tooltip>
                <CategorySearchandFilter
            onData={handleDataFromChild}
            isType={true}
            isProduct={true}
            isFromLocation={true}
            isToLocation={true}
            typeList={typeList}
            onBranchSelect={handleBranchSelect}
            onFilterChange={handleFilterChange}
          /> 
           
          </div>
          {/* <CategorySearchandFilter
            onData={handleDataFromChild}
            isType={true}
            isProduct={true}
            isFromLocation={true}
            isToLocation={true}
            typeList={typeList}
            onBranchSelect={handleBranchSelect}
          /> */}
          </div>
        </div>
        <div className="create-button-blue-container">
          <h3>Stock Moves</h3>
          
            <CustomPagination
              currentPage={currentPage}
              totalPages={tableListData?.pages}
              onPageChange={handlePageChange}
              setFormDataInfo={setFormDataInfo}
            />
        
        </div>
        <div className="global-table-container">
          <table className="global-table">
            <thead>
              <tr>
                <th style={{ width: "80px" }}>Date</th>
                <th>Reference</th>
                <th>Product</th>
                <th>From</th>
                <th>To</th>
                <th>Quantity</th>
                <th>UOM</th>
                <th>Branch</th>
                <th>Type</th>
                {/* <th>
              <ArrowUpwardIcon sx={{color:"red"}}/>
              <ArrowDownwardIcon sx={{color:"#00ef00"}}/>
              </th> */}
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                [...Array(10)].map((r, i) => (
                  <tr key={i}>
                    <td colSpan={10}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                ))
              ) : tableListData !== undefined &&
                tableListData?.list &&
                tableListData.list.length !== 0 ? (
                tableListData?.list
                  ?.slice(0)
                  ?.reverse()
                  ?.map((r, i) => (
                    <tr
                      key={i}
                      onClick={() => handleClickRow(r)}
                      style={{ cursor: "pointer" }}
                    >
                      <td>{r?.date}</td>
                      <td>{r?.reference}</td>
                      <td>{r?.product}</td>
                      <td>{r?.from1}</td>
                      <td>{r?.to1}</td>
                      <td>{r?.Quantity}</td>
                      <td>{r?.uom}</td>
                      <td>{r?.branchId}</td>
                      <td>{r?.type}</td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan={11}>No data</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
