import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter';
import { companyProfileInfoForUser } from '../../../../../API/Settings/Company Settings/companySettingsAPI';
import { viewAllCurrencyUpdated } from '../../../../../API/Settings/Currency/CurrencyAPI';
import { filterObjFromList } from '../../../../../Js/generalFunctions';
import { PurchaseReportNewAPI } from './purchaseReportNewAPI';
import { getCurrentTime } from '../../../../../Js/Date';
import { setFilterActive } from '../../../../Single Components/listSlice/filterCategorySlice';
import store from '../../../../../Redux/store';
import { IconButton, Tooltip } from '@mui/material';
import ReactToPrint from 'react-to-print';
import ExcelJS from "exceljs"

const PurchaseReportNew = () => {
    let navigate = useNavigate();
    const [symbol, setSymbol] = useState(null);
    const [FormDataInfo, setFormDataInfo] = useState([])
    const [selectedBranch, setSelectedBranch] = useState(null);
    const filtereDataOptions = useSelector((state) => state.listCategory);
    const [filteredData,setFilteredData]=useState("")
    const decimalPosition = localStorage.getItem("decimalPosition");

    const currencyListUpdated = useSelector(
        (state) => state.viewUpdatedCurrency.value
    );

    const purchaseList = useSelector((state=>state?. purchaseRport?.purhaseReportNwList))

     console.log(purchaseList);
    const companyProfileData = useSelector(
        (state) => state?.companyProfileSlice?.userValue
    );

    const handleBranchSelect = (selectedBranch) => {
        setSelectedBranch(selectedBranch); 
      
      };
    const handleDataFromChild = (data) => {
        setFormDataInfo(data)
    };
    const handleFilterChange = (updatedItems) => {
        setFilteredData(updatedItems);
      };
    const formatFilterInfo = (filters) => {
        const parts = [];
      
        if (filters?.fromDate && filters?.toDate) {
          parts.push(`As of ${filters?.fromDate} to ${filters?.toDate}`);
        }
        console.log(filters?.fromDate);
    
        if (filters?.branchList) {
          parts.push(`Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`);
        }
       
        
        return parts; 
      };
    const downloadList = () => {
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet("purchase report");
        sheet.properties.defaultRowHeight = 20;
      
        // Add header section
        const titleRow = sheet.addRow(["purchase report"]);
        titleRow.font = { size: 18, bold: true };
        sheet.mergeCells('A1:G1');
        titleRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
      
        // Add additional header information
        const filterParts = formatFilterInfo(filteredData) || [];
      
        // Ensure filterParts is an array and iterate over it
        if (Array.isArray(filterParts)) {
          filterParts.forEach((part, index) => {
            const row = sheet.addRow([part]);
            row.font = { size: 15, bold: true };
            sheet.mergeCells(`A${index + 2}:G${index + 2}`);
            row.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
          });
        }
      
        // Add an empty row between header and table
        sheet.addRow([]);
      
        // Header row styling
        const headerRow = sheet.addRow(["Item", "UOM", "Purchase Qty", "Purchase Return Qty","Total Quatity","Average Unit Cost","Average Total Value"]);
        const headerFill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF1d1da7" }, // Use full hex code with "FF" prefix for better compatibility
        };
        const headerFont = {
          size: 15,
          bold: true,
          color: { argb: "FFFFFFFF" }, // White font color
        };
      
        headerRow.eachCell((cell) => {
          cell.fill = headerFill;
          cell.font = headerFont;
          cell.alignment = { vertical: 'middle', horizontal: 'center' }; // Ensure header text is centered
        });
      
        sheet.columns = [
          { header: "Item", key: "itemName", width: 30 },
          { header: "UOM", key: "uom", width: 10 },
          { header: "Purchase Qty", key: "purchaseQty", width: 20 },
          { header: "Purchase Return Qty", key: "purchaseRet", width: 30 },
          { header: "Total Quantity", key: "totalQuantity", width: 20 },
          { header: "Average Unit Cost", key: "cost", width: 30 },
          { header: "Purchase Report", key: "totalValue", width: 30 },
        ];
      
        purchaseList?.forEach((item) => {
          const row = sheet.addRow({
            itemName: item?.itemName,
            uom: item?.uom,
            purchaseQty: ` ${item?.purchaseQty?.toFixed(2)}`,
            purchaseRet: ` ${item?.purchaseRet?.toFixed(2)}`,
            totalQuantity: ` ${item?.totalQuantity?.toFixed(2)}`,
            cost: ` ${item?.cost?.toFixed(2)}`,
            totalValue: ` ${item?.totalValue?.toFixed(2)}`,
          });
      
          const rowFill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFC3D4F7" }, // Use full hex code with "FF" prefix for better compatibility
          };
          
          row.eachCell((cell, colNumber) => {
            cell.fill = rowFill;
            cell.border = {
              top: { style: "thin", color: { argb: "FFFFFFFF" } },
              left: { style: "thin", color: { argb: "FFFFFFFF" } },
              bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
              right: { style: "thin", color: { argb: "FFFFFFFF" } },
            };
            if (colNumber >2) { // Right align all columns except the first
                cell.alignment = { horizontal: 'right' };
              }
          });
        });
      
        // Add row for totals
        const totalRow = sheet.addRow([
          "Total",
          "",
          `${symbol} ${purchaseList?.reduce((acc, row) => acc + row.purchaseQty, 0)?.toFixed(decimalPosition)}`,
          `${symbol} ${purchaseList?.reduce((acc, row) => acc + row.purchaseRet, 0)?.toFixed(decimalPosition)}`,
          `${symbol} ${purchaseList?.reduce((acc, row) => acc + row.totalQuantity, 0)?.toFixed(decimalPosition)}`,
          `${symbol} ${purchaseList?.reduce((acc, row) => acc + row.cost, 0)?.toFixed(decimalPosition)}`,
          `${symbol} ${purchaseList?.reduce((acc, row) => acc + row.totalValue, 0)?.toFixed(decimalPosition)}`,
         
        ]);
      
        totalRow.eachCell((cell,colNumber) => {
          cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
          cell.fill = headerFill;
          cell.border = {
            top: { style: "thin", color: { argb: "FFFFFFFF" } },
            left: { style: "thin", color: { argb: "FFFFFFFF" } },
            bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
            right: { style: "thin", color: { argb: "FFFFFFFF" } },
          };
          if (colNumber >1) { 
            cell.alignment = { horizontal: 'right' };
          }
        });
      
        workbook.xlsx.writeBuffer().then(function (data) {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.download = "Purchase Report.xlsx";
          anchor.click();
          window.URL.revokeObjectURL(url);
        });
      };
    const handleRow = (row) => {
        store.dispatch( setFilterActive(window.location.pathname))
        localStorage.setItem("singleViewId",row._id)
        localStorage.setItem("name",row.itemName)
        localStorage.setItem("fromDate", FormDataInfo?.fromDate);
        localStorage.setItem("toDate", FormDataInfo?.toDate);
        localStorage.setItem("branch", FormDataInfo?.branchList?.length === 0 ? '' : FormDataInfo?.branchList[0])
        navigate("/userdashboard/purchasemanager/PurchaseReport/PurchaseReportNew/SingleView")
    }

    useEffect(() => {
        let currencyObj = filterObjFromList(
            "_id",
            currencyListUpdated,
            "currency",
            companyProfileData
        );
        // console.log(currencyObj);
        setSymbol(currencyObj?.symbol);
    }, [companyProfileData, currencyListUpdated]);

    useEffect(() => {
        viewAllCurrencyUpdated({ status: 0 });
        companyProfileInfoForUser();
    }, []);

useEffect(()=>{
    if(FormDataInfo?.length!==0){ let body ={
        fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} ${getCurrentTime()}`,
        endDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} ${getCurrentTime()}`,
        branchId: FormDataInfo?.branchList?.length === 0 ? '' : FormDataInfo?.branchList[0],
        search:FormDataInfo?.search,
    }
    let loadData=window.location.pathname===filtereDataOptions?.filterActive
    !loadData &&  PurchaseReportNewAPI(body);
  }
    },[FormDataInfo]);
   

    return (
        <div className="global-page-parent-container" style={{ margin: "0" }}>
            <div className="global-white-bg-container">
                <div className="justify-space-between" >
                    <div className="sharp-arrow" style={{ width: "125Px" }}> <p>Purchase Report</p></div>
                    <div style={{ display: "flex" }}>
                        <Tooltip title="Share">
                            <IconButton>
                                <i
                                    class="bi bi-share"
                                    style={{
                                        backgroundColor: "#9797e9",
                                        color: "white",
                                        fontSize: "medium",
                                        padding: "8px",
                                        borderRadius: "2px",
                                    }}
                                ></i>
                            </IconButton>
                        </Tooltip>

                        <ReactToPrint
                            trigger={() => (
                                <IconButton className="printer-div" style={{ padding: "1px" }}>
                                    <i
                                        style={{
                                            color: "white",
                                            backgroundColor: " #d787e7",
                                            fontSize: "medium",
                                            padding: "8px",
                                            borderRadius: "2px",
                                        }}
                                        class="bi bi-printer"
                                    ></i>
                                </IconButton>
                            )}
                            // content={() => paymentsPrint}
                        />

                        <Tooltip title="Download">
                            <IconButton onClick={downloadList}>
                                <i
                                    class="bi bi-arrow-down-circle"
                                    style={{
                                        backgroundColor: "rgb(255 118 133 / 69%)",
                                        color: "white",
                                        fontSize: "medium",
                                        padding: "8px",
                                        borderRadius: "2px",
                                    }}
                                ></i>
                            </IconButton>
                        </Tooltip>
                        <div>
                            <CategorySearchandFilter
                                statusOptions={['PURCHASE ORDER', 'DONE', 'CANCEL']}
                                onData={handleDataFromChild}
                                onBranchSelect={()=>{}}
                                onFilterChange={handleFilterChange}
                            />

                        </div>
                    </div>
                    {/* <div>
                        <CategorySearchandFilter
                            statusOptions={['PURCHASE ORDER', 'DONE', 'CANCEL']}
                            onData={handleDataFromChild}
                            onBranchSelect={handleBranchSelect}
                        />

                    </div> */}
                </div>
                <h1 style={{ margin: "1px", fontSize: "large" }}>Purchase Report</h1>

                <div className="global-report-table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th >UOM</th>
                                <th >Opening Qty</th>
                                <th >Purchase Qty</th>
                                <th >Purchase Return Qty</th>
                                <th >Total Quatity</th>
                                <th >Average Unit Cost</th>
                                <th >Average Total Value</th>
                               
                            </tr>
                        </thead>
                        <tbody>
                            { purchaseList !== undefined && purchaseList?.length !== 0 ?purchaseList?.map((item,index)=>(
                            <tr key={index} onClick={() => handleRow(item)}>
                                <td style={{ textAlignLast: "start", paddingLeft: "10px" }}>{item.itemName}</td>
                                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.uom}</td>
                                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.openingQty?.toFixed(decimalPosition)}</td>
                                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.purchaseQty?.toFixed(decimalPosition)}</td>
                                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.purchaseRet?.toFixed(decimalPosition)}</td>
                                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.totalQuantity?.toFixed(decimalPosition)}</td>
                                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{symbol}&nbsp;{item.cost?.toFixed(decimalPosition)}</td>
                                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{symbol}&nbsp;{item.totalValue?.toFixed(decimalPosition)}</td>
                        </tr>   
                            )):
                            <tr>
                           <td colSpan={8}>NO DATA</td>
                        </tr>
                            }
                        </tbody>
                        <tfoot>
                            <tr>
                                <th colSpan={7}>Total</th>
                                <th >{symbol}&nbsp;{purchaseList?.reduce((acc, row) => acc + row.totalValue, 0)?.toFixed(decimalPosition)}</th>
                              
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>

    )
}

export default PurchaseReportNew