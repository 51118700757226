import { Autocomplete, Dialog, IconButton, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { open_dialog } from "../../PosNewSlice";
import { AutocompleteField, TextFieldComponent } from "./CustomFields";
import { useDispatch, useSelector } from "react-redux";
import { CreateTypeDialog } from "./CreateTypeDialog";
import {
  addFpModelAPICall,
  addModelAPICall,
  addNeckModelAPICall,
  addOrderItemTypeAPI,
  addOrderModelAPI,
  addStitchingAPICall,
  deleteFpModelAPICall,
  deleteModelAPICall,
  deleteNeckModelAPICall,
  deleteOrderModelAPI,
  deleteStitchingAPICall,
  viewFpModelAPICall,
  viewModelAPICall,
  viewNeckModelAPICall,
  viewOrderItemTypeAPI,
  viewOrderModelAPI,
  viewStitchingAPICall,
} from "../MeasurmentsAPI";
import { filterObjFromList } from "../../../../../../Js/generalFunctions";
import { CreateModelDialog } from "./CreateModelDialog";

export const JalabiaForm = ({ onFormDataChange,selectedType,editData }) => {
  const dispatch = useDispatch();
  const { openDialog, isModify } = useSelector((state) => state.posNewSlice);
  const { neckModel, stitching, fpModel,jalabiaModel,model,itemType} = useSelector(
    (state) => state.measurementsslice
  );
  const [inputValue, setInputValue] = useState();
  const formInitialState = {
    modelId:null,
    itemType:null,
    length: "",
    shoulder: "",
    sleeve: "",
    chest: "",
    stomach: "",
    slChest: "",
    slSo: "",
    neck: "",
    bottom: "",
    sleeveSize: "",
    neckModel: null,
    stitching: null,
    regalSize: "",
    neckDownButton: "",
    mar: "",
    model: null,
    fpModel: null,
    fpSize1: "",
    fpSize2: "",
    remark: "",
  };
  const [formValues, setFormValues] = useState(formInitialState);
  const [modelInputValues, setModelInputValues] = useState({});
  
  const handleAdd = (label) => {
    dispatch(open_dialog({ openDialogState: label }));
  };
  const closeDialog = () => {
    dispatch(open_dialog({ openDialogState: false }));
  };

  const items = [
    { label: "Length", value: formValues.length, field: "length" },
    { label: "Shoulder", value: formValues.shoulder, field: "shoulder" },
    { label: "Sleeve", value: formValues.sleeve, field: "sleeve" },
    { label: "Chest", value: formValues.chest, field: "chest" },
    { label: "Stomach", value: formValues.stomach, field: "stomach" },
    { label: "S.L Chest", value: formValues.slChest, field: "slChest" },
    { label: "S.L So", value: formValues.slSo, field: "slSo" },
    { label: "Neck", value: formValues.neck, field: "neck" },
    { label: "Bottom", value: formValues.bottom, field: "bottom" },
    { label: "Sleeve Size", value: formValues.sleeveSize, field: "sleeveSize" },
  ];

  const handleFormChange = (key, value) => {
    console.log(key, value);
    const updatedData = {...formValues,[key]:value };
    setFormValues(updatedData);
    onFormDataChange(updatedData);
  };

  const handleGetFormData = (data) => {
    setInputValue(data);
  };

  const handleGetModelFormData = (data) => {
    setModelInputValues(data);
  };

  const handleAddModel = () => {
    if (openDialog === "Jalabia Model") {
      addOrderModelAPI(
        {
          modelName: modelInputValues?.modelName,
          fromLength: modelInputValues?.fromLength,
          toLength: modelInputValues?.toLength,
          minRate: modelInputValues?.minRate,
          commission: modelInputValues?.commission,
          workorderTypeId: selectedType,
        },
        setModelInputValues
      );
    }
  };
  const handleDeleteModel = (id, type) => {
    if (type === "Jalabia Model") {
      deleteOrderModelAPI({ thobId: id });
    }
  };

  const handleAddData = (data) => {
    if (openDialog === "Type") {
      addOrderItemTypeAPI(
        { typeName: inputValue, workorderTypeId: selectedType },
        setInputValue
      );
    } 
    else if (openDialog === "Neck Model") {
      addNeckModelAPICall({ neckName: inputValue }, setInputValue);
    } else if (openDialog === "Stitching") {
      addStitchingAPICall({ stitching: inputValue }, setInputValue);
    } else if (openDialog === "FP Model") {
      addFpModelAPICall({ fpModel: inputValue }, setInputValue);
    } else if (openDialog === "Model") {
      addModelAPICall({ model: inputValue }, setInputValue);
    }
  };
  const handleDelete = (id, type) => {
    if (type === "Neck Model") {
      deleteNeckModelAPICall({ neckId: id });
    } else if (type === "Stitching") {
      deleteStitchingAPICall({ stitchingId: id });
    } else if (type === "FP Model") {
      deleteFpModelAPICall({ fpModelId: id });
    }  else if (openDialog === "Model") {
      deleteModelAPICall({ modelId:id });
    }

  };

  const renderRows = (data, type) =>
    data
      ?.slice(0)
      ?.reverse()
      ?.map((r, i) => (
        <tr key={i}>
          <td>{r?.neckName || r?.stitching || r?.fpModel||r?.model||r?.typeName}</td>
          <td onClick={() => handleDelete(r?._id, type)}>
            <IconButton>
              <i class="bi bi-trash3 delete-icon"></i>
            </IconButton>
          </td>
        </tr>
      )) || (
      <tr>
        <td colSpan={3}>No data</td>
      </tr>
    );
console.log(editData);
  useEffect(() => {
    if (editData !== undefined) {
      setFormValues({
        ...formValues,
        modelId: filterObjFromList("_id", model, "modelId", editData),
        itemType: filterObjFromList("_id", itemType, "itemType", editData),
        length: editData?.length,
        shoulder: editData?.shoulder,
        sleeve: editData?.sleeve,
        chest: editData?.chest,
        stomach: editData?.stomach,
        slChest: editData?.slChest,
        slSo: editData?.slSo,
        neck: editData?.neck,
        bottom: editData?.bottom,
        sleeveSize: editData?.sleeveSize,
        neckModel:filterObjFromList("_id",neckModel,"neckModel",editData),
        stitching: filterObjFromList("_id", stitching, "stitching", editData),
        regalSize: editData?.regalSize,
        neckDownButton: editData?.neckDownButton,
        mar:editData?.mar,
        model:filterObjFromList("_id",jalabiaModel,"model",editData),
        fpModel: filterObjFromList("_id", fpModel, "fpModel", editData),
        fpSize1: editData?.fpSize1,
        fpSize2: editData?.fpSize2,
        remark: editData?.remark,
      });
    }
  }, [editData,neckModel,fpModel,stitching]);

  console.log(formValues);

  useEffect(() => {
    viewOrderItemTypeAPI({ workorderTypeId: selectedType });
    viewOrderModelAPI({ workorderTypeId: selectedType });
  }, [selectedType, isModify]);

  useEffect(() => {
    viewNeckModelAPICall();
    viewStitchingAPICall();
    viewFpModelAPICall();
    viewModelAPICall()
  }, []);
  return (
    <div className="thob-form-container">
      <div className="thob-model-left-container">
        <AutocompleteField
          label="Jalabia Model"
          options={model}
          getOptionLabel={(option) => option.modelName}
          onAdd={handleAdd}
          fieldKey="modelId"
          value={formValues?.modelId}
          onChange={handleFormChange}
        />
        <AutocompleteField
          label="Type"
          options={itemType}
          getOptionLabel={(option) => option.typeName}
          onAdd={handleAdd}
          fieldKey="itemType"
          value={formValues?.itemType}
          onChange={handleFormChange}
        />
        {items.map((r, index) => (
          <TextFieldComponent
            key={index}
            label={r?.label}
            fieldKey={r?.field}
            value={r?.value}
            onChange={handleFormChange}
          />
        ))}
        <AutocompleteField
          label="Neck Model"
          options={neckModel}
          getOptionLabel={(option) => option.neckName}
          onAdd={handleAdd}
          fieldKey="neckModel"
          value={formValues?.neckModel}
          onChange={handleFormChange}
        />
        <AutocompleteField
          label="Stitching"
          options={stitching}
          getOptionLabel={(option) => option.stitching}
          onAdd={handleAdd}
          fieldKey="stitching"
          value={formValues?.stitching}
          onChange={handleFormChange}
        />
      </div>
      <div className="border-right"></div>
      <div className="thob-model-right-container" style={{ height: "19vh" }}>
        <TextFieldComponent
          label="Regal Size"
          fieldKey="regalSize"
          value={formValues?.regalSize}
          onChange={handleFormChange}
        />
        <TextFieldComponent
          label="Neck Down Button"
          fieldKey="neckDownButton"
          value={formValues?.neckDownButton}
          onChange={handleFormChange}
        />
        <TextFieldComponent 
          label="Mar"
          fieldKey="mar"
          value={formValues?.mar}
          onChange={handleFormChange}
          />
        <AutocompleteField
          label="Model"
          options={jalabiaModel}
          getOptionLabel={(option) => option?.model}
          onAdd={handleAdd}
          fieldKey="model"
          value={formValues?.model}
          onChange={handleFormChange}
        />
        <AutocompleteField
          label="FP Model"
          options={fpModel}
          getOptionLabel={(option) => option.fpModel}
          onAdd={handleAdd}
          fieldKey="fpModel"
          value={formValues?.fpModel}
          onChange={handleFormChange}
        />
        <div style={{ display: "flex", width: "31%" }}>
          <TextFieldComponent
            label="FP Size"
            style={{ width: "49%", margin: "12px 2px 12px 8px" }}
            fieldKey="fpSize1"
            value={formValues?.fpSize1}
            onChange={handleFormChange}
          />
          <TextFieldComponent
            label=""
            style={{ width: "49%", margin: "12px 0px 12px 8px" }}
            fieldKey="fpSize2"
            value={formValues?.fpSize2}
            onChange={handleFormChange}
          />
        </div>
        <TextFieldComponent
          label="Notes"
          style={{ width: "94%" }}
          fieldKey="remark"
          value={formValues?.remark}
          onChange={handleFormChange}
        />
      </div>

      {/* create neck model */}
      <CreateTypeDialog
        open={openDialog === "Neck Model"}
        label="Add Neck Model"
        closeDialog={closeDialog}
        tablelabel="Neck Model"
        addBtnFn={handleAddData}
        formData={handleGetFormData}
        clearFormData={inputValue}
      >
        {renderRows(neckModel, "Neck Model")}
      </CreateTypeDialog>
      {/* create stitching*/}
      <CreateTypeDialog
        open={openDialog === "Stitching"}
        label="Add Add Stitching"
        closeDialog={closeDialog}
        tablelabel="Stitching"
        addBtnFn={handleAddData}
        formData={handleGetFormData}
        clearFormData={inputValue}
      >
        {renderRows(stitching, "Stitching")}
      </CreateTypeDialog>
      {/* create fp model*/}
      <CreateTypeDialog
        open={openDialog === "FP Model"}
        label="Add FP Model"
        closeDialog={closeDialog}
        tablelabel="FP Model"
        addBtnFn={handleAddData}
        formData={handleGetFormData}
        clearFormData={inputValue}
      >
        {renderRows(fpModel, "FP Model")}
      </CreateTypeDialog>
        {/* create  model*/}
      <CreateTypeDialog
        open={openDialog === "Model"}
        label="Model"
        closeDialog={closeDialog}
        tablelabel="Model"
        addBtnFn={handleAddData}
        formData={handleGetFormData}
        clearFormData={inputValue}
      >
        {renderRows(jalabiaModel,"Model")}
      </CreateTypeDialog>

       {/* create JALABIA model pop up */}
       <Dialog open={openDialog === "Jalabia Model"} maxWidth="lg">
        <CreateModelDialog
          closeDialog={closeDialog}
          addBtnFn={handleAddModel}
          modelFormData={handleGetModelFormData}
          clearFormData={modelInputValues}
        >
          {model
            ?.slice(0)
            ?.reverse()
            ?.map((r, i) => (
              <tr key={i}>
                <td>{r?.modelName}</td>
                <td>{`${r?.fromLength}-${r?.toLength}`}</td>
                <td>{r?.minRate}</td>
                <td>{r?.commission}</td>
                <td>
                  <IconButton
                    onClick={() => handleDeleteModel(r?._id, "Model")}
                  >
                    <i class="bi bi-trash3 delete-icon"></i>
                  </IconButton>
                </td>
              </tr>
            )) || (
            <tr>
              <td colSpan={5}>No Data</td>
            </tr>
          )}
        </CreateModelDialog>
      </Dialog>

       {/* create type pop up */}
       <CreateTypeDialog
        open={openDialog === "Type"}
        label="Add Type"
        closeDialog={closeDialog}
        tablelabel="Type"
        addBtnFn={handleAddData}
        formData={handleGetFormData}
        clearFormData={inputValue}
      >
        {renderRows(itemType,"Type")}
      </CreateTypeDialog>

    </div>

     
  );
};
