import React, { useEffect, useState } from 'react';
import "../../css/Single Components/calculator.css"
import { useDispatch, useSelector } from 'react-redux';
import { setInputCalculator } from '../Modules/PosNew/PosNew/Billing/Slice/calculatorSlice';
const Calculator = () => {

  const dispatch=useDispatch()
  const {inputValueCalculator} = useSelector((state) =>state.calculatorSlice);


  const [input, setInput] = useState("");

  const handleButtonClick = (value) => {
    setInput((prevInput) => prevInput + value);
  };

  const handleClear = () => {
    setInput('');
  };

  const handleBackspace = () => {
    setInput((prevInput) => prevInput.slice(0, -1));
  };

  const handleEquals = () => {
    try {
      setInput(eval(input).toString());
    } catch (error) {
      setInput('Error');
      
    }
  };


  useEffect(()=>{
    input!=="Error" && dispatch(setInputCalculator(input))
  },[input])
  useEffect(()=>{
    inputValueCalculator==="" &&setInput("")
  },[inputValueCalculator])
  return (
    <div className="billing-calculator">
    <div className="billing-display">
      <input type="text" value={input==="Error"?input:inputValueCalculator} readOnly />
    </div>
    <div className="billing-input-section">
        <div className="row">
          <button className="white-btn" onClick={handleBackspace}>&larr;</button>
          <button className="white-btn" onClick={() => handleButtonClick('%')}>%</button>
          <button className="black-btn">CE</button>
          <button className="black-btn" onClick={handleClear}>C</button>
        </div>
        <div className="row">
          <button onClick={() => handleButtonClick('7')}>7</button>
          <button onClick={() => handleButtonClick('8')}>8</button>
          <button onClick={() => handleButtonClick('9')}>9</button>
          <button className="white-btn" onClick={() => handleButtonClick('-')}>−</button>
        </div>
        <div className="row">
          <button onClick={() => handleButtonClick('4')}>4</button>
          <button onClick={() => handleButtonClick('5')}>5</button>
          <button onClick={() => handleButtonClick('6')}>6</button>
          <button className="white-btn" onClick={() => handleButtonClick('/')}>÷</button>
        </div>
        <div className="row">
          <button onClick={() => handleButtonClick('1')}>1</button>
          <button onClick={() => handleButtonClick('2')}>2</button>
          <button onClick={() => handleButtonClick('3')}>3</button>
          <button className="white-btn" onClick={() => handleButtonClick('*')}>X</button>
        </div>
        <div className="row">
          <button onClick={() => handleButtonClick('.')}>.</button>
          <button onClick={() => handleButtonClick('0')}>0</button>
          <button className="white-btn" onClick={handleEquals}>=</button>
          <button className="white-btn" onClick={() => handleButtonClick('+')}>+</button>
        </div>
      </div>
    </div>
  );
};

export default Calculator;
