  import React,{useState,useRef} from 'react'
import {Select,IconButton, Autocomplete, TextField, Skeleton} from '@mui/material'
import ReactToPrint from "react-to-print";
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { paymentReportAPICall } from '../../../../API/Report/paymentReportAPI';
import { viewAllBranchesAPICall } from '../../../../API/Settings/Company Settings/companySettingsAPI';
import { convertDateFormat, getCurrentTime, today } from '../../../../Js/Date';
import { sortGeneral } from '../../../../Js/generalFunctions';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { LoadingForm } from '../../../Single Components/LoadingForm';
export const PaymentReport = () => {
  // print view
  let paymentReportPrint=useRef()
  // all branch list
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  /// payment report
  const paymentReport=useSelector((state)=>state.paymentReportSlice.value)
 // login response
 const loginResponse = useSelector((state) => state.loginResponseSlice.value);
 // role based rendering
 const userRole = useSelector((state) => state.userRoleSlice.value);

const storeCode=localStorage.getItem('branchId')

 // states
 const [branch,setBranch]=useState(null)
 const [branchDetails,setBranchDetails]=useState(null)
 const [fromDate,setFromDate]=useState('')
 const [toDate,setToDate]=useState('')
 const [time, setTime] = useState(null);
 const [isLoading,setIsLoading]=useState(false)
 const [isAscending,setIsAscending]=useState(false)
 const [paymentFilter,setPaymentFilter]=useState([])
 const [branchWithAll,setBranchWithAll]=useState([])
  // get current time fn
  useEffect(() => {
    setInterval(() => {
      setTime(`${new Date().getHours()}:${new Date().getMinutes()}`);
    }, 1000);
  }, [time]);

  useEffect(()=>{
    // paymentReportAPICall({branchId:null,fromDate:null,endDate:null})
    // paymentReportAPICall({branchId:storeCode},setIsLoading)
    viewAllBranchesAPICall();
  },[])

  // onchange functions
  const getFromDate=(e)=>{
    setFromDate(e.target.value)
  }
  const getToDate=(e)=>{
    setToDate(e.target.value)
  }

  // add -All- into branch list
  useEffect(()=>{
    if(allBranchesList !== undefined){
      let branchList=allBranchesList?.map((obj)=>{
        return obj
      })
      setBranchWithAll([{storeCode:"All Branches",branchName:""},...branchList])
    }
  },[allBranchesList])

  const bodyToApi={
    branchId:branch === "-All"?null:branch,
    fromDate:fromDate !== ''?fromDate:null,
    endDate:toDate !==''?toDate:null
  }
  const paymentReportSubmitFn=()=>{
    paymentReportAPICall(bodyToApi,setIsLoading)
  }

// map payment report response to state
useEffect(()=>{
   if(paymentReport !== undefined){
    setPaymentFilter(paymentReport?.map(obj=>{return {...obj}}))
   }
   else{
    setPaymentFilter([])
   }
},[paymentReport])

//  customer sorting
const sortCustomerAscending=()=>{
  setIsAscending(!isAscending)
  let sorted = sortGeneral(paymentFilter, "String", "customer", true);
  setPaymentFilter(sorted)
}
const sortCustomerDescending=()=>{
  setIsAscending(!isAscending)
  let sorted = sortGeneral(paymentFilter, "String", "customer", false);
  setPaymentFilter(sorted)
}

// date sorting
const sortAmountAscending=()=>{
  setIsAscending(!isAscending)
  let sorted = sortGeneral(paymentFilter, "Number", "totalAmount", true);
  setPaymentFilter(sorted)
}
const sortAmountDescending=()=>{
  setIsAscending(!isAscending)
  let sorted = sortGeneral(paymentFilter, "Number", "totalAmount", false);
  setPaymentFilter(sorted)
}
 // branch code sorting
 const sortBranchCodeAscending=()=>{
  setIsAscending(!isAscending)
  let sorted = sortGeneral(paymentFilter, "String", "branchId", true);
  setPaymentFilter(sorted)
}
const sortBranchCodeDescending=()=>{
  setIsAscending(!isAscending)
  let sorted = sortGeneral(paymentFilter, "String", "branchId", false);
  setPaymentFilter(sorted)
}
// branch name sorting
const sortBranchNameAscending=()=>{
setIsAscending(!isAscending)
let sorted = sortGeneral(paymentFilter, "String", "locationName", true);
setPaymentFilter(sorted)
}
const sortBranchNameDescending=()=>{
setIsAscending(!isAscending)
let sorted = sortGeneral(paymentFilter, "String", "locationName", false);
setPaymentFilter(sorted)
}
  return (
    <div className="global-page-parent-container">
    <p className="breadcrumb">Report &gt; Payment Report</p>
    <div className="daily-report-top-container">
      <h3>Payment Report</h3>
      <hr className="global-hr"/>
      <div className="daily-report-top-input-container" >
        {
          userRole === 'admin' && 
          <div className="global-single-input auto-complete">
          <p>Branch</p>
          <Autocomplete
                  sx={{ width: "100%" }}
                  options={branchWithAll || [""]}
                  getOptionLabel={(option) =>
                    `${option?.storeCode}-${option?.branchName}`
                  }
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select Branch" />
                  )}
                  // value={branch}
                  onChange={(e, newValue) => {setBranch(newValue?.storeCode) 
                                              setBranchDetails(newValue)}}
                />
         </div>
        }
      
       <div className="global-single-input">
        <p>From</p>
        <input type="date" onChange={getFromDate}/>
       </div>
       <div className="global-single-input">
        <p>To</p>
        <input type="date" onChange={getToDate}/>
       </div>
       <button onClick={paymentReportSubmitFn} className="btn btn-primary report-submit-btn">Submit</button>
      
      </div>
     
    </div>
    <div className="global-white-bg-container">
     <div className="stock-report-print-download-icon-container">
       <ReactToPrint
             trigger={()=>(
            <IconButton className="print-icon-container">
              <i class="bi bi-printer printer-icon"></i>
            </IconButton>
           )}
            content={()=>paymentReportPrint}
             pageStyle="@page { size: auto;  margin: 15mm 0 10mm 0;} }"
            />  
     <IconButton className="download-icon-container">
       <i class="bi bi-arrow-down-circle download-icon"></i>
     </IconButton>
     </div>
     <div className="global-table-container">
        <table className="global-table">
           <thead>
             <tr>
              <th>No</th>
              {
                userRole === 'admin' &&
                <>
                  <th>
                  Branch Code
                  {
                isAscending ?(
                  <IconButton
                    onClick={sortBranchCodeAscending}
                  >
                     <ArrowUpward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                ):
                (
                  <IconButton 
                  onClick={sortBranchCodeDescending}
                  >
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                )
              }
                </th>
                <th>
                  Branch Name
                  {
                isAscending ?(
                  <IconButton
                    onClick={sortBranchNameAscending}
                  >
                     <ArrowUpward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                ):
                (
                  <IconButton 
                  onClick={sortBranchNameDescending}
                  >
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                )
              }  
                </th>
                </>
              }
             
              <th>
                Customer
                {
                isAscending ?(
                  <IconButton
                    onClick={sortCustomerAscending}
                  >
                     <ArrowUpward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                ):
                (
                  <IconButton 
                  onClick={sortCustomerDescending}
                  >
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                )
              }
              </th>
              <th>Contact</th>
              <th>
                Date
               
              </th>
              <th>Payment Method</th>
              <th>Invoice</th>
              <th>
                Amount
                {
                isAscending ?(
                  <IconButton
                    onClick={sortAmountAscending}
                  >
                     <ArrowUpward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                ):
                (
                  <IconButton 
                  onClick={sortAmountDescending}
                  >
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                )
              }
                </th>
             </tr>
           </thead>
          
<tbody>
            {
             paymentFilter?.length !==0 ? paymentFilter?.map((r,i)=>(
                <tr>
                <td>{i+1}</td>
                {
                  userRole === 'admin' &&
                  <>
                   <td>{r?.branchId}</td>
                  <td>{r?.locationName}</td>
                  </>
                }
                
                <td>{r?.customer}</td>
                <td>{r?.mobileNo}</td>
                <td>{
                  r?.date?.map((m)=>(
                    <p>{convertDateFormat(m)}</p>
                  ))
                }</td>
                <td>{
                  r?.paymentMethod?.map((k)=>(
                    <p>{`${k?.type}-${k?.paidAmount}`}</p>
                  ))
                  }</td>
                <td><span style={{color:'#069c54',fontSize:"0.8rem",fontWeight:600}}>{r?.invoiceNo}</span></td>
               <td>{r?.totalAmount?.toFixed(2)}</td>
              </tr>
              ))
              :
              <tr>
                <td colSpan={10}>SEARCH RECORDS</td>
              </tr>
            }
           
           </tbody>
            
           
        </table>
     </div>
    </div>

{/* payment report print */}
    <div 
       style={{display:'none'}}
     >
       <div ref={(el)=>(paymentReportPrint = el)}>
          <div className='print-view-top-container'>
            <div className='print-view-top-left-container' style={{width:"35%"}}>
            <h6 style={{margin:"0 0 0 2%"}}>Printed On : {convertDateFormat(today)}&nbsp;{getCurrentTime()}</h6>
             
            </div>
            <div className='print-view-top-middle-container' style={{width:"30%"}}>
            {userRole === "admin" ? (
                <h3>{loginResponse?.data?.profile?.companyName}</h3>
              ) : userRole === "user" ? (
                <h3>{loginResponse?.storeName}</h3>
              ) : undefined}
                 <p>PAYMENT REPORT</p>
                 {
                  fromDate!==""&& toDate!=="" &&
                 <h5 style={{margin:"0",textAlign:"center"}}>{`(${convertDateFormat(fromDate)}-${convertDateFormat(toDate)})`}</h5>
                 }
            </div>
            <div className='print-view-top-right-container' style={{width:"35%"}}>
            <p>{branchDetails!==null &&branchDetails.storeCode}-{branchDetails!==null ?branchDetails.branchName:"All Branches"}</p>
            </div>
          </div>
          <div className='print-view-table-container report-print-view-table-with-clr'>

          <table>
          <thead>
             <tr>
              <th>No</th>
              {
                userRole === 'admin' &&
                <>
                <th>Branch Code</th>
                <th>Branch Name</th>
                </>
              }
              
              <th>Customer</th>
              <th>Contact</th>
              <th>Date</th>
              <th>Payment Method</th>
              <th>Invoice</th>
              <th>Amount</th>
             </tr>
           </thead>
           <tbody>
           {
             paymentFilter?.length !==0 ? paymentFilter?.map((r,i)=>(
                <tr>
                <td>{i+1}</td>
                {
                  userRole === 'admin' &&
                  <>
                  <td>{r?.branchId}</td>
                  <td>{r?.locationName}</td>
                  </>
                }
                
                <td>{r?.customer}</td>
                <td>{r?.mobileNo}</td>
                <td>{
                  r?.date?.map((m)=>(
                    <p>{convertDateFormat(m)}</p>
                  ))
               }</td>
                <td>{
                  r?.paymentMethod?.map((k)=>(
                    <p>{`${k?.type}-${k?.paidAmount}`}</p>
                  ))
                  }</td>
                <td><span style={{color:'#069c54',fontSize:"0.8rem",fontWeight:600}}>{r?.invoiceNo}</span></td>
               <td>{r?.totalAmount?.toFixed(2)}</td>
              </tr>
              ))
              :
              <tr>
                <td colSpan={10}>No Data</td>
              </tr>
            }
           </tbody>
          </table>
          </div>
     
       </div>
     </div>
       <LoadingForm loading={isLoading} />
    </div>
  )
}
