
import React, { useEffect, useState } from 'react'
import ReactToPrint from 'react-to-print'
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter'
import { IconButton, Tooltip } from '@mui/material'
import CustomPagination from '../../../../Single Components/CustomPagination'
import { listPaymentAPI, listPaymentgAPI } from '../GeneralAPI'
import { useSelector } from 'react-redux'
import cash from "../../../../../Assets/SVG/payment-method.png"
import TableLoading from "../../../../Single Components/TableLoding"

const PosPayment = () => {
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [FormDataInfo, setFormDataInfo] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const list = useSelector((state) => state?.GeneralSlice?.paymentListData)
  const handleDataFromChild = (data) => {
    setFormDataInfo(data)

  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch);
  };
  const hasData = list && list.length > 0;

  console.log(list);

  useEffect(() => {
    listPaymentAPI(setIsLoading)
  }, [])
  return (
    <div className='pos-conatiner' style={{ backgroundColor: "unset", padding: "0" }}>
      <div className="new-order-top-container" style={{ padding: "0", backgroundColor: "white", paddingInline: "10px" }}>
        <div className='new-order-top-left' >
          {/* <div className='new-order-top-left-home' onClick={homeBtnClickFn}>
             <HomeIcon/> 
           </div> */}
          <p className='new-order-top-left-p'>PAYMENT</p>
        </div>
        <div className="justify-center" style={{ gap: "10px", width: "70%", justifyContent: "flex-end" }}>
          <ReactToPrint
            trigger={() => (
              <IconButton className="printer-div" style={{ color: "#fff", backgroundColor: "#002995", borderRadius: "0" }}>
                <i class="bi bi-printer"></i>
              </IconButton>
            )}
          //   content={() => paymentsPrint}
          />
          <Tooltip title="Download">
            <IconButton style={{ backgroundColor: "#ffd2d2", color: "#002995", borderRadius: "0" }}>
              <i class="bi bi-arrow-down-circle"></i>
            </IconButton>
          </Tooltip>

          <CategorySearchandFilter
            statusOptions={[]}
            onData={handleDataFromChild}
            // isCreatedBy={}
            // isStatus={}
            onBranchSelect={handleBranchSelect}
            isSearch={true}
            isDate={false}
          //  isPos={true}
          />

        </div>
      </div>
      <div className="flex-column table-div-container" >
        <div className='pagi-div'>
          <CustomPagination
            currentPage={currentPage}
          // totalPages={totalPages}
          // onPageChange={handlePageChange}
          // setFormDataInfo={setFormDataInfo}
          />
        </div>
        <div className="new-global-table-container pos-table" style={{ paddingTop: "0 42px", maxHeight: "calc(100vh - 196px)" }}>

          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Sequence</th>
                <th>Order Ref</th>
                <th>Customer</th>
                <th>Branch</th>
                <th>Shift</th>
                <th>Employee</th>
                <th>Payment Method</th>
                <th>Amount </th>
                <th>Status</th>
              </tr>
            </thead>
            {isLoading ? (
                <TableLoading  colSpan={10} rowcount={10}/>
              ) : (
            <tbody>
              {hasData ? list?.map((r, i) => (
                <tr key={i} style={{ backgroundColor: i % 2 === 0 ? "white" : "#e9e7e7" }}>
                  <td>{r?.date}</td>
                  <td>{r?.sequence}</td>
                  <td>{r?.orderRef}</td>
                  <td>{r?.customer}</td>
                  <td>{r?.branch}</td>
                  <td>{r?.shift}</td>
                  <td>{r?.employe}</td>
                  <td>{r?.paymentMethod}</td>
                  <td style={{ color: r.amount < 0 ? 'red' : 'black'}}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {r?.amount < 0 && <img src={cash} alt="Cash" style={{ width: '20px', height: '20px', marginRight: '5px' }} />}
                      {r?.amount}
                    </div>
                  </td>
                  <td> <p
                    className={
                      r?.status === "POSTED"
                        ? "shift-status-active-table"
                        : "shift-status-end"
                    }
                  >
                    {r?.status === "POSTED" ? "POSTED" : "Ended"}
                  </p></td>
                </tr>
              )) : (
                <tr>
                  <td colSpan={10}>NO DATA</td>
                </tr>
              )
              }
            </tbody>
              )}
          </table>
        </div>
      </div>
    </div>
  )
}

export default PosPayment