import { Autocomplete, IconButton, TextField, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { categoryListAPICall } from '../../Inventory/Configuration/Category/CategoryAPI';
import { viewAllAllowedBranchesAPICall } from '../../../../API/Settings/Company Settings/companySettingsAPI';
import { createPromotionAndOffersAPI, editedPromotionAndofferAPI, SingleViewPromotionAPI } from './PromotionAPI';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FilePicker } from '../../../Single Components/filePicker';
import { productsFilterOptions } from '../../../Single Components/GeneralUtilities';

const CreatePromotions = () => {
    let navigateTo = useNavigate();
  const singleViewId = localStorage?.getItem("rowId")
  const allBranchesList = useSelector(
    (state) => state.allBranchesSlice.value);
  const userRole = useSelector((state) => state.userRoleSlice.value)
  const userInfo = useSelector((state) => state.loginResponseSlice.value);
  const allowedBranchList = useSelector((state) => state.allAllowedBranchesSlice.value);
  const allNewProductList = useSelector((state) => state.vendorBillSlice.NewProductList);
  const CategoryList = useSelector((state) => state.categorySlice.value);
  const singleView = useSelector((state) => state.PromotionSlice.singleViewPromotion);
  // console.log(singleView);
  const FormInitial = {
    isEdit: false,
    isActive: true,
    name: '',
    branch: [],
    promotionType: "Discount",
    startDate: "",
    endDate: "",
    category: [],
    qty: "",
    product: [],
    discount: "",
    productReward: [],
    qtyReward: ""
  }
  const [formData, setFormData] = useState(FormInitial)
  const [image, setImage] = useState()
  const [validationAlert, setValidationAlert] = useState({});

  // onChange
  const getFormPostInfo = (key) => (e, newValue) => {
    setValidationAlert({});
    const { value } = e.target;
    setFormData({
      ...formData,
      [key]: value !== 0 ? value : newValue,
    });
  };
  const handleBranchChange = (event, newValue) => {
    setValidationAlert({});
    setFormData({ ...formData, branch: newValue });
  };
  const categoryOnchange = (event, newValue) => {
    setValidationAlert({});
    setFormData({ ...formData, category: newValue });
  };
  const productOnchange = (event, newValue) => {
    setValidationAlert({});
    setFormData({ ...formData, product: newValue });
  };
  const rewardProductOnchange = (event, newValue) => {
    setFormData({ ...formData, productReward: newValue });
  };
  const handleDiscountChange = (e) => {
    const { value } = e.target;
    // Check if the value is numeric and less than or equal to 100
    if (value === '' || (!isNaN(value) && parseInt(value) <= 100)) {
      setFormData({
        ...formData,
        discount: value
      });
    }
  };

  const imageOnchange = (e) => {
    let file = e.target.files[0];
    setImage(file)
  }

  // console.log(formData);

  const DocumentFormData = new FormData();
  DocumentFormData.append("name", formData?.name)
  DocumentFormData.append("file", image)
  DocumentFormData.append("allowBranches", JSON.stringify(formData?.branch?.map((obj) => obj._id)))
  DocumentFormData.append("type", formData?.promotionType === "Discount" ? 1 : formData?.promotionType === "Buy X Get Y" ? 2 : null)
  DocumentFormData.append("startDate", formData?.startDate)
  DocumentFormData.append("endDate", formData?.endDate)
  DocumentFormData.append("products", JSON.stringify(formData?.product?.map((obj) => obj._id)))
  DocumentFormData.append("categoryIds", JSON.stringify(formData?.category.map((obj) => obj._id)))
  DocumentFormData.append("isActive", formData?.isActive)
//   DocumentFormData.append("qty", formData?.qty)
//   DocumentFormData.append("rewardProduct", JSON.stringify({ productIds: formData?.productReward.map((obj) => obj._id), qty: formData?.qtyReward }))


if (formData?.promotionType === "Discount") {
    DocumentFormData.append("discount", formData?.discount === "null" ? null : formData?.discount)
  }
if (formData?.promotionType === "Buy X Get Y") {
    DocumentFormData.append("rewardProduct", JSON.stringify({
      productIds: formData?.productReward.map((obj) => obj._id),
      qty: formData?.qtyReward
    }));
    DocumentFormData.append("qty", formData?.qty)
  }
  //   for (let pair of DocumentFormData.entries()) {
  //     console.log(pair[0] + ', ' + pair[1]);
  // }



  const submitBtnClicked = () => {
    console.log("submit");
    if (formData?.name === '' || formData?.undefined) {
      setValidationAlert({
        ...validationAlert,
        name: "fill this field !!",
      });
    }
    else if (formData?.branch?.length === 0 || formData?.undefined) {
      setValidationAlert({
        ...validationAlert,
        branch: "fill this field !!",
      });
    }

    else if (formData?.promotionType === '' || formData?.promotionType === undefined) {
      setValidationAlert({
        ...validationAlert,
        promotionType: "fill this field !!",
      });
    }
    else if (formData?.startDate === '' || formData?.startDate === undefined) {
      setValidationAlert({
        ...validationAlert,
        startDate: "fill this field !!",
      });
    }
    else if (formData?.endDate === '' || formData?.endDate === undefined) {
      setValidationAlert({
        ...validationAlert,
        endDate: "fill this field !!",
      });
    }
    // else if (formData?.qty === '' || formData?.qty === undefined) {
    //   setValidationAlert({
    //     ...validationAlert,
    //     qty: "fill this field !!",
    //   });
    // }
    else {
      createPromotionAndOffersAPI(DocumentFormData)
    }
  }
  // edit data 
  const onClickEdit = () => {
    if (formData.isEdit) {
      setFormData({ ...formData, isEdit: false });
    }
    else {
      if (formData?.name === '' || formData?.undefined) {
        setValidationAlert({
          ...validationAlert,
          name: "fill this field !!",
        });
      }
      else if (formData?.branch?.length === 0 || formData?.undefined) {
        setValidationAlert({
          ...validationAlert,
          branch: "fill this field !!",
        });
      }
      else if (formData?.promotionType === '' || formData?.promotionType === undefined) {
        setValidationAlert({
          ...validationAlert,
          promotionType: "fill this field !!",
        });
      }
      // else if (formData?.promotionType === '' || formData?.promotionType === undefined) {
      //   setValidationAlert({
      //     ...validationAlert,
      //     promotionType: "fill this field !!",
      //   });
      // }
      else if (formData?.startDate === '' || formData?.startDate === undefined) {
        setValidationAlert({
          ...validationAlert,
          startDate: "fill this field !!",
        });
      }
      else if (formData?.endDate === '' || formData?.endDate === undefined) {
        setValidationAlert({
          ...validationAlert,
          endDate: "fill this field !!",
        });
      }
      // else if (formData?.qty === '' || formData?.qty === undefined) {
      //   setValidationAlert({
      //     ...validationAlert,
      //     qty: "fill this field !!",
      //   });
      // }
      else {

        // const DocumentFormData = new FormData();
        // DocumentFormData.append("name", formData?.name)
        // DocumentFormData.append("file", image)
        // DocumentFormData.append("allowBranches", JSON.stringify(formData?.branch?.map((obj) => obj._id)))
        // DocumentFormData.append("type", formData?.promotionType === "Discount" ? 1 : formData?.promotionType === "Buy X Get Y" ? 2 : null)
        // DocumentFormData.append("startDate", formData?.startDate)
        // DocumentFormData.append("endDate", formData?.endDate)
        // DocumentFormData.append("products", JSON.stringify(formData?.product?.map((obj) => obj._id)))
        // DocumentFormData.append("discount", formData?.discount)
        // DocumentFormData.append("categories", JSON.stringify({ categoryIds: formData?.category.map((obj) => obj._id), qty: formData.qty }))
        // DocumentFormData.append("isActive", formData?.isActive)
        // DocumentFormData.append("rewardProduct", JSON.stringify({ productIds: formData?.productReward.map((obj) => obj._id), qty: formData?.qtyReward }))
        DocumentFormData.append("_id", singleViewId)


        const draftFn = () => {
          setFormData({ ...formData, isEdit: true });
        };
        editedPromotionAndofferAPI(DocumentFormData, draftFn)
      }
    }
  }

  //singleView
  useEffect(() => {
    if (singleView !== undefined) {
      setFormData({
        ...formData,
        isEdit: true,
        isActive: singleView?.isActive,
        name: singleView?.name,
        branch: allowedBranchList !== undefined ? allowedBranchList?.filter(item => singleView?.allowBranches.some(branch => item._id === branch.branchId)) : [],
        promotionType: singleView?.type,
        startDate: singleView?.startDate,
        endDate: singleView?.endDate,
        category: CategoryList !== undefined ? CategoryList?.filter(item => singleView?.categories?.some(catogery => item._id === catogery.categoryId)) : [],
        qty: singleView?.qty,
        product: allNewProductList !== undefined ? allNewProductList.filter(item => singleView?.products?.some(product => item._id === product.productId)) : [],
        discount: singleView?.discount,
        productReward: allNewProductList !== undefined ? allNewProductList.filter(item => singleView?.rewardProducts?.some(product => item._id === product.productId)) : [],
        qtyReward: singleView?.rewardQty

      })
    }
    setImage(singleView?.imageUrl)
  }, [singleView])
  // console.log(formData);

  useEffect(() => {
    if (singleViewId !== undefined) {
      SingleViewPromotionAPI({ _id: singleViewId });
    }
  }, [])

  useEffect(() => {
    categoryListAPICall();
    userRole === "user" && viewAllAllowedBranchesAPICall({ employeeId: userInfo?.data?._id });

  }, [])

  return (
    <div className="global-page-parent-container">
      <div
        className="justify-space-between global-white-bg-container"
        style={{ padding: "0 4%" }}
      >
        <h3 style={{ style: "17px 0px 15px 29px" }}>Promotion & Offers</h3></div>
      <div
        className="global-white-bg-container"
        style={{ padding: "0 2%" }}
      >
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ alignContent: "center" }}>
              <Tooltip title="Back">
                <IconButton onClick={() => {
                  navigateTo(
                    "/userdashboard/pos/promotions"
                  );
                }}>
                  <i className="bi bi-chevron-left" style={{ fontSize: '30px', color: 'black', cursor: 'pointer' }}></i>
                </IconButton>
              </Tooltip>
              {singleView !== undefined && (
                <Tooltip title="Edit">
                  <IconButton onClick={onClickEdit}>
                    <i
                      class={formData?.isEdit ?
                        "bi bi-pencil-square edit-icon1"
                        :
                        "bi bi-check2-square edit-icon"
                      }
                    ></i>
                  </IconButton>
                </Tooltip>
              )}
              {singleView === undefined && (
                <Tooltip title="Save">
                  <IconButton onClick={submitBtnClicked}>
                    <i className="bi bi-floppy" style={{ color: 'black' }}></i>
                  </IconButton>
                </Tooltip>
              )}
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <h3>Active</h3>
              <input
                style={{ marginRight: "50px" }}
                className="toggle-checkbox"
                type="checkbox"
                checked={formData.isActive}
                onChange={() => setFormData({ ...formData, isActive: !formData.isActive })}
                disabled={formData?.isEdit}
              />
            </div>
          </div>
          <hr className="global-hr" />
        </div>
        <div style={{ padding: "16px 0px 37px 0px", display: "flex" }}>
          <div style={{ width: "73%" }} >
            <div className="new-global-single-input" style={{ width: "90%", padding: "1px 1px 20px 13px" }}>
              <TextField
                value={formData?.name}
                onChange={getFormPostInfo("name")}
                id="outlined-basic"
                label="Name*"
                variant="outlined"
                type="text"
                focused
                disabled={formData?.isEdit}
              />
              <p className="doc-validation-alert" style={{ top: '67%' }}>{validationAlert?.name}</p>

            </div>

            <div className="new-global-single-input auto-complete-new" style={{ width: "90%", padding: "1px 1px 20px 13px" }}>
  <Autocomplete
    multiple
    disablePortal
    options={(userRole === "admin" ? allBranchesList : allowedBranchList) || []}
    getOptionLabel={(option) => option?.branchName || ''}
    placeholder='Branch'
    sx={{ width: 300 }}
    renderInput={(params) => (
      <TextField {...params} label="Branch" focused placeholder='' />
    )}
    value={formData?.branch || []}
    onChange={handleBranchChange}
    disabled={formData?.isEdit}
  />
  <p className="doc-validation-alert" style={{ top: '67%' }}>{validationAlert?.branch}</p>
</div>

            <div style={{ display: "flex", justifyContent: "space-between", width: '91%' }}>
              <div className="new-global-single-input auto-complete-new" style={{ width: "40%", padding: "1px 1px 20px 13px" }}>
                <Autocomplete
                  disablePortal
                  options={['Discount', 'Buy X Get Y']}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Promtion Type*" focused placeholder='' />
                  )}
                  value={formData?.promotionType}
                  onChange={getFormPostInfo("promotionType")}
                  disabled={formData?.isEdit}
                />
                <p className="doc-validation-alert" style={{ top: '67%' }}>{validationAlert?.promotionType}</p>

              </div>
              <div className="new-global-single-input" style={{ width: '21%' }}>
                <TextField
                  id="outlined-basic"
                  placeholder="From"
                  onChange={getFormPostInfo("startDate")}
                  value={formData?.startDate}
                  variant="outlined"
                  type="date"
                  focused
                  label="Start Date"
                  disabled={formData?.isEdit}
                />
                <p className="doc-validation-alert" style={{ top: '67%' }}>{validationAlert?.startDate}</p>

              </div>
              <div className="new-global-single-input" style={{ width: '21%' }}>
                <TextField
                  id="outlined-basic"
                  placeholder="From"
                  onChange={getFormPostInfo("endDate")}
                  value={formData?.endDate}
                  variant="outlined"
                  type="date"
                  focused
                  label="End Date"
                  disabled={formData?.isEdit}
                  inputProps={{
                    min: formData?.startDate // Set minimum date to start date
                  }}
                />
                <p className="doc-validation-alert" style={{ top: '67%' }}>{validationAlert?.endDate}</p>

              </div>
            </div>
          </div>
          <div>
            <div className="global-single-input " style={{ width: "100%" }}>
              <p>Image Upload</p>
              <FilePicker
                id="image"
                uploadImageUrl={image}
                setImage={image}
                onChange={imageOnchange}
                disabled={formData?.isEdit}
              />
            </div>
          </div>
        </div>
      </div>
      {/* Condition  */}
      <div className="global-white-bg-container" style={{ padding: "0 2%" }}>
        <h3 style={{ margin: "5px", padding: "18px 1px 18px 19px" }}>Condition</h3>

        <div style={{ width: "83%" }} >
          <div style={{ display: "flex" }}>
            <div className="new-global-single-input auto-complete-new" style={{ width: formData?.promotionType === "Buy X Get Y" ? "80%" : "95%", padding: "1px 1px 20px 13px" }}>
              <Autocomplete
                multiple
                disablePortal
                options={CategoryList || []}
                getOptionLabel={(option) => option?.categoryName}
                // placeholder='Branch'
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Catogery" focused placeholder='' />
                )}
                value={formData?.category}
                onChange={categoryOnchange}
                disabled={formData?.isEdit}
              />
            </div>
            {formData?.promotionType === "Buy X Get Y" && (
              <div className="new-global-single-input" style={{ width: "13%", padding: "1px 1px 20px 13px" }}>
                <TextField
                  value={formData?.qty}
                  onChange={getFormPostInfo("qty")}
                  id="outlined-basic"
                  label="Qty*"
                  variant="outlined"
                  type="text"
                  focused
                  disabled={formData?.isEdit}
                />
              </div>
            )}
          </div>
          <div className="new-global-single-input auto-complete-new" style={{ width: "95%", padding: "1px 1px 20px 13px" }}>
            <Autocomplete
              multiple
              disablePortal
              options={allNewProductList || []}
              getOptionLabel={(option) => option?.productName}
              // placeholder='Branch'
              renderOption={(props, option) => (
                <li {...props} key={option._id}>
                  <span>{option.productName}</span>
                </li>
              )}
              filterOptions={productsFilterOptions}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Product" focused placeholder='' />
              )}
              value={formData?.product}
              onChange={productOnchange}
              disabled={formData?.isEdit}
            />
          </div>

        </div>
      </div>

      {/* discount and reward */}
      {formData?.promotionType === "Buy X Get Y" && (
        <div className="global-white-bg-container" style={{ padding: "0 2%" }}>
          <h3 style={{ margin: "5px", padding: "18px 1px 18px 19px" }}>Reward Product</h3>
          <div style={{ display: "flex", width: "40%" }}>
            <div className="new-global-single-input auto-complete-new" style={{ width: "75%", padding: "1px 1px 20px 13px" }}>
              <Autocomplete
                multiple
                disablePortal
                options={allNewProductList || []}
                getOptionLabel={(option) => option?.productName}
                // placeholder='Branch'
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    <span>{option.productName}</span>
                  </li>
                )}
              filterOptions={productsFilterOptions}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Product" focused placeholder='' />
                )}
                value={formData?.productReward}
                onChange={rewardProductOnchange}
                disabled={formData?.isEdit}
              />
            </div>
            <div className="new-global-single-input" style={{ width: "25%", padding: "1px 1px 20px 13px" }}>
              <TextField
                value={formData?.qtyReward}
                onChange={getFormPostInfo("qtyReward")}
                id="outlined-basic"
                label="Qty*"
                variant="outlined"
                type="text"
                focused
                disabled={formData?.isEdit}
              />
            </div>
          </div>

        </div>
      )}

      {formData?.promotionType === "Discount" && (
        <div className="global-white-bg-container" style={{ padding: "0 2%" }}>
          <h3 style={{ margin: "5px", padding: "18px 1px 18px 19px" }}>Discount</h3>
          <div style={{ display: "flex", width: "40%" }}>

            <div className="new-global-single-input" style={{ width: "75%", padding: "1px 1px 20px 13px" }}>
              <TextField
                value={formData?.discount}
                onChange={handleDiscountChange}
                id="outlined-basic"
                label="Discount**"
                variant="outlined"
                type="text"
                focused
                disabled={formData?.isEdit}
                inputProps={{
                  max: 100 // Set maximum value to 100
                }}
              />
            </div>
          </div>

        </div>
      )}
    </div>
  )
}


export default CreatePromotions