import React from "react";
import { TextField, styled } from "@mui/material";

const StyledTextField = styled(TextField)(({ theme, error }) => ({
  "& .MuiInputLabel-root": {
    transform: "translate(14px, -9px) scale(0.75)",
    "&.Mui-focused": {
      transform: "translate(14px, -9px) scale(0.75)",
    },
  },
  "& .MuiInputLabel-shrink": {
    transform: "translate(14px, -9px) scale(0.75)",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      //   borderColor: error ? theme.palette.error.main : theme.palette.primary.main,
    },
    "&:hover fieldset": {
      borderColor: error
        ? theme.palette.error.dark
        : theme.palette.primary.dark,
    },
    "&.Mui-focused fieldset": {
      borderColor: error
        ? theme.palette.error.main
        : theme.palette.primary.main,
    },
  },
  // New styles for the helper text (error message)
  "& .MuiFormHelperText-root": {
    marginLeft: 0, // Remove left margin
    // paddingLeft: '14px',
  },
}));

const StyledInputBox = ({
  label,
  id,
  name,
  value,
  onChange,
  error,
  errorMessage,
  type,
  ...props
}) => {
  return (
    <StyledTextField
      type={type}
      label={label}
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      variant="outlined"
      size="small"
      error={error}
      helperText={error ? errorMessage : ""}
      InputLabelProps={{ shrink: true }}
      fullWidth
      {...props}
    />
  );
};

export default StyledInputBox;
