import React, { useState } from "react";
import "../../../../css/Orders/delivery.css";
import {
  Select,
  IconButton,
  Autocomplete,
  TextField,
  Collapse,
  Dialog,
  Tooltip,
  createFilterOptions,
} from "@mui/material";
import { PaymentsWO } from "../WorkOrder/PaymentsWO";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useEffect } from "react";
import {
  deliveryWithBillingAPICall,
  generateDeliveryId,
  generateDeliveryIdAPICall,
  getDeliveryDataForUserAPICall,
  getListOfDeliveryIdAPICall,
  getOldBalancesOfCustomerAPICall,
  getPaymentDetailsOfCustomerInDeliveryAPICall,
  updateStatusToDeliveredAPICall,
} from "../../../../API/Orders/DeliveryAPI";
import { useSelector } from "react-redux";
import { viewAllCustomersAPICall } from "../../../../API/Customer/customerAPI";
import { convertDateFormat, getCurrentTime, previousDate, today } from "../../../../Js/Date";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import { extractObjectForSingleView, filterObjFromList } from "../../../../Js/generalFunctions";
import { LoadingForm } from "../../../Single Components/LoadingForm";
export const Delivery = () => {
  //Customer id from LS , saved it from viewOrders icon click
  const cusIdFromLs = localStorage.getItem("DELRY_CUS_ID");

  const userLoginMeta = localStorage.getItem('login_meta')
  const userBranchId=JSON.parse(userLoginMeta)?.branchPk;
  const userRole=useSelector((state)=>state.userRoleSlice.value)
  
  const storeCode=localStorage.getItem('branchId')
  //*redux states
  //New delivery id generated
  const deliveryId = useSelector((state) => state.deliveryIdSlice.value);
  //List of All Customers
  const customerList = useSelector((state) => state.allCustomerListSlice.value);
  //List of Delivery Ids
  const listOfDeliveryIds = useSelector(
    (state) => state.deliveryIdListSlice.value
  );
  //UserFilter Result
  const userFilterResponse = useSelector(
    (state) => state.deliveryFilterDataSlice.value
  );
  //Delivery Payment details response
  const deliveryPaymentDetailsResponse = useSelector(
    (state) => state.deliveryPaymentDetailsSlice.value
  );
  //Delivery Old balances response
  const deliveryOldBalancesResponse = useSelector(
    (state) => state.deliveryOldBalanceSlice.value
  );

  //Snack bar states
  const [successSnackOpen, setSuccessSnackOpen] = useState(false);
  const [errorSnackOpen, setErrorSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState("oops!!");

  const [isModify, setIsModify] = useState(false);

  const [payment, setPayment] = useState(false);
  const [suffixBtnState, setSuffixBtnState] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState(false);
  const [oldBalance, setOldBalance] = useState(false);
  const [oldBalancePayment, setOldBalancePayment] = useState(false);

  const [isExpand, setIsExpand] = useState(false);
  const [searchKeyWord,setSearchKeyWord]=useState("")
  const [loadingForm, setLoadingForm] = useState(false);
  const [btnClickState,setBtnClickState]=useState(false)
  const getSearchKeyword=(e)=>{
    setSearchKeyWord(e.target.value)
  }
  //User Filter Form
  const userFilterFormInitialState = {
    deliveryId: null,
    customerId: null,
    fromDate: previousDate,
    toDate: today,
  };
  const [userFilterForm, setUserFilterForm] = useState(
    userFilterFormInitialState
  );
  //UserFilter response State for front end
  const [userFilterResponseFrontEnd, setUserFilterResponseFrontEnd] = useState(
    []
  );
  const [filteredSearchList,setFilteredSearchList]=useState([])

  //WorkOrder Numbers
  const [workOrderNumbers, setWorkOrderNumbers] = useState([]);
  //Deliver list initial for API body
  const [deliverListInitial, setDeliverListInitial] = useState([]);
  //Selected material subtotal
  const [selectedItemsSubTotal, setSelectedItemsSubTotal] = useState(0);
  //Selected Items Order total
  const [selectedOrderTotal, setSelectedOrderTotal] = useState(0);
  //Selected Order shipmentCharge
  const [selectedOrderShipmentCharge, setSelectedOrderShipmentCharge] =
    useState(0);
  //Selected Work order id
  const [selectedWOId, setSelectedWOId] = useState(null);
  //Selected customer obj from view orders shortcut
  const [selectedCusObj, setSelectedCusObj] = useState(null);
  //Selected input value for customer autocomplete for display purpose
  const [displayCus, setDisplayCus] = useState("customer default");
  //Multiple Payment or not
  const [isMultiplePayment, setIsMultiplePayment] = useState(false);
  //Old balance selected work order
  const [oldBalanceSelected, setOldBalanceSelected] = useState(null);
  //Balance Work order amount to be paid
  const [balanceWoAmount, setBalanceWoAmount] = useState();

  //*onChange
  //onchange for user filter form
  const getUserFilterForm = (key) => (e, newValue) => {
    switch (key) {
      case "deliveryId":
        setUserFilterForm({
          ...userFilterForm,
          deliveryId: newValue?.deliveryNo,
        });
        break;
      case "customer":
        setSelectedCusObj(newValue);
        // setUserFilterForm({ ...userFilterForm, customerId: newValue?._id });
        // selectedCusObj(newValue)
        break;
      case "fromDate":
        setUserFilterForm({ ...userFilterForm, fromDate: e.target.value });
        break;
      case "toDate":
        setUserFilterForm({ ...userFilterForm, toDate: e.target.value });
        break;

      default:
        break;
    }
  };
  //TODO Extract customer onClick of a row
  // console.log(userFilterResponseFrontEnd);
  //Check Type row
  const checkTypeRow = (index) => () => {
    // userFilterResponseFrontEnd[index].isTypeChecked =
    //   !userFilterResponseFrontEnd[index].isTypeChecked;
    //    checkAllChildItems(index);
    // setUserFilterResponseFrontEnd([...userFilterResponseFrontEnd]);
    filteredSearchList[index].isTypeChecked =
      !filteredSearchList[index].isTypeChecked;
       checkAllChildItems(index);

       setFilteredSearchList([...filteredSearchList]);
       //Extract all checked Items
    let _deliverListInitial = extractAllCheckedItems(
      filteredSearchList
    );
       // setCurrentTypeCheckIndex(index)
    findMultipleOrdersCheck(
      filteredSearchList,
      _deliverListInitial,
      index
    );

    setSuffixBtnState(false);
    if (filteredSearchList[index].isTypeChecked) {
      let _selectedCusId = filteredSearchList[index].products[0].cusId;
      extractCusObj(_selectedCusId);
    }
  };




  //Check Expanded items checked
  const checkExpandedItemsRow = (pIndex, cIndex) => (e) => {
    userFilterResponseFrontEnd[pIndex].products[cIndex].isItemChecked =
      !userFilterResponseFrontEnd[pIndex].products[cIndex].isItemChecked;
    checkTypeRowIfAllChildChecked(pIndex);
    setUserFilterResponseFrontEnd([...userFilterResponseFrontEnd]);

    
    let _deliverListInitial = extractAllCheckedItems(
      userFilterResponseFrontEnd
    );
   
    findMultipleOrdersCheck(
      userFilterResponseFrontEnd,
      _deliverListInitial,
      pIndex,
      cIndex
    );

    setSuffixBtnState(false);

    if (userFilterResponseFrontEnd[pIndex].isTypeChecked) {
      let _selectedCusId = userFilterResponseFrontEnd[pIndex].products[0].cusId;
      extractCusObj(_selectedCusId);
    }
  };

  // const checkExpandedItemsRow = (pIndex, cIndex) => (e) => {
  //   filteredSearchList[pIndex].products[cIndex].isItemChecked =
  //     !filteredSearchList[pIndex].products[cIndex].isItemChecked;
  //   checkTypeRowIfAllChildChecked(pIndex);
  //   setUserFilterResponseFrontEnd([...filteredSearchList]);

    
  //   let _deliverListInitial = extractAllCheckedItems(
  //     filteredSearchList
  //   );
   
  //   findMultipleOrdersCheck(
  //     filteredSearchList,
  //     _deliverListInitial,
  //     pIndex,
  //     cIndex
  //   );

  //   setSuffixBtnState(false);

  //   if (filteredSearchList[pIndex].isTypeChecked) {
  //     let _selectedCusId = filteredSearchList[pIndex].products[0].cusId;
  //     extractCusObj(_selectedCusId);
  //   }
  // };

  // console.log(oldBalanceSelected);

  //*Fns

  //Filter Selected Customer object (id obtained from view orders )
  const extractCusObj = (cusId) => {
    if (customerList !== undefined) {
      let _extractedCusObj = extractObjectForSingleView(cusId, customerList);
      setSelectedCusObj(_extractedCusObj[0]);
    }
  };
  // console.log(selectedCusObj);

  //Close SuccessSnackbar
  const closeSuccessSnackbar = () => {
    setSuccessSnackOpen(false);
  };
  //Close Error Snackbar
  const closeErrorSnackbar = () => {
    setErrorSnackOpen(false);
  };

  //update Listener
  const updateListener = () => {
    setIsModify(!isModify);
  };

  //Check all child items if parent type is checked
  const checkAllChildItems = (index) => {
    let _isTypeChecked = filteredSearchList[index].isTypeChecked;
    if (_isTypeChecked) {
      console.log("fn invoked");
      filteredSearchList[index].products?.forEach((obj) => {
        obj.isItemChecked = true;
      });
    } else {
      filteredSearchList[index].products?.forEach((obj) => {
        obj.isItemChecked = false;
      });
    }
  };

  //Check Type Row if all child is checked
  const checkTypeRowIfAllChildChecked = (pIndex) => {
    let _itemsCheckedStatusArray = userFilterResponseFrontEnd[
      pIndex
    ].products?.map((obj) => {
      if (obj.isItemChecked) return true;
      else return false;
    });

    if (!_itemsCheckedStatusArray.includes(false)) {
      userFilterResponseFrontEnd[pIndex].isTypeChecked = true;
    } else {
      userFilterResponseFrontEnd[pIndex].isTypeChecked = false;
    }

    // console.log(_itemsCheckedStatusArray);
  };

  //Table Expand Fn
  const tableExpandFunction = (index) => () => {
    filteredSearchList[index].isExpand =
      !filteredSearchList[index].isExpand;
    setIsExpand(!isExpand);
  };

  const openPaymentPopup = () => {
    if (
      selectedCusObj === null 
      
    ) {
      alert("Select a customer and click submit to continue!!");
    } else if (deliverListInitial.length === 0) {
      alert("Select items to deliver!!");
    } else {
      setPayment(true);
    }
  };
  const closePaymentPopup = () => {
    setPayment(false);
  };

  // suffix button change function
  const suffixBtnChange = () => {
    setSuffixBtnState(!suffixBtnState);
    cancelButtonClick()
  };

  const paymentDetailsDialogClose = () => {
    setPaymentDetails(false);
  };

  const oldBalancesDialogClose = () => {
    setOldBalance(false);
  };

  // old balance payment pop up function
  const oldBalancePaymentPopupOpen = (r) => () => {
    setOldBalanceSelected(r);
    setOldBalancePayment(true);
  };
  const oldBalancePaymentPopupClose = () => {
    setOldBalancePayment(false);
  };

  //Body for Delivery user side filter
  const userSideFilterBody = {
    deliveryNo: userFilterForm?.deliveryId || null,
    cusId: userFilterForm?.customerId || null,
    fromDate:
      (userFilterForm?.fromDate && `${userFilterForm?.fromDate} 00:00:00`) ||
      null,
    toDate:
      (userFilterForm?.toDate && `${userFilterForm?.toDate} 23:59:00`) || null,
    branchId:storeCode
  };

  //Click Filter submitBtn userSide
  const clickUserSubmitBtn = () => {
    getDeliveryDataForUserAPICall(userSideFilterBody,setLoadingForm);
  };
  //Create userFilterResponse For front end (to prevent not extensible behavior)
  const createUserFilterResponseForFrontEnd = (response) => {
    if (response !== undefined) {
      let _response = response?.map((obj) => {
        return {
          ...obj,
          products: obj.products?.map((cObj) => {
            {
              return {
                ...cObj,
                workorderId: obj?.workorderId,
                typeId: obj?.typeId,
                isItemChecked: false,
              };
            }
          }),
          isTypeChecked: false,
        };
      });
      setUserFilterResponseFrontEnd(_response);
    } else {
      setUserFilterResponseFrontEnd([]);
    }
  };

  //Extract all work order numbers into an array for calling payment details api
  const getAllWorkOrderNumbers = (response) => {
    if (response !== undefined) {
      let _allWoNos = response?.map((obj) => obj.orderNo);
      let _uniqueWoNos = [...new Set(_allWoNos)];
      // console.log(_allWoNos);
      // console.log(_uniqueWoNos);
      setWorkOrderNumbers(_uniqueWoNos);
    } else {
      setWorkOrderNumbers([]);
    }
  };

  //Body to view Payment details
  const paymentDetailsBody = {
    workorderNos: workOrderNumbers,
    cusId: userFilterForm?.customerId,
    branchId:storeCode
  };
  //Click PaymentDetails Button FN
  const clickPaymentDetailsBtn = () => {
    if (workOrderNumbers?.length === 0) {
      alert("No orders available for delivery!!");
    } else if (userFilterForm?.customerId === null) {
      alert("Select a customer first!!");
    } else {
      getPaymentDetailsOfCustomerInDeliveryAPICall(paymentDetailsBody);
      setPaymentDetails(true);
    }
  };

  //Click Old balance button fn
  const clickOldBalanceBtn = () => {
    if (userFilterForm?.customerId === null) {
      alert("Select a customer first!!");
    } else {
      getOldBalancesOfCustomerAPICall({ cusId: userFilterForm?.customerId,branchId:storeCode });
      setOldBalance(true);
    }
  };

  //Extract all checked Items
  const extractAllCheckedItems = (parentArray) => {
    console.log(parentArray);
    let _allCheckedProdItems = [];
    let _deliverListInitial = [];

    //Extract checked Items as objects of a single array
    parentArray?.forEach((pObj) => {
      let _singleTypeProdItemsChecked = pObj?.products?.map((cObj) =>
          cObj?.isItemChecked && {
            ...cObj,
            orderTotal: pObj.total,
            shipmentCharge: pObj.shipmentCharge,
            balance: pObj.balance,
          }
      );
      _allCheckedProdItems.push(..._singleTypeProdItemsChecked);
      
    });

    // set balance amount into state  if 0 no payments due
    setBalanceWoAmount(
      _allCheckedProdItems?.filter((obj) => obj !== false)[0]?.balance
    );

    
    //convert checked product Items into API body like structure
    calculateSubTotal(_allCheckedProdItems);
    _deliverListInitial = _allCheckedProdItems
      .map((obj) => {
        if (typeof obj === "object" && obj !== null) {
          return {
            workorderId: obj.workorderId,
            uuid:obj.uuid,
            typeId: obj.typeId,
            productId: obj.productId,
            deliveredQty: obj.completedQty,
            deliveryNo: deliveryId?.deliveryNo || null,
          };
        }
      })
      .filter((obj) => obj !== undefined);
    setSelectedWOId(_deliverListInitial[0]?.workorderId || null);
    setDeliverListInitial(_deliverListInitial);

    console.log(_allCheckedProdItems);
    console.log(_deliverListInitial);

    return _deliverListInitial;
  };

 

  //Find multiple orders checked or not
  const findMultipleOrdersCheck = (parentArray, deliverList, pIndex) => {
    let _currentClickedWOId = parentArray[pIndex].workorderId;
    let _woIdAlreadyChecked = deliverList.map((obj) => obj.workorderId);
    let _isMultiple = false;

    //Check Delivery body has any different WO with current WO id
    _woIdAlreadyChecked.forEach((el) => {
      if (el !== _currentClickedWOId) {
        _isMultiple = true;
      }
    });

    
    //If multiple orders checked remove latest checked WO from both check box  and body created
    if (_isMultiple) {
      parentArray[pIndex].isTypeChecked = false;
      parentArray[pIndex].products.forEach((el) => {
        el.isItemChecked = false;
      });
      deliverList.splice(pIndex, 1);
      deliverList=deliverList?.filter(obj=>obj.workorderId!==_currentClickedWOId)
      setDeliverListInitial(deliverList?.filter(obj=>obj.workorderId!==_currentClickedWOId));
      setBalanceWoAmount(0)
      alert("You are not allowed to deliver multiple orders at a time");
    }
  };


  const clearDeliveryWoPaymentStates=()=>{
    setSelectedCusObj(null)
    setUserFilterForm(userFilterFormInitialState)
    setDeliverListInitial([])
  }
  const newclearDeliveryWoPaymentStates=()=>{
    setUserFilterForm(userFilterFormInitialState)
    setDeliverListInitial([])
  }

  const bodyToFilterFn={
    branchId:storeCode,
    fromDate:`${previousDate} 00:00:00`,
    toDate:`${today} 23:59:59`,
    cusId:selectedCusObj?._id
  }

  //Delivery W/O payment body
  const deliveryWOPaymentBody = {
    deliver: deliverListInitial,
    branchId:storeCode,
    deliveryDate:`${today} ${getCurrentTime()}`
  };
  //Click Deliver Btn
  const clickDeliverBtn = () => {
    if (deliveryId?.deliveryNo === undefined) {
      alert("Generate Delivery Number!!");
    } else if (selectedCusObj === null) {
      alert("Select a customer and click submit!!");
    } else if (deliverListInitial.length === 0) {
      alert("Select items!!");
    } else {
      updateStatusToDeliveredAPICall(
        deliveryWOPaymentBody,
        setSuccessSnackOpen,
        setErrorSnackOpen,
        setSnackMsg,
        updateListener,
        newclearDeliveryWoPaymentStates,
        setLoadingForm,
        setBtnClickState,
        bodyToFilterFn
      );
    }
  };

  // console.log(userFilterResponseFrontEnd);
  //Cancel button
  const cancelButtonClick = () => {
    setUserFilterForm(userFilterFormInitialState);
    localStorage.removeItem("DELRY_CUS_ID");
    updateListener();
    setSelectedCusObj(null);
    clearDeliveryWoPaymentStates()
  };

  //Calculate Selected Items SubT
  const calculateSubTotal = (selectedItems) => {
    let _payableAmount = 0;
    selectedItems
      .filter((obj) => obj !== false)
      .forEach((obj) => {
        _payableAmount += parseFloat(obj?.totalAmount || 0);
        setSelectedOrderTotal(obj?.orderTotal);
        setSelectedOrderShipmentCharge(obj?.shipmentCharge || 0);
      });

    setSelectedItemsSubTotal(_payableAmount);

    
    // console.log(_payableAmount);
  };

  // console.log(selectedOrderTotal);
  // console.log(selectedItemsSubTotal);
  // console.log(deliverListInitial);

  //*useEffects
  //Call Filter only on page loads from single order view
  // useEffect(()=>{
  //    localStorage.removeItem("DELRY_CUS_ID")
  // },[])

  useEffect(() => {
    if (cusIdFromLs !== null && cusIdFromLs !== undefined) {
      console.log("nihad",customerList);
      setSelectedCusObj(filterObjFromList("_id",customerList,"_id",{_id:customerList}))
      extractCusObj(cusIdFromLs);
      getDeliveryDataForUserAPICall({ cusId: cusIdFromLs, branchId:storeCode },setLoadingForm);
    } 
    else {
      //  getDeliveryDataForUserAPICall({branchId:storeCode,fromDate:`${previousDate} 00:00:00`,toDate:`${today} 23:59:59`},setLoadingForm);
      // getDeliveryDataForUserAPICall({branchId:storeCode},setLoadingForm);
    }
  }, [isModify]);



  // useEffect(()=>{
  //   // btnClickState && 
  //   getDeliveryDataForUserAPICall(
  //     {branchId:storeCode,
  //     fromDate:`${previousDate} 00:00:00`,
  //     toDate:`${today} 23:59:59`,
  //     cusId:selectedCusObj?._id
  //   },setLoadingForm);
  // },[btnClickState])

  //initial API calls
  useEffect(() => {
    generateDeliveryIdAPICall();
    // viewAllCustomersAPICall({branchId:userRole==="admin"?null:userBranchId});
    getListOfDeliveryIdAPICall();
  }, [isModify]);

  //Create UserFilterResponse for front end
  useEffect(() => {
    createUserFilterResponseForFrontEnd(userFilterResponse);
    getAllWorkOrderNumbers(userFilterResponse);
  }, [userFilterResponse]);
  //Set Selected Customer Id From selected Customer Obj
  useEffect(() => {
    if (selectedCusObj !== null && selectedCusObj !== undefined) {
      setUserFilterForm({ ...userFilterForm, customerId: selectedCusObj?._id });
    } else {
      setUserFilterForm({ ...userFilterForm, customerId: null });
    }
  }, [selectedCusObj]);

  // console.log(selectedCusObj);

  // console.log(selectedOrderTotal);

  //Filter by name and mob no in same input field
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option?.name + option.mobileNo,
  });
  // search workorder
  const searchFilterList=(chars,listTofilter)=>{
    let searchedArray=[]
    let filteredArray=[]
    if(chars!==""){
        searchedArray=listTofilter.filter((obj)=>
            obj?.orderId?.toLowerCase()?.includes(chars?.toLowerCase())
        )
        filteredArray=searchedArray
    }
    else{
        filteredArray=listTofilter
    }
    return filteredArray;
}
  useEffect(()=>{
    if(userFilterResponseFrontEnd.length!==0){
        setFilteredSearchList(userFilterResponseFrontEnd)
        let filteredArray=searchFilterList(searchKeyWord,userFilterResponseFrontEnd)
        setFilteredSearchList(filteredArray)
    }
    else{
        setFilteredSearchList([])
    }
},[searchKeyWord,userFilterResponseFrontEnd])


console.log(filteredSearchList);
console.log(deliverListInitial);


  return (
    <div className="global-page-parent-container">
      <div className="order-delivery-top-container">
        <div className="global-single-input delivery-top-input auto-complete">
          <p>Delivery ID</p>

          {suffixBtnState ? (
            <>
              <Autocomplete
                sx={{ width: "100%" }}
                options={listOfDeliveryIds || [{ prefix: "No data" }]}
                renderInput={(params) => <TextField {...params} />}
                getOptionLabel={(option) =>
                  `${option?.prefix}${option?.deliveryNo}`
                }
                onChange={getUserFilterForm("deliveryId")}
              />
              <p onClick={suffixBtnChange} className="suffix-text-blue">
                Generate Delivery ID
              </p>
            </>
          ) : (
            <>
              <input
                type="text"
                readOnly
                value={
                  (deliveryId &&
                    `${deliveryId?.prefix}${deliveryId?.deliveryNo}`) ||
                  "##"
                }
              />
              <p onClick={suffixBtnChange} className="suffix-text-red">
                Clear
              </p>
            </>
          )}
        </div>
        <div className="global-single-input delivery-top-input auto-complete">
          <p>Customer Name</p>
          <Autocomplete
            sx={{ width: "100%" }}
            value={selectedCusObj || null}
            // inputValue={displayCus}
            options={customerList || [{ name: "No data" }]}
            renderInput={(params) => (
              <TextField placeholder="select customer..." {...params} />
            )}
            // getOptionLabel={(option) => option["name"]}
            getOptionLabel={(option) =>
              `${option?.name}`
            }
            renderOption={(props, option) => (
              <li {...props} key={option._id}>
                <span>{option.name}</span>
              </li>
            )}
            onChange={getUserFilterForm("customer")}
            // onInputChange={(e,newInputValue)=>setDisplayCus(newInputValue)}
            filterOptions={filterOptions}
          />
          <p className="suffix-text-blue">&nbsp;</p>
        </div>
        <div className="global-single-input delivery-top-input">
          <p>From</p>
          <input
            type="date"
            value={userFilterForm?.fromDate}
            onChange={getUserFilterForm("fromDate")}
          />
          <p className="suffix-text-blue">&nbsp;</p>
        </div>
        <div className="global-single-input delivery-top-input">
          <p>To</p>
          <input
            type="date"
            value={userFilterForm?.toDate}
            onChange={getUserFilterForm("toDate")}
          />
          <p className="suffix-text-blue">&nbsp;</p>
        </div>
        <button className="btn btn-primary" onClick={clickUserSubmitBtn}>
          Submit
        </button>
      </div>

      <div className="order-delivery-bottom-container">
        <div className="order-delivery-bottom-table-top-container">
          <h3>Delivery Orders</h3>
          <div className="order-delivery-bottom-table-top-right-container">
            <div className="view-order-search-container view-order-admin-search-container">
                <input
                    placeholder="search workorder id"
                      value={searchKeyWord}
                      onChange={getSearchKeyword}
                    />
                <IconButton>
                    <i class="bi bi-search search-icon-order"></i>
                </IconButton>
            </div>
            {userFilterForm?.customerId == null && (
              <Tooltip
                placement="bottom"
                arrow
                title="Select a customer first to view payment details"
              >
                <IconButton className="info-icon-btn">
                  <i class="bi bi-info-circle info-icon"></i>
                </IconButton>
              </Tooltip>
            )}
            <button
              disabled={userFilterForm?.customerId == null}
              onClick={clickPaymentDetailsBtn}
              className="btn btn-primary order-delivery-payment-details-btn"
            >
              Payment Details
            </button>
            {userFilterForm?.customerId == null && (
              <Tooltip
                placement="bottom"
                arrow
                title="Select a customer first to view old balances"
              >
                <IconButton className="info-icon-btn">
                  <i class="bi bi-info-circle info-icon"></i>
                </IconButton>
              </Tooltip>
            )}
            <button
              disabled={userFilterForm?.customerId == null}
              // onClick={oldBalancesDialogOpen}
              onClick={clickOldBalanceBtn}
              className="btn btn-primary order-delivery-old-balance-btn"
            >
              Old Balance
            </button>
          </div>
        </div>
        <hr className="global-hr" />
        <div className="global-table-container delivery-table-container">
          <table className="global-table">
            <thead>
              <tr>
                <th>Delivery ID</th>
                <th>Work Order ID</th>
                <th>Type</th>
                <th>Ord. Date</th>
                {/* <th>Delivery Date</th> */}
                <th>Amount</th>
                <th>Total Qty</th>
                <th>Delivered Qty</th>
                <th>Remaining Qty</th>
                <th>Status</th>
                <th>
                  Select
                  {/* <input type="checkbox" /> */}
                </th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {(filteredSearchList.length > 0 &&
                filteredSearchList?.map((r, i) => (
                  <>
                    <tr key={i}>
                      <td>{r?.deliveryNo || "-NIL-"}</td>
                      <td>{r?.orderId}</td>
                      <td>{r?.type?.toUpperCase()}</td>
                      <td>{convertDateFormat(r?.orderDate)}</td>
                      {/* <td>{r?.deliveryDate}</td> */}
                      <td>{r?.amount?.toFixed(2)}</td>
                      <td>{r?.totalQty}</td>
                      <td>{r?.deliveryQty || "0"}</td>
                      <td>{r?.remainingQty || "0"}</td>
                      <td>
                        {r?.status === "completed"
                          ? "Finished"
                          : r?.status === "delivered"
                          ? "Delivered"
                          : r?.status}
                      </td>
                      <td>
                        <input
                          className="global-checkbox"
                          type="checkbox"
                          disabled={r?.remainingQty <1}
                          checked={r?.isTypeChecked}
                          onClick={checkTypeRow(i)}
                        />
                      </td>
                      <td>
                        <IconButton
                          disabled={r?.remainingQty <1}
                          onClick={
                            r?.remainingQty >0
                              ? tableExpandFunction(i)
                              : undefined
                          }
                          className="delivery-expand-table-arrow-icon"
                        >
                          {r?.isExpand ? (
                            <Tooltip title="Collapse" placement="left" arrow>
                              <ExpandMoreIcon />
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title={r?.status === "completed" ? "Expand" : ""}
                              placement="left"
                              arrow
                            >
                              <KeyboardArrowRightIcon />
                            </Tooltip>
                          )}
                        </IconButton>
                      </td>
                    </tr>
                    {r?.isExpand && (
                      <>
                        <tr className="delivery-expand-table-th-container">
                          <th colSpan={2}>Type</th>
                          <th colSpan={2}>Item</th>
                          <th>Qty</th>
                          <th colSpan={2}>Color</th>
                          <th colSpan={2}>Order Date</th>
                          <th colSpan={3}>Select</th>
                        </tr>
                        {r?.products?.map((sr, j) => (

                          sr?.completedQty>1 &&
                          <tr
                            className="delivery-expand-table-td-container"
                            key={j}
                          >
                            <td colSpan={2}>{sr?.type}</td>
                            <td colSpan={2}>{sr?.productName}</td>
                            <td>{sr?.completedQty}</td>
                            <td colSpan={2}>{sr?.color || "-NIL-"}</td>
                            <td colSpan={2}>{sr?.orderDate}</td>
                            <td colSpan={3}>
                              <input
                                type="checkbox"
                                disabled={r?.remainingQty <1}
                                checked={sr?.isItemChecked}
                                onClick={checkExpandedItemsRow(i, j)}
                              />
                            </td>
                          </tr>
                        )) || (
                          <tr>
                            <td colSpan={5}>No data</td>
                          </tr>
                        )}
                      </>
                    )}
                  </>
                ))) || (
                <tr>
                  <td colSpan={11}>No Data</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div>
        
      <button
        className="btn btn-secondary-outlined"
        onClick={cancelButtonClick}
      >
        Cancel
      </button>
      <button disabled={deliverListInitial.length===0} className="btn btn-secondary deliver-btn" onClick={clickDeliverBtn}>
        Deliver
      </button>
      <button
        onClick={openPaymentPopup}
        className="btn btn-secondary delivery-payment-btn"
        disabled={balanceWoAmount === 0 || balanceWoAmount === undefined}
      >
        Payment
      </button>
      </div>
     {balanceWoAmount === 0  && <Tooltip title='Selected order have no pending payment' placement="right" arrow>
        <IconButton className="info-icon-btn">
          <i class="bi bi-info-circle info-icon"></i>
        </IconButton>
      </Tooltip>}

      {payment === true && (
        <PaymentsWO
          closePayments={closePaymentPopup}
          parentWidth="95%"
          right="17%"
          // width="520px"
          // left="40%"
          // marginTop="3%"
          boxShadow="0 0 10px 2px #eee"
          subTotal={balanceWoAmount} //imp
          bodyWOPayment={deliveryWOPaymentBody}
          finalAPI={deliveryWithBillingAPICall} //API call
          setOpenSuccessSnack={setSuccessSnackOpen}
          setOpenErrorSnack={setErrorSnackOpen}
          setSnackMsg={setSnackMsg}
          clearWOState={cancelButtonClick} //Clear fn
          updateListenerProp={updateListener}
          cusId={userFilterForm.customerId} // imp
          // placeOrderClick={undefined}
          isMultiplePayment={setIsMultiplePayment}
          // paidAmount={selectedItemsSubTotal}
          // shipmentCharge={selectedOrderShipmentCharge}
          branchId={storeCode}
          paymentSection="delivery"
          totalOrderAmount={selectedOrderTotal}
          woId={selectedWOId}
          filterDataDelivery={bodyToFilterFn}
        />
      )}
      {/* old balance payment  */}
      {oldBalancePayment === true && (
        <PaymentsWO
          closePayments={oldBalancePaymentPopupClose}
          parentWidth="95%"
          right="17%"
          boxShadow="0 0 10px 2px #eee"
          subTotal={oldBalanceSelected?.balance} //imp
          bodyWOPayment={{}}
          finalAPI={deliveryWithBillingAPICall} //API call
          setOpenSuccessSnack={setSuccessSnackOpen}
          setOpenErrorSnack={setErrorSnackOpen}
          setSnackMsg={setSnackMsg}
          // clearWOState={newclearDeliveryWoPaymentStates}
          // clearWOState={getOldBalancesOfCustomerAPICall({ cusId: userFilterForm?.customerId })} //Clear fn
          updateListenerProp={updateListener}
          cusId={userFilterForm.customerId} // imp
          // placeOrderClick={undefined}
          // isMultiplePayment={setIsMultiplePayment}
          // paidAmount={selectedItemsSubTotal}
          // shipmentCharge={selectedOrderShipmentCharge}
          paymentSection="delivery"
          woId={oldBalanceSelected?._id}
          branchId={storeCode}
        />
      )}
      {/* payment details dialog */}
      <Dialog open={paymentDetails} maxWidth="lg"  onKeyDown={(e)=>e.key === "Escape" && setPaymentDetails(false)}>
        <div className="order-delivery-payment-details-container">
          <div className="order-delivery-payment-details-table-container">
            <h3>Payment Details</h3>
            <div className="global-table-container">
              <table className="global-table">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>WO. Id</th>
                    <th>Payment Type</th>
                    <th>Transaction ID</th>
                    <th>Date</th>
                    <th>Paid Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {deliveryPaymentDetailsResponse?.paymentDetails?.map(
                    (r, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{r?.workorderId}</td>
                        <td>{r?.type}</td>
                        <td>{"--"}</td>
                        <td>{r?.date}</td>
                        <td>{r?.paidAmount}</td>
                      </tr>
                    )
                  ) || (
                    <tr>
                      <td colSpan={6}>No data</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <hr className="global-hr" />
            <div className="order-delivery-payment-details-amount-container">
              <p>Total Paid Amount</p>
              <h4>
                {deliveryPaymentDetailsResponse?.paymentTotal?.toFixed(2) ||
                  "0.00"}
              </h4>
            </div>
            <div className="order-delivery-payment-details-amount-container">
              <p>Total Billed Amount</p>
              <h4>
                {deliveryPaymentDetailsResponse?.billTotal?.toFixed(2) ||
                  "0.00"}
              </h4>
            </div>
            <div className="order-delivery-payment-details-amount-container">
              <p>Balance Amount</p>
              <h4>
                {deliveryPaymentDetailsResponse?.balance?.toFixed(2) || "0.00"}
              </h4>
            </div>
          </div>

          <button
            onClick={paymentDetailsDialogClose}
            className="btn btn-secondary delivery-payment-close-button"
          >
            Close
          </button>
        </div>
      </Dialog>

      {/* old balance dialog */}
      <Dialog open={oldBalance} maxWidth="lg" style={{ zIndex: 1210 }}  onKeyDown={(e)=>e.key === "Escape" && setOldBalance(false)}>
        <div className="order-delivery-old-balances-container">
          <h3>Old Balances</h3>
          <hr className="global-hr" />
          <div className="order-delivery-old-balances-input-container">
            <div className="global-single-input">
              <p>Contact</p>
              <input
                readOnly
                type="text"
                value={deliveryOldBalancesResponse?.mobile || "-NIL-"}
              />
            </div>
            <div className="global-single-input">
              <p>Customer ID</p>
              <input
                readOnly
                type="text"
                value={deliveryOldBalancesResponse?.customerId || "-NIL-"}
              />
            </div>
            <div className="global-single-input">
              <p>Customer Name</p>
              <input
                readOnly
                type="text"
                value={deliveryOldBalancesResponse?.customerName || "-NIL-"}
              />
            </div>
          </div>
          <div className="global-table-container delivery-old-balance-table">
            <table className="global-table">
              <thead>
                <tr>
                  <th>Work Order ID</th>
                  <th>Date</th>
                  {/* <th>Time</th> */}
                  <th>Amount</th>
                  {/* <th>
                    Select */}
                  {/* <input type="checkbox" /> */}
                  {/* </th> */}
                </tr>
              </thead>
              <tbody>
                {(deliveryOldBalancesResponse?.balances?.length > 0 &&
                  deliveryOldBalancesResponse?.balances?.map((r, i) => (
                    <tr key={i}>
                      <td>{r?.orderNo}</td>
                      <td>{r?.orderDate}</td>
                      <td>{r?.balance}</td>
                      {/* <td>500</td> */}
                      {/* <td> */}
                      {/* <input type="checkbox" disabled={oldBalanceSelected?.index!==i} checked={r?.isChecked} className="global-checkbox" onClick={checkOldBalanceDialog(r,i)} /> */}
                      {/* <button
                          onClick={oldBalancePaymentPopupOpen(r)}
                          className="btn btn-secondary old-balances-payment-btn"
                        >
                          Pay
                        </button>
                      </td> */}
                    </tr>
                  ))) || (
                  <tr>
                    {" "}
                    <td colSpan={4}>No data</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="order-delivery-old-balances-button-container">
            <button
              onClick={oldBalancesDialogClose}
              className="btn btn-secondary-outlined"
            >
              Cancel
            </button>
            {/* <button
              onClick={oldBalancePaymentPopupOpen}
              className="btn btn-secondary old-balances-payment-btn"
            >
              Payment
            </button> */}
          </div>
        </div>
      </Dialog>

      {/* Snackbars  */}
      <SuccessSnackbar
        open={successSnackOpen}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />
      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
      <LoadingForm loading={loadingForm} />
    </div>
  );
};
