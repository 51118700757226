import React from "react";
import "./AdvancedPagination.css";
import { ChevronLeft, ChevronRight } from "lucide-react";

const AdvancedPagination = ({
  currentPage,
  totalPages,
  onPageChange,
  maxVisiblePages = 5,
}) => {
  const getPageNumbers = () => {
    const pageNumbers = [];
    const halfVisible = Math.floor(maxVisiblePages / 2);
    let start = Math.max(1, currentPage - halfVisible);
    let end = Math.min(totalPages, start + maxVisiblePages - 1);

    if (end - start + 1 < maxVisiblePages) {
      start = Math.max(1, end - maxVisiblePages + 1);
    }

    for (let i = start; i <= end; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages && page !== currentPage) {
      onPageChange(page);
    }
  };

  const pageNumbers = getPageNumbers();

  return (
    <nav className="cst-page-pagination">
      <ul className="cst-page-list">
        <li className="cst-page-item">
          <button
            className="cst-page-link"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            style={{padding:"2px"}}
          >
           <ChevronLeft  />
          </button>
        </li>
        {pageNumbers.map((number) => (
          <li
            key={number}
            className={`cst-page-item ${
              number === currentPage ? "cst-page-active" : ""
            }`}
          >
            <button
              className="cst-page-link"
              onClick={() => handlePageChange(number)}
            >
              {number}
            </button>
          </li>
        ))}
        <li className="cst-page-item">
          <button
            className="cst-page-link"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            style={{padding:"2px"}}
          >
           <ChevronRight  />
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default AdvancedPagination;
