import React from "react";
import { TextField, Autocomplete, styled, Box, Typography } from "@mui/material";

const StyledTextField = styled(TextField)(({ theme, error }) => ({
  "& .MuiInputLabel-root": {
    transform: "translate(14px, -9px) scale(0.75)",
    "&.Mui-focused": {
      transform: "translate(14px, -9px) scale(0.75)",
    },
  },
  "& .MuiInputLabel-shrink": {
    transform: "translate(14px, -9px) scale(0.75)",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      // borderColor: error ? theme.palette.error.main : theme.palette.primary.main,
    },
    "&:hover fieldset": {
      borderColor: error
        ? theme.palette.error.dark
        : theme.palette.primary.dark,
    },
    "&.Mui-focused fieldset": {
      borderColor: error
        ? theme.palette.error.main
        : theme.palette.primary.main,
    },
  },
  "& .MuiFormHelperText-root": {
    marginLeft: 0,
  },
}));

// const AutocompleteInputBox = ({
//   label,
//   id,
//   name,
//   value,
//   onChange,
//   error,
//   errorMessage,
//   infoMessage,
//   type,
//   options,
//   multiple = false,
//   optionLabelKey = 'label',
//   ...props
// }) => {
//   // Ensure options is always an array
//   const safeOptions = Array.isArray(options) ? options : [];

//   // Normalize value
//   const normalizedValue = React.useMemo(() => {
//     if (multiple) {
//       if (Array.isArray(value)) return value;
//       return value ? [value] : [];
//     }
//     return value === undefined || value === "" ? null : value;
//   }, [value, multiple]);

//   const handleChange = (event, newValue) => {
//     const syntheticEvent = {
//       target: {
//         name: name,
//         value: multiple ? newValue : newValue === null ? "" : newValue,
//       },
//     };
//     onChange(syntheticEvent);
//   };

//   const isOptionEqualToValue = (option, value) => {
//     if (value === null || value === undefined || value === "") return false;
//     if (typeof option === 'string' || typeof value === 'string') {
//       return option === value;
//     }
//     return option && value && option[optionLabelKey] === value[optionLabelKey];
//   };

//   const getOptionLabel = (option) => {
//     if (option === null || option === undefined) return '';
//     if (typeof option === 'string') return option;
//     return option[optionLabelKey] ? option[optionLabelKey].toString() : '';
//   };

//   return (
//     <Box sx={{minHeight:'4em'}}>
//       <Autocomplete
//         multiple={multiple}
//         options={safeOptions}
//         renderInput={(params) => (
//           <StyledTextField
//             {...params}
//             type={type}
//             label={label}
//             id={id}
//             name={name}
//             error={error}
//             helperText={error ? errorMessage : ""}
//             InputLabelProps={{ shrink: true }}
//             fullWidth
//           />
//         )}
//         value={normalizedValue}
//         onChange={handleChange}
//         isOptionEqualToValue={isOptionEqualToValue}
//         getOptionLabel={getOptionLabel}
//         size="small"
//         {...props}
//       />
//       {!error && infoMessage && (
//         <Typography
//           variant="caption"
//           sx={{
//             color: 'primary.main',
//             textAlign: 'right',
//             display: 'block',
//             mt: 0.5,
//           }}
//         >
//           {infoMessage}
//         </Typography>
//       )}
//     </Box>
//   );
// };


const AutocompleteInputBox = ({
  label,
  id,
  name,
  value,
  onChange,
  error,
  errorMessage,
  infoMessage,
  type,
  options,
  multiple = false,
  optionLabelKey = 'label',
  optionValueKey = '_id', // Use _id or another unique identifier
  ...props
}) => {
  // Ensure options is always an array
  const safeOptions = Array.isArray(options) ? options : [];

  // Normalize value
  const normalizedValue = React.useMemo(() => {
    if (multiple) {
      if (Array.isArray(value)) return value;
      return value ? [value] : [];
    }
    return value === undefined || value === "" ? null : value;
  }, [value, multiple]);

  const handleChange = (event, newValue) => {
    const syntheticEvent = {
      target: {
        name: name,
        value: multiple ? newValue : newValue === null ? "" : newValue,
      },
    };
    onChange(syntheticEvent);
  };

  const isOptionEqualToValue = (option, value) => {
    if (value === null || value === undefined || value === "") return false;
    if (typeof option === 'string' || typeof value === 'string') {
      return option === value;
    }
    return option && value && option[optionValueKey] === value[optionValueKey];
  };

  // const getOptionLabel = (option) => {
  //   if (option === null || option === undefined) return '';
  //   if (typeof option === 'string') return option;
  //   return option[optionLabelKey]
  //     ? `${option[optionLabelKey]} (${option.mobileNo})`
  //     : '';
  // };

  const getOptionLabel = (option) => {
    if (option === null || option === undefined) return '';
    if (typeof option === 'string') return option;
    const name = option[optionLabelKey];
    const mobileNo = option.mobileNo ? `(${option.mobileNo})` : '';
    const id = option._id; // or use optionValueKey if different
    return name ? `${name} ` : '';
};
const renderOption = (props, option) => {
  return (
    <li {...props} key={option._id}>
      {option[optionLabelKey]}
      {/* Unique ID, not displayed but ensures uniqueness */}
      {/* <span style={{ display: 'none' }}>{option._id}</span> */}
    </li>
  );
};


  return (
    <Box sx={{ minHeight: '4em' }}>
      <Autocomplete
        multiple={multiple}
        options={safeOptions}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            type={type}
            label={label}
            id={id}
            name={name}
            error={error}
            helperText={error ? errorMessage : ""}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
        )}
        value={normalizedValue}
        onChange={handleChange}
        isOptionEqualToValue={isOptionEqualToValue}
        getOptionLabel={getOptionLabel}
        renderOption={renderOption} 
        size="small"
        {...props}
      />
      {!error && infoMessage && (
        <Typography
          variant="caption"
          sx={{
            color: 'primary.main',
            textAlign: 'right',
            display: 'block',
            mt: 0.5,
          }}
        >
          {infoMessage}
        </Typography>
      )}
    </Box>
  );
};
export default AutocompleteInputBox;
