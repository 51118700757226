import React, { useEffect, useState } from 'react'
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';
import { Autocomplete, TextField } from '@mui/material';
import "./addmeasurment.css"
import { ThobForm } from './Measurments/ThobForm';
import { useDispatch, useSelector } from 'react-redux';
import { switch_to_pos_screen } from '../PosNewSlice';
import { JalabiaForm } from './Measurments/JalabiaForm';
import { SalwarForm } from './Measurments/SalwarForm';
import { ShirtForm } from './Measurments/ShirtForm';
import { PantForm } from './Measurments/PantForm';
import { SuitForm } from './Measurments/SuitForm';
import { editWorkOrderAPICall, workOrderSingleViewAPI } from './NewOrderAPI';
import { filterObjFromList } from '../../../../../Js/generalFunctions';
import { viewProductColor } from './MeasurmentsAPI';
import { currentTime, today } from '../../../../../Js/Date';

export const AddMeasurment = () => {
   const singleOrderId=localStorage.getItem("singleOrderId")
    const navigate=useNavigate()
    const dispatch = useDispatch();
    const formComponents = {
      Thob: ThobForm,
      Jalabia: JalabiaForm,
      Salwar: SalwarForm,
      Abaya: JalabiaForm,
      Churidhar: JalabiaForm,
      Shirt: JalabiaForm,
      Pant: JalabiaForm,
      Coat: JalabiaForm,
      Sharvani: JalabiaForm,
      Safari: JalabiaForm
    };

    const {orderTypes,productColor}=useSelector((state)=>state.measurementsslice)
    const {workOrderSingleView}=useSelector((state)=>state.neworderslice)
    const {
      customerList,
      productListByStock
    } = useSelector((state) => state.neworderslice);
    const loginResponse = useSelector((state) => state.loginResponseSlice.value);
    const shiftStatus = useSelector((state) => state.shiftStatusSlice.value);
    const [selectedType, setSelectedType] = useState();
    const [workOrderSingleList,setWorkOrderSingleList]=useState([])
    const [formValues, setFormValues] = useState({});

    const [singleRowData,setSingleRowData]=useState()

    const homeBtnClickFn=()=>{
        navigate("/userdashboard/posnew")
      }

      const handleRadioChange=(id) =>{
        // const selectedValue = event.target.value;
        setSelectedType(id);
    }

    const selectedTypeObj = orderTypes?.find(item => item._id === selectedType);
    const SelectedComponent = selectedTypeObj ? formComponents[selectedTypeObj.wotype] : null;


    const calculateTax = (itemRate, qty, taxes, inclusive) => {
      let totalTax = 0;
      let calculatedTaxes = [];
    
      if (inclusive) {
        const totalTaxRate = taxes.reduce((sum, item) => sum + item.amount, 0);
        const taxableAmount = ((itemRate * qty) / (100 + totalTaxRate)) * 100;
        calculatedTaxes = taxes.map(tax => ({
          ...tax,
          taxAmount: (taxableAmount * tax.amount) / 100,
        }));
        totalTax = calculatedTaxes.reduce((sum, item) => sum + item.taxAmount, 0);
      } else {
        const taxableAmount = itemRate * qty;
        calculatedTaxes = taxes.map(tax => ({
          ...tax,
          taxAmount: (taxableAmount * tax.amount) / 100,
        }));
        totalTax = calculatedTaxes.reduce((sum, item) => sum + item.taxAmount, 0);
      }
    
      return { totalTax, calculatedTaxes };
    };

    const handleTableChange=(lineId,key,value)=>{

      if(key==="orderQuantity"){

        setWorkOrderSingleList(prevList =>
          prevList.map(item => {
            if (item.lineId === lineId) {
              const itemRate = parseFloat(item?.itemRate || 0);
              const qty = parseFloat(value || 0);
              const stitchRate = parseFloat(item?.stitchRate || 0);
              
    
              // Calculate the base amount
               const baseAmount = qty * (itemRate + stitchRate);
           
             
              // Calculate tax
              const { totalTax } = calculateTax(itemRate, qty, item.taxes, item?.taxes[0].includedPrice);
             console.log(item?.taxes[0]?.includedPrice);
              // Calculate total amount
              const totalAmount = item?.taxes[0]?.includedPrice ? baseAmount : baseAmount + totalTax;
    
              return {
                ...item,
                orderQuantity: value,
                amount: totalAmount,
                taxTotal: totalTax
              };
            }
            return item;
          })
        );
      }
      else if(key==="itemRate"){
        setWorkOrderSingleList(prevList =>
          prevList.map(item => {
            if (item.lineId === lineId) {
              const itemRate = parseFloat(value || 0);
              const qty = parseFloat(item?.orderQuantity || 0);
              const stitchRate = parseFloat(item?.stitchRate || 0);
              
    
              // Calculate the base amount
               const baseAmount = qty * (itemRate + stitchRate);
           
             
              // Calculate tax
              const { totalTax } = calculateTax(itemRate, qty, item.taxes, item?.taxes[0].includedPrice);
             console.log(item?.taxes[0]?.includedPrice);
              // Calculate total amount
              const totalAmount = item?.taxes[0]?.includedPrice ? baseAmount : baseAmount + totalTax;
    
              return {
                ...item,
                itemRate: value,
                amount: totalAmount,
                taxTotal: totalTax
              };
            }
            return item;
          })
        );
      }
      else{
        setWorkOrderSingleList(prevList =>
          prevList.map(item =>
            item.lineId === lineId ? { ...item,[key]: value } : item
          )
        );
      }
     
 

    }
    // const handleFormDataChange = (data) => {
    //   setSingleRowData(prevValues => ({
    //       ...prevValues,
    //       ...data,
    //      }));
    // };

    const handleFormDataChange = (data) => {
      const updatedSingleRowData = {
        ...singleRowData,
        ...data,
        modelId:data?.modelId?._id,
        itemType:data?.itemType?._id,
        marModel:data?.marModel?._id,
        cuff:data?.cuff?._id,
        cuffCloth:data?.cuffCloth?._id,
        cuffModel:data?.cuffModel?._id,
        collar:data?.collar?._id,
        collarCloth:data?.collarCloth?._id,
        collarModel:data?.collarModel?._id,
        fpModel:data?.fpModel?._id,
        pen:data?.pen?._id,
        sidePtModel:data?.sidePtModel?._id,
        stitching:data?.stitching?._id,
        button:data?.button?._id,
        mobilePocket:data?.mobilePocket?.type,
        cuffSize2:data?.cuffSize_2,
        neckModel:data?.neckModel?._id,
        elasticWidth:data?.elasticWidth?._id,
        model:data?.model?._id
      };
      const updatedWorkOrderSingleList = workOrderSingleList.map(item => {
        if (item.lineId === updatedSingleRowData.lineId) {
          return { ...item, ...updatedSingleRowData };
        }
        return item;
      });
      setSingleRowData(updatedSingleRowData);
      setWorkOrderSingleList(updatedWorkOrderSingleList);
    };

    


    // click table row
    const clickTableRow=(row)=>{
      setSingleRowData(row)
      setSelectedType(row?.workorderTypeId)

     
    }

    console.log(singleRowData,"singleRowData");
    console.log(workOrderSingleList,"wosingleData");

    const updatedDataToBackend=workOrderSingleList?.map((item,i)=>{
        return {
            ...item,
            _id:item?.lineId,
            modelId:item?.modelId,
            itemType:item?.itemType,
            length:item?.length,
            shoulder:item?.shoulder,
            sleeve:item?.sleeve,
            chest:item?.chest,
            stomach:item?.stomach,
            slChest:item?.slChest,
            slSo:item?.slSo,
            neck:item?.neck,
            bottom:item?.bottom,
            marSize:item?.marSize,
            marModel:item?.marModel,
            cuff:item?.cuff,
            cuffSize1:item?.cuffSize1,
            cuffSize_2:item?.cuffSize_2,
            cuffCloth:item?.cuffCloth,
            cuffModel:item?.cuffModel,
            neckDownButton:item?.neckDownButton,
            sidePtSize:item?.sidePtSize,
            collar:item?.collar,
            collarSize:item?.collarSize,
            collarCloth:item?.collarCloth,
            collarModel:item?.collarModel,
            regalSize:item?.regalSize,
            kneeLoose:item?.kneeLoose,
            fpDown:item?.fpDown,
            fpModel:item?.fpModel,
            fpSize1:item?.fpSize1,
            fpSize2:item?.fpSize2,
            pen:item?.pen,
            sidePtModel:item?.sidePtModel,
            stitching:item?.stitching,
            button:item?.button,
            mobilePocket:item?.mobilePocket==="YES"?true:false,

            neckModel:item?.neckModel,
            mar:item?.mar,

            elasticWidth:item?.elasticWidth,
            model:item?.model,


            customerId:item?.customerId?._id,
            color:item?.color?._id,
            itemId:item?.itemId?._id,
            stitchRate:"",


          }
    })

    const updateBody={
      _id:workOrderSingleView?._id,
      items:updatedDataToBackend,
      // items: workOrderSingleList?.map((item) => ({
      //   ...item,
        
      //   length: item?.lineId === singleRowData?.lineId ? singleRowData?.length : item.length,
      // })),
      total:workOrderSingleList.reduce((sum, item) => sum + item?.taxAmount, 0),
      branchId:loginResponse?.branchPk,
      shiftId:shiftStatus?._id,
      editDate:`${today} ${currentTime}`
    }

    const editWorkOrderBtnFn=()=>{
      editWorkOrderAPICall(updateBody)
      console.log(updateBody);
    }

    // map response data to a state
    useEffect(() => {
      if (workOrderSingleView !== undefined) {
        const workOrderTableData = workOrderSingleView?.items?.map((obj) => ({
          ...obj,
          customerId: filterObjFromList("_id", customerList, "customerId", obj),
          itemId: filterObjFromList("_id", productListByStock, "itemId", obj),
          color:filterObjFromList("_id", productColor, "color", obj),
          itemType:obj?.itemType,
          modelType:obj?.modelType,
          orderQuantity:obj?.orderQuantity,
          itemRate:obj?.itemRate,
          stitchRate:obj?.stitchRate,
          amount:obj?.amount

        }));
        setWorkOrderSingleList(workOrderTableData);
      }
    }, [workOrderSingleView,
         customerList,
         productColor,
         productListByStock]);

         console.log(workOrderSingleList);
         


      useEffect(() => {
        dispatch(switch_to_pos_screen({ posScreenState: true }));
        workOrderSingleViewAPI({_id:singleOrderId})
        viewProductColor()
      }, []);

  
  return (
    <div className='pos-conatiner'>
       <div className='pos-top-container' >
         <div className='new-order-top-left'>
           <div className='new-order-top-left-home' onClick={homeBtnClickFn}>
             <HomeIcon/> 
           </div>
           <p className='new-order-top-left-p'>MEASURMENT</p>
         </div>
         <div className='new-order-top-right-btn' style={{width:"88%"}}>
           <div className="new-global-single-input">
              <TextField
                id="outlined-basic"
                label="Order No"
                variant="outlined"
                focused
                value={workOrderSingleView?.sequence}
              />
            </div>
            <div className="new-global-single-input">
              <TextField
                type='date'
                id="outlined-basic"
                label="Order Date"
                variant="outlined"
                focused
                value={workOrderSingleView?.orderDate}
              />
            </div>
            <div className="new-global-single-input">
              <TextField
                type='date'
                id="outlined-basic"
                label="Delivery Date"
                variant="outlined"
                focused
                value={workOrderSingleView?.deliveryDate}
              />
            </div>
            <div className="new-global-single-input">
              <TextField
                type='text'
                id="outlined-basic"
                label="Customer Name"
                variant="outlined"
                focused
                value={workOrderSingleView?.customerName}
              />
            </div>
           
            <div className="new-global-single-input">
              <TextField
                type='text'
                id="outlined-basic"
                label="Contact Number"
                variant="outlined"
                focused
                value={workOrderSingleView?.mobileNo}
              />
            </div>
         </div>
      </div>
      <hr className='pos-hr' />
      <div className='new-order-top-right-radio' style={{margin:"0px",width:"100%"}}>
          {
            orderTypes?.map((r, index) => (
            <div key={index} className='new-order-top-right-radio-single'>
                <input 
                  type="radio"
                  id={`radio-${index}`} 
                  name="types"
                  value={r?._id}
                  checked={selectedType === r?._id}
                  onChange={(event) => handleRadioChange(r?._id)}
                  />
                <label htmlFor={`radio-${index}`}>{r?.typeName}</label>
             </div>
            ))
            }
           
      </div>
      <div style={{minHeight:"40vh"}}>
       
         {SelectedComponent ? <SelectedComponent  onFormDataChange={handleFormDataChange} selectedType={selectedType} editData={singleRowData}/> : null}
          
      </div>
      <div className="new-global-table-container pos-table" style={{ paddingTop: "0 42px",maxHeight:"25vh" }}>
       <table>
        <thead>
            <tr>
                <th>CUSTOMER</th>
                <th>PRODUCT</th>
                <th>COLOR</th>
                <th>TYPE</th>
                {/* <th>MODEL</th> */}
                <th>QTY</th>
                <th>ITEM RATE</th>
                <th>STITCH RATE</th>
                <th>AMOUNT</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
        {
            workOrderSingleList?.map((r,i)=>(
              <tr onClick={()=>clickTableRow(r)} key={i} 
                 style={{ backgroundColor: i % 2 === 0 ? "white" : "#e9e7e7",
                 border:singleRowData?.lineId===r?.lineId ? "1px solid #cc0486" : ""
                  }}>
                <td>
                   <div className="autocomplete-new-pos-table">
                              <Autocomplete
                                id="combo-box-demo"
                                options={customerList?.slice(0)?.reverse()||[]}
                                // className="autocomplete-new-pos-table"
                                getOptionLabel={(option)=>option?.name}
                                renderOption={(props, option) => (
                                  <li {...props} key={option._id}>
                                    <span>{option?.name}</span>
                                  </li>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                   
                                  />
                                )}
                                 value={r?.customerId}
                                 onChange={(e,newValue)=>handleTableChange(r?.lineId,"customerId",newValue)}
                             
                              />
                              

                   </div>
                </td>
                <td>
                  <div className="autocomplete-new-pos-table">
                              <Autocomplete
                               id="combo-box-demo"
                                options={productListByStock||[]}
                                // className="autocomplete-new-pos-table"
                                getOptionLabel={(option)=>option?.productName}
                                renderOption={(props, option) => (
                                  <li {...props} key={option}>
                                    <span>{option?.productName}</span>
                                  </li>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                   
                                  />
                                )}
                                value={r?.itemId}
                                onChange={(e,newValue)=>handleTableChange(r?.lineId,"itemId",newValue)}
                             
                              />
                  </div>
                </td>
                <td>
                   <div className="autocomplete-new-pos-table">
                              <Autocomplete
                               id="combo-box-demo"
                                options={productColor||[]}
                                // className="autocomplete-new-pos-table"
                                getOptionLabel={(option)=>option?.colorName}
                                renderOption={(props, option) => (
                                  <li {...props} key={option}>
                                    <span>{option?.colorName}</span>
                                  </li>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                   
                                  />
                                )}
                                value={r?.color}
                                onChange={(e,newValue)=>handleTableChange(r?.lineId,"color",newValue)}
                             
                              />
                   </div>
                </td>
                 <td style={{width:"10%"}}>
                  <div className="autocomplete-new-pos-table border-none">
                   <TextField
                        value={r?.woType}
            
                      />
                   </div>
                 </td>
                  {/* <td style={{width:"12%"}}>
                   <div className="autocomplete-new-pos-table border-none">
                   <TextField
                        value={r?.modelType}
                      />
                   </div>
                  </td> */}
                  <td style={{width:"10%"}}>
                   <div className="autocomplete-new-pos-table">
                      <TextField
                        value={r?.orderQuantity}
                        onChange={(e)=>handleTableChange(r?.lineId,"orderQuantity",e.target.value)}

                      />
                   </div>
                  </td>
                  <td style={{width:"10%"}}>
                   <div className="autocomplete-new-pos-table">
                   <TextField
                        value={r?.itemRate}
                        onChange={(e)=>handleTableChange(r?.lineId,"itemRate",e.target.value)}
                      />
                   </div>
                  </td>
                  <td style={{width:"10%"}}>
                  <div className="autocomplete-new-pos-table">
                   <TextField
                        value={r?.stitchRate}
                        onChange={(e)=>handleTableChange(r?.lineId,"stitchRate",e.target.value)}
                      />
                   </div>
                  </td>
                  <td style={{width:"10%"}}>
                  <div className="autocomplete-new-pos-table border-none">
                   <TextField
                        value={r?.amount}
                      />
                   </div>
                  </td>
                <td><i class="bi bi-trash3 delete-icon"></i></td>
            </tr>
            ))
          }

        </tbody>
    
       
          
           
         </table>
       </div>
       <div className='measurment-btn-container'>
         {/* <button className='pos-status-blue-btn save-measurment-btn'>Save</button> */}
         <button onClick={editWorkOrderBtnFn} className='update-measurment-btn'>Update</button>
         <button className='reset-measurment-btn'>Reset</button>
       </div>
    </div>
  )
}

