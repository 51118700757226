import React, { Fragment, useEffect, useState } from "react";
import "./printTempStyles.css";
import TempFooter from "./common/TempFooter";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";
import { numToWords } from "../../../../../Js/generalFunctions";
import { printSection } from "./common/enum";

export const InvoiceHeader = ({ invoiceDetails, companyProfileData }) => {
  const [headerImage, setHeaderImage] = useState(null);

  useEffect(() => {
    if (companyProfileData?.logo) {
      const img = new Image();
      img.onload = () => setHeaderImage(companyProfileData.logo);
      img.onerror = () => setHeaderImage(null);
      img.src = companyProfileData.logo;
    }
  }, [companyProfileData?.logo]);

  return (
    <div className="invoice-header-cntr-temp-1">
      <div className="header-container-temp-1">
        <div className="header-left-temp-1">
          <div style={{ minHeight: headerImage ? "0em" : "5em" }}>
            {headerImage && (
              <img
                src={headerImage}
                alt="Logo"
                className="header-logo-temp-1"
              />
            )}
          </div>
          <div className="header-quotation-temp-1">
            {companyProfileData?.companyName || ""}
          </div>
        </div>
        <div className="header-right-inv-temp-1">
          <div className="header-invoice-temp-1">
            {invoiceDetails?.printSection || ""}
          </div>
        </div>
        <div className="header-right-temp-1">
          <div className="header-company-details-temp-1">
            <p className="temp-company-name-temp-1">
              {companyProfileData?.companyName || "---"}
            </p>
            <p>{companyProfileData?.companyAddress || "---"}</p>
            {/* <p>Post code: {}</p> */}
            {/* <p>TRN: {"---"}</p> */}
          </div>
        </div>
        <div className="fixed-detail-head-temp-1">
          <div className="invoice-details-temp-1">
            <div className="invoice-to-temp-1">
              <p>
                <strong>Vendor:</strong>
              </p>
              {/* <p className="temp-company-name">
                <strong>{companyProfileData?.companyName || ""}</strong>
              </p> */}
              <p> {invoiceDetails?.customerName || ""}</p>
              <p> {invoiceDetails?.customerEmail || ""}</p>
              <p> {invoiceDetails?.customerAddress || ""}</p>
              <p> {invoiceDetails?.customerContact || ""}</p>
              {/* <p>Post code: {invoiceDetails?.postCode || ""}</p> */}
              {/* <p>TRN:{"---"}</p> */}
            </div>
            <div className="invoice-info-temp-1">
              <p>
                <strong>
                  {invoiceDetails?.printSection == printSection.rfq
                    ? "RFQ No"
                    : invoiceDetails?.printSection ===
                      printSection.purchaseOrder
                      ? "PO NO"
                      : invoiceDetails?.printSection ===
                        printSection.goodsReceiveNote
                        ? "GRN NO"
                        : invoiceDetails?.printSection ===
                          printSection.salesOrder
                          ? "Sales No"
                          : invoiceDetails?.printSection ===
                            printSection.deliveryNote
                            ? "DN No" : invoiceDetails?.printSection ===
                              printSection.customerInvoice
                              ? "invoice No" : invoiceDetails?.printSection ===
                                printSection.salesWSO
                                ? "invoice No" : invoiceDetails?.printSection ===
                                  printSection.creditNote
                                  ? "Invoice No" : invoiceDetails?.printSection ===
                                    printSection.vendorBill
                                    ? "Bill No" : invoiceDetails?.printSection ===
                                      printSection.purchaseWPO
                                      ? "Bill No" : invoiceDetails?.printSection ===
                                        printSection.debitNote
                                        ? "Return No" : invoiceDetails?.printSection ===
                                          printSection.salesQuotation
                                          ? "sales No" : ""}
                </strong>
                : {invoiceDetails?.name || ""}
              </p>

              {invoiceDetails?.printSection !==
                printSection.goodsReceiveNote && (
                  <p>
                    <strong>Quotation Date</strong>:{" "}
                    {invoiceDetails?.firstDate || ""}
                  </p>
                )}
              {invoiceDetails?.printSection !==
                printSection.goodsReceiveNote && (
                  <p>
                    <strong>Expiry Date</strong>:{" "}
                    {invoiceDetails?.secondDate || ""}
                  </p>
                )}
              {invoiceDetails?.printSection !==
                printSection.goodsReceiveNote && (
                  <p>
                    <strong>LPO No</strong>: {invoiceDetails?.lpo || ""}
                  </p>
                )}
              {invoiceDetails?.printSection !==
                printSection.goodsReceiveNote && (
                  <p>
                    <strong>Payment Terms</strong>:{" "}
                    {invoiceDetails?.payTerm || ""}
                  </p>
                )}
              {invoiceDetails?.printSection ===
                printSection.goodsReceiveNote && (
                  <p>
                    <strong>Return Date</strong>: {invoiceDetails?.dateN1 || ""}
                  </p>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

//print component
export const PrintTempOne = ({
  pageNumber,
  totalPages,
  items,
  isLastPage,
  tableTotal,
  allData,
  taxDetails,
  qrData,
  invoiceDetails,
}) => {
  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );
  const decimalPosition = localStorage.getItem("decimalPosition");
  const [TotalValues, setTotalValues] = useState({
    ttBeforeVat: 0,
    ttIncVat: 0,
  });

  const { currencyDetails } = useSelector((state) => state.mainDashBoardSlice);

  useEffect(() => {
    if (allData?.length > 0) {
      setTotalValues({
        ttBeforeVat: allData
          ?.reduce((total, item) => {
            const qty = typeof item.qty === "number" ? item.qty : 0;
            const unitPrice =
              typeof item.unitPrice === "number" ? item.unitPrice : 0;
            return total + qty * unitPrice;
          }, 0)
          ?.toFixed(decimalPosition),
        ttIncVat: allData
          ?.reduce((acc, row) => {
            if (row.includedPrice === false) {
              return acc + (row.lineTotal + row.taxAmt);
            } else {
              return acc + row.lineTotal;
            }
          }, 0.0)
          ?.toFixed(decimalPosition),
      });
    }
  }, [allData]);

  return (
    <div
      className="sheet"
      style={{
        height: "350mm",
        width: "250mm",
        padding: "6mm",
        boxSizing: "border-box",
        position: "relative",
        pageBreakAfter: "always",
      }}
    >
      {/* Invoice Header */}
      {!isLastPage && (
        <header>
          <InvoiceHeader
            invoiceDetails={invoiceDetails}
            companyProfileData={companyProfileData}
          />
        </header>
      )}
      {/* Invoice Items Table */}
      {items?.length > 0 && (
        <table
          className="invoice-table-temp-1 striped-table"
          style={{ marginTop: "1.5em" }}
        >
          <thead>
            <tr>
              <th>Sl.No</th>
              {invoiceDetails?.printSection !==
                printSection.rfq && invoiceDetails?.printSection !==
                printSection.purchaseOrder && <th>PRODUCT</th>}
              {invoiceDetails?.printSection !==
                printSection.goodsReceiveNote && invoiceDetails?.printSection !==
                printSection.deliveryNote && invoiceDetails?.printSection !== printSection.customerInvoice && invoiceDetails?.printSection !==
                printSection.vendorBill && invoiceDetails?.printSection !==
                printSection.purchaseWPO && invoiceDetails?.printSection !==
                printSection.purchaseWPO && invoiceDetails?.printSection !==
                printSection.debitNote && <th>DESCRIPTION</th>}
              {invoiceDetails?.printSection ===
                printSection.customerInvoice || invoiceDetails?.printSection ===
                printSection.vendorBill && <th> ACCOUNT</th>}
              {invoiceDetails?.printSection !==
                printSection.goodsReceiveNote && invoiceDetails?.printSection !==
                printSection.deliveryNote && printSection.deliveryNote && invoiceDetails?.printSection !==
                printSection.deliveryNote && <th>QTY</th>}
              <th>UOM</th>
              {invoiceDetails?.printSection ===
                printSection.deliveryNote && <th>DEMAND QUANTITY</th>}
              {invoiceDetails?.printSection ===
                printSection.deliveryNote && <th>DONE QUANTITY</th>}
              {invoiceDetails?.printSection ===
                printSection.goodsReceiveNote && <th>ORDER QUANTITY</th>}
              {invoiceDetails?.printSection ===
                printSection.goodsReceiveNote && <th>DELIVER QUANTITY</th>}
              {invoiceDetails?.printSection !==
                printSection.goodsReceiveNote && printSection.deliveryNote && invoiceDetails?.printSection !==
                printSection.deliveryNote && <th>RATE</th>}
              {invoiceDetails?.printSection !==
                printSection.goodsReceiveNote && invoiceDetails?.printSection !==
                printSection.deliveryNote && invoiceDetails?.printSection !==
                printSection.customerInvoice && invoiceDetails?.printSection !==
                printSection.salesWSO && invoiceDetails?.printSection !==
                printSection.creditNote && invoiceDetails?.printSection !==
                printSection.vendorBill && invoiceDetails?.printSection !==
                printSection.purchaseWPO && invoiceDetails?.printSection !==
                printSection.debitNote && <th>AMOUNT</th>}
              {invoiceDetails?.printSection !==
                printSection.goodsReceiveNote && invoiceDetails?.printSection !==
                printSection.deliveryNote && <th>DISCOUNT</th>}
              {invoiceDetails?.printSection !==
                printSection.goodsReceiveNote && invoiceDetails?.printSection !==
                printSection.deliveryNote && invoiceDetails?.printSection !==
                printSection.customerInvoice && invoiceDetails?.printSection !==
                printSection.creditNote && invoiceDetails?.printSection !==
                printSection.purchaseWPO && invoiceDetails?.printSection !==
                printSection.debitNote && <th>VAT%</th>}
              {invoiceDetails?.printSection !==
                printSection.goodsReceiveNote && invoiceDetails?.printSection !==
                printSection.deliveryNote && <th>VAT</th>}
              {invoiceDetails?.printSection !==
                printSection.goodsReceiveNote && invoiceDetails?.printSection !==
                printSection.deliveryNote && <th>NET AMOUNT</th>}

            </tr>
          </thead>
          <tbody>
            {items?.length > 0 ? (
              <>
                {items.map((item, index) => (
                  <tr
                    key={index}
                    className={
                      index === items.length - 1
                        ? "invoice-table-btm-temp-1"
                        : ""
                    }
                  >
                    <td>{item?.id}</td>
                    {invoiceDetails?.printSection !==
                      printSection.rfq && invoiceDetails?.printSection !==
                      printSection.purchaseOrder && <td>{item?.product}</td>}


                    {invoiceDetails?.printSection !== printSection.goodsReceiveNote &&
                      invoiceDetails?.printSection !== printSection.deliveryNote &&
                      invoiceDetails?.printSection !== printSection.customerInvoice && invoiceDetails?.printSection !==
                      printSection.vendorBill && invoiceDetails?.printSection !==
                      printSection.purchaseWPO && invoiceDetails?.printSection !==
                      printSection.debitNote &&
                      <td>{item?.label}</td>}
                    {invoiceDetails?.printSection ===
                      printSection.customerInvoice || invoiceDetails?.printSection ===
                      printSection.vendorBill && (
                        <td>{item?.accountName}</td>
                      )}
                    {invoiceDetails?.printSection !==
                      printSection.goodsReceiveNote && invoiceDetails?.printSection !==
                      printSection.deliveryNote && <td style={{ textAlign: "center" }}>{item?.qty}</td>}
                    <td style={{ textAlign: "center" }}>{item?.uomName}</td>
                    {invoiceDetails?.printSection ===
                      printSection.goodsReceiveNote && (
                        <td style={{ textAlign: "center" }}>{item?.orderQuantity}</td>
                      )}
                    {invoiceDetails?.printSection ===
                      printSection.goodsReceiveNote && (
                        <td style={{ textAlign: "center" }}>{item?.recievedQuantity}</td>
                      )}

                    {invoiceDetails?.printSection !==
                      printSection.goodsReceiveNote && printSection.deliveryNote && (
                        <td style={{ textAlign: "right" }}>{item.unitPrice?.toFixed(decimalPosition)}</td>
                      )}
                    {invoiceDetails?.printSection !==
                      printSection.goodsReceiveNote && invoiceDetails?.printSection !==
                      printSection.customerInvoice && invoiceDetails?.printSection !==
                      printSection.salesWSO && invoiceDetails?.printSection !==
                      printSection.creditNote && invoiceDetails?.printSection !==
                      printSection.vendorBill && invoiceDetails?.printSection !==
                      printSection.purchaseWPO && invoiceDetails?.printSection !==
                      printSection.debitNote && (
                        <td style={{ textAlign: "right" }}>
                          {(item.qty * item.unitPrice)?.toFixed(decimalPosition)}
                        </td>
                      )}
                    {invoiceDetails?.printSection !==
                      printSection.goodsReceiveNote && invoiceDetails?.printSection !==
                      printSection.deliveryNote && (
                        <td style={{ textAlign: "right" }}>{item?.discount}%</td>
                      )}
                    {invoiceDetails?.printSection !==
                      printSection.goodsReceiveNote && invoiceDetails?.printSection !==
                      printSection.deliveryNote && invoiceDetails?.printSection !==
                      printSection.customerInvoice && invoiceDetails?.printSection !==
                      printSection.creditNote && invoiceDetails?.printSection !==
                      printSection.purchaseWPO && invoiceDetails?.printSection !==
                      printSection.debitNote && (
                        <td style={{ textAlign: "right" }}>
                          {item?.taxes?.map((r, i) => (
                            <p
                              key={i}
                              className="tax"
                              style={{ margin: "1px", border: "none" }}
                            >
                              {r?.amount}%
                            </p>
                          ))}
                        </td>
                      )}
                    {invoiceDetails?.printSection !==
                      printSection.goodsReceiveNote && invoiceDetails?.printSection !==
                      printSection.deliveryNote && (
                        <td style={{ textAlign: "right" }}>{item.taxAmt?.toFixed(decimalPosition)}</td>
                      )}
                    {invoiceDetails?.printSection !==
                      printSection.goodsReceiveNote && invoiceDetails?.printSection !==
                      printSection.deliveryNote && (
                        <td style={{ textAlign: "right" }}>
                          {item?.includedPrice === false
                            ? (item.lineTotal + item.taxAmt)?.toFixed(
                              decimalPosition
                            )
                            : item.lineTotal?.toFixed(decimalPosition)}
                        </td>
                      )}
                  </tr>
                ))}

                {invoiceDetails?.printSection !==
                  printSection.deliveryNote && invoiceDetails?.printSection !==
                  printSection.customerInvoice && invoiceDetails?.printSection !==
                  printSection.vendorBill && tableTotal && (
                    <tr className="table-tt-temp-1">
                      <td colSpan={
                        invoiceDetails?.printSection === printSection.goodsReceiveNote
                          ? 3
                          : invoiceDetails?.printSection === printSection.salesWSO || invoiceDetails?.printSection === printSection.purchaseWPO || invoiceDetails?.printSection === printSection.debitNote
                            || invoiceDetails?.printSection ===
                            printSection.purchaseOrder ? 5
                            : 6
                      }>
                        Total
                      </td>

                      {invoiceDetails?.printSection !==
                        printSection.goodsReceiveNote && invoiceDetails?.printSection !==
                        printSection.deliveryNote && invoiceDetails?.printSection !==
                        printSection.creditNote && invoiceDetails?.printSection !==
                        printSection.purchaseWPO && invoiceDetails?.printSection !==
                        printSection.debitNote && (
                          <td style={{ textAlign: "right" }}>
                            {currencyDetails?.symbol}
                            {TotalValues?.ttBeforeVat || "0.00"}
                          </td>
                        )}
                      {invoiceDetails?.printSection !==
                        printSection.goodsReceiveNote && <td colSpan={2}></td>}
                      {invoiceDetails?.printSection !==
                        printSection.goodsReceiveNote && invoiceDetails?.printSection !==
                        printSection.creditNote && invoiceDetails?.printSection !==
                        printSection.purchaseWPO && invoiceDetails?.printSection !==
                        printSection.debitNote && (
                          <td style={{ textAlign: "right" }}>
                            {" "}
                            {allData
                              ?.reduce((acc, row) => acc + row?.taxAmt, 0.0)
                              ?.toFixed(decimalPosition) || "0.00"}
                          </td>
                        )}
                      {invoiceDetails?.printSection !==
                        printSection.goodsReceiveNote && (
                          <td style={{ textAlign: "right" }}>
                            {currencyDetails?.symbol}
                            {TotalValues?.ttIncVat || "0.00"}
                          </td>
                        )}
                      {invoiceDetails?.printSection ===
                        printSection.goodsReceiveNote && (
                          <td>{invoiceDetails?.totalReceivedQty || ""}</td>
                        )}
                      {invoiceDetails?.printSection ===
                        printSection.goodsReceiveNote && (
                          <td style={{ textAlign: "right" }}>{invoiceDetails?.totalReceivedQty || ""}</td>
                        )}
                    </tr>
                  )}
              </>
            ) : (
              <tr className="no-data-bt-temp-1">
                <td colSpan={11}>No Data</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
      {isLastPage && (
        <div style={{ marginTop: items?.length > 0 ? "0" : "23.5em" }}>
          {invoiceDetails?.printSection !== printSection.creditNote && invoiceDetails?.printSection !== printSection.debitNote && (
            <div className="dynamic-ctn-temp-1">
              <div className="amt-in-wrd-temp-1">
                <span>Amount in words :</span>
                <br />
                <span className="wrd-show-span-temp-1">
                  {numToWords(TotalValues?.ttIncVat || 0)}{" "}
                  {currencyDetails?.currencyCode}
                </span>
              </div>
              <div className="ttls-temp-1">
                <div className="tt-1-temp-1">
                  <div className="flx-btwn-temp1">
                    <span>Total before vat</span>{" "}
                    <span>
                      {currencyDetails?.symbol}
                      {TotalValues?.ttBeforeVat || 0}
                    </span>
                  </div>
                  <ol className="ol-lst-temp-1 ">
                    {taxDetails?.map((item, i) => (
                      <li key={i} className="flx-btwn-temp1">
                        <span>{item?.taxName}</span>{" "}
                        <span>${item?.amount?.toFixed(decimalPosition)}</span>
                      </li>
                    ))}
                  </ol>
                </div>
                <div className="tt-2-temp-1">
                  <span>Total inc vat ({currencyDetails?.symbol})</span>
                  <span>
                    {currencyDetails?.symbol}
                    {TotalValues?.ttIncVat || 0}
                  </span>
                </div>
              </div>
            </div>
          )
          }
          <div className="qr-code-cntr-temp-1">
            <QRCode
              size={256}
              style={{
                height: "auto",
                width: "70px",
              }}
              value={qrData}
              viewBox="0 0 256 256"
            />
          </div>

          <div className="foot-detail-cntr-temp-1">
            <div className="terms-conditions-temp-2">
              <h3>
                <span>Terms and Conditions</span>
              </h3>
              {/* <ol>
                <li>
                  <span>
                    GOODS ONCE SOLD WILL NOT BE TAKEN BACK OR EXCHANGED
                  </span>
                </li>
                <li>
                  <span>
                    ALL DISPUTES ARE SUBJECT TO [CITY_NAME] JURISDICTION
                  </span>
                </li>
              </ol> */}
            </div>

            <div className="authorized-signatory-temp-2">
              <p>
                <span>AUTHORIZED SIGNATORY FOR</span>
              </p>
              <div className="signature-line-temp-2"></div>
            </div>
          </div>
        </div>
      )}
      {/* Page Footer with Page Number */}
      <footer
        style={{
          position: "absolute",
          width: "100%",
          padding: "0 1em ",
          right: "0mm",
          bottom: "0mm",

        }}
      >
        <TempFooter
          template={1}
          totalPage={totalPages}
          currentPage={pageNumber}
          companyDetails={{
            phoneNumber: companyProfileData?.contactNumber,
            email: companyProfileData?.companyEmail,
            website: companyProfileData?.website,
          }}
        />
      </footer>
    </div>
  );
};

export default PrintTempOne;
