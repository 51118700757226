import React, { useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { switch_to_pos_screen } from "../../PosNew/PosNewSlice";
import { IconButton, Tooltip } from "@mui/material";
import { listAlterationAPI } from "../GeneralAPI";
import AdvancedFilter from "../../../../filter/AdvancedFilter";
import AdvancedPagination from "../../../../pagination/AdvancedPagination";
import {
  currentTime,
  extractValue,
} from "../../../../../util/utilityFunctions";
import { setAlterationList } from "../GeneralSlice";

const GeneralAlteration = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loadingPage, setLoadingPage] = useState(false);
  const { alterationListData } = useSelector((state) => state.GeneralSlice);
  //pagination
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  //  filter submission
  const [filterData, setFilterData] = useState(null);
  const handleFilterSubmission = (data) => {
    setFilterData(data);
  };

  const homeBtnClickFn = () => {
    navigate("/userdashboard/dashboardhome");
    dispatch(switch_to_pos_screen({ posScreenState: false }));
  };

  const handleClickRow = (row) => {
    navigate(`/userdashboard/pos/general/alteration/singleview?id=${row._id}`);
  };

  const childRef = useRef();

  // Function to get the child's state
  const handleGetChildState = () => {
    if (childRef.current) {
      const state = childRef.current.getChildState();
      setFilterData(state);
    }
  };

  //  initial rendering
  useEffect(() => {
    if (!filterData) {
      handleGetChildState();
    }
    if (filterData) {
      setLoadingPage(true);
      dispatch(setAlterationList({ ...alterationListData, data: [] }));
      listAlterationAPI({
        body: {
          index: currentPage,
          shiftId: "",
          filter: {
            date: {
              from: `${filterData?.date?.from} ${currentTime}`,
              to: `${filterData?.date?.to} 23:59:59`,
            },
            branch: extractValue(filterData.branch),
            status: extractValue(filterData.status),
          },
        },
        setLoadingPage,
      });
    }
  }, [filterData, currentPage]);

  return (
    <div className="pos-conatiner">
      <div className="justify-space-between">
        <div className="new-order-top-left">
          <strong>ALTERATION</strong>
        </div>
        <div
          className="justify-center"
          style={{ gap: "10px", width: "70%", justifyContent: "flex-end" }}
        >
          <button
            className="pos-new-order-btn"
            onClick={() =>
              navigate("/userdashboard/pos/general/alteration/create")
            }
          >
            {" "}
            <span>New Order</span>
          </button>
          <AdvancedFilter
            handleFilter={handleFilterSubmission}
            filterOptions={["date", "branch", "status"]}
            ref={childRef}
          />
          <ReactToPrint
            trigger={() => (
              <IconButton
                className="printer-div"
                style={{
                  color: "#fff",
                  backgroundColor: "#002995",
                  borderRadius: "0",
                }}
              >
                <i class="bi bi-printer"></i>
              </IconButton>
            )}
          />
          <Tooltip title="Download">
            <IconButton
              style={{
                backgroundColor: "#ffd2d2",
                color: "#002995",
                borderRadius: "0",
              }}
            >
              <i class="bi bi-arrow-down-circle"></i>
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <hr className="pos-hr" />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <AdvancedPagination
          currentPage={currentPage}
          totalPages={alterationListData?.totalPages}
          onPageChange={handlePageChange}
          maxVisiblePages={5}
        />
      </div>
      <div
        className="new-global-table-container pos-table"
        style={{ paddingTop: "0 42px", maxHeight: "79vh" }}
      >
        <table>
          <thead>
            <tr>
              <th>DATE</th>
              <th>ORDER NO</th>
              <th>SHIFT</th>
              <th>EMPLOYEE</th>
              {/* <th>MODEL</th> */}
              <th>CUSTOMER</th>
              <th>MOBILE</th>
              <th>ORDER AMOUNT</th>
              <th>ORDER DUE</th>
              <th>STATUS</th>
            </tr>
          </thead>
          <tbody>
            {alterationListData?.data?.length > 0 ? (
              alterationListData?.data?.map((r, i) => (
                <>
                  <tr
                    onClick={() => handleClickRow(r)}
                    key={i}
                    style={{
                      backgroundColor: i % 2 === 0 ? "white" : "#e9e7e7",
                    }}
                  >
                    <td>{r?.date}</td>
                    <td>{r?.orderNo}</td>
                    <td>{r?.shift}</td>
                    <td>{r?.employee}</td>
                    <td>{r?.customerName}</td>
                    <td>{r?.contactNumber}</td>
                    <td>{r?.orderAmount}</td>
                    <td>{r?.amountDue}</td>
                    <td>{r?.status}</td>
                  </tr>
                </>
              ))
            ) : (
              <tr>
                <td colSpan={9} style={{ textAlign: "center" }}>
                  {loadingPage ? "LOADING...." : "NO DATA"}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GeneralAlteration;
