import axios from "axios";
import { HEADERS } from "../../../../API/UrlAndPaths";
import store from "../../../../Redux/store";
import { setPosReceiptSlice } from "./posReceiptSlice";

//get select template info
export const getTemplateInfoAPI = async () => {
  try {
    const result = await axios.post(
      "admin/getPosReceiptTemplateModel",
      {},
      HEADERS
    );
    if (result.status === 200) {
      store.dispatch(setPosReceiptSlice(result.data));
    }
  } catch (error) {
   store.dispatch(setPosReceiptSlice(null));

    console.log(error);
  }
};
//post  select pos receipt template data to server
export const setPosReceiptTemplateAPI = async (data) => {
  try {
    const result = await axios.post(
      "admin/setPosReceiptTemplateModel",
      data.body,
      HEADERS
    );
    if (result.status === 200) {
      console.log("success");
    }
  } catch (error) {
    console.log(error);
  }
};
