import { IconButton, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ReactToPrint from 'react-to-print'
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter'
import DeleteIcon from '@mui/icons-material/Delete';
import simImgg from "../../../../../Assets/SVG/Group 17014.svg"
import { useNavigate } from 'react-router-dom';
import { ListRewardPointAPI } from '../GeneralAPI';
import { useSelector } from 'react-redux';
import CustomPagination from '../../../../Single Components/CustomPagination';

const RewardPoints = () => {
  let navigateTo = useNavigate();
  const [FormDataInfo, setFormDataInfo] = useState([])
  const decimalPosition=localStorage.getItem("decimalPosition")
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (page) => {

      setCurrentPage(page);
  };
 
  const rewardList = useSelector((state) => state?.GeneralSlice?.listOfReward)
  const totalPages = rewardList?.totalPages;
  console.log(rewardList);
  const onClickSingleView=(row)=>{
      localStorage.setItem("singleViewById",row?._id);
      navigateTo("/userdashboard/pos/general/RewardPointSingleView")
  }

  const handleDataFromChild = (data) => {
      setFormDataInfo(data)
      // console.log(data);
  };
  useEffect(() => {
      ListRewardPointAPI()
  }, [])
  return  (
    <div className='global-page-parent-container'>

        <div
            style={{ padding: "0 1%" }}
            className="justify-space-between global-white-bg-container">
            <h3 style={{ margin: "0" }}>Reward Point</h3>

            <div className="justify-center" style={{ gap: "10px", width: "70%", justifyContent: "flex-end" }}>
                <ReactToPrint
                    trigger={() => (
                        <IconButton className="printer-div" style={{ color: "#fff", backgroundColor: "#002995", borderRadius: "0" }}>
                            <i class="bi bi-printer"></i>
                        </IconButton>
                    )}
                //   content={() => paymentsPrint}
                />
                <Tooltip title="Download">
                    <IconButton style={{ backgroundColor: "#ffd2d2", color: "#002995", borderRadius: "0" }}>
                        <i class="bi bi-arrow-down-circle"></i>
                    </IconButton>
                </Tooltip>
                <CategorySearchandFilter
                    statusOptions={['DONE', 'DRAFT']}
                    onData={handleDataFromChild}
                    isStatus={true}
                    isType={true}
                    isPaymentType={true}
                // isBranch={userRole === "admin"}
                />
            </div>
        </div>
        <div className='global-white-bg-container'>
        <div className="justify-center" style={{ justifyContent: "flex-end"}}>
                <CustomPagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />
            </div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
                {rewardList?.list!==undefined ? rewardList?.list?.map((r, i) =>(
                    <div className="receipe-card" style={{ background: "linear-gradient(to right, #eae7e7, #adb9c4)", width: "18%", padding: "15px", borderRadius: "12px", cursor: "pointer" }}
                        onClick={() => onClickSingleView(r)}
                        key={i} >

                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div>
                                <p style={{ margin: "0",fontSize:"small" }}>customer</p>
                                <p style={{ margin: "0",fontWeight:"bold" }}>{r?.customerName}</p>
                            </div>
                            <div>

                                <img src={simImgg} alt='' />
                            </div>
                        </div>
                        <div style={{ display: "flex",justifyContent:"space-between", marginTop: "19px" }}>
                            <div>
                                <p style={{ margin: "0" }}>point</p>
                                <p style={{ margin: "0" }}>{r?.balancePoint}</p>
                            </div>
                            <p>=</p>
                            <div>
                                <p style={{ margin: "0" }}>Amount</p>
                                <p style={{ margin: "0" }}>{r.redeemableAmt !== null && !isNaN(r.redeemableAmt)? r.redeemableAmt.toFixed(decimalPosition) : "00"}</p>

                            </div>
                        </div>

                    </div>
                )):
                    <div style={{width:"100%",textAlign:"center"}}>
                        <p>NO DATA</p>
                        </div>
                }
            </div>
        </div>
    </div>
  )
}

export default RewardPoints