import React, { useEffect, useState } from 'react'
import { viewAllCurrencyUpdated } from '../../../../API/Settings/Currency/CurrencyAPI';
import { companyProfileInfoForUser } from '../../../../API/Settings/Company Settings/companySettingsAPI';
import { filterObjFromList } from '../../../../Js/generalFunctions';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IconButton, Skeleton } from '@mui/material';
import CustomPagination from '../../../Single Components/CustomPagination';
import { convertDateFormat } from '../../../../Js/Date';
import CategorySearchandFilter from '../../../Single Components/CategorySearchandFilter';
import { ViewCustomerCreditReportAPICall } from './CustomerCreditReportAPI';
import { LoadingForm } from '../../../Single Components/LoadingForm';
import axios from 'axios';
import { setFilterActive } from '../../../Single Components/listSlice/filterCategorySlice';
import store from '../../../../Redux/store';

function CustomerCreditReport() {
  let navigate = useNavigate();
  let location = useLocation()
  const SingleListData = useSelector(state => state.customerCreditReport.value);
  const filtereDataOptions = useSelector((state) => state.listCategory);
  const currencyListUpdated = useSelector(
    (state) => state.viewUpdatedCurrency.value
  );
  const companyProfileData = useSelector((state) => state?.companyProfileSlice?.userValue)

  const [FormDataInfo, setFormDataInfo] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedBranch, setSelectedBranch] = useState(null);
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch);

  };
  const [symbol, setSymbol] = useState(null);
  const handleDataFromChild = (data) => {
    setFormDataInfo(data);
  };

  const totalPages = 10;
  const decimalPosition = localStorage.getItem("decimalPosition");


  const handlePageChange = (page) => {

    setCurrentPage(page);
  };

  const handleClick = (row) => {
    store.dispatch( setFilterActive(window.location.pathname))
    localStorage.setItem("SingleViewId", row?._id);
    navigate("/userdashboard/report/CustomerCreditReport/SingleView1", { state: { customerName: row?.customerName } })
  };
  // useEffect(() => {

  //   ViewCustomerCreditReportAPICall({}, setIsLoading)

  // }, []) 
  // useEffect(() => {
  //   // Check if data already exists in Redux
  //   if (!SingleListData) {
  //     // If not, call API
  //     ViewCustomerCreditReportAPICall({}, setIsLoading)

  //   }
  // }, [SingleListData]);

  useEffect(() => {
    // const source = axios.CancelToken.source();
    if (FormDataInfo.length !== 0) {
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
      !loadData && ViewCustomerCreditReportAPICall({
        fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} 00:00:00`,
        toDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} 23:59:59`,
        branchList: FormDataInfo?.branchList,
        search: FormDataInfo?.search,
        balanceType: FormDataInfo?.balanceOperator === "Greater" ? 0 :
          FormDataInfo?.balanceOperator === "Greater than equal" ? 1 :
            FormDataInfo?.balanceOperator === "Less" ? 2 :
              FormDataInfo?.balanceOperator === "Less than equal" ? 3 :
                FormDataInfo?.balanceOperator === "Equal" ? 4 :
                  null,

        balance: Number(FormDataInfo?.balanceValue),
        // index: currentPage-1,
      }, setIsLoading,
        // source
      );
    }
    // return () => {
    //   source.cancel('Request canceled by cleanup');
    // };
  }, [FormDataInfo
    //  currentPage
  ]);


  useEffect(() => {
    let currencyObj = filterObjFromList("_id", currencyListUpdated, "currency", companyProfileData)
    setSymbol(currencyObj?.symbol)
  }, [companyProfileData, currencyListUpdated])

  useEffect(() => {
    viewAllCurrencyUpdated({ status: 0 });
    companyProfileInfoForUser();
  }, [])
  return (
    <div className="global-page-parent-container">
      {/* table */}
      <div className="global-white-bg-container">
        <div className='create-button-blue-container' style={{ margin: "1%", display: 'flex', justifyContent: 'flex-end', alignItems: 'end' }}>
          {FormDataInfo?.branchList?.length > 0 && (
            <div style={{ marginLeft: '1%' }}>
              <h3>Branch name:
                {selectedBranch?.storeCode || filtereDataOptions?.filterCategorylist?.branchList?.storeCode}
                -{selectedBranch?.branchName || filtereDataOptions?.filterCategorylist?.branchList?.branchName}</h3>
            </div>
          )}
          <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: '100%'
          }}>
            <CategorySearchandFilter
              onData={handleDataFromChild}
              style={{ width: "100%" }}
              onBranchSelect={handleBranchSelect}
              isBalance={true}

            />
            <IconButton
              style={{ width: "100px" }}

              className="print-icon-container">
              <i class="bi bi-printer printer-icon"></i>
            </IconButton>
            <IconButton
              style={{ width: "100px" }}
              className="download-icon-container">
              <i class="bi bi-arrow-down-circle download-icon"></i>
            </IconButton>
          </div>

        </div>


        <div className="create-button-blue-container">
          <h3>Customer Credit Report</h3>
          {/* <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          /> */}
        </div>
        <div className="customer-receipt-table-container">
          <table>
            <thead>
              <tr>

                <th>CUSTOMERS</th>
                <th>MOBILE</th>
                <th>LAST PAID DATE</th>
                <th>WORK ORDER</th>
                <th>ALTERATION</th>
                <th>BILLING</th>
                <th>TOTAL</th>
                <th>DISCOUNT</th>
                <th>PAID</th>
                <th>BALANCE</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                [...Array(10)].map((r, i) => (
                  <tr key={i}>
                    <td colSpan={10}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                ))
              ) : SingleListData?.report !== undefined && SingleListData?.report?.length !== 0 ? (
                SingleListData?.report
                  ?.slice(0)
                  ?.map((r, i) => (
                    <tr key={i} onClick={() => handleClick(r)} >
                      <td style={{ width: "25px" }}>{r?.customerName}</td>
                      <td>{r?.customerMobile}</td>
                      <td>{r?.date ? convertDateFormat(r?.date): "-"} </td>
                      <td style={{ textAlign: 'end', paddingRight: '3%' }}>{r?.workOrder?.toFixed(decimalPosition)}</td>
                      <td>{r?.alteration?.toFixed(decimalPosition)}</td>
                      <td>{r?.billing?.toFixed(decimalPosition)}</td>
                      <td>{r?.total?.toFixed(decimalPosition)}</td>
                      <td>{r?.discount?.toFixed(decimalPosition)}</td>
                      <td>{r?.paidAmount?.toFixed(decimalPosition)}</td>
                      <td>{r?.balance?.toFixed(decimalPosition)}</td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td style={{ textAlign: 'center' }} colSpan={10}>No Data</td>
                </tr>
              )}

            </tbody>
            <tfoot >

              <tr>
                <th style={{ textAlign: 'start', fontSize: '1rem', paddingLeft: '5%' }} colSpan={3}>TOTAL</th>
                <th >{SingleListData?.total !== undefined && SingleListData?.total?.workOrder?.toFixed(decimalPosition)}</th>
                <th >{SingleListData?.total !== undefined && SingleListData?.total?.alteration?.toFixed(decimalPosition)}</th>
                <th >{SingleListData?.total !== undefined && SingleListData?.total?.billing?.toFixed(decimalPosition)}</th>
                <th >{SingleListData?.total !== undefined && SingleListData?.total?.total?.toFixed(decimalPosition)}</th>
                <th >{SingleListData?.total !== undefined && SingleListData?.total?.discount?.toFixed(decimalPosition)}</th>
                <th >{SingleListData?.total !== undefined && SingleListData?.total?.paidAmount?.toFixed(decimalPosition)}</th>
                <th >{SingleListData?.total !== undefined && SingleListData?.total?.balance?.toFixed(decimalPosition)}</th>
              </tr>
            </tfoot>
          </table>
        </div>
        <LoadingForm loading={isLoading} />
      </div>

    </div>
  )
}

export default CustomerCreditReport