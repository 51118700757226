// Function to format date as YYYY-MM-DD
export const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  
  // Get current date and date 30 days ago
  const currentDate = new Date();
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(currentDate.getDate() - 30);

//   convert date to DD-MM-YYYY formate 
export const dateFormatConverter = (date)=>{
  const [year,month,day] =date.split("-")
  return `${day}-${month}-${year}`
} 

// get current time 
export const currentTime = new Date().toTimeString().slice(0, 8);

//formatDateTime 
export function formatDateTime(dateTimeStr) {
    const dateObj = new Date(dateTimeStr);
  
    // Get date parts
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = dateObj.getFullYear();
  
    // Get time parts
    let hours = dateObj.getHours();
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
  
    // Convert 24-hour time to 12-hour time
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const formattedTime = `${String(hours).padStart(2, '0')}:${minutes} ${ampm}`;
  
    // Combine date and time
    const formattedDateTime = `${day}-${month}-${year} ${formattedTime}`;
    return formattedDateTime;
  }


// fetch all apis 
export const fetchAllApis = async (apiCalls, body) => {
  try {
    const results = await Promise.all(
      apiCalls.map(({ api, key, responseKey = "data" }) =>
        api({ body }).then((response) => ({
          [key]: response.status === 200 ? response[responseKey] : null,
        }))
      )
    );
    const combinedResults = Object.assign({}, ...results);
    return combinedResults;
  } catch (error) {
    console.error("An error occurred during API calls:", error);
    throw new Error("Failed to fetch data from APIs");
  }
};

// extract value 
export const extractValue = (dataArray) => {
  return dataArray.map(item => item.value);
}

// to get current date and  time with second
// Examples:
// console.log(getCurrentDateTime()); // Returns full date and time
// console.log(getCurrentDateTime({ date: false, time: true })); // Returns only time
// console.log(getCurrentDateTime({ date: true, time: false })); // Returns only date
export const getCurrentDateTime =({ date = true, time = true } = {}) => {
  const now = new Date();

  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');

  let datePart = '';
  let timePart = '';

  if (date) {
      datePart = `${year}-${month}-${day}`;
  }

  if (time) {
      timePart = `${hours}:${minutes}:${seconds}`;
  }

  return date && time ? `${datePart} ${timePart}` : datePart || timePart;
}


//convert 2024-09-26T18:30:00.000Z to "2024-09-17 11:05:05"

export const formatDateAndTime = (dateString) => {
  const date = new Date(dateString);
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, '0');
  const dd = String(date.getDate()).padStart(2, '0');
  const hh = String(date.getHours()).padStart(2, '0');
  const min = String(date.getMinutes()).padStart(2, '0');
  const ss = String(date.getSeconds()).padStart(2, '0');
  return `${yyyy}-${mm}-${dd} ${hh}:${min}:${ss}`;
};





