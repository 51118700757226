import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { open_dialog, switch_to_pos_screen } from './PosNewSlice';
import "./posNew.css"
import { Dialog, TextField } from '@mui/material';
import CategorySearchandFilter from '../../../Single Components/CategorySearchandFilter';
import job from "../../../../Assets/SVG/PosNew/Job Completion.svg"
import delivery from "../../../../Assets/SVG/PosNew/Delivery.svg"
import returnimg from "../../../../Assets/SVG/PosNew/Return.svg"
import { Return } from './Return/Return';
import { Delivery } from './Delivery/Delivery';
import { useNavigate } from 'react-router-dom';
import { viewCustomerOnSearch, viewOrderListBasedOnCustomer } from './NewOrder/NewOrderAPI';
import { get_customer_on_search, get_orderlist_basedon_customer } from './NewOrder/neworderSlice';
import store from '../../../../Redux/store';
export const PosNew = () => {
  const dispatch = useDispatch();
  const navigate=useNavigate()

  const {
    openDialog,
    posEditBtn,
    posPayBtn,
    posViewBtn,
    posPrintBtn,
    posjobBtn,
    posdeliveryBtn,
    posreturnBtn

  }=useSelector((state)=>state.posNewSlice)
  const {getcustomerOnSearch,getOrderListBasedOnCustomer}=useSelector((state)=>state.neworderslice)
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);
  const searchStatesInitial={
    mobile:"",
    name:""
  }
  const [searchStates,setSearchStates]=useState(searchStatesInitial)
  const [selectedOrderId,setSelectedOrderId]=useState(null)
  const [selectedCusId,setSelectedCusId]=useState(null)
  const handleFormChange=(key,value)=>{
    setSearchStates({...searchStates,[key]:value})
 }
 const searchFunction=()=>{
  const requestBody = {
    branchId: loginResponse?.branchPk
  };

  if (searchStates.mobile) {
    requestBody.mobileNo = searchStates.mobile;
  }

  if (searchStates.name) {
    requestBody.name = searchStates.name;
  }
  viewCustomerOnSearch(requestBody)
 }

 const handleClickRow=(id)=>{
  setSelectedCusId(id)
  viewOrderListBasedOnCustomer({
    _id:id,
    branchId:loginResponse?.branchPk,

  })

 }
 console.log(searchStates);

  const handleDataFromChild = (data) => {
    
  };
  const ReturnBtnClickFn=()=>{
    dispatch(open_dialog({openDialogState:"Return"}))
  }
  const DeliveryBtnClickFn=()=>{
    dispatch(open_dialog({openDialogState:"Delivery"}))
  }
  const newOrderBtnFn=()=>{
    navigate("/userdashboard/pos/neworder")
  }
  const alterationBtnClick=()=>{
    navigate("/userdashboard/pos/alteration")
  }
  const billingBtnClick=()=>{
    navigate("/userdashboard/pos/billing")
  }



  const handleClickOrderRow=(id)=>{
    setSelectedOrderId(id)
    localStorage.setItem("singleOrderId",id)
  }

  const editBtnClickFn=()=>{
    navigate("/userdashboard/pos/measurment")
  }

  const clearBtnFunction=()=>{
    store.dispatch(get_customer_on_search(undefined))
    store.dispatch(get_orderlist_basedon_customer(undefined))
    setSelectedOrderId(null)
    setSelectedCusId(null)
  }
  useEffect(() => {
    dispatch(switch_to_pos_screen({ posScreenState: true }));
  }, []);



  return (
    <div className='pos-conatiner'>
       <div className='pos-top-container'>
            <p className='pos-heading'>SEARCH</p>
            <div className="new-global-single-input">
              <TextField
                id="outlined-basic"
                label="Mobile"
                variant="outlined"
                focused
                value={searchStates?.mobile}
                onChange={(e)=>handleFormChange("mobile",e.target.value)}
                onKeyDown={(e) =>
                  e.key === "Enter" && searchFunction()
                }
              disabled={searchStates?.name !== ""}
           />
            </div>
            <div className="new-global-single-input">
              <TextField
                id="outlined-basic"
                label="Name"
                variant="outlined"
                focused
                value={searchStates?.name}
                onChange={(e)=>handleFormChange("name",e.target.value)}
                onKeyDown={(e) =>
                  e.key === "Enter" && searchFunction()
                }
                disabled={searchStates?.mobile !== ""}
              />
            </div>
            <div className="new-global-single-input">
              <TextField
                id="outlined-basic"
                label="W/O"
                variant="outlined"
                focused
              />
            </div>
            <p className='balance-pos'>Balance : 500.00</p>
         
            <div className="new-global-single-input" style={{width:"10%",flexDirection:"row",justifyContent:"end"}}>
               <CategorySearchandFilter
                 statusOptions={[]}
                 onData={handleDataFromChild}
                 // isCreatedBy={}
                 // isStatus={}
                 // onBranchSelect={}
                 isSearch={false}
                 isPos={true}
          />
            </div>
           
             <button onClick={newOrderBtnFn} className='new-order-btn'>New Order</button>
             <button onClick={clearBtnFunction} className='pos-clear-btn'>Clear</button>
       </div>
       <hr className='pos-hr' />
       <div className="new-global-table-container pos-table" style={{ paddingTop: "0 42px",minHeight:"35vh"}}>
       <table>
        <thead>
            <tr>
                <th>CUSTOMER</th>
                <th>MOBILE</th>
                <th>LAST ORDER NO</th>
                <th>ORDER DATE</th>
                <th>STATUS</th>
                <th>RACK</th>
                <th>RELATIONSHIP</th>
            </tr>
        </thead>
        <tbody>
        {
           getcustomerOnSearch !== undefined ? getcustomerOnSearch?.map((r,i)=>(
              <tr key={i} 
              style={{ 
                backgroundColor: i % 2 === 0 ? "white" : "#e9e7e7",
                border: selectedCusId === r?._id ? "1px solid #cc0486" : "",
              }} 
              onClick={()=>handleClickRow(r?._id)}>
                <td>{r?.name === null ? "---":r?.name}</td>
                <td>{r?.mobileNo === null ? "---":r?.mobileNo}</td>
                <td>{r?.orderNo === null ? "---":r?.orderNo}</td>
                <td>{r?.orderDate === null ? "---":r?.orderDate}</td>
                <td>{r?.orderstatus === null ? "---":r?.orderstatus}</td>
                <td>{r?.rack === null ? "---":r?.rack}</td>
                <td>{r?.relation === null ? "---":r?.relation}</td>
            </tr>
            ))
            :
            <tr>
                <td colSpan={7}>No Data</td>
            </tr>
          }
        </tbody>
          
           
         </table>
       </div>
       <div className="new-global-table-container pos-table" style={{ paddingTop: "0 42px",minHeight:"35vh" }}>
       <table>
        <thead>
            <tr>
                <th>ORDER NO</th>
                <th>DATE</th>
                <th>DELIVERY DATE</th>
                <th>ORDER QTY</th>
                <th>DELIVERED QTY</th>
                <th>PENDING QTY</th>
                <th>NET AMOUNT</th>
                <th>DISCOUNT</th>
                <th>PAID AMOUNT</th>
                <th>BALANCE</th>
                <th>TYPE</th>
                <th>STATUS</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
        {
            getOrderListBasedOnCustomer !== undefined ?getOrderListBasedOnCustomer?.orders?.map((r,i)=>(
              <tr key={i} onClick={()=>handleClickOrderRow(r?._id)} 
              style={{ 
                backgroundColor: i % 2 === 0 ? "white" : "#e9e7e7",
                border: selectedOrderId === r?._id ? "1px solid #cc0486" : "",
                }}>
                <td>{r?.sequence}</td>
                <td>{r?.orderDate}</td>
                <td>{r?.deliveryDate}</td>
                <td>{r?.customer}</td>
                <td>{r?.customer}</td>
                <td>{r?.customer}</td>
                <td>{r?.netAmount}</td>
                <td>{r?.discount}</td>
                <td>{r?.paidAmount}</td>
                <td>{r?.balance}</td>
                <td>{r?.orderType}</td>
                <td>{r?.status}</td>
                <td><p className='pos-table-round'>2/5</p></td>
            </tr>
            ))
            :
            <tr>
              <td colSpan={13}>No Data</td>
            </tr>
          }
        </tbody>
          
           
         </table>
       </div>
       <div className='pos-components'>
       <p onClick={billingBtnClick}>Billing</p>
       <div className='border-right'></div>
       <p onClick={alterationBtnClick}> Aleration</p>
       <div className='border-right'></div>
       <p>Customers</p>
       <div className='border-right'></div>
       <p>Orders</p>
       <div className='border-right'></div>
       <p>Payments</p>
       <div className='border-right'></div>
       <p>Credit Orders</p>
       <div className='border-right'></div>
       <p>Wallet</p>
       </div>
       <div className='pos-status-btn-container'>
        {
          posEditBtn &&
           <button onClick={editBtnClickFn} className={selectedOrderId===null?'pos-status-blue-btn disabled':'pos-status-blue-btn'} disabled={selectedOrderId===null}>
             <i class="bi bi-pencil-square"></i>
              Edit
          </button>
        }
        {/* {
          posPayBtn && */}
           <button className='pos-status-blue-btn'>
             <i class="bi bi-credit-card"></i>
              Pay
           </button>
        {/* } */}
        {
          posViewBtn &&
          <button className='pos-status-blue-btn'>
          <i class="bi bi-eye"></i>
           View
          </button>
        }
        {/* {
          posPrintBtn && */}
          <button className='pos-status-blue-btn'>
          <i class="bi bi-printer"></i>
           Print
          </button>
        {/* } */}
        {/* {
          posjobBtn && */}
          <button className='pos-status-blue-btn'>
          <img src={job} alt="" />
           Job
          </button>
        {/* } */}
        {/* {
          posdeliveryBtn && */}
          <button onClick={DeliveryBtnClickFn} className='pos-status-blue-btn'>
          <img src={delivery} alt="" />
           Delivery
          </button>
        {/* } */}
        {/* {
          posreturnBtn && */}
          <button onClick={ReturnBtnClickFn} className='pos-status-blue-btn pos-return-btn'>
          <img src={returnimg} alt="" />
           Return
          </button>
        {/* } */}
      
       
      
        
         
       
       
       </div>

       {/* return popup */}
       <Dialog open={openDialog==="Return"} maxWidth="lg">
          <Return/>
       </Dialog>

       {/* delivery popup */}
       <Dialog open={openDialog==="Delivery"} maxWidth="lg" >
           <Delivery/>
       </Dialog>
    </div>
  )
}
