import { clear } from "@testing-library/user-event/dist/clear";
import axios from "axios";
import { update_all_customer_list, update_filtered_customer_list } from "../../Redux/Customer/allCustomerListSlice";
import { get_all_customer_Filter } from "../../Redux/Customer/allCustomerListWithFilterSlice";
import { update_customer_id } from "../../Redux/Customer/customerIdSlice";
import { single_customer_history, update_customer_relation_id } from "../../Redux/Customer/customerRelationIdSlice";
import { update_relationship_list } from "../../Redux/Customer/relationshipSlice";
import { get_single_customer_order } from "../../Redux/Customer/singleCustomerOrderSlice";
import store from "../../Redux/store";
import { HEADERS, HEADER_QUERY } from "../UrlAndPaths";
import { errorSnackMsg } from "../../component/Custom Hooks/SnackBarUtilities";

//view all Customers API
export const viewAllCustomersAPICall = async (body,setIsLoading) => {
  setIsLoading!==undefined && setIsLoading(true)
  await axios
    .post("customer/viewCustomer", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_all_customer_list({ customerList: res.data }));
        setIsLoading!==undefined && setIsLoading(false)

      }
    })
    .catch((err) => {
      store.dispatch(update_all_customer_list({ customerList: undefined }));
      setIsLoading!==undefined && setIsLoading(false)

    });
};
export const CustomersFilterAPICall =(body,setIsLoading)=>{
  setIsLoading!==undefined&&setIsLoading(true)
  axios.post("customer/searchCustomer",body,{
    headers: {
      'Content-Type': 'application/json',
    },
  
  })
  .then((res)=>{
    if(res.status===200){
      store.dispatch(update_filtered_customer_list({filterCustomer:res.data}))
      setIsLoading!==undefined&&setIsLoading(false)
    }
  })
  .catch((err)=>{
    store.dispatch(update_filtered_customer_list({filterCustomer:undefined}))
    setIsLoading!==undefined&&setIsLoading(false)
    errorSnackMsg(err.response.data || "Network Failed")

  })

}
//get search all customer 
export const customerListApi = async (body)=>{
  try {
    const response = await axios.post("account/viewCustomerForDropdown", body, HEADERS)
    if(response.status === 200){
      store.dispatch(update_all_customer_list({ customerList: response.data }));
    }
  } catch (error) {
    console.error(error.message)
    store.dispatch(update_all_customer_list({ customerList: undefined }));
  }
}

//Generate Customer Id
export const generateCustomerIdAPICall = async () => {
  await axios
    .get("customer/generateCustomerId", HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_customer_id({ customerId: res.data }));
      }
    })
    .catch((err) => {
      store.dispatch(update_customer_id({ customerId: undefined }));
      console.error(err.response);
    });
};

//Add Customer API
export const addNewCustomerAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener,
  clearStates,
  closeDialog
) => {
  await axios
    .post("customer/addCustomer", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("New customer added 👍");
        setSuccess(true);
        updateListener();
        clearStates();
        closeDialog();
        console.log("New Customer Added Successfully");
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 409) {
        setSnackMsg("Customer already registered!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server error!!");
      }
      setError(true);
    });
};

//Generate Customer Relation ID
export const generateCustomerRelationIdAPICall = async (body) => {
  await axios
    .post("customer/generateRelationShipId",body,HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          update_customer_relation_id({ customerRelationId: res.data })
        );
      }
    })
    .catch((err) => {
      store.dispatch(
        update_customer_relation_id({ customerRelationId: undefined })
      );
      console.error(err.response);
    });
};
//Add New Relation
export const addNewCustomerRelationshipAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener,
  clearStates,setLoadingForm
) => {
  setLoadingForm!==undefined && setLoadingForm(true)

  await axios
    .post("customer/addrelationship", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setLoadingForm!==undefined && setLoadingForm(false)

        setSnackMsg("New relation added 👍");
        setSuccess(true);
        updateListener();
        clearStates();
      }
    })
    .catch((err) => {
      setLoadingForm!==undefined && setLoadingForm(false)

      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 409) {
        setSnackMsg("Relation exists already!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server error!!");
      }
      setError(true);
    });
};

//view all Customer Relations API
export const viewAllCustomerRelationsAPICall = async (body) => {
  await axios
    .post("customer/viewrelationshipbycusid", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_relationship_list({ relationList: res.data }));
        console.log("All customer relations fetched");
      }
    })
    .catch((err) => {
      store.dispatch(update_relationship_list({ relationList: undefined }));
      console.error(err.response);
    });
};

// view order list by customer id
export const viewOrderByCustomerId = async (body) => {
  await axios
    .post("sales/customerOrderList", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          get_single_customer_order({ singleCustomerOrder: res.data })
        );
      }
    })
    .catch((err) => {
      store.dispatch(
        get_single_customer_order({ singleCustomerOrder: undefined })
      );
      console.log(err.response);
    });
};

//Validate customer mobile existing or not
export const validateCustomerExistsAPICall = async (
  body,
  setCustomerStatusCode
) => {
  await axios
    .post("customer/validityCustomer", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        console.log("Customer verified");
      }
    })
    .catch((err) => {
      console.error(err.response);
      setCustomerStatusCode(err.response.status);
      switch (err.response.status) {
        case 409:
          console.log("conflict");
          //Confirmation msg
          break;
        case 403:
          //Can not add customer
          console.log("conflict");
          break;

        default:
          break;
      }
    });
};
//Validate Relation mobile existing or not
export const validateRelationExistsAPICall = async (
  body,
  setRelationStatusCode
) => {
  await axios
    .post("customer/validityrelation", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        console.log("Relation verified");
      }
    })
    .catch((err) => {
      console.error(err.response);
      setRelationStatusCode(err.response.status);
      switch (err.response.status) {
        case 409:
          console.log("conflict");
          //Confirmation msg
          break;
        case 403:
          //Can not add customer
          console.log("conflict");
          break;

        default:
          break;
      }
    });
};

//Convert Relation to customer (28 dec 22)
export const convertRelationToCustomerAPICall = async (body) => {
  const response = await axios.post(
    "customer/relationToCustomer",
    body,
    HEADERS
  );
  // .then((res) => {
  //   if (res.status === 200) {
  //     console.log("Relation converted to customer");
  //   }
  // })
  // .catch((err) => console.error(err.response));

  return response;
};

// view all customer with filter
export const viewAllCustomerWithFilterAPICall = async (
  body,
  setIsLoading,
  setErrorSnack,
  setSnackMsg
) => {
  if (setIsLoading !== undefined) setIsLoading(true);
  await axios
    .post("customer/viewCustomerwithfilter", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          get_all_customer_Filter({ allCustomerFilterData: res.data })
        );
        if (setIsLoading !== undefined) setIsLoading(false);
      }
    })
    .catch((err) => {
      store.dispatch(
        get_all_customer_Filter({ allCustomerFilterData: undefined })
      );
      console.log(err.response);
      switch (err.response.status) {
        case 404:
          setSnackMsg("No data available..");
          break;
        case 500:
          setSnackMsg("Server error!!");
          break;
        case 401:
          setSnackMsg("Unauthorized!!");
          break;
        case 403:
          setSnackMsg("Forbidden!!");
          break;

        default:
          setSnackMsg(
            "Unseen error occurred,Please contact to EYET Support!!!!"
          );
          break;
      }
      setErrorSnack(true);
      if (setIsLoading !== undefined) setIsLoading(false);
    });
};

//Edit Customer
export const editCustomerAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  clearStates,
  updateListener,
  closeDialog
) => {
  await axios
    .put("customer/editCustomer", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Edit Success..");
        clearStates();
        setSuccess(true);
        closeDialog();
        updateListener();
      }
    })
    .catch((err) => {
      setSnackMsg(err.response.data.msg);
      setError(true);
      console.error(err);
    });
};

//delete a relation
export const deleteRelationAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener
) => {
  await axios
    .delete("customer/deleteRelationShip", {
      headers: HEADER_QUERY,
      data: body,
    })
    .then((res)=>{
      if(res.status===200)
      {
        setSnackMsg('Relation deleted successfully...')
        setSuccess(true)
        updateListener()
      }
    })
    .catch((err)=>{
      setSnackMsg(err.response.data.msg)
      setError(true)
    });
};


//Edit a relation 
export const editRelationAPICall=async (body,setSuccess,setError,setSnackMsg,updateListener,clearState)=>{
  await axios.put('customer/editRelationship',body,HEADERS).then((res)=>{
    if(res.status===200)
    {
      setSnackMsg('Relationship edited successfully..')
      setSuccess(true)
      updateListener()
      clearState()
    }
  }).catch((err)=>{
    setSnackMsg(err.response.data.msg)
    setError(true)
  })
}

// delete a customer

export const deleteCustomerAPICall=async(body)=>{
  await axios.delete("customer/deleteCustomer",{headers:HEADER_QUERY,data:body})
    .then((res)=>{
      if(res.status===200){
        console.log("Del");
      }
    })
    .catch((err)=>{
      console.error(err.res.status);
    })
}

//customer single view 
export const viewingleCustomerAPICall = async (body) => {
  await axios
    .post("customer/getCustomerDetail", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(single_customer_history({ customerHistory: res.data }));
        
      }
    })
    .catch((err) => {
      store.dispatch(single_customer_history({ customerHistory: undefined }));
      console.error(err.response);
    });
};