import { IconButton, Skeleton, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Datepicker } from "../../../Single Components/Datepicker";
import { convertDateFormat, getCurrentTime, previousDate, today } from "../../../../Js/Date";
import { useNavigate } from "react-router-dom";
import { viewPurchaseOrderApiCall } from "../../../../API/Purchase Manager/purchaseOrderAPI";
import { PurchaseOrderFilterAPICall, viewPurchaseOrderList } from "../../../../API/Purchase Manager/NewPurchaseOrder/PurchaseOrder";
import { useSelector } from "react-redux";
import store from "../../../../Redux/store";
import { purchaseOrderSingleView } from "../../../../Redux/Prchase Manager/RFQ/RfqSlice";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Autocomplete, MenuItem, Select, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { salesQuotationFilterAPICall } from "../../SalesNew/SalesOrders/SalesQuotation/salesQuatationAPI";
import { viewAllAllowedBranchesAPICall } from "../../../../API/Settings/Company Settings/companySettingsAPI";
import { getAllActiveEmployeesAPICall } from "../../../../API/Staff/HRM/employeeAPI";
import { get_sales_order_quot_row_data_list } from "../../SalesNew/SalesOrders/SalesQuotation/salesQuatationSlice";
import CategorySearchandFilter from "../../../Single Components/CategorySearchandFilter";
import CustomPagination from "../../../Single Components/CustomPagination";
import axios from "axios";
import { setFilterActive } from "../../../Single Components/listSlice/filterCategorySlice";
import ReactToPrint from "react-to-print";
import ExcelJS from "exceljs"
// import { convertDateFormat, previousDate } from "../../../../../Js/Date";

export const NewPurchaseOrder = () => {
  const filtereDataOptions = useSelector((state) => state.listCategory);
  const userRole = useSelector((state) => state.userRoleSlice.value);
  const userBranchId = localStorage.getItem("branchId");
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [filteredData, setFilteredData] = useState({});
  const branchId = localStorage.getItem("branchId");
  const PurchaseOrderList = useSelector(
    (state) => state?.RfqSlice?.purchaseOrderList
  );
  //console.log(PurchaseOrderList);
  let navigateTo = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = PurchaseOrderList?.pages;
  const [selectedBranch, setSelectedBranch] = useState(null);
  localStorage.removeItem("singleViewByIdgrn");
  useEffect(() => {

    // viewPurchaseOrderList(branchId);
    localStorage.removeItem("singleViewById");
    store.dispatch(purchaseOrderSingleView({ singleRowData: undefined }));
  }, []);

  //handleClickRow

  const handleClickRow = (row) => {
    //store.dispatch(purchaseOrderSingleView({singleRowData:row }));
    //navigateTo("/userdashboard/purchaseManager/CreateNewPurchaseOrder");
    store.dispatch( setFilterActive(window.location.pathname))
    localStorage.setItem("singleViewById", row?._id);
    localStorage.setItem("singleViewByIdgrn", row?._id);
    navigateTo("/userdashboard/purchaseManager/PurchaseOrder/singleView");
  };
  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };
  const handlePageChange = (page) => {

    setCurrentPage(page);
  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };
  const handleFilterChange = (updatedItems) => {
    setFilteredData(updatedItems);
  };
  const formatFilterInfo = (filters) => {
    const parts = [];
  
    if (filters?.toDate && filters.fromDate) {
      parts.push(`As of ${filters.fromDate} to ${filters?.toDate}`);
    }
    if (filters?.branchList) {
      parts.push(`Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`);
    }
    if (filters?.createdBy) {
      parts.push(`createdBy: ${filters?.createdBy?.staff_name}`);
    }
    if (filters?.status) {
      parts.push(`Status: ${filters?.status}`);
    }
   
    
    return parts; // Return the array directly
  };
 
  const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Purchase Order");
    sheet.properties.defaultRowHeight = 20;
  
    // Add header section
    const titleRow = sheet.addRow(["Purchase Order"]);
    titleRow.font = { size: 15, bold: true };
    sheet.mergeCells('A1:G1');
    titleRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
  
    // Add additional header information
    const filterParts = formatFilterInfo(filteredData) || [];
  
    // Ensure filterParts is an array and iterate over it
    if (Array.isArray(filterParts)) {
      filterParts.forEach((part, index) => {
        const row = sheet.addRow([part]);
        row.font = { size: 11, bold: true };
        sheet.mergeCells(`A${index + 2}:G${index + 2}`);
        row.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
      });
    }
  
    // Add an empty row between header and table
    sheet.addRow([]);
  
    // Header row styling
    const headerRow = sheet.addRow(["No", "PO No", "Supplier Name", "Date","Total","CreatedBy","Status"]);
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFedf2f9" }, // Use full hex code with "FF" prefix for better compatibility
    };
    const headerFont = {
      size: 12,
      bold: true,
      color: { argb: "FF000000" }, // White font color
    };
  
    headerRow.eachCell((cell, colNumber) => {
      cell.fill = headerFill;
      cell.font = headerFont;
      cell.alignment = { vertical: 'middle', horizontal: 'center' }; // Ensure header text is centered
    })
  
    sheet.columns = [
      { header: "No", key: "No", width: 8 },
      { header: "PO No", key: "sequence", width: 15 },
      { header: "Supplier Name", key: "supplierName", width: 25 },
      { header: "Date", key: "quotationDate", width: 15 },
      { header: "Total", key: "total", width: 15 },
      { header: "CreatedBy", key: "CREATEDBY", width: 15 },
      { header: "Purchase Order", key: "type", width: 20 },
  
    ];
  
    PurchaseOrderList?.list?.forEach((item,index) => {
      const row = sheet.addRow({
        No: index+1,
        sequence: item.sequence,
        supplierName: item?.supplierName,
        quotationDate: item?.quotationDate,
        total: item?.total,
        CREATEDBY: item?.CREATEDBY,
        type: item?.type,
      
      });
  
      const rowFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFFFF" }, // Use full hex code with "FF" prefix for better compatibility
      };
      
      row.eachCell((cell, colNumber) => {
        cell.fill = rowFill;
        cell.border = {
        //   top: { style: "thin", color: { argb: "FF000000" } },
        //   left: { style: "thin", color: { argb: "FF000000" } },
          bottom: { style: "thin", color: { argb: "FFedf2f9" } },
        //   right: { style: "thin", color: { argb: "FFFFFFFF" } },
        };
        if (colNumber >= 1) { // Right align all columns except the first
            cell.alignment = { horizontal: 'center' };
          }
         
      });
    });
  
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Purchase Order.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  // useEffect(() => {
  //   if (FormDataInfo.length !== 0) {
  //     PurchaseOrderFilterAPICall({
  //       fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} 00:00:00`,
  //       toDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} 23:59:59`,
  //       createdBy: FormDataInfo.createdBy,
  //       branchList: FormDataInfo.branchList,
  //       type: FormDataInfo.status,
  //       search: FormDataInfo.search,
  //       index: currentPage - 1,
  //     }, setIsLoading);
  //   };
  // }, [FormDataInfo, currentPage]);
  useEffect(() => {
    

    if (FormDataInfo.length !== 0) {
  
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
      !loadData &&  PurchaseOrderFilterAPICall({
        // fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} 00:00:00`,
        // toDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} 23:59:59`,
        createdBy: FormDataInfo.createdBy,
        branchList: FormDataInfo.branchList,
        type: FormDataInfo.status,
        search: FormDataInfo.search,
        index: currentPage - 1,
      }, setIsLoading);
    };
   
  }, [FormDataInfo, currentPage]);
  return (
    <>
      <div className="global-page-parent-container">
        {/* <p className="breadcrumb">Purchase Manager &gt; Purchase Order</p> */}
        <div className="purchase-order-main-container">
          <div className="justify-space-between"
            style={{ gap: "0 12px", display: 'flex', justifyContent: 'space-between', alignItems: 'end'  }}
          >
             {FormDataInfo?.branchList?.length > 0 && (
              <div style={{ marginLeft: '1%' }}>
                <h3>Branch name: 
                  {selectedBranch?.storeCode||filtereDataOptions?.filterCategorylist?.branchList?.storeCode}
                  -{selectedBranch?.branchName||filtereDataOptions?.filterCategorylist?.branchList?.branchName}</h3>
              </div>
            )}
             <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              width: FormDataInfo?.branchList?.length ? '72%' : '100%'
            }}>
                <div style={{ display: "flex", alignItems: "center" }}>
              <Tooltip title="Share">
                  <IconButton>
                    <i
                      class="bi bi-share"
                      style={{
                        backgroundColor: "#9797e9",
                        color: "white",
                        fontSize: "medium",
                        padding: "8px",
                        borderRadius: "2px",
                      }}
                    ></i>
                  </IconButton>
                </Tooltip>

                <ReactToPrint
                  trigger={() => (
                    <IconButton
                      className="printer-div"
                      style={{ padding: "1px" }}
                    >
                      <i
                        style={{
                          color: "white",
                          backgroundColor: " #d787e7",
                          fontSize: "medium",
                          padding: "8px",
                          borderRadius: "2px",
                        }}
                        class="bi bi-printer"
                      ></i>
                    </IconButton>
                  )}
                  // content={() => paymentsPrintNew}
                />
                <Tooltip title="Download">
                  <IconButton onClick={downloadList}>
                    <i
                      class="bi bi-arrow-down-circle"
                      style={{
                        backgroundColor: "rgb(255 118 133 / 69%)",
                        color: "white",
                        fontSize: "medium",
                        padding: "8px",
                        borderRadius: "2px",
                      }}
                    ></i>
                  </IconButton>
                </Tooltip>
                <CategorySearchandFilter
              statusOptions={['PURCHASE ORDER', 'DONE']}
              onData={handleDataFromChild}
              isDate={false}
              isCreatedBy={true}
              isStatus={true}
              onBranchSelect={handleBranchSelect}
              onFilterChange={handleFilterChange}
            />
           
          </div>
            {/* <CategorySearchandFilter
              statusOptions={['PURCHASE ORDER', 'DONE']}
              onData={handleDataFromChild}
              isDate={false}
              isCreatedBy={true}
              isStatus={true}
              onBranchSelect={handleBranchSelect}
            />
            <button
              className="btn btn-primary"
              onClick={() =>
                navigateTo(
                  "/userdashboard/purchaseManager/CreateNewPurchaseOrder"
                )
              }
            >
              Create
            </button> */}
             <button
              className="btn btn-primary"
              onClick={() =>
                navigateTo(
                  "/userdashboard/purchaseManager/CreateNewPurchaseOrder"
                )
              }
            >
              Create
            </button>
            </div>
          </div>
          <div className="top-bar">
            <h3>Purchase Order</h3>
            <CustomPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              setFormDataInfo={setFormDataInfo}
            />
          </div>
          <div className="new-global-table-container">
            <table >
              <thead>
                <tr>
                  <th>No</th>
                  <th>PO No</th>
                  <th>Supplier Name</th>

                  <th>Date</th>
                  <th>Total</th>
                  <th>CreatedBy</th>
                  <th
                    style={{
                      width: "24%",
                    }}
                  >
                    Status
                  </th>
                </tr>
              </thead>
              {isLoading ? (
                <tbody>
                  <tr>
                    <td colSpan={10}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={30}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={10}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={30}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={10}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={30}
                      />
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {PurchaseOrderList?.list?.length !== 0 ? (
                    PurchaseOrderList?.list?.slice(0)

                      ?.map((r, i) => (
                        <tr key={i} onClick={() => handleClickRow(r)}>
                          <td>{i + 1}</td>
                          <td>{r?.sequence}</td>
                          <td>{r?.supplierName}</td>

                          <td>{r?.quotationDate}</td>
                          <td>{r?.total}</td>
                          <td>{r?.CREATEDBY}</td>
                          <td className="table-data-box">
                            {r?.type === "RFQ" ? (
                              <span className="rfq"> RFQ</span>
                            ) : r?.type === "PURCHASE ORDER" ? (
                              <span
                                className="po"
                                style={{ padding: "5px 25px" }}
                              >
                                PO
                              </span>
                            ) : r?.type === "CANCEL" ? (
                              <span className="cancel"> CANCEL</span>
                            ) : r?.type === "DONE" ? (
                              <span className="post"> DONE</span>
                            ) : (
                              "----"
                            )}
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td colSpan={10}>No Data</td>
                    </tr>
                  )}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
