import React, { useEffect, useState } from "react";
import { Home, ChevronLeft, HelpCircle, Printer, Download } from "lucide-react";
import "./generalBillingStyles.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { listBillingSingleViewAPI, singleWorkOrderPaymentAPI } from "../GeneralAPI";
import { listWorkOrderPaymentAPI } from "../Workorder/workOrderNewAPI";

const GeneralBillingSingleView = () => {
    const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState("Products");
  const location = useLocation();
  const {singleViewById } = location.state || {};
  const singleview = useSelector((state)=>state?.GeneralSlice?.billingSingleData)
  // console.log(singleview );
  
  
  const hasData = singleview?.items && singleview.items.length > 0;
  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(); // Returns date in format: MM/DD/YYYY or based on locale settings
};
 
const singleList = useSelector((state) => state?.workOrderNewList?.workorderPaymentsData)
//  console.log(singleList);
const hasDAta2 = singleList?.paymentList?.length > 0 && singleList?.paymentList


  
  
  const handleTabSwitch = (tab) => {
    setActiveTab(tab);
  };

  const handleHomeClick = () => {
    // Navigate to home
    navigate('/userdashboard/dashboardhome')
  };

  const handlePrintClick = () => {
    // Print action
    console.log("Print");
  };

  const handleDownloadClick = () => {
    // Download action
    console.log("Download");
  };

  const handleHelpClick = () => {
    // Help action
    console.log("Help");
  };
  const totals = {
    qty: 0,
    itemRate: 0,
    amount: 0,
    taxAmount: 0,
    netAmount: 0,
    
  };
  if (hasData) {
    singleview.items.forEach((item) => {
      totals.qty += item?.qty || 0;
      totals.itemRate += item?.itemRate || 0;
      totals.amount += item?.amount || 0;
      totals.taxAmount += item?.taxAmount || 0;
      totals.netAmount += item?.netAmount || 0;
      
    });
  }

  const handleBack = () => {
    // Navigate to home
   navigate("/userdashboard/pos/general/billing")
  };
  useEffect(()=>{
if(singleViewById!==undefined){
  listBillingSingleViewAPI({_id:singleViewById})
  listWorkOrderPaymentAPI({_id:singleViewById})

}
let payload={
  body:{
    _id:singleViewById
  }
}
let data = singleWorkOrderPaymentAPI(payload)
  },[])

  return (
    <div className="order-details">
      <div className="ord-cntr">
        <header>
          <div className="nav-left">
            <Home className="icon" onClick={handleHomeClick} />
            <ChevronLeft className="icon" onClick={handleBack} />
            <span className="orders-text">ORDERS</span>
          </div>
          <div className="nav-right">
            <HelpCircle className="icon help" onClick={handleHelpClick} />
            <Printer className="icon print" onClick={handlePrintClick} />
            <Download className="icon download" onClick={handleDownloadClick} />
          </div>
        </header>

        <main>
          {/* <div className="order-header">
            <h1>{singleview?.sequence}</h1>
            <span className="paid-tag">POST</span>
          </div> */}

          <div className="order-info">
            <div>
              <p>
                <span className="label">Date</span>:&#160;{formatDate(singleview?.date)}
              </p>
              <p>
                <span className="label">Delivery Date</span>:&#160;
                {formatDate(singleview?.deliveryDate)}
              </p>
              <p>
                <span className="label">Branch</span>:&#160;
                {singleview?.branch}
              </p>
              <p>
                <span className="label">Shift NO</span>:&#160;
                {singleview?.shiftId}
              </p>
              <p>
                <span className="label">Employee</span>:&#160;
                {singleview?.employee}
              </p>
            </div>
            <div>
              <p>
                <span className="label">Customer</span>:&#160;
                {singleview?.customer}
              </p>
              <p>
                <span className="label">Mobile</span>:&#160;
                {singleview?.mobile}
              </p>
              <p>
                <span className="label">Order Type</span>:&#160;
                {singleview?.orderType}
              </p>
              {/* <p>
                <span className="label">Delivery Note</span>:&#160;
                --
              </p>
              <p>
                <span className="label">Return Ref</span>:&#160;
                --
              </p> */}
            </div>
          </div>

          <div className="tabs-pos">
            <button
              className={activeTab === "Products" ? "active-tabs" : ""}
              onClick={() => handleTabSwitch("Products")}
            >
              Products
            </button>
            <button
              className={activeTab === "Payments" ? "active-tabs" : ""}
              onClick={() => handleTabSwitch("Payments")}
            >
              Payments
            </button>
          </div>

          {activeTab === "Products" ? (
            <table>
              <thead>
                <tr>
                  <th className="text-left">Product</th>
                  <th className="text-right">Qty</th>
                  <th className="text-right">Item Rate</th>
                  <th className="text-right">Amount</th>
                  <th className="text-right">VAT%</th>
                  <th className="text-right">VAT</th>
                  <th className="text-right">Net Amount</th>
                </tr>
              </thead>
              <tbody>
                {singleview?.items.map((product, index) => (
                  <tr key={index}>
                    <td>{product?.productName}</td>
                    <td className="text-right">{product?.qty?.toFixed(2)}</td>
                    <td className="text-right">
                      {product?.itemRate?.toFixed(2)}
                    </td>
                    <td className="text-right">{product?.amount?.toFixed(2)}</td>
                    <td className="text-right">{product?.taxPercentage}</td>
                    <td className="text-right">
                      {product?.taxAmount?.toFixed(2)}
                    </td>
                    <td className="text-right">
                      {product?.netAmount?.toFixed(2)}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td>TOTAL (AED)</td>
                  <td className="text-right">{totals?.qty.toFixed(2)}</td>
                  <td className="text-right">{totals?.itemRate.toFixed(2)}</td>

                  <td className="text-right">{totals?.amount.toFixed(2)}</td>
                  <td></td>
                  <td className="text-right">{totals?.taxAmount.toFixed(2)}</td>
                  <td className="text-right">{totals?.netAmount.toFixed(2)}</td>
                </tr>
              </tfoot>
            </table>
          ) : (
            <table>
              <thead>
                <tr>
                  <th className="text-left">Date</th>
                  <th className="text-left">Payment Method</th>
                  <th className="text-left">Type</th>
                  <th className="text-right">Amount</th>
                </tr>
              </thead>
              <tbody>
              {hasDAta2 ? singleList?.paymentList?.map((r, i) => (
                  <tr key={i}>
                    <td>{r.date}</td>
                    <td>{r.method}</td>
                    <td>{r.type}</td>
                    <td className="text-right">{r.amount.toFixed(2)}</td>
                  </tr>
                )) : (
                  <tr><th colSpan={4} >NO DATA</th></tr>
                )}
              </tbody>
            </table>
          )}

          {activeTab === "Products" && (
            <div className="summary">
              <div className="summary-item">
                <strong>Total Before VAT</strong>
                <span> {singleview?.totalBeforeVat.toFixed(2)}</span>
              </div>
              <div className="summary-item">
                <span>{singleview?.taxlist?.map((tax, index) => (
                <p key={index}>{tax.name}</p>
              ))}</span>
                <span> {singleview?.taxlist?.map((tax, index) => (
                <p key={index}>{tax.taxAmount}</p>
              ))}</span>
              </div>
              <div className="summary-item">
                <strong>Discount</strong>
                <span> {singleview?.discount.toFixed(2)}</span>
              </div>
              <div className="summary-item">
                <strong>Delivery Charge</strong>
                <span> {singleview?.deliveryCharge.toFixed(2)}</span>
              </div>
              <div className="summary-item total-gsv">
                <span>Total Inc VAT</span>
                <span> {singleview?.totalIncVat?.toFixed(2)}</span>
              </div>
              <div className="summary-item amount-due">
                <span>Amount Due</span>
                <span> {singleview?.amountDue?.toFixed(2)}</span>
              </div>
            </div>
          )}
        </main>
      </div>
    </div>
  );
};

export default GeneralBillingSingleView;
