import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router";
import { InventoryAdjustmentFilterAPICall, deleteInventaryAdjustmentAPICall, viewInventoryAdjustmentAPICall } from './InventoryAdjustmentAPI';
import { useSelector } from 'react-redux';
import store from '../../../../../Redux/store';
import { get_inventory_adjustment_single_view } from './InventoryAdjustmentSlice';
import { IconButton, Skeleton, Tooltip } from '@mui/material';
import ErrorSnackbar from '../../../../Single Components/SnackBars/ErrorSnackbar';
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter';
import { convertDateFormat, getCurrentTime } from '../../../../../Js/Date';
import CustomPagination from '../../../../Single Components/CustomPagination';
import axios from 'axios';
import { Source } from '@mui/icons-material';
import { setFilterActive } from '../../../../Single Components/listSlice/filterCategorySlice';
import ReactToPrint from 'react-to-print';
import ExcelJS from "exceljs"

export const InventoryAdjustment = () => {
  const filtereDataOptions = useSelector((state) => state.listCategory);
  const inventoryAdjList = useSelector((state) => state.InventoryAdjustmentSlice.value)
  let navigate = useNavigate();
  const [snackBarStates, setSnackBarStates] = useState({
    success: false,
    error: false,
    message: "",
    alert: false
  });
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = inventoryAdjList?.pages;
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [filteredData, setFilteredData] = useState({});

  const createFn = () => {
    navigate("/userdashboard/inventory/operation/inventoryAdjustment/create")
  }
  const handleFilterChange = (updatedItems) => {
    setFilteredData(updatedItems);
  };

  const formatFilterInfo = (filters) => {
    const parts = [];

    if (filters?.toDate && filters.fromDate) {
      parts.push(`As of ${filters.fromDate} to ${filters?.toDate}`);
    }
    if (filters?.branchList) {
      parts.push(
        `Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`
      );
    }
    if (filters?.location) {
      parts.push(`location: ${filters?.location?.name}`);
    }
    if (filters?.toLocation) {
      parts.push(`To Location: ${filters?.toLocation?.name}`);
    }
    if (filters?.status) {
      parts.push(`status: ${filters?.status}`);
    }
    return parts; // Return the array directly
  };
  const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Inventory Adjustment");
    sheet.properties.defaultRowHeight = 20;

    // Add header section
    const titleRow = sheet.addRow(["Inventory Adjustment"]);
    titleRow.font = { size: 15, bold: true };
    sheet.mergeCells("A1:F1");
    titleRow.getCell(1).alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    // Add additional header information
    const filterParts = formatFilterInfo(filteredData) || [];

    // Ensure filterParts is an array and iterate over it
    if (Array.isArray(filterParts)) {
      filterParts.forEach((part, index) => {
        const row = sheet.addRow([part]);
        row.font = { size: 11, bold: true };
        sheet.mergeCells(`A${index + 2}:F${index + 2}`);
        row.getCell(1).alignment = { vertical: "middle", horizontal: "center" };
      });
    }

    // Add an empty row between header and table
    sheet.addRow([]);

    // Header row styling
    const headerRow = sheet.addRow([
      "Adjustment date",
      "Name",
      "Branch",
      "Location",
      "Created By",
      "Status",
     
    ]);
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFedf2f9" }, // Use full hex code with "FF" prefix for better compatibility
    };
    const headerFont = {
      size: 12,
      bold: true,
      color: { argb: "FF000000" }, // White font color
    };

    headerRow.eachCell((cell, colNumber) => {
      cell.fill = headerFill;
      cell.font = headerFont;
      cell.alignment = { vertical: "middle", horizontal: "center" }; // Ensure header text is centered
    });

    sheet.columns = [
      { header: "Adjustment date", key: "adjustmentDate", width: 20 },
      { header: "Name", key: "name", width: 20 },
      { header: "Branch", key: "branchName", width: 25 },
      { header: "Location", key: "locationName", width: 25 },
      { header: "Created By", key: "CREATEDBY", width: 25 },
      { header: "Inventory Adjustment", key: "status", width: 15 },
    ];

    inventoryAdjList?.list?.forEach((item, index) => {
      const row = sheet.addRow({
        adjustmentDate:convertDateFormat(item?.adjustmentDate) ,
        name: item?.name,
        branchName: item.branchName,
        locationName: item?.locationName,
        CREATEDBY: item?.CREATEDBY,
        status: item?.status,
        
      });

      const rowFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFFFF" }, // Use full hex code with "FF" prefix for better compatibility
      };

      row.eachCell((cell, colNumber) => {
        cell.fill = rowFill;
        cell.border = {
          //   top: { style: "thin", color: { argb: "FF000000" } },
          //   left: { style: "thin", color: { argb: "FF000000" } },
          bottom: { style: "thin", color: { argb: "FFedf2f9" } },
          //   right: { style: "thin", color: { argb: "FFFFFFFF" } },
        };
        if (colNumber >= 1) {
          // Right align all columns except the first
          cell.alignment = { horizontal: "center" };
        }
      });
    });

    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Inventory Adjustment.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };
  const closeSnackbar = () => {
    setSnackBarStates({
      ...snackBarStates,
      success: false,
      error: false,
      alert: false,
      message: "",
    });
  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };
  const handlePageChange = (page) => {

    setCurrentPage(page);
  };

  const handleClickRow = (row) => {
    store.dispatch( setFilterActive(window.location.pathname))
    // store.dispatch(get_inventory_adjustment_single_view({singleinventoryAdjustmentrData:row}))
    localStorage.setItem("singleViewById", row?._id)
    navigate("/userdashboard/inventory/operation/inventoryAdjustment/create")
  }

  const deleteJournalEntryClick = (id) => () => {
    deleteInventaryAdjustmentAPICall({ id })
  }
  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };

  const deletePostedJournal = () => {
    setSnackBarStates({ ...snackBarStates, message: "posted inventory Adjustment entrie can't be deleted!! ", error: true })
  }
 
  useEffect(() => {
    // viewInventoryAdjustmentAPICall()
    store.dispatch(get_inventory_adjustment_single_view({ singleinventoryAdjustmentrData: undefined }))
    localStorage.removeItem("singleViewById")
  }, [])

  // useEffect(() => {
  //    InventoryAdjustmentFilterAPICall({
  //       // fromDate: `${FormDataInfo.fromDate} ${getCurrentTime()}`,
  //       // toDate: `${FormDataInfo.toDate} ${getCurrentTime()}`,
  //       // branchList: FormDataInfo.branchList,
  //       // createdBy: FormDataInfo.createdBy,
  //       // location: FormDataInfo.location,
  //       // status: FormDataInfo.status,
  //       // search: FormDataInfo.search,
  //       index: currentPage-1,
  //     }, setIsLoading);

  // }, [
  //    currentPage
  //   ]);
  
  useEffect(() => {
  
    if (FormDataInfo.length !== 0) {
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
      !loadData && viewInventoryAdjustmentAPICall({
        branchList: FormDataInfo.branchList,
        locationId: FormDataInfo.location,
        search: FormDataInfo.search,
        index: currentPage - 1,
        status: FormDataInfo.status,
      }, setIsLoading);
    }
  
  }, [FormDataInfo, currentPage]);

  return (
    <div className='global-page-parent-container'>
      {/* table */}
      <div className='global-white-bg-container'>
        <div className="justify-space-between"
          style={{ gap: "0 12px", display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}
        >
            {FormDataInfo?.branchList?.length > 0 && (
              <div style={{ marginLeft: '1%' }}>
               <h3>Branch name: 
                  {selectedBranch?.storeCode||filtereDataOptions?.filterCategorylist?.branchList?.storeCode}
                  -{selectedBranch?.branchName||filtereDataOptions?.filterCategorylist?.branchList?.branchName}</h3>
              </div>
            )}
             <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap:'9px',
              width: FormDataInfo?.branchList?.length ? '72%' : '100%'
            }}>
               <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap:'9px',
              width: FormDataInfo?.branchList?.length ? '72%' : '100%'
            }}>
               <div style={{ display: "flex", alignItems: "center" }}>
              <Tooltip title="Share">
                  <IconButton>
                    <i
                      class="bi bi-share"
                      style={{
                        backgroundColor: "#9797e9",
                        color: "white",
                        fontSize: "medium",
                        padding: "8px",
                        borderRadius: "2px",
                      }}
                    ></i>
                  </IconButton>
                </Tooltip>

                <ReactToPrint
                  trigger={() => (
                    <IconButton
                      className="printer-div"
                      style={{ padding: "1px" }}
                    >
                      <i
                        style={{
                          color: "white",
                          backgroundColor: " #d787e7",
                          fontSize: "medium",
                          padding: "8px",
                          borderRadius: "2px",
                        }}
                        class="bi bi-printer"
                      ></i>
                    </IconButton>
                  )}
                  // content={() => paymentsPrintNew}
                />
                <Tooltip title="Download">
                  <IconButton onClick={downloadList}>
                    <i
                      class="bi bi-arrow-down-circle"
                      style={{
                        backgroundColor: "rgb(255 118 133 / 69%)",
                        color: "white",
                        fontSize: "medium",
                        padding: "8px",
                        borderRadius: "2px",
                      }}
                    ></i>
                  </IconButton>
                </Tooltip>
                <CategorySearchandFilter
            statusOptions={['POSTED', 'DRAFT', 'INPROGRESS', 'CANCEL', 'REQUESTED', 'APPROVED']}
            onData={handleDataFromChild}
            isLocation={true}
            isDate={false}
            isStatus={true}
            onBranchSelect={handleBranchSelect}
            onFilterChange={handleFilterChange}
          /> 
           
          </div>
          {/* <CategorySearchandFilter
            statusOptions={['POSTED', 'DRAFT', 'INPROGRESS', 'CANCEL', 'REQUESTED', 'APPROVED']}
            onData={handleDataFromChild}
            isLocation={true}
            isDate={false}
            isStatus={true}
            onBranchSelect={handleBranchSelect}
          /> */}
          <button onClick={createFn} className='create-button-blue'>Create</button>
          </div>
         
          </div>
        </div>
        <div className='create-button-blue-container'>
          <h3>Inventory Adjustment</h3>
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            setFormDataInfo={setFormDataInfo}
          />
          {/* <button onClick={createFn} className='create-button-blue'>Create</button> */}
        </div>
        <div className='new-global-table-container'>
          <table>
            <thead>
              <tr>
                <th>Adjustment date</th>
                <th>Name</th>
                <th>Branch</th>
                <th>Location</th>
                <th>Created By</th>
                <th>Status</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                [...Array(10)].map((r, i) => (
                  <tr key={i}>
                    <td colSpan={8}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                ))
              ) : inventoryAdjList?.list !== undefined &&
                inventoryAdjList?.list?.length !== 0 ? (
                inventoryAdjList?.list
                  ?.slice(0)

                  ?.map((r, i) => (
                    <tr key={i} >
                      <td onClick={() => handleClickRow(r)}>{convertDateFormat(r?.adjustmentDate)}</td>
                      <td onClick={() => handleClickRow(r)}>{r?.name}</td>
                      <td onClick={() => handleClickRow(r)}>{r?.branchName}</td>
                      <td onClick={() => handleClickRow(r)}>{r?.locationName}</td>
                      <td onClick={() => handleClickRow(r)}>{r?.CREATEDBY}</td>
                      <td className="table-data-box">
                        {r?.status === "POSTED" ? (
                          <span className="post"> DONE</span>
                        ) : r?.status === "DRAFT" ? (
                          <span className="draft"> DRAFTED</span>
                        ) : r?.status === "INPROGRESS" ? (
                          <span className="inprogress"> IN PROGRESS</span>
                        ) : r?.status === "CANCEL" ? (
                          <span className="cancel"> CANCEL</span>
                        ) : r?.status === "REQUESTED" ? (
                          <span className="requested"> REQUESTED</span>
                        ) : r?.status === "APPROVED" ? (
                          <span className="granted"> GRANTED</span>
                        ) :
                          (
                            "---"
                          )}
                      </td>
                      <td>
                        <IconButton
                          onClick={r?.status !== "CANCEL" ? deletePostedJournal : deleteJournalEntryClick(r?._id)}
                        >
                          <i className={`bi bi-trash3 ${r?.status !== "CANCEL"
                              ? "edit-icon-disabled"
                              : "delete-icon"
                            }`}></i>
                        </IconButton>
                      </td>

                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan={6}>No Data</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <ErrorSnackbar
        open={snackBarStates.error}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
    </div>
  )
}
