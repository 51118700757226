import React, { useEffect, useMemo, useRef } from "react";
import "../../../../../css/Sales/Billingnew.css";
import { useState } from "react";
import {
  Autocomplete,
  Backdrop,
  CircularProgress,
  Dialog,
  IconButton,
  Skeleton,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { categoryListAPICall } from "../../../Inventory/Configuration/Category/CategoryAPI";
import {
  comboOffersProductsListForBilling,
  getAllProductsListForBilling,
  getAllSubCatForBilling,
  getProductFromBarCodeScan,
  paymentTypesForBilling,
  sendMessageToWhatsApp,
  specialOfferProductsListForBilling,
} from "./newBillingAPI";
import {
  areArraysEqual,
  getTodaysLowerCaseDay,
  roundNumericFields,
} from "../../../../../Js/generalFunctions";
import { PrintOutlined } from "@mui/icons-material";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { viewPosSettingsAPICall } from "../../../PointOfSale/Configuration/Settings/posSettingsAPI";
import { getCurrentTime, today } from "../../../../../Js/Date";
import ReactToPrint from "react-to-print";
import { getDiscountAPICall } from "../../../../../API/Settings/Discount/discountAPI";
import dummyProductItemImg from "../../../../../Assets/BillingImages/emptyFoodImg.svg";
import emptyCartItemImg from "../../../../../Assets/BillingImages/emptyCart.svg";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import "../../../../../css/Orders/viewOrder.css";
import "../../../../../css/Purchase Manager/purchaseOrder.css";
import { switch_to_billing_screen } from "./newBillingSlice";
import TopBar from "./Components/TopBar";
import {
  setBtnSelected,
  setOrderWaiter,
} from "./Components/topBarSliceBilling";
import BillingPayment from "./Components/BillingPayment";
import ViewOrders from "./Components/ViewOrders";
import {
  setCartTOBackend,
  setDialogBox,
  setOrderIdInPOS,
  setPOSBranchId,
  setPageIndex,
  setPagination,
  setSelectedPosCat,
  setSingleOrderInfo,
  setUpdateOrder,
} from "./Slice/billingScreenSlice";
import {
  addPOSOrderWoFloorSelectionAPI,
  addPOSOrdertoKOT,
  holdPOSOrderAPI,
  singlePOSOrderInfoAPICall,
  updatePosOrderAPICall,
} from "./API/billingScreenSection";
import { viewCardManualAPICall } from "../../../../../API/Settings/CardAndUpiType/cardManualAPI";
import {
  listHeldOrders,
  listOrdersForBillingAPI,
} from "./API/viewOrderSection";
import CategoryEmptyAnimation from "./Gif/CategoryEmptyAnimation";
import brokenImg from "./image/repair-tool.png";
import ArrowPath from "./Utilities/ArrowPath";
import { clearPathData, setArrowPathData } from "./Utilities/ArrowPathSlice";
// import { listSpecialItemsForBilling } from "../SpecialItems/specialItemsApi";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
// import ComboPriceTag from "../SpecialItems/components/ComboPriceTag";
import TableRestaurantTwoToneIcon from "@mui/icons-material/TableRestaurantTwoTone";
import PosReceiptTemplate from "../../../components/template/posReceipt/PosReceiptTemplate";
import { getTemplateInfoAPI } from "../../../Settings/posReceipt/posReceiptApi";
import BarcodeReader from "react-barcode-reader";
// import ReservationBilling from "./Components/ReservationBilling";
// import { listReservationList } from "./API/billingReservation";
import axios from "axios";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import html2pdf from 'html2pdf.js';
import { switch_to_pos_screen } from "../PosNewSlice";

export const Billing = () => {

  const dispatch = useDispatch();
  // to hide the sidemenu
  dispatch(switch_to_billing_screen({ billingBoolean: true }));

  // useref
  let invoicePrint = useRef();
  const categoryArrayRef = useRef(null);
  // locallyStored items
  const decimalPosition = Number(localStorage.getItem("decimalPosition"));
  const userShiftStatus = localStorage.getItem("shiftStatus");

// global states
  const {tableLoading,currencyDetails}=useSelector((state)=>state.mainDashBoardSlice)
  // store states from topbar
  const {
    btnClicked,
    billType,
    customer,
    defaultCustomer,
    aggregator,
    searchBarInput,
    waiter,
    aggregatorRef,
  } = useSelector((state) => state.topBarSliceBilling);


  // billingSlice states
  const {
    orderIdForPos,
    dialogOpen,
    billLoading,
    singleOrderInfo,
    updatedOrder,
    categoryLoading,
    posCategoryLoading,
    selectedPOSCategory,
    pageIndex,
    pagination,
  } = useSelector((state) => state.billingScreenSlice);


  // const { specialItemsListForBilling } = useSelector(
  //   (state) => state.specialItemsSlice
  // );
  // newBillingSlice
  const { productsList, productType } = useSelector(
    (state) => state.newBillingSlice
  );

  const userInfo = useSelector((state) => state.loginResponseSlice.value);

  //initial API Responses
  const CategoryList = useSelector((state) => state.categorySlice.value);
  const posCategoryList = useSelector(
    (state) => state.newBillingSlice.subCategoryList
  );
  const allActiveEmpList = useSelector(
    (state) => state.allActiveEmployeeSlice.value
  );
  const { receiptData } = useSelector((state) => state.viewOrdersBillingSlice);

  // user ID's
  const userShiftpk = JSON.parse(userShiftStatus)?._id;
  const employeeId = userInfo?.data?._id;
  const userBranchId = userInfo?.branchPk;

  // useStates

  const [input, setInput] = useState(""); //keypad button value
  const [inputSelection, setInputSelection] = useState(null); //keypad action selection

  const [isLoading, setIsLoading] = useState(false);
  const [selectedCartRow, setSelectedCartRow] = useState(null);

  const [isCartModified, setIsCartModified] = useState(false);
  const [duplicateCartArray, setDuplicateCartArray] = useState([]);

  const [cartItems, setCartItems] = useState([]);
  const qtyInputRefs = useRef(cartItems?.map(() => React.createRef()));
  const salesPriceRefs = useRef(cartItems?.map(() => React.createRef()));

  const [selectedPIndex, setSelectedPIndex] = useState("non-veg");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [specialItemCat, setSpecialItemCat] = useState(null);

  const [barCodeInput, setBarcodeInput] = useState("");

  // barcode reading function
  const handleScan = (result) => {
    if (result) {
      setBarcodeInput(result);
    }
  };
  // main category selection
  const categoryOnClick = (id) => () => {
    setSelectedCategory(selectedCategory === id ? null : id);
    dispatch(setSelectedPosCat(null));
    dispatch(setPageIndex(0));
    dispatch(setPagination(false));
    dispatch(clearPathData());
    let payload = {
      catId: selectedCategory === id ? undefined : id,
    };
    getAllSubCatForBilling(payload);
  };

  // pos/sub category select
  const posCategorySelect = (row) => {
    if (selectedPOSCategory === row?._id) {
      dispatch(setSelectedPosCat(null));
    } else {
      dispatch(setSelectedPosCat(row?._id));
      dispatch(setArrowPathData(row));
    }

    let payload = {
      catId: selectedCategory !== null ? selectedCategory : "",
      parentCategories: selectedPOSCategory === row?._id ? undefined : row?._id,
    };
    getAllSubCatForBilling(payload);
    dispatch(setPageIndex(0));
    dispatch(setPagination(false));

  };

  // special items and combo offers selection
  const specialItemsClick = (row) => {
    if (specialItemCat === row?._id) {
      getAllProductsListForBilling(
        { branchId: userBranchId, index: 0 },
        setIsLoading
      );
      setSpecialItemCat(null);
    } else {
      setSpecialItemCat(row?._id);

      if (row?._id === "combo") {
        comboOffersProductsListForBilling(
          { branchId: userBranchId },
          setIsLoading
        );
      } else {
        specialOfferProductsListForBilling(
          {
            id: row?._id,
            branchId: userBranchId,
          },
          setIsLoading
        );
      }
    }
  };

  // Function to add a product to the cart
  const addToCart = (product) => {
    // Check if the product is already in the cart based on its id
    const existingItemIndex = cartItems.findIndex(
      (item) => item._id === product._id
    );
    if (existingItemIndex !== -1) {
      const updatedCartItems = [...cartItems];
      let existingQty=updatedCartItems[existingItemIndex].quantity
      // If the product is already in the cart, update its quantity
      updatedCartItems[existingItemIndex].quantity = Number(existingQty) + 1; // Increase quantity by 1
      setCartItems(updatedCartItems);
    } else {
      // If the product is not in the cart, add it with a quantity of 1
      setCartItems([
        ...cartItems,
        { ...product, quantity: 1, itemType: productType },
      ]);
    }
    setIsCartModified(!isCartModified);
  };

  // Functions to delete cart item
  const handleDelete = (index) => {
    const updatedCartItems = [...cartItems];
    updatedCartItems.splice(index, 1); // Remove the item at the specified index
    setCartItems(updatedCartItems);
    setIsCartModified(!isCartModified);
  };

  // input the changes of quantity from cart
  const cartQuantityChange = (index, type) => (e) => {
    setInput("");
    const updatedCartItems = [...cartItems];

    updatedCartItems[index][type] = Number(e.target.value);
    setCartItems(updatedCartItems);

    setIsCartModified(!isCartModified);
  };
  // add note on cart items
  const cartAddNote = () => {
    const updatedCartItems = [...cartItems];
    updatedCartItems[selectedCartRow].customerNote = input;
    setCartItems(updatedCartItems);
    dispatch(setDialogBox(null));
    setInputSelection(null);
    setInput("");
  };

  // keypad buttons onChange
  const handleButtonClick = (value) => {
    if (selectedCartRow !== null) {
      setInput((prevInput) => prevInput + value);
      const updatedCartItems = [...cartItems];

      if (inputSelection === "qty") {
        updatedCartItems[selectedCartRow].quantity = Number(input + value);
      } else if (inputSelection === "price") {
        updatedCartItems[selectedCartRow].salesPrice = Number(input + value);
      }
      setCartItems(updatedCartItems);
      setIsCartModified(!isCartModified);
    }
  };

  // calculate taxes of the cart items
  const calculateAndSetCalculatedValues = () => {
    const calculatedValues = cartItems?.map((row) => {
      let totalAmt = Number(row?.quantity) * Number(row?.salesPrice);
      let subtotalBeforeTax = totalAmt?.toFixed(decimalPosition);
      let totalTax = row?.taxes?.reduce((sum, item) => sum + item?.amount, 0);
      let totalExclTax = (Number(subtotalBeforeTax) * totalTax) / 100;

      if (row?.includedPrice === "true" || row?.includedPrice === true) {

        let taxesAmount=row?.taxes?.map((obj)=>obj?.amount)
        let totalInclArr=taxesAmount?.map((item,index)=>{
          let total=(Number(subtotalBeforeTax) * item) / (100+item)
          return total
        },0)

        let totalIncl=totalInclArr?.reduce((sum,item)=>sum+item,0)
        return {
          ...row,
          inclusiveTax: totalIncl,
          exclusiveTax: 0,
          subTotalWoTax: totalAmt,
          lineTotal: totalAmt,
        };
      } else if (
        row?.includedPrice === "false" ||
        row?.includedPrice === false
      ) {
        return {
          ...row,
          exclusiveTax: totalExclTax,
          inclusiveTax: 0,
          subTotalWoTax: totalAmt,
          lineTotal: totalAmt + totalExclTax,
        };
      } else {
        return {
          ...row,
          exclusiveTax: 0,
          inclusiveTax: 0,
          subTotalWoTax: totalAmt,
          lineTotal: totalAmt,
        };
      }
    });
    setCartItems(calculatedValues);
  };

  const clearPOSOrder = () => {
    setCartItems([]);
    dispatch(setOrderIdInPOS(null));
    dispatch(setSingleOrderInfo(null));
    dispatch(setSingleOrderInfo(null));
    dispatch(setCartTOBackend(null));
    dispatch(setUpdateOrder(false));
  };

  const generateOrderInfo = (items) => {
    return items?.map((item) => ({
      productId: item?._id,
      desccription: item?.productName,
      customerNote: item?.customerNote || null,
      qty: item?.quantity,
      uom: item?.uom,
      unitPrice: item?.salesPrice,
      subTotalWoTax: item?.subTotalWoTax,
      lineTotal: item?.lineTotal,
      taxes: item?.taxes,
      itemType: item?.itemType,
      includedPrice:
        item?.includedPrice === "true" || item?.includedPrice === true
          ? true
          : item?.includedPrice === "false" || item?.includedPrice === false
          ? false
          : null,
    }));
  };

  const calculateTotals = (items) => {
    const subTotal = items?.reduce((sum, item) => sum + item?.subTotalWoTax, 0);
    const amtTotal = items?.reduce((sum, item) => sum + item?.lineTotal, 0);
    const totalExclusive = items?.reduce(
      (sum, item) => sum + item?.exclusiveTax,
      0
    );
    const totalInclusive = items?.reduce(
      (sum, item) => sum + item?.inclusiveTax,
      0
    );
    return { subTotal, amtTotal, totalExclusive, totalInclusive };
  };

  // final post api call
  const finalPlaceOrder = (orderType) => () => {
    const orderInfo = generateOrderInfo(cartItems);
    const { subTotal, amtTotal, totalExclusive, totalInclusive } =
      calculateTotals(cartItems);

    let payload = {
      orderInfo: orderInfo,
      subTotal,
      discountAmt: 0,
      amtTotal,
      totalInclusive,
      totalExclusive,
      branchId: userBranchId,
      cusId: customer?._id || defaultCustomer?._id,
      waiterId: waiter?._id || null,
      aggregatorId: billType === "DELIVERY" ? aggregator?._id || null : null,
      aggregatorRef: billType === "DELIVERY" ? aggregatorRef : null,
    };
    if (orderIdForPos === null) {
      payload.orderDate = `${today} ${getCurrentTime()}`;
      payload.ordType = billType;
    } else {
      payload.orderId = orderIdForPos;
    }

    let clearFn = () => {
      setCartItems([]);
      listHeldOrders({ branchId: userBranchId, type: 1 });
    };

    // updating an existing order case
    if (updatedOrder) {
      const updatedOrderInfo = cartItems?.map((item) => ({
        productId: item?._id,
        desccription: item?.productName,
        customerNote: item?.customerNote || null,
        qty: item?.quantity,
        uom: item?.uom,
        unitPrice: item?.salesPrice,
        subTotalWoTax: item?.subTotalWoTax,
        lineTotal: item?.lineTotal,
        taxes: item?.taxes,
        kotOrderId: item?.kotOrderId || null,
        kotStatus: item?.kotStatus || "DRAFT",
        includedPrice:
          item?.includedPrice === "true" || item?.includedPrice === true
            ? true
            : item?.includedPrice === "false" || item?.includedPrice === false
            ? false
            : null,
        objType:
          item?.quantity > item?.lastQty
            ? "add"
            : item?.quantity < item?.lastQty
            ? "min"
            : "eq",
        _id: item?.existingId,
        itemType: item?.itemType || 1,
      }));
      let updatedOrderPayload = {
        orderId: orderIdForPos,
        orderInfo: updatedOrderInfo,
        subTotal,
        discountAmt: 0,
        totalExclusive,
        totalInclusive,
        amtTotal,
        branchId: userBranchId,
        aggregatorId: billType === "DELIVERY" ? aggregator?._id || null : null,
        aggregatorRef: billType === "DELIVERY" ? aggregatorRef : null,
        cusId: customer?._id || defaultCustomer?._id,
        waiterId: waiter?._id || null,
        ordType:billType
      };
      let isOrderNotUpdated = areArraysEqual(cartItems, duplicateCartArray);
      if (isOrderNotUpdated) {
        dispatch(setBtnSelected("payment"));
        dispatch(setDialogBox(null));
      } else {
        updatePosOrderAPICall(updatedOrderPayload, clearFn, orderType);
      }
    } else {
      // api call
      if (orderIdForPos === null) {
        // add order directly without selecting table and chair
        addPOSOrderWoFloorSelectionAPI(payload, clearFn, orderType);
      } else {
        payload.orderDate = `${today} ${getCurrentTime()}`;
        // add order to the selected table and chair
        addPOSOrdertoKOT(payload, clearFn, orderType);
      }
    }
  };

  // keypad hold button click
  const holdOrderClick = () => {
    const orderInfo = generateOrderInfo(cartItems);
    const { subTotal, amtTotal, totalExclusive, totalInclusive } =
      calculateTotals(cartItems);

    let payload = {
      orderId: singleOrderInfo?._id || null,
      orderInfo: orderInfo,
      subTotal,
      discountAmt: 0,
      amtTotal,
      totalInclusive,
      totalExclusive,
      branchId: userBranchId,
      cusId: customer?._id || defaultCustomer?._id,
      orderDate: `${today} ${getCurrentTime()}`,
      ordType: billType,
      aggregatorId: billType === "DELIVERY" ? aggregator?._id || null : null,
      aggregatorRef: billType === "DELIVERY" ? aggregatorRef : null,
      waiterId: waiter?._id || null,
    };
    let clearFn = () => {
      setCartItems([]);
      listHeldOrders({ branchId: userBranchId, type: 1 });
    };
    holdPOSOrderAPI(payload, clearFn);
  };
  // select cart action price || note || qty
  const keyPadTypeSelection = (key, index) => {
    setInput("");
    setInputSelection(key);
    const inputRef =
      key === "qty"
        ? qtyInputRefs.current[index || selectedCartRow]
        : key === "price"
        ? salesPriceRefs.current[selectedCartRow]
        : null;

    if (inputRef && inputRef.current) {
      const originalType = inputRef.current.type;
      inputRef.current.type = "text";

      inputRef.current.focus();

      setTimeout(() => {
        inputRef.current.setSelectionRange(
          inputRef.current.value.length,
          inputRef.current.value.length
        );
        inputRef.current.type = originalType;
      }, 0);
    }

    if (key === "note") {
      dispatch(setDialogBox("addNote"));
    }
  };
  // cart row
  const cartRowClick = (index) => () => {
    handleMouseEnter()
    setSelectedCartRow(index === selectedCartRow ? null : index);
    if (index === selectedCartRow) {
      setInputSelection(null);
    } else {
      keyPadTypeSelection("qty", index);
    }
  };

  // pagination for productlist
  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollTop + clientHeight+100 >= scrollHeight) {
      // Call your function when scrolled to the bottom
      if (productsList?.length >= 30 && !isLoading && pagination !== "max") {
        dispatch(setPageIndex(pageIndex + 1));
        dispatch(setPagination(true));
      }
    }
  };


  const handleMouseEnter = () => {
    const updatedArray = roundNumericFields(cartItems, ["quantity", "salesPrice"]);
    setCartItems(updatedArray);
    setIsCartModified(!isCartModified)
  }
  // initial API Calls
  useEffect(() => {
    categoryListAPICall();
    paymentTypesForBilling({ branchId: userBranchId });
    viewPosSettingsAPICall();
    getDiscountAPICall();
    viewCardManualAPICall();
    // listReservationList({ branchId: userBranchId });
    dispatch(setPOSBranchId(userBranchId));
    listOrdersForBillingAPI({ branchId: userBranchId, type: 2 });
    listHeldOrders({ branchId: userBranchId, type: 1 });
    // listSpecialItemsForBilling({
    //   branchId: userBranchId,
    //   day: getTodaysLowerCaseDay(),
    // });
    getTemplateInfoAPI();
    getAllSubCatForBilling({});
    dispatch(switch_to_pos_screen({ posScreenState: true }));
  }, []);

  // rendered whenever cart is modified
  useEffect(() => {
    calculateAndSetCalculatedValues();
  }, [isCartModified]);

  // api calls to filter productList
  useEffect(() => {
    let payload = {
      branchId: userBranchId,
      index: pageIndex,
      search: searchBarInput,
    };
    if (selectedCategory !== null) {
      payload.mainCatgeoryId = selectedCategory;
    }
    if (selectedPOSCategory !== null) {
      payload.poscat = selectedPOSCategory;
    }
    btnClicked === null && getAllProductsListForBilling(payload, setIsLoading);
  }, [selectedCategory, selectedPOSCategory, searchBarInput, pageIndex]);


  // api call to get the single order info
  useEffect(() => {
    orderIdForPos !== null
      ? singlePOSOrderInfoAPICall({ id: orderIdForPos })
      : dispatch(setSingleOrderInfo(null));
  }, [orderIdForPos]);

  // selected order info apllying in the screen
  useEffect(() => {
    if (singleOrderInfo !== null) {
      singleOrderInfo?.orderInfo?.length > 0 &&
        singleOrderInfo?.status !== "CHDRAFT" &&
        dispatch(setUpdateOrder(true));

      const orderInfo = singleOrderInfo?.orderInfo?.map((item) => ({
        customerNote: item?.customerNote || null,
        productName: item?.desccription || "",
        includedPrice:
          item?.includedPrice === true
            ? true
            : item?.includedPrice === false
            ? false
            : null,
        kotOrderId: item?.kotOrderId,
        kotStatus: item?.kotStatus,
        lineTotal: item?.lineTotal || 0,
        _id: item?.productId,
        quantity: item?.qty,
        subTotalWoTax: item?.subTotalWoTax,
        taxes: item.taxes,
        salesPrice: item?.unitPrice,
        uom: item?.uom,
        existingId: item?._id,
        objType: "eq",
        lastQty: item?.qty,
        imageUrl: item?.imageUrl,
        itemType: item?.itemType,
      }));
      setCartItems(orderInfo);
      setDuplicateCartArray(orderInfo);
      setIsCartModified(!isCartModified);
    } else {
      setCartItems([]);
      setDuplicateCartArray([]);
      dispatch(setUpdateOrder(false));
    }
  }, [singleOrderInfo]);

  // effects to focusing input fields in cart  and socket connection for customer display
  useEffect(() => {
    qtyInputRefs.current = cartItems?.map(() => React.createRef());
    salesPriceRefs.current = cartItems?.map(() => React.createRef());
  }, [cartItems]);

  // efffect to call an api when barcode is scanned
  useEffect(() => {
    let payload = {
      barcode: Number(barCodeInput),
      branchId: userBranchId,
    };
    barCodeInput !== "" &&
      getProductFromBarCodeScan(payload, setBarcodeInput, addToCart);
  }, [barCodeInput]);

  // <--------send-to-ws--btn-action------>

  const sendMessageToWs = async () => {
    try {
      // Convert to PDF 
      const content = invoicePrint;
  
      const options = {
        filename: 'sample.pdf',
        image: { type: 'jpeg', quality: 1 }, 
        html2canvas: { scale: 2, logging: false }, 
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
      };
  
      // Generate PDF
      const pdfBlob = await html2pdf().from(content).set(options).outputPdf('blob');
  
      // Create FormData object and append the PDF file
      const formData = new FormData();
      formData.append('pdfFile', pdfBlob, 'sample.pdf');
      formData.append('_id',receiptData?._id );
      formData.append('type',"POS" );
  
      // Now you can pass formData to your API
    
     


      const response = await sendMessageToWhatsApp(formData) 

    
  
     
      
  
    } catch (error) {
      console.log("Error sending message:", error);
      // Handle error if required
    }
  }
  return (
    <>
      <div className="global-page-parent-container billing-parent-container">
        {btnClicked === "payment" ? (
          <BillingPayment />
        ) : btnClicked === "viewOrder" || btnClicked === "heldOrder" ? (
          <ViewOrders />
        ) 
        // : btnClicked === "reservation" ? (
        //   <ReservationBilling />
        // ) 
        : (
          <div className="billing-main-container">
            <div className="billing-left-container" style={{ width: "72%" }} 
                //  onMouseEnter={handleMouseEnter}
                //  onMouseLeave={handleMouseEnter}
            >
              <TopBar />

              <div className="billing-left-button">
                {/* <div className="left-second-div"> */}
                {categoryLoading ? (
                  <div
                    ref={categoryArrayRef}
                    className="billing-array"
                    style={{ gap: "6px", overflowX: "hidden" }}
                  >
                    {[...Array(15)]?.map((r, i) => (
                      <div key={i} className="billing-new-category-single">
                        <Skeleton width={"90px"} height="100px" />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div ref={categoryArrayRef} className="billing-array">
                    {CategoryList !== undefined &&
                    CategoryList?.length !== 0 ? (
                      CategoryList?.map((text, i) => (
                        <div
                          onClick={categoryOnClick(text?._id)}
                          key={i}
                          className={`billing-new-category-single ${
                            selectedCategory === text?._id
                              ? "selected-category-single"
                              : ""
                          }`}
                        >
                          <img src={text?.imageUrl || brokenImg} alt="" />
                          <p>{text?.categoryName}</p>
                        </div>
                      ))
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        <CategoryEmptyAnimation />
                        <p
                          style={{ border: "none", margin: "0", padding: "0" }}
                        >
                          Category Not found !!
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div className="products-list">
                <div className="special-items">
                  <div
                    className="top justify-space-between"
                    onClick={() => {
                      dispatch(
                        setDialogBox(
                          dialogOpen === "specialOffer" ? null : "specialOffer"
                        )
                      );
                      dialogOpen === "specialOffer" &&
                        specialItemCat !== null &&
                        specialItemsClick({ _id: specialItemCat });
                    }}
                  >
                    <p>Special Items</p>
                    <LocalOfferIcon
                      sx={{ color: "#db1919", fontSize: "1rem" }}
                    />
                  </div>
                  {dialogOpen === "specialOffer" && (
                    <div className="category-list-menu">
                      <div
                        className="tab justify-space-between"
                        style={{
                          backgroundColor:
                            specialItemCat === "combo" && "#1a0800",
                        }}
                        onClick={() => specialItemsClick({ _id: "combo" })}
                      >
                        COMBO OFFERS
                        {specialItemCat === "combo" && <ArrowRightIcon />}
                      </div>
                      {/* {specialItemsListForBilling.length !== 0 ? (
                        <>
                          {specialItemsListForBilling?.map((r, i) => (
                            <div
                              key={i}
                              className="tab justify-space-between"
                              style={{
                                backgroundColor:
                                  specialItemCat === r?._id && "#1a0800",
                              }}
                              onClick={() => specialItemsClick(r)}
                            >
                              {r?.name}
                              {specialItemCat === r?._id && <ArrowRightIcon />}
                            </div>
                          ))}
                        </>
                      ) : (
                        <div
                          className="tab"
                          style={{ justifyContent: "center" }}
                        >
                          No Items found !!
                        </div>
                      )} */}
                    </div>
                  )}
                  <div className="category-list">
                    <ArrowPath />
                  </div>
                  <div className="category-list-menu">
                    {posCategoryLoading ? (
                      [...Array(10)].map((r) => (
                        <div key={r} className="tab">
                          <Skeleton
                            width={"100%"}
                            height={"40px"}
                            sx={{ backgroundColor: "#fff" }}
                          />
                        </div>
                      ))
                    ) : posCategoryList !== undefined &&
                      posCategoryList.length !== 0 ? (
                      posCategoryList?.map((r, i) => (
                        <div
                          key={i}
                          className="tab"
                          onClick={() => posCategorySelect(r)}
                        >
                          {r?.name}
                        </div>
                      ))
                    ) : (
                      <div className="tab" style={{ justifyContent: "center" }}>
                        Sub category not found
                      </div>
                    )}
                  </div>
                </div>
                <div
                  onScroll={handleScroll}
                  className="cards"
                  style={{ width: "75%", marginTop: "0" }}
                >
                  {isLoading ? (
                    [...Array(10)].map((r, i) => (
                      <div key={i} className="billing-single-product-container">
                        <Skeleton width={"100%"} height="100px" />
                        <Skeleton width={"100%"} height="20px" />
                        <div className="billing-product-list-select-container">
                          <Skeleton width={"60%"} height="20px" />
                          <Skeleton width={"30%"} height="30px" />
                        </div>
                      </div>
                    ))
                  ) : productsList?.length !== 0 ? (
                    productsList?.map((product, index) => (
                      <div
                        className="card-content"
                        key={index}
                        onClick={() => addToCart(product)}
                        style={{ maxHeight: "fit-content" }}
                      >
                        {
                          <img
                            src={
                              product?.imageUrl !== null &&
                              product?.imageUrl.length !== 0
                                ? product?.imageUrl
                                : dummyProductItemImg
                            }
                            alt="No Img"
                            className="product-image "
                          />
                        }
                        <div className="card-details">
                          <h1>{product.productName}</h1>
                          {productType === 3 ? (
                            product?.productList?.map((item, i) => (
                              <p style={{ display: "flex", flexWrap: "wrap" }}>
                                <span style={{ overflow: "hidden" }}>
                                  {item?.productName}
                                </span>
                                <span style={{ marginLeft: "auto" }}>
                                  {item.qty}
                                </span>
                              </p>
                            ))
                          ) : (
                            <p>
                              {product.salesPrice?.toFixed(decimalPosition)}
                            </p>
                          )}
                          <p style={{ color: "blue" }}>
                            {product.stock?.toFixed(decimalPosition)}
                          </p>
                        </div>
                        {productType === 3 && (
                          <div className="offer-price" style={{ right: "0px" }}>
                            {/* <ComboPriceTag
                              amount={product.salesPrice?.toFixed(
                                decimalPosition
                              )}
                            /> */}
                          </div>
                        )}
                      </div>
                    ))
                  ) : (
                    <div className="center-div" style={{ height: "61vh" }}>
                      No Products Available !!
                    </div>
                  )}
                    {
                    tableLoading &&
                    [...Array(10)].map((r, i) => (
                      <div key={i} className="billing-single-product-container">
                        <Skeleton width={"100%"} height="60px" />
                        <Skeleton width={"100%"} height="20px" />
                        <div className="billing-product-list-select-container">
                          <Skeleton width={"60%"} height="20px" />
                          <Skeleton width={"30%"} height="30px" />
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <>
              {cartItems?.length !== 0 ? (
                <div className="flex-column">
                  <div className="cart-table-top">
                    {/* <p>Order ID</p> */}
                    <p>{singleOrderInfo?.name || "New Order"}</p>
                  </div>
                  <div
                    className="billing-rightside-container"
                    style={{ height: "85vh" }}
                    onMouseLeave={handleMouseEnter}
                  >
                    <div
                    className="billing-right-card">
                      {cartItems.map((cartItem, index) => (
                        <div
                          key={index}
                          onClick={cartRowClick(index)}
                          className="billing-card-content"
                          style={{
                            borderLeft:
                              selectedCartRow === index
                                ? "7px solid #088051"
                                : cartItem.itemType === 3 &&
                                  "7px solid rgb(211 20 0)",
                            // paddingBottom:cartItem.itemType===3?"15px":"2px"
                          }}
                        >
                          <div className="billing-card-image justify-center">
                            {cartItem?.imageUrl === undefined ? (
                              <DoneOutlineIcon sx={{ color: "green" }} />
                            ) : (
                              <img
                                src={
                                  cartItem?.imageUrl !== null &&
                                  cartItem?.imageUrl.length !== 0
                                    ? cartItem?.imageUrl
                                    : dummyProductItemImg
                                }
                                alt=""
                              />
                            )}
                          </div>
                          <div className="billing-card-details">
                            <h1>{cartItem.productName}</h1>
                            <p>{cartItem.customerNote}</p>
                          </div>
                          <div className="cart-buttons-ctn">
                            <input
                              ref={qtyInputRefs.current[index]}
                              onClick={(e) => {
                                inputSelection === "qty" && e.stopPropagation();
                              }}
                              type="number"
                              value={cartItem.quantity}
                              style={{
                                border:
                                  selectedCartRow === index &&
                                  inputSelection === "qty" &&
                                  "1px solid #088051",
                              }}
                              onChange={cartQuantityChange(index, "quantity")}
                              readOnly={
                                selectedCartRow !== index ||
                                inputSelection !== "qty"
                              }
                              onFocus={(e) => e.target.select()}
                            />
                          </div>
                          <div
                            className="billing-cart-total-price"
                            style={{ fontSize: "0.8rem" }}
                          >
                            <p>{currencyDetails?.symbol} {cartItem?.subTotalWoTax?.toFixed(decimalPosition)}</p>
                            <p className="sub">
                              {currencyDetails?.symbol}
                              <input
                                type="number"
                                ref={salesPriceRefs.current[index]}
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                                style={{
                                  width: `${
                                    String(cartItem?.salesPrice).length * 9
                                  }px`,
                                  border:
                                    selectedCartRow === index &&
                                    "1px solid #088051",
                                  marginLeft:
                                    selectedCartRow === index &&
                                    "5px",
                                  minWidth: "15px",
                                }}
                                onFocus={(e) => e.target.select()}
                                onChange={cartQuantityChange(
                                  index,
                                  "salesPrice"
                                )}
                                value={cartItem?.salesPrice}
                                readOnly={
                                  selectedCartRow !== index 
                                }
                              />
                            </p>
                          </div>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDelete(index);
                            }}
                            disabled={cartItem?.imageUrl === undefined}
                          >
                            <i
                              class={`bi bi-trash3 ${
                                cartItem?.imageUrl === undefined
                                  ? ""
                                  : "delete-icon"
                              }`}
                              style={{ fontSize: "15px" }}
                            ></i>
                          </IconButton>
                          {/* {cartItem.itemType===3 &&
                    
                  <p className="combo-list">{cartItem?.productList.map((item)=>item.productName).join(', ')}</p>
                  
                  } */}
                        </div>
                      ))}
                    </div>

                    <div
                    onMouseEnter={handleMouseEnter}
                    style={{ display: "flex", flexDirection: "column" }}>
                      <div className="subtotal-amount-ctn">
                        <p>Subtotal</p>
                        <input
                          type="text"
                          value={ 
                            `${cartItems?.reduce(
                            (sum, item) => sum + item?.subTotalWoTax,
                            0
                          )} ${currencyDetails?.symbol}`}
                          readOnly
                        />
                      </div>
                      <div className="billing-keypad-section">
                        <div className="billing-input-section">
                          <div className="row">
                            <button onClick={() => handleButtonClick("7")}>
                              7
                            </button>
                            <button onClick={() => handleButtonClick("8")}>
                              8
                            </button>
                            <button onClick={() => handleButtonClick("9")}>
                              9
                            </button>
                          </div>
                          <div className="row">
                            <button onClick={() => handleButtonClick("4")}>
                              4
                            </button>
                            <button onClick={() => handleButtonClick("5")}>
                              5
                            </button>
                            <button onClick={() => handleButtonClick("6")}>
                              6
                            </button>
                          </div>
                          <div className="row">
                            <button onClick={() => handleButtonClick("1")}>
                              1
                            </button>
                            <button onClick={() => handleButtonClick("2")}>
                              2
                            </button>
                            <button onClick={() => handleButtonClick("3")}>
                              3
                            </button>
                          </div>
                          <div className="row">
                            <button onClick={() => handleButtonClick(".")}>
                              .
                            </button>
                            <button onClick={() => handleButtonClick("0")}>
                              0
                            </button>
                            <button
                              className="billing-Hold-button"
                              // disabled={singleOrderInfo!==null}
                              onClick={holdOrderClick}
                              // style={{cursor:singleOrderInfo!==null?"not-allowed":"pointer"}}
                            >
                              Hold
                            </button>
                          </div>
                          <div className="row">
                            {inputSelection === "note" ? (
                              <button
                                onClick={() => keyPadTypeSelection(null)}
                                className="keypad-qty-btn closekey-pad-btn"
                                style={{ backgroundColor: "#478045" }}
                              >
                                <DoneOutlineIcon />
                              </button>
                            ) : (
                              <button
                                disabled={selectedCartRow === null}
                                onClick={() => keyPadTypeSelection("note")}
                                className={`${
                                  selectedCartRow === null
                                    ? "keypad-qty-btn-enable"
                                    : ""
                                } keypad-qty-btn`}
                              >
                                Note
                              </button>
                            )}
                            {inputSelection === "qty" ? (
                              <button
                                disabled={selectedCartRow === null}
                                onClick={() => keyPadTypeSelection(null)}
                                className="keypad-qty-btn closekey-pad-btn"
                                style={{ backgroundColor: "#478045" }}
                              >
                                <DoneOutlineIcon />
                              </button>
                            ) : (
                              <button
                                onClick={() => keyPadTypeSelection("qty")}
                                disabled={selectedCartRow === null}
                                className={`${
                                  selectedCartRow === null
                                    ? "keypad-qty-btn-enable"
                                    : ""
                                } keypad-qty-btn`}
                              >
                                Qty
                              </button>
                            )}
                            {inputSelection === "price" ? (
                              <button
                                onClick={() => keyPadTypeSelection(null)}
                                className="keypad-qty-btn closekey-pad-btn"
                                style={{ backgroundColor: "#478045" }}
                              >
                                <DoneOutlineIcon />
                              </button>
                            ) : (
                              <button
                                onClick={() => keyPadTypeSelection("price")}
                                disabled={selectedCartRow === null}
                                className={`${
                                  selectedCartRow === null
                                    ? "keypad-qty-btn-enable"
                                    : ""
                                } keypad-qty-btn`}
                              >
                                Price
                              </button>
                            )}
                          </div>
                        </div>
                        <div className="billing-keypad-buttons">
                          <button
                            onClick={() => {
                              dispatch(setDialogBox("confirmOrder"));
                            }}
                            className="billing-placeOrder-button"
                          >
                            {updatedOrder ? "Update" : "Add Order"}
                          </button>
                          <button
                            onClick={finalPlaceOrder("paymentOrder")}
                            className="billing-payment-button"
                          >
                            Payment
                          </button>
                          <button
                            className="billing-clear-button"
                            onClick={clearPOSOrder}
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex-column">
                  <div className="cart-table-top">
                    <p>{singleOrderInfo?.name || "New Order"}</p>

                  </div>
                  <div
                    className="billing-rightside-container"
                    style={{
                      backgroundColor: "#fff",
                      height: "86vh",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={emptyCartItemImg} alt="" />
                    <p style={{ color: "gray" }}>Your cart is empty</p>
                  </div>
                </div>
              )}
            </>
          </div>
        )}
      </div>

      {/* invoice bill dialog */}
      <Dialog
        open={dialogOpen === "invoicePrint"}
        maxWidth="lg"
        onClose={() => dispatch(setDialogBox(null))}
        onKeyDown={(e) => e.key === "Escape" && dispatch(setDialogBox(null))}
      >
        {/* <Invoice data={invoiceData} /> */}
        <div className="billing-parent-wrapper">
          <div ref={(el) => (invoicePrint = el)}>
            <PosReceiptTemplate />
          </div>
          <div className="invoice-print-icon-container" id="invoicePrintIcon">
            <ReactToPrint
              trigger={() => (
                <IconButton
                  className="invoice-print-btn"
                  sx={{
                    backgroundColor: "#d3e3ec",
                  }}
                >
                  <PrintOutlined
                    className="print-icon"
                    sx={{ color: "#000" }}
                  />
                </IconButton>
              )}
              content={() => invoicePrint}
            />
            <IconButton
              sx={{
                backgroundColor: "#d3e3ec",
              }}
              onClick={() => sendMessageToWs()}
            >
              <WhatsAppIcon color="success" />
            </IconButton>
          </div>
        </div>
      </Dialog>

      {/* confirm add order dialogue */}
      <Dialog
        open={dialogOpen === "confirmOrder"}
        maxWidth="lg"
        onClose={() => dispatch(setDialogBox(null))}
      >
        <div className="billing-confirm-order-dialog">
          <p className="head">Confirm Order</p>
          <p>Are you sure to confirm order?</p>

          <div
            className="new-global-single-input  auto-complete-new"
            style={{ width: "275px" }}
          >
            <Autocomplete
              options={allActiveEmpList || []}
              getOptionLabel={(option) => option?.staff_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Waiter"
                  focused
                  variant="outlined"
                  placeholder="optional"
                />
              )}
              onChange={(e, newValue) => dispatch(setOrderWaiter(newValue))}
              value={waiter}
            />
          </div>
          <div className="justify-center">
            <button
              onClick={() => dispatch(setDialogBox(null))}
              className="btn btn-secondary-outlined"
            >
              Cancel
            </button>
            <button
              onClick={finalPlaceOrder("OrderWoPayment")}
              style={{ backgroundColor: "#139109" }}
              className="btn btn-primary"
            >
              Confirm
            </button>
          </div>
        </div>
      </Dialog>

      {/* Add Note */}
      <Dialog
        open={dialogOpen === "addNote"}
        maxWidth="lg"
        onClose={() => {
          dispatch(setDialogBox(null));
          setInputSelection(null);
        }}
      >
        <div className="billing-confirm-order-dialog">
          <p className="head">Add Note</p>
          <hr className="global-hr" />
          <input
            style={{ height: "40px", margin: "2% 0" }}
            value={input}
            onChange={(e) => setInput(e.target.value)}
            type="text"
          />
          <button
            onClick={cartAddNote}
            className="btn btn-primary"
            style={{ backgroundColor: "#139109", width: "100%" }}
          >
            Add
          </button>
        </div>
      </Dialog>

      {/* Backdrop on loading  */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 500 }}
        open={billLoading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* to read the barcodevalue */}
      <BarcodeReader onScan={handleScan} />
    </>
  )
}
