import React,{useState,useEffect} from 'react'
import {Autocomplete,TextField,IconButton,Dialog,Select,MenuItem, Skeleton } from '@mui/material'
import {
  generatePettyCashDocNoAPICall,
  viewPettyCashReceiptAPICall,
  addPettyCashReceiptAPICall,
  editPettyCashReceipt
} from '../../../API/Expense/pettyCashReceiptAPI'
import { useSelector } from 'react-redux'
import {viewAllBranchesAPICall} from '../../../API/Settings/Company Settings/companySettingsAPI'
import {viewAddExpenseTypeAPICall} from '../../../API/Expense/addExpenseTypeAPI'
import SuccessSnackbar from '../../Single Components/SnackBars/SuccessSnackbar'
import ErrorSnackbar from '../../Single Components/SnackBars/ErrorSnackbar'
import {today,previousDate, convertDateFormat} from '../../../Js/Date'
import {verifyPasswordAPICall} from '../../../API/Login/VerifyPasswordAPI'
import {extractObjectForSingleView} from '../../../Js/generalFunctions'
import { LoadingForm } from '../../Single Components/LoadingForm'

export const PettyCashReceipt = () => {
  // generate petty cash doc no
  const pettyCashDocNoData=useSelector((state)=>state.pettyCashReceiptDocNoSlice.value)
  // petty cash data
  const pettyCashData=useSelector((state)=>state.pettyCashReceiptSlice.value)
  // all branch list
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  // role based rendering
  const userRole = useSelector((state) => state.userRoleSlice.value);
  // view all expense type
  const expenseTypeData = useSelector((state) => state.addExpenseType.value);
  const storeCode=localStorage.getItem('branchId')

   const [addPettyCashDialog,setAddPettyCashDialog]=useState(false)
   const [pettyCashEditPassword,setPettyCashEditPassword]=useState(false)
   const [editPettyCashDialog,setEditPettyCashDialog]=useState(false)
   //Update Listener States
   const [isModify, setIsModify] = useState(false);
   // visibility states
   const [isVisible,setIsVisible]=useState(false)

   const addPettyCashInitialState={
    branch:"",
    branchName:"",
    expenseType:"",
    amount:'',
    date:today
   }
   const [addPettyCashForm,setAddPettyCashForm]=useState(addPettyCashInitialState)
    // snackbar state
    const [successSnackbar,setSuccessSnackbar]=useState(false)
    const [errorSnackbar,setErrorSnackbar]=useState(false)
    const [snackMsg,setSnackMsg]=useState('Error!!')

    // admin view filter
    const [branchName,setBranchName]=useState()
    const [fromDate,setFromDate]=useState(previousDate)
    const [toDate,setToDate]=useState(today)

    // edit states
    const [editedId,setEditedId]=useState()
    const [docNo,setDocNo]=useState()
    const [verifyPassword,setVerifyPassword]=useState()

    //Is Password verified
    const [isVerified,setIsVerified]=useState(false)
    const [loadingForm,setLoadingForm]=useState(false)

    // validation states
    const [branchAlert,setBranchAlert]=useState(false)
    const [expTypeAlert,setExpTypeAlert]=useState(false)
    const [amountAlert,setAmountAlert]=useState(false)
    // loading states
    const [isLoading,setIsLoading]=useState(false)
    const [searchKeyword,setSearchKeyword]=useState("")
    const [filteredSearchList,setFilteredSearchList]=useState([])

    const editPettyCashSubmitBtn=()=>{
    // setPettyCashEditPassword(false)
    // setEditPettyCashDialog(true)
     verifyPasswordAPICall(
      {password:verifyPassword},
      setPettyCashEditPassword,
      setErrorSnackbar,
      setSnackMsg,
      setEditPettyCashDialog
      )
   }

   useEffect(()=>{
    generatePettyCashDocNoAPICall()
    viewPettyCashReceiptAPICall({branchId:storeCode},setIsLoading,setErrorSnackbar,setSnackMsg)
    viewAllBranchesAPICall()
    viewAddExpenseTypeAPICall();
   },[isModify])

   //*Update Listener
   const updateListener = () => {
    setIsModify(!isModify);
  };
  // visibility function
  const visibilityFunction=()=>{
    setIsVisible(!isVisible)
  }
  // snackbar functions
const closeSuccessSnackbar=()=>{
  setSuccessSnackbar(false)
}
const closeErrorSnackbar=()=>{
  setErrorSnackbar(false)
}
// admin view filter onChange Fn
const getFromDate=(e)=>{
  setFromDate(e.target.value)
}
const getToDate=(e)=>{
  setToDate(e.target.value)
}

const clearValues=()=>{
  setAddPettyCashForm(addPettyCashInitialState)
  setAddPettyCashDialog(false)
}

// petty cash admin view filter function
 const pettyCashAdminFilterSubmitBtn=()=>{
  viewPettyCashReceiptAPICall({
    branchId:branchName,
    fromDate:`${fromDate} 00:00:00`,
    endDate:`${toDate} 23:59:59`
  },setIsLoading,setErrorSnackbar,setSnackMsg)
 }
  
// add petty cash dialog open
 const addPettyCashDialogOpen=()=>{
  setAddPettyCashDialog(true)
  setAddPettyCashForm(addPettyCashInitialState)
 }
  const bodyToApi={
    amount:addPettyCashForm.amount,
    expenseType:addPettyCashForm.expenseType,
    date:addPettyCashForm.date,
    branchId:addPettyCashForm.branch
  }
  /// add petty cash expense
  const addPettyCashReceiptSubmitBtn=()=>{

    if(addPettyCashForm.branch===""){
      setBranchAlert(true)
    }
    else if(addPettyCashForm.expenseType===""){
      setExpTypeAlert(true)
    }
    else if(addPettyCashForm.amount===""){
      setAmountAlert(true)
    }
    else{
      addPettyCashReceiptAPICall(
        bodyToApi,
        updateListener,
        setSuccessSnackbar,
        setErrorSnackbar,
        setSnackMsg,
        setAddPettyCashDialog,setLoadingForm
        )
        clearValues()
      }
  }

  // edit petty cash receipt
  const clickPettyCashEditButton=(id,docNo,branch,branchName,expType,amt,date)=>{
    setPettyCashEditPassword(true)
    setVerifyPassword()
    setIsVisible(false)
    setEditedId(id)
    setDocNo(docNo)
    setAddPettyCashForm({
      ...addPettyCashForm,
      branch:branch,
      branchName:branchName,
      expenseType:expType,
      amount:amt,
      date:date
    })
   
}


const editedBodyToApi={
  amount:addPettyCashForm.amount,
  expenseType:addPettyCashForm.expenseType,
  date:addPettyCashForm.date,
  branchId:addPettyCashForm.branch,
  _id:editedId
}
// edit submit button function
const editSubmitBtnFn=()=>{
  editPettyCashReceipt(
    editedBodyToApi,
    updateListener,
    setSuccessSnackbar,
    setErrorSnackbar,
    setSnackMsg,
    setEditPettyCashDialog,setLoadingForm
    )
}

const getSearchKeyword=(e)=>{
  setSearchKeyword(e.target.value)
}
const SearchFilter=(chars,listToFilter)=>{
  let searchedArray=[]
  let filteredArray=[]
  if(chars!==""){
    searchedArray=listToFilter.filter((obj)=>
    obj?.expenseTypename?.toLowerCase()?.includes(chars?.toLowerCase())||
    obj?.branchId?.toLowerCase()?.includes(chars?.toLowerCase())||
    obj?.locationName?.toLowerCase()?.includes(chars?.toLowerCase())||
    obj?.transNo?.toLowerCase()?.includes(chars?.toLowerCase())
  )
    filteredArray=searchedArray
  }
  else{
    filteredArray=listToFilter
  }
  return filteredArray
}
useEffect(()=>{
  if(pettyCashData!==undefined){
    let filteredArray=[]
    setFilteredSearchList(pettyCashData)
    filteredArray=SearchFilter(searchKeyword,pettyCashData)
    setFilteredSearchList(filteredArray)    
  }
  else{
    setFilteredSearchList([])
  }
  },[searchKeyword,pettyCashData])
 
  return (
    <div className="global-page-parent-container">
    <p className="breadcrumb">Expense &gt; Petty Cash Receipt</p>
    {
      userRole==='admin'&&
    
    <div className="add-expense-top-container">
      <div className="add-expense-input-container">
        <div className="global-single-input auto-complete">
          <p>Branch</p>
          <Autocomplete
             sx={{width:"100%"}}
             options={allBranchesList||[{storeCode:"no",branchName:"data"}]}
             getOptionLabel={(option)=>`${option?.storeCode}-${option?.branchName}`}
             renderInput={(params)=>
               <TextField  {...params}
                placeholder="Select Branch"
               />
             }
             onChange={(e,newValue)=>setBranchName(
               newValue?._id
             )}
          />
        </div>
        <div className="global-single-input">
          <p>From</p>
          <input type="date" onChange={getFromDate} value={fromDate}/>
        </div>
        <div className="global-single-input">
          <p>To</p>
          <input type="date" onChange={getToDate} value={toDate}/>
        </div>
        <button
          className="btn btn-primary add-expense-button"
         onClick={pettyCashAdminFilterSubmitBtn}
     >
       Submit
     </button>
      </div>
    </div>
   }
     <div className="global-white-bg-container">
        <div className="expense-top-container">
           <h3>Petty Cash Receipt</h3>
           <div className="supplier-manage-top-container-right">
              <div className="view-order-search-container view-order-admin-search-container">
                  <input
                    placeholder="Expense Type,Doc No,Branch"
                    value={searchKeyword}
                    onChange={getSearchKeyword}
                  />
                  <IconButton>
                      <i class="bi bi-search search-icon-order"></i>
                  </IconButton>
              </div>
              {
                userRole==='admin'&&
                <button 
                onClick={addPettyCashDialogOpen} 
                className="btn btn-primary expense-button"
                style={{width:"40%"}}>
                  Add Petty Cash Receipt</button>
              }
            </div>
   
        </div>
        {/* table */}
        <div className="global-table-container">
          <table className="global-table">
            <thead style={{zIndex:'0'}}>
               <tr>
                 <th>No</th>
                 {userRole==='admin' &&
                 <>
                 <th>Branch Code</th>
                 <th>Branch Name</th>
                 </>
                 }
                
                 <th>Doc No</th>
                 <th>Expense Type</th>
                 <th>Date</th>
                 <th>Amount</th>
                 {
                  userRole==='admin'&&
                   <th>Action</th>
                 }
               
               </tr>
            </thead>
            {
              isLoading?
              <tbody>
                <tr>
                  <td colSpan={10}>
                    {/* <Loader /> */}
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                    />
                  </td>
                  </tr> 
                  <tr>
                  <td colSpan={10}>
                    {/* <Loader /> */}
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                    />
                  </td>
                  </tr> 
                  <tr>
                  <td colSpan={10}>
                    {/* <Loader /> */}
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                    />
                  </td>
                  </tr> 
                  <tr>
                  <td colSpan={10}>
                    {/* <Loader /> */}
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                    />
                  </td>
                  </tr>
              </tbody>:
            <tbody>
              {
              filteredSearchList?.length!==0?
              filteredSearchList?.slice(0)
                    .reverse().map((r,i)=>(
                 <tr key={i}>
                 <td>{i+1}</td>
                 {userRole==='admin' &&
                 <>
                 <td>{r.branchId}</td>
                 <td>{r.locationName}</td>
                 </>
                 }
                  <td>{r.transNo}</td>
                 <td>{r.expenseTypename}</td>
                 <td>{convertDateFormat(r.date)}</td>
                 <td>{r.amount}</td>
                 {
                  userRole === 'admin' &&
                  <td>
                    {
                      r.isEditable===true?
                      <IconButton onClick={()=>clickPettyCashEditButton(
                        r._id,
                        r.transNo,
                        r.branchId,
                        r.locationName,
                        r.expenseTypename,
                        r.amount,
                        r.date
                      )}>
                         <i class="bi bi-pencil-square edit-icon"></i>
                      </IconButton>
                      :r.isEditable===false?
                       <IconButton disabled>
                        <i class="bi bi-pencil-square edit-icon-disabled"></i>
                       </IconButton>
                      :undefined
                    }
                 
                   </td>
                 }
               
               </tr>
              ))
              :
              <tr><td colSpan={8} style={{textAlign:"center"}}>No Records</td></tr>
              }
              
            </tbody>}
          </table>
        </div>

        {/* add petty cash receipt */}
        <Dialog open={addPettyCashDialog} maxWidth="lg" onKeyDown={(e)=>e.key === "Escape" && setAddPettyCashDialog(false)}>
           <div className='add-expense-dialog-container'>
              <h3>Add Petty Cash Receipt</h3>
              <hr className='global-hr'/>
              <div className="add-outlet-expense-container">
                <div className="global-single-input outlet-expense-input">
                   <p>Doc No</p>
                  <input type="text" value={pettyCashDocNoData?.transNo} disabled/>
                </div>
                <div className="global-single-input outlet-expense-input auto-complete">
                   <p>Branch</p>
                   <Autocomplete
                    sx={{width:"100%"}}
                    // value={addPettyCashForm.branch}
                    options={allBranchesList||[""]}
                    getOptionLabel={(option)=>`${option?.storeCode}-${option?.branchName}`}
                    renderInput={(params)=>
                    <TextField  {...params}
                    placeholder="Select Branch"
                  />
                }
                  onChange={(e,newValue)=>{
                    setAddPettyCashForm({
                  ...addPettyCashForm,branch:newValue?._id
                  })
                  setBranchAlert(false)
                  if(newValue===null){
                    setBranchAlert(true)
                  }
                }
              }
                /> 
                {branchAlert?
                  <p className="doc-validation-alert">Select a branch !!</p>
                :""}
                </div>
              
                <div className="global-single-input outlet-expense-input auto-complete">
                   <p>Expense Type</p>
                    <Autocomplete
                sx={{width:"100%"}}
                options={expenseTypeData||[""]}
                getOptionLabel={(option)=>option?.expenseType}
                renderInput={(params)=>
                  <TextField  {...params}
                  placeholder="Select Expense Type"
                  />
                }
                onChange={(e,newValue)=>{
                  setAddPettyCashForm({
                  ...addPettyCashForm,expenseType:newValue?._id
                  })
                  setExpTypeAlert(false)
                  if(newValue===null){
                    setExpTypeAlert(true)
                  }
                }
              }
                /> 
                  {expTypeAlert?
                  <p className="doc-validation-alert">Select a type !!</p>
                  :""} 
                </div>
                <div className="global-single-input outlet-expense-input">
                   <p>Amount</p>
                  <input 
                  type="number" 
                  value={addPettyCashForm.amount} 
                  onChange={(e)=>{
                    setAddPettyCashForm({...addPettyCashForm,amount:parseFloat(e.target.value)})
                    setAmountAlert(false)
                    if(e.target.value===""){
                      setAmountAlert(true)
                      }
                    }
                  }
                  />
                  {amountAlert?
                  <p className="doc-validation-alert">Enter a amount !!</p>
                  :""}
                </div>
                <div className="global-single-input outlet-expense-input">
                   <p>Date</p>
                  <input 
                  type="date" 
                  value={addPettyCashForm.date}
                  onChange={(e)=>setAddPettyCashForm({...addPettyCashForm,date:e.target.value})}
                  />
                </div>
                <div className="expense-button-container">
                  <button onClick={clearValues} className="btn btn-secondary-outlined">Cancel</button>
                  <button onClick={addPettyCashReceiptSubmitBtn} className="btn btn-primary">Submit</button>
                </div>
              </div>
           </div>
        </Dialog>

        {/* petty cash edit password dialog */}
        <Dialog open={pettyCashEditPassword} maxWidth="lg" onKeyDown={(e)=>e.key === "Escape" && setPettyCashEditPassword(false)}>
        <div className='add-expense-dialog-container'>
         <h3>Edit Petty Cash Receipt</h3>
         <div className='global-single-input outlet-expense-password-input'>
          <p>Password</p>
          <input 
          type={isVisible?'text':'password'} 
          value={verifyPassword} 
          onChange={(e)=>setVerifyPassword(e.target.value)} 
          onKeyDown={(e) => {
            e.key === "Enter" && editPettyCashSubmitBtn();
          }}
          />
          <div className="password-visibility-icon">
          <IconButton onClick={visibilityFunction}>
            {
              isVisible?
              <i class="bi bi-eye-slash"></i>
              :
              <i class="bi bi-eye"></i>
            }
         
          </IconButton>
          </div>
         
         </div>
         <div className="expense-button-container">
           <button onClick={()=>setPettyCashEditPassword(false)} className="btn btn-secondary-outlined">Cancel</button>
           <button onClick={editPettyCashSubmitBtn} className='btn btn-primary' style={{width:'30%'}}>Submit</button>
         </div>
        </div>
        </Dialog>

        {/* edit petty cash */}
        <Dialog open={editPettyCashDialog} maxWidth="lg" onKeyDown={(e)=>e.key === "Escape" && setEditPettyCashDialog(false)}>
        <div className='add-expense-dialog-container'>
              <h3>Edit Petty Cash Receipt</h3>
              <hr className='global-hr'/>
              <div className="add-outlet-expense-container">
                <div className="global-single-input outlet-expense-input">
                   <p>Doc No</p>
                  <input type="text" value={docNo} disabled/>
                </div>
               
                <div className="global-single-input outlet-expense-input auto-complete">
                   <p>Branch</p>
                   <Autocomplete
                     sx={{width:"100%"}}
                    // value={addPettyCashForm.branch}
                    // defaultValue={addPettyCashForm.branch}
                    defaultValue={{storeCode:addPettyCashForm.branch,branchName:addPettyCashForm.branchName}}
                     options={allBranchesList||[""]}
                     getOptionLabel={(option)=>`${option?.storeCode}-${option?.branchName}`}
                     renderInput={(params)=>
                       <TextField  {...params}/>
                       }
                    onChange={(e,newValue)=>{setAddPettyCashForm({
                      ...addPettyCashForm,branch:newValue?.storeCode
                    })
                   
                  }
                   
                  
                  }
              /> 
                </div>
                <div className="global-single-input outlet-expense-input auto-complete">
                   <p>Expense Type</p>
                   <Autocomplete
                sx={{width:"100%"}}
                // value={addPettyCashForm.expenseType}
                defaultValue={{expenseType:addPettyCashForm.expenseType}}
                options={expenseTypeData||[""]}
                getOptionLabel={(option)=>option?.expenseType}
                renderInput={(params)=>
                  <TextField  {...params}/>
                }
               
                onChange={(e,newValue)=>{setAddPettyCashForm({
                  ...addPettyCashForm,expenseType:newValue?._id
                })
              }             
              }
              /> 
                </div>
                <div className="global-single-input outlet-expense-input">
                   <p>Amount</p>
                  <input type="text" 
                   value={addPettyCashForm.amount} 
                   onChange={(e)=>setAddPettyCashForm({...addPettyCashForm,amount:parseFloat(e.target.value)})}
                  />
                </div>
                <div className="global-single-input outlet-expense-input">
                   <p>Date</p>
                  <input type="date" 
                     disabled
                     value={addPettyCashForm.date}
                     onChange={(e)=>setAddPettyCashForm({...addPettyCashForm,date:e.target.value})}
                  />
                </div>
                <div className="expense-button-container">
                  <button onClick={()=>setEditPettyCashDialog(false)} className="btn btn-secondary-outlined">Cancel</button>
                  <button onClick={editSubmitBtnFn} className="btn btn-primary">Submit</button>
                </div>
              </div>
           </div>
        </Dialog>
       
     </div>
     <SuccessSnackbar
       open={successSnackbar}
       handleClose={closeSuccessSnackbar}
       message={snackMsg}
     />
     
     <ErrorSnackbar
       open={errorSnackbar}
       handleClose={closeErrorSnackbar}
       message={snackMsg}
     />
     <LoadingForm loading={loadingForm}/>
     </div>
  )
}