import { Autocomplete, Dialog, IconButton, Skeleton, TextField, Tooltip } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { categoryListAPICall } from "../../Configuration/Category/CategoryAPI";
import { useSelector } from "react-redux";
import { useState } from "react";
import store from "../../../../../Redux/store";
import { get_inventory_product_row_list } from "../inventoryProductsSlice";
import { ProductListUpdatedFilterAPICall, deleteProductAPICall, exportProductListAPICall, importProductListAPICall, viewInventoryProductListAPICall } from "../inventoryProductsAPI";
import BrokenImageIcon from '../../../../../Assets/Images/brokenImg.png';
import ErrorSnackbar from "../../../../Single Components/SnackBars/ErrorSnackbar";
import exportIcon from "../../../../../Assets/Images/Icon awesome-file-export.png"
import importIcon from "../../../../../Assets/Images/Icon awesome-file-import.png"
import uploadFile from "../../../../../Assets/Images/Icon feather-upload.png";
import SuccessSnackbar from "../../../../Single Components/SnackBars/SuccessSnackbar";
import CategorySearchandFilter from "../../../../Single Components/CategorySearchandFilter";
import CustomPagination from "../../../../Single Components/CustomPagination";
import { getCurrentTime } from "../../../../../Js/Date";
import axios from "axios";
import { Source } from "@mui/icons-material";
import { setFilterActive } from "../../../../Single Components/listSlice/filterCategorySlice";
import ReactToPrint from "react-to-print";
import ExcelJS from "exceljs"

export const ProductListUpdated = () => {
    const filtereDataOptions = useSelector((state) => state.listCategory);
    let navigateTo = useNavigate()

    const tableData = useSelector((state) => state.inventoryProductSlice.apiResListProduct)
    const CategoryList = useSelector((state) => state.categorySlice.value);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [isLoading, setIsLoading] = useState(false)
    const [importPopup, setImportPopup] = useState(false)
    const [FormDataInfo, setFormDataInfo] = useState([])
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [fileToExport, setFileToExport] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = tableData?.pages;
    const [validationAlert, setValidationAlert] = useState({});
    const [filteredData, setFilteredData] = useState({});
    const [snackBarStates, setSnackBarStates] = useState({
        success: false,
        error: false,
        message: "",
        alert: false
    });

    const closeImportPopup = (e) => {
        setImportPopup(false)
        setFileToExport([])
    }
    const changeImage = (e) => {
        setFileToExport([...fileToExport, e.target.files[0]]);
        setValidationAlert({})
    };
    const closeSnackbar = () => {
        setSnackBarStates({
            ...snackBarStates,
            success: false,
            error: false,
            alert: false,
            message: "",
        });
    };
    const submitExportClick = () => {
        let newFormData = new FormData()

        // newFormData.append("_id",singleViewById)
        newFormData.append("file", fileToExport[0])

        if (fileToExport.length === 0) {
            setValidationAlert({ ...validationAlert, fileExport: "select a file !!" })
        }
        else {
            importProductListAPICall(newFormData, setSnackBarStates, snackBarStates, closeImportPopup,setIsLoading)
        }
    }
    const singleViewFn = (data) => {
        store.dispatch( setFilterActive(window.location.pathname))
        navigateTo("/userdashboard/inventory/products/product/create")
        localStorage.setItem("singleViewById", data?._id)
        // store.dispatch(get_inventory_product_row_list({inventoryProductSingleData:data}))
    }

    // select all products
    const handleSelectAllChange = () => {
        if (selectAllChecked) {
            setSelectedRows([]);
        } else {
            const allRowIds = tableData?.list?.map((row) => row._id);
            setSelectedRows(allRowIds);
        }
        setSelectAllChecked(!selectAllChecked);
    };
    const checkBoxClick = (id) => () => {
        if (selectedRows.includes(id)) {
            setSelectedRows(selectedRows.filter((selectedId) => selectedId !== id));
            setSelectAllChecked(false);
  
        } else {
            setSelectedRows([...selectedRows, id]);
        }

    };
    const handleDataFromChild = (data) => {
        setFormDataInfo(data)
    };
    const handlePageChange = (page) => {

        setCurrentPage(page);
    };
    const handleFilterChange = (updatedItems) => {
        setFilteredData(updatedItems);
      };
    const formatFilterInfo = (filters) => {
        const parts = [];
      
        if (filters?.toDate && filters.fromDate) {
          parts.push(`As of ${filters.fromDate} to ${filters?.toDate}`);
        }
        if (filters?.branchList) {
          parts.push(`Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`);
        }
       
        if (filters?.category) {
          parts.push(`Category: ${filters?.category?.categoryName}`);
        }
        if (filters?.posCategory) {
          parts.push(`POS Category: ${filters?.posCategory?.name}`);
        }
        return parts; 
      };
      const downloadList = () => {
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet("Product");
        sheet.properties.defaultRowHeight = 20;
      
        // Add header section
        const titleRow = sheet.addRow(["Product"]);
        titleRow.font = { size: 15, bold: true };
        sheet.mergeCells('A1:E1');
        titleRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
      
        // Add additional header information
        const filterParts = formatFilterInfo(filteredData) || [];
      
        // Ensure filterParts is an array and iterate over it
        if (Array.isArray(filterParts)) {
          filterParts.forEach((part, index) => {
            const row = sheet.addRow([part]);
            row.font = { size: 11, bold: true };
            sheet.mergeCells(`A${index + 2}:E${index + 2}`);
            row.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
          });
        }
      
        // Add an empty row between header and table
        sheet.addRow([]);
      
        // Header row styling
        const headerRow = sheet.addRow(["Product Id", "Product Name","Product Image","UOM","Variant"]);
        const headerFill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFedf2f9" }, // Use full hex code with "FF" prefix for better compatibility
        };
        const headerFont = {
          size: 12,
          bold: true,
          color: { argb: "FF000000" }, // White font color
        };
      
        headerRow.eachCell((cell, colNumber) => {
          cell.fill = headerFill;
          cell.font = headerFont;
          cell.alignment = { vertical: 'middle', horizontal: 'center' }; // Ensure header text is centered
        })
      
        sheet.columns = [
          { header: "Product Id", key: "prodId", width: 15},
          { header: "Product Name", key: "name", width: 20 },
          { header: "Product Image", key: "imageUrl", width: 25 },
          { header: "Product Master", key: "UOMNAME", width: 15 },
          { header: "Product", key: "attributes", width: 30 },
          // { header: "Delete", key: "locationName", width: 25 },
      
        ];
      
        tableData?.list?.forEach((item) => {
            const attributes = item?.attribute?.map(attr => `${attr.attribute}: ${attr.value}`).join(", ");
          const row = sheet.addRow({
            prodId: item?.prodId,
            name: item?.name,
            imageUrl: item?.imageUrl,
            UOMNAME: item?.UOMNAME, 
            attributes: attributes,   
          });
      
          const rowFill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFFFFF" }, // Use full hex code with "FF" prefix for better compatibility
          };
          
          row.eachCell((cell, colNumber) => {
            cell.fill = rowFill;
            cell.border = {
            //   top: { style: "thin", color: { argb: "FF000000" } },
            //   left: { style: "thin", color: { argb: "FF000000" } },
              bottom: { style: "thin", color: { argb: "FFedf2f9" } },
            //   right: { style: "thin", color: { argb: "FFFFFFFF" } },
            };
            if (colNumber >= 1) { // Right align all columns except the first
                cell.alignment = { horizontal: 'center' };
              }
             
          });
        });
      
        // Add row for totals
        // const totalRow = sheet.addRow([
        //   "Total",
        //   "",
        //   `${symbol} ${SingleListData?.sumOfCurrent.toFixed(2)}`,
        //   `${symbol} ${SingleListData?.sumOf30?.toFixed(2)}`,
        //   `${symbol} ${SingleListData?.sumOf60?.toFixed(2)}`,
        //   `${symbol} ${SingleListData?.sumOf90?.toFixed(2)}`,
        //   `${symbol} ${SingleListData?.sumOf120?.toFixed(2)}`,
        //   `${symbol} ${SingleListData?.sumOfAbove120?.toFixed(2)}`,
        //   `${symbol} ${SingleListData?.sumOfTotal?.toFixed(2)}`,
        // ]);
      
        // totalRow.eachCell((cell,colNumber) => {
        //   cell.font = { bold: true, color: { argb: "FF000000" } };
        //   cell.fill = headerFill;
        //   cell.border = {
        //     top: { style: "thin", color: { argb: "FFFFFFFF" } },
        //     left: { style: "thin", color: { argb: "FFFFFFFF" } },
        //     bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
        //     right: { style: "thin", color: { argb: "FFFFFFFF" } },
        //   };
        //   if (colNumber > 1) { // Right align all columns except the first
        //     cell.alignment = { horizontal: 'right' };
        //   }
        // });
      
        workbook.xlsx.writeBuffer().then(function (data) {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.download = "Product.xlsx";
          anchor.click();
          window.URL.revokeObjectURL(url);
        });
      };

    const handleDelete = (id) => {
        deleteProductAPICall({ id }, setSnackBarStates)
    }
    useEffect(() => {
        // viewInventoryProductListAPICall({}, setIsLoading, setSnackBarStates, snackBarStates)
        categoryListAPICall()
        localStorage.removeItem("singleViewById")
        store.dispatch(get_inventory_product_row_list({ inventoryProductSingleData: undefined }))
    }, [])
    const handleBranchSelect = (selectedBranch) => {
        setSelectedBranch(selectedBranch); 
      
      };
    useEffect(() =>     
    {
      
        if (FormDataInfo.length !== 0) {
           
            let loadData=window.location.pathname===filtereDataOptions?.filterActive
            !loadData &&  ProductListUpdatedFilterAPICall({
                branchList: FormDataInfo.branchList,
                mainCategory: FormDataInfo.category,
                posCategory: FormDataInfo.posCategory,
                search: FormDataInfo.search,
                index: currentPage - 1,
            }, setIsLoading);
        }
       
    }, [FormDataInfo, currentPage]);
    return (
        <>
            <div className="global-page-parent-container">

                <div className="global-white-bg-container">
                    <div className="create-button-blue-container" style={{ justifyContent: "space-between", alignItems: "center" }}>
                        <div className="export-actions-ctn">
                            <div
                                onClick={() => setImportPopup(true)}
                                className="export-actions-section">
                                <p>Import</p>
                                <div className="import">
                                    <img src={importIcon} alt="" />
                                </div>
                            </div>
                            <div
                                onClick={() => exportProductListAPICall({ productIds: selectAllChecked ? [] : selectedRows, noProduct: selectedRows?.length === 0 }, setSnackBarStates, snackBarStates)}
                                className="export-actions-section">
                                <p>Export</p>
                                <div className="export">
                                    <img src={exportIcon} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="justify-space-between"
                            style={{ gap: "0 12px", justifyContent: "flex-end" }}
                        >
                            <div style={{ display: "flex", alignItems: "center" }}>
                        <Tooltip title="Share">
                            <IconButton>
                              <i
                                class="bi bi-share"
                                style={{
                                  backgroundColor: "#9797e9",
                                  color: "white",
                                  fontSize: "medium",
                                  padding: "8px",
                                  borderRadius: "2px",
                                }}
                              ></i>
                            </IconButton>
                          </Tooltip>
          
                          <ReactToPrint
                            trigger={() => (
                              <IconButton
                                className="printer-div"
                                style={{ padding: "1px" }}
                              >
                                <i
                                  style={{
                                    color: "white",
                                    backgroundColor: " #d787e7",
                                    fontSize: "medium",
                                    padding: "8px",
                                    borderRadius: "2px",
                                  }}
                                  class="bi bi-printer"
                                ></i>
                              </IconButton>
                            )}
                            // content={() => paymentsPrintNew}
                          />
                          <Tooltip title="Download">
                            <IconButton 
                            onClick={downloadList}
                            >
                              <i
                                class="bi bi-arrow-down-circle"
                                style={{
                                  backgroundColor: "rgb(255 118 133 / 69%)",
                                  color: "white",
                                  fontSize: "medium",
                                  padding: "8px",
                                  borderRadius: "2px",
                                }}
                              ></i>
                            </IconButton>
                          </Tooltip>
                          <CategorySearchandFilter
                                onData={handleDataFromChild}
                                onBranchSelect={()=>{}}
                                isCategory={true}
                                isposCategory={true}
                                isDate={false}
                                isBranch={false}
                                onFilterChange={handleFilterChange}
                            />
                     
                    </div>
                            {/* <CategorySearchandFilter
                                onData={handleDataFromChild}
                                isCategory={true}
                                isposCategory={true}
                                isDate={false}
                                isBranch={false}
                                onBranchSelect={handleBranchSelect}
                            />
                            <button
                                className='create-button-blue'
                                onClick={() => {
                                    navigateTo("/userdashboard/inventory/products/product/create")
                                }}>
                                Create</button> */}
                                 <button
                                className='create-button-blue'
                                onClick={() => {
                                    navigateTo("/userdashboard/inventory/products/product/create")
                                }}>
                                Create</button>
                        </div>

                        {/* <button
                            className='create-button-blue'
                            onClick={() => {
                                navigateTo("/userdashboard/inventory/products/product/create")
                            }}>
                            Create</button> */}
                    </div>
                    <div className="justify-center" style={{ justifyContent: "flex-end" }}>
                        <CustomPagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                            setFormDataInfo={setFormDataInfo}
                        />
                    </div>
                    <div className="global-table-container check-box-table">
                        <table className="global-table">
                            <thead>
                                <tr>
                                    <th>Product Id</th>
                                    <th>Product Name</th>
                                    <th>Product Image</th>
                                    <th>UOM</th>
                                    <th>Variant</th>
                                    <th><input
                                        style={{ cursor: "pointer" }}
                                        type="checkbox"
                                        checked={selectAllChecked}
                                        onChange={handleSelectAllChange}
                                    /></th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    isLoading ? (
                                        [...Array(10)].map((r, i) => (
                                            <tr key={i}>
                                                <td colSpan={7}>
                                                    <Skeleton
                                                        variant="rectangular"
                                                        animation="wave"
                                                        width={"100%"}
                                                        height={40}
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                    )
                                        :
                                        (tableData?.list !== undefined && tableData?.list?.length !== 0) ?
                                            tableData?.list?.map((r, i) => (
                                                <tr key={i}>
                                                    <td onClick={() => singleViewFn(r)}>{r?.prodId}</td>
                                                    <td onClick={() => singleViewFn(r)}>{r?.name}</td>
                                                    <td onClick={() => singleViewFn(r)}>
                                                        <img style={{ width: "40px", height: "40px", marginTop: "8px" }}
                                                            src={(r?.imageUrl !== null && r?.imageUrl[0]) || BrokenImageIcon}
                                                            alt="no img" /></td>
                                                    <td onClick={() => singleViewFn(r)}>{r?.UOMNAME || "---"}</td>
                                                    <td onClick={() => singleViewFn(r)} className='table-data-box' style={{ textAlign: "center" }}>
                                                        <div className='table-data-round-parent'>

                                                            {
                                                                r?.attribute?.map((attribute, i) => (
                                                                    <div className='round-border'>{`${attribute?.attribute}: ${attribute?.value}`}</div>
                                                                ))
                                                            }
                                                        </div>
                                                    </td>
                                                    <td onClick={checkBoxClick(r?._id)}>
                                                        <input
                                                            style={{ cursor: "pointer" }}
                                                            checked={selectedRows.includes(r?._id)}

                                                            type="checkbox" />
                                                    </td>
                                                    <td>
                                                        <IconButton
                                                            onClick={() => handleDelete(r?._id)}
                                                        >
                                                            <i
                                                                class="bi bi-trash3 delete-icon"
                                                                style={{ fontSize: "15px" }}
                                                            >     </i>
                                                        </IconButton>
                                                    </td>
                                                </tr>
                                            )) :
                                            <tr>
                                                <td colSpan={7}>No data</td>
                                            </tr>
                                }
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
            <ErrorSnackbar
                open={snackBarStates.alert}
                handleClose={closeSnackbar}
                message={snackBarStates.message}
            />
            <SuccessSnackbar
                open={snackBarStates.success}
                handleClose={closeSnackbar}
                message={snackBarStates.message}
            />
            <ErrorSnackbar
                open={snackBarStates.error}
                handleClose={closeSnackbar}
                message={snackBarStates.message}
            />
            <Dialog
                open={importPopup}
                maxWidth="lg"
                onClose={closeImportPopup}
            >
                <div className='actions-select-dropdown-dialog'>
                    <h4 style={{ margin: "0" }}>Upload</h4>
                    <hr className="global-hr" />
                    <div className="file-picker-new" style={{ position: "relative", margin: "10px 0" }}>
                        <input
                            // disabled={formValues?.isEdit} 
                            type="file"
                            id="fileUpload"
                            accept=".xlsx"
                            onChange={changeImage}
                            disabled={fileToExport?.length !== 0}
                        />
                        <label htmlFor="fileUpload"
                            style={{ cursor: fileToExport?.length !== 0 && "not-allowed" }}
                        >
                            <img src={uploadFile} alt="" />
                            Upload File
                        </label>

                        <p style={{ top: "70%" }} className="doc-validation-alert">{validationAlert?.fileExport}
                        </p>
                    </div>

                    {fileToExport.length !== 0 &&
                        <div style={{ width: "100%" }} className="global-single-input">
                            <p>{fileToExport[0]?.name}
                                <sup
                                    onClick={() => setFileToExport([])}
                                    style={{ fontSize: "1rem", color: "red", fontWeight: "600", cursor: "pointer" }}>X</sup>
                            </p>
                        </div>}
                    <div className='justify-center'>
                        <button
                            onClick={submitExportClick}
                            className="btn btn-primary">Submit</button>
                        <button className="btn btn-secondary-outlined"
                            onClick={closeImportPopup}
                        >Cancel</button>
                    </div>
                    <ErrorSnackbar
                        open={snackBarStates.error}
                        handleClose={closeSnackbar}
                        message={snackBarStates.message}
                    />
                    <SuccessSnackbar
                        open={snackBarStates.success}
                        handleClose={closeSnackbar}
                        message={snackBarStates.message}
                    />
                </div>
            </Dialog>
        </>
    )
}