import React, { useEffect, useState } from 'react'
import { TextFieldComponent } from './CustomFields'
import { IconButton } from '@mui/material'
import { addOrderModelAPI, deleteOrderModelAPI, viewOrderModelAPI } from '../MeasurmentsAPI'
import { useSelector } from 'react-redux'

export const CreateModelDialog = ({
  closeDialog,
  addBtnFn,
  children,
  modelFormData,
  clearFormData
}) => {
 
  
    const newModelInitial={
            modelName: '',
            fromLength: '',
            toLength: '',
            minRate: '',
            commission: '',
    }
     const [newModel, setNewModel] = useState(newModelInitial);
     const handleInputChange = (key, value) => {
        const updatedData={ ...newModel, [key]: value }
        setNewModel(updatedData);
        modelFormData(updatedData)
      };
    

      useEffect(()=>{
         if(Object.keys(clearFormData).length === 0){
          setNewModel(newModelInitial)
         }
      },[clearFormData])
     
    
   
    
  return (
    <div className="add-model-dialog-container">
        <h3>Add Thob Model</h3>
        <div className='add-model-dialog-input-container'>
           <TextFieldComponent
             label="Model Name" 
             style={{width:"49%",margin: "12px 0px 12px 8px"}}
             fieldKey="modelName"
             value={newModel?.modelName}
             onChange={handleInputChange}
            
             />
             <TextFieldComponent
             label="Length From" 
             style={{width:"49%",margin: "12px 0px 12px 8px"}}
             fieldKey="fromLength"
             value={newModel?.fromLength}
             onChange={handleInputChange}
            
             />
             <TextFieldComponent
             label="To" 
             style={{width:"49%",margin: "12px 0px 12px 8px"}}
             fieldKey="toLength"
             value={newModel?.toLength}
             onChange={handleInputChange}
            
             />
             <TextFieldComponent
             label="Min Rate" 
             style={{width:"49%",margin: "12px 0px 12px 8px"}}
             fieldKey="minRate"
             value={newModel?.minRate}
             onChange={handleInputChange}
            
             />
             <TextFieldComponent
             label="Commission" 
             style={{width:"49%",margin: "12px 0px 12px 8px"}}
             fieldKey="commission"
             value={newModel?.commission}
             onChange={handleInputChange}
            
             />
                <button
                className='add-customer-plus-btn'
            //   disabled={thobModelForm?.modelName !== "" ? false : true}
            //   className={thobModelForm?.modelName !== "" ? "" : "disabled"}
                 onClick={addBtnFn}
            >
              +
            </button>
        </div>
        <div className="add-model-popup-table">
            <table>
              <thead>
                <tr>
                  <th>Model Name</th>
                  <th>Length(From-To)</th>
                  <th>Min Rate</th>
                  <th>Commission</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                 {children}
              </tbody>
            </table>
          </div>
          <div className="add-model-popup-button-container">
            <button
            onClick={() => closeDialog()}
              className="btn btn-secondary"
            >
              Close
            </button>
          </div>
    </div>
  )
}
